import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';

import { NorthRhineWestphaliaConstants } from 'library/common/commonConstants/northRhineWestphaliaConstants';

const searchFn = (searchKey) => {
  const lowerCaseKey = searchKey.toLowerCase();

  return NorthRhineWestphaliaConstants.filter(entry => {
    return Object.values(entry).some(value =>
      value.toString().toLowerCase().includes(lowerCaseKey)
    );
  });
}

export default function CityPicker({ setSelectedPlaces }) {

  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue && searchValue !== "") {
      const result = searchFn(searchValue);
      const mapped = result.map(city => {
        const cityData = Object.values(city).filter(fieldValue => fieldValue);
        return {
          name: city['Stadt'] ? cityData.join(', ') : city['PLZ'],
          id: city['PLZ'] + city['Stadt'],
        };
      });
      setOptions(mapped);
    }
  }, [searchValue]);

  //console.log(selectedValues);
  function onSelect(selectedList, selectedItem) {
    //console.log(selectedList);
    //console.log(selectedItem);
    setSelectedPlaces(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelectedPlaces(selectedList);
  }

  function onSearch(input) {
    setSearchValue(input);
  }

  return (<>
    <Multiselect
      options={options} // Options to display in the dropdown
      selectedValues={selectedValues} // Preselected value to persist in dropdown
      onSelect={onSelect} // Function will trigger on select event
      onSearch={onSearch}
      onRemove={onRemove}
      style={{
        searchBox: {
          backgroundColor: "white"
        }
      }}
      emptyRecordMsg=""
      //onRemove={this.onRemove} // Function will trigger on remove event
      displayValue="name" // Property name to display in the dropdown options
      placeholder='Ort'
    />
  </>);

}
