export function getCropByType({ type, groupInfo }) {
  const group = groupInfo || {};
  return type === 'logo'
    ? group.groupLogoXCoordinate || group.groupLogoXCoordinate === 0
      ? {
          url: group.logoUrl,
          x: group.groupLogoXCoordinate,
          y: group.groupLogoYCoordinate,
          width: group.groupLogoWidth,
          height: group.groupLogoWidth / group.groupLogoAspect,
          aspect: group.groupLogoAspect,
        }
      : {
          url: group.logoUrl,
          x: group.profileLogoXCoordinate,
          y: group.profileLogoYCoordinate,
          width: group.profileLogoWidth,
          height: group.profileLogoWidth / group.profileLogoAspect,
          aspect: group.profileLogoAspect,
        }
    : {
        url: group.headerUrl,
        x: group.groupHeaderXCoordinate,
        y: group.groupHeaderYCoordinate,
        width: group.groupHeaderWidth,
        height: group.groupHeaderWidth / group.groupHeaderAspect,
        aspect: group.groupHeaderAspect,
      };
}

export function getPathWithGroupId(path) {
  return path
    .split('/')
    .splice(0, 3)
    .join('/');
}

export function getLetterInLogo(groupName) {
  const [firstWord, secondWord] = groupName.trim().split(' ');

  return `${firstWord[0]}${secondWord ? secondWord[0] : ''}`;
}

export function isBazaarGroup(groupData) {
  return (
    groupData.groupName === 'Basar' &&
    groupData.groupDescription ===
      'Diese Gruppe ist für alle Nutzer, welche ihre Gegenstände auf dem Basar verkaufen möchten.'
  );
}

export function checkIsProviderRelevant(activeKita, group) {
  let isProviderRelevant = false;

  if (
    activeKita &&
    activeKita.kitaTypeTranslations &&
    activeKita.kitaTypeTranslations !== 'undefined'
  ) {
    const { kitaTypeTranslations } = activeKita;
    const providerRelevantGroups = [
      'Eltern',
      'Mitarbeitende',
      'Mitarbeiter',
      'Führungskräfte',
      'Führungskräfte Kita',
      'Führungskräfte MIB',
      'Elternbeiräte',
      'Sicherheit',
      'Vorschule',
    ];
    if (
      providerRelevantGroups.includes(group?.groupName) &&
      kitaTypeTranslations &&
      kitaTypeTranslations !== 'undefined' &&
      (kitaTypeTranslations.includes('Träger') || kitaTypeTranslations.includes('Provider'))
    ) {
      isProviderRelevant = true;
    }
  }

  return isProviderRelevant;
}
