export function checkLink(container) {
  const emailReg = /(^(http(s)?:\/\/)|^(www\.))[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/;
  const httpReg = new RegExp('^http', 'ig');

  const recursiveCheck = (nodes) => {
    const resultArr = [];

    nodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();

        if (tagName === 'img' || tagName === 'br' || node.hasAttribute('data-mention-id')) {
          resultArr.push(node.outerHTML);
        } else {
          const childNodesResult = recursiveCheck(node.childNodes);
          const currentStyle = node.getAttribute('style') || '';

          if (tagName === 'a') {
            const href = node.getAttribute('href') || '';
            const target = '_blank';
            resultArr.push(`<a target="${target}" href="${href}" style="${currentStyle}">${childNodesResult}</a>`);
          } else {
            resultArr.push(`<${tagName} style="${currentStyle}">${childNodesResult}</${tagName}>`);
          }
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        const wordsArr = node.nodeValue.split(' ');
        const wordResultArr = wordsArr.map((word) => {
          if (emailReg.test(word)) {
            const isHaveHttps = httpReg.test(word);
            const href = isHaveHttps ? word : `https://${word}`;
            return `<a target="_blank" href="${href}">${word}</a>`;
          } else {
            return word;
          }
        });

        resultArr.push(wordResultArr.join(' '));
      }
    });

    return resultArr.join(' ');
  };

  return recursiveCheck(container.childNodes);
}
