import React, { useState, useEffect } from 'react';

import Loader from 'library/common/commonComponents/Loader';
import { getKitas, setActiveKita } from 'library/utilities/kitaEffects';
import GroupsFrameList from '../GroupsFrameList';

export default function GroupsFrameListWrapper({
  activeKita,
  superAdminStatus,
  setCreateGroupMode,
  onlyPortfolioGroups
}) {
  const [selectedKita, setSelectedKita] = useState(null);
  useEffect(setActiveKita({ activeKita, setSelectedKita }), [activeKita]);
  const [kitas, setKitas] = useState([]);
  useEffect(getKitas({ setKitas }), []);

  return selectedKita ? (
    <GroupsFrameList
      kitas={kitas}
      selectedKita={selectedKita}
      setSelectedKita={setSelectedKita}
      superAdminStatus={superAdminStatus}
      setCreateGroupMode={setCreateGroupMode}
      onlyPortfolioGroups={onlyPortfolioGroups}
    />
  ) : (
    <Loader />
  );
}
