import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Loader from 'library/common/commonComponents/Loader';
import BlockedGroupsList from 'library/common/commonComponents/BlockedGroupsList';
import {
  deleteUserFromKita,
  getGroupsBeforeDeletion,
  getGroupsBeforeDeletionForAllKitas,
  deleteUserForAll,
} from 'library/api/user';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from '../../usersFrameList.module.scss';

export default function UserFrameDeletion({
  kitaId,
  deletionProfile,
  setEditingProfile,
  setDeletionProfile,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteForAllPopup, setDeleteForAllPopup] = useState(false);
  const [groupsForAllKitas, setGroupsForAllKitas] = useState([]);

  useEffect(() => {
    getGroupsBeforeDeletion({ userId: deletionProfile.id }).then(({ data }) => {
      setGroups(data);
      getGroupsBeforeDeletionForAllKitas({ userId: deletionProfile.id }).then(({ data }) => {
        setGroupsForAllKitas(data);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUser = () =>
    deleteUserHandler({
      kitaId,
      userId: deletionProfile.id,
      setEditingProfile,
      setDeletionProfile,
      isSubmitting,
      setIsSubmitting,
    });

  const deleteUserForAllKita = () => {
    setDeleteForAllPopup(true);
  };

  const deleteAll = () => {
    deleteUserForAll({ userId: deletionProfile.id }).then(res => {
      setIsSubmitting(false);
      setEditingProfile(null);
      setDeletionProfile(null);
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t('Administration.UserSection.Confirm user deletion')}</div>

        <div className={styles.deletionQuestion}>
          {t('Administration.UserSection.Are you sure that you want to delete following user?')}
        </div>
        <div className={styles.deletionFrame}>
          <div className={styles.deletionProfile}>
            <img src={defaultAvatar} alt='' className={styles.deletionProfileAvatar} />
            <div>
              <div className={cn(styles.deletionProfileName, 'notranslate')}>
                {deletionProfile.firstName} {deletionProfile.lastName}
              </div>
              <div className={cn(styles.deletionProfileEmail, 'notranslate')}>
                {deletionProfile.email}
              </div>
            </div>
          </div>

          <div
            className={styles.deletionProfileText}
            style={{ fontWeight: 'bolder', fontSize: '22px', color: 'red' }}
          >
            <i className={cn('fa fa-exclamation-triangle fa-2x', styles.deletionProfileIcon)} />
            {t(
              'Administration.UserSection.All the personal data of this user will be irrevocably deleted',
            )}
          </div>
          <div className={styles.deletionProfileText}>
            {isLoading ? (
              <Loader />
            ) : groups.length > 0 ? (
              <BlockedGroupsList
                text={t('Administration.UserSection.The user is the owner of these spaces')}
                groups={groups}
              />
            ) : (
              <>{t('Administration.UserSection.This user owns no spaces')}</>
            )}
          </div>
        </div>

        <div className={styles.deletionButtons}>
          {!isLoading && groups.length === 0 && (
            <>
              <ButtonWithLoader type='danger' onClick={deleteUser} isLoading={isSubmitting}>
                {t('AccountSettings.Delete account')}
              </ButtonWithLoader>
              <ButtonWithLoader
                type='danger'
                onClick={() => deleteUserForAllKita(true)}
                isLoading={isSubmitting}
              >
                {t('AccountSettings.Delete account for All Kita')}
              </ButtonWithLoader>
            </>
          )}

          <Button type='primary' onClick={() => setEditingProfile(deletionProfile)}>
            {t('Popup.Cancel')}
          </Button>
        </div>
      </div>
      <Popup
        isOpened={deleteForAllPopup}
        closePopup={() => setDeleteForAllPopup(false)}
        header={t('AccountSettings.Delete account for All Kita')}
        body={
          <div className={styles.container}>
            <div className={styles.title}>
              {t('Administration.UserSection.Confirm user deletion')}
            </div>

            <div className={styles.deletionQuestion}>
              {t('Administration.UserSection.Are you sure that you want to delete following user?')}
            </div>
            <div className={styles.deletionFrame}>
              <div className={styles.deletionProfile}>
                <img src={defaultAvatar} alt='' className={styles.deletionProfileAvatar} />
                <div>
                  <div className={cn(styles.deletionProfileName, 'notranslate')}>
                    {deletionProfile.firstName} {deletionProfile.lastName}
                  </div>
                  <div className={cn(styles.deletionProfileEmail, 'notranslate')}>
                    {deletionProfile.email}
                  </div>
                </div>
              </div>

              <div
                className={styles.deletionProfileText}
                style={{ fontWeight: 'bolder', fontSize: '22px', color: 'red' }}
              >
                <i className={cn('fa fa-exclamation-triangle fa-2x', styles.deletionProfileIcon)} />
                {t(
                  'Administration.UserSection.All the personal data of this user will be irrevocably deleted',
                )}
              </div>
              <div className={styles.deletionProfileText}>
                {isLoading ? (
                  <Loader />
                ) : groupsForAllKitas.length > 0 ? (
                  <BlockedGroupsList
                    text={t(
                      'Administration.UserSection.The user is the owner of these spaces around all the kitas',
                    )}
                    groups={groupsForAllKitas}
                  />
                ) : (
                  <>{t('Administration.UserSection.This user owns no spaces')}</>
                )}
              </div>
            </div>

            <div className={styles.deletionButtons}>
              {!isLoading && groupsForAllKitas.length === 0 && (
                <ButtonWithLoader
                  type='danger'
                  onClick={() => deleteAll()}
                  isLoading={isSubmitting}
                >
                  {t('AccountSettings.Delete account for All Kita')}
                </ButtonWithLoader>
              )}
              <Button type='primary' onClick={() => setDeleteForAllPopup(false)}>
                {t('Popup.Cancel')}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}

export async function deleteUserHandler({
  kitaId,
  userId,
  setEditingProfile,
  setDeletionProfile,
  isSubmitting,
  setIsSubmitting,
}) {
  if (isSubmitting) {
    return;
  }
  setIsSubmitting(true);
  await deleteUserFromKita({ kitaId, userId });
  setIsSubmitting(false);
  setEditingProfile(null);
  setDeletionProfile(null);
}
