import React from 'react';
import styles from './employeeReviewManualCheckInOut.module.scss';
import { useTranslation } from 'react-i18next';
import { reviewManualEmployeeCheckInOut } from 'library/api/employee';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import ReviewButtons from 'library/common/commonComponents/Buttons/ReviewButtons';

export default function EmployeeReviewManualCheckInOut({
  unreviewedCheckInOuts,
  setUnreviewedCheckInOuts,
  setIsLoading,
}) {
  const { t } = useTranslation();

  const handleReviewButtonClick = async (employeeCheckInId, employeeCheckOutId, reviewStatus) => {
    const checkInOut = {
      employeeCheckInId,
      employeeCheckOutId,
      reviewStatus,
    };

    setIsLoading(true);
    try {
      await reviewManualEmployeeCheckInOut(checkInOut);
      // remove reviewed checkin and checkout from unreviewedCheckInOuts array, skip refetching
      setUnreviewedCheckInOuts(
        unreviewedCheckInOuts.filter(
          checkInOut => checkInOut.id !== employeeCheckInId && checkInOut.id !== employeeCheckOutId,
        ),
      );
      setIsLoading(false);
      store.dispatch(
        showBottomNotification(
          reviewStatus === 'APPROVED'
            ? t('EmployeeCheckinout.Working time approved')
            : t('EmployeeCheckinout.Working time rejected'),
          {
            isSuccess: true,
          },
        ),
      );
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formateDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      weekday: 'short',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const formateTime = dateString => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className={styles.container}>
      <h2>{t('EmployeeCheckinout.Review manual working time entries')}</h2>

      {unreviewedCheckInOuts.length !== 0 ? (
        <table className={styles.holidaysTable}>
          <thead className={styles.holidaysTableHeader}>
            <tr>
              <th className={styles.nameColumn}>{t('EmployeeCheckinout.Employee')}</th>
              <th className={styles.periodColumn}>{`${t('EmployeeCheckinout.Period')} / ${t(
                'EmployeeCheckinout.Reason',
              )}`}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {unreviewedCheckInOuts.map((checkInOut, index, array) => {
              // return row every second iteration to group checkin and checkout
              if (index % 2 === 1) {
                return (
                  <tr key={checkInOut.id}>
                    <td>{checkInOut.name}</td>
                    <td>
                      {`${formateTime(array[index - 1].actionDate)} -
                        ${formateTime(checkInOut.actionDate)} |
                        ${formateDate(checkInOut.actionDate)}`}
                      <br />
                      <span className={styles.reason}>{checkInOut.note}</span>
                    </td>
                    <td>
                      <ReviewButtons
                        handleReviewButtonClick={(id, status) =>
                          handleReviewButtonClick(id.checkInId, id.checkOutId, status)
                        }
                        id={{
                          checkInId: array[index - 1].id,
                          checkOutId: checkInOut.id,
                        }}
                        reviewStatus={checkInOut.reviewStatus}
                      />
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.noManualCheckInOuts}>
          {t('EmployeeCheckinout.All manual working time entries are reviewed')}
        </p>
      )}
    </div>
  );
}
