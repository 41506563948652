import React, { useEffect } from 'react';

import CreateGroup from 'library/common/commonComponents/CreateGroup/CreateGroupContainer';
import CreateKita from 'library/common/commonComponents/CreateKita/CreateKitaContainer';
import Topbar from './headerFrames/Topbar';
import Bottombar from './headerFrames/Bottombar';

import styles from './header.module.scss';

export default function Header({
  history,
  user,
  isCreateGroupMode,
  isCreateKitaMode,
  setCreateKitaMode,
  openNewChatWithRecipient,
  superAdminStatus,
  setSelectedChatId,
  modules,
  showBottomNotification,
  activeKita,
  checkInOutActiveForParentsSignal,
  timeRangeAbsenceInfo
}) {
  useEffect(() => {
    document.querySelector('body').classList.add('not-login');

    return () => document.querySelector('body').classList.remove('not-login');
  }, []);

  return activeKita && (!user.qrCodeScanner) ? (
    <div className={styles.header}>
      <Topbar
        history={history}
        user={user}
        setSelectedChatId={setSelectedChatId}
        showBottomNotification={showBottomNotification}
        activeKita={activeKita}
        modules={modules}
        checkInOutActiveForParentsSignal={checkInOutActiveForParentsSignal}
        openNewChatWithRecipient={openNewChatWithRecipient}
        timeRangeAbsenceInfo={timeRangeAbsenceInfo}
      />
      <Bottombar
        modules={modules}
        kitaId={activeKita.kitaId}
        kitaName={activeKita.kitaName}
        kitaDescription={activeKita.description}
        isVirtualKita={activeKita.virtual}
        kitaAdmin={user.administrationAccess}
        employee={user.employee}
        userEmail={user.email}
      />
      {isCreateGroupMode && <CreateGroup />}
      {isCreateKitaMode && superAdminStatus && (
        <CreateKita closePopup={() => setCreateKitaMode(false)} />
      )}
    </div>
  ) : user.qrCodeScanner ?
    (<div className={styles.header}>
      <Topbar
        history={history}
        user={user}
        setSelectedChatId={setSelectedChatId}
        showBottomNotification={showBottomNotification}
        activeKita={activeKita}
        modules={modules}
        checkInOutActiveForParentsSignal={checkInOutActiveForParentsSignal}
        openNewChatWithRecipient={openNewChatWithRecipient}
        timeRangeAbsenceInfo={timeRangeAbsenceInfo}
      />
    </div>) : null;
}
