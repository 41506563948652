import React, { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getAllKitasWithAllData } from 'library/api/kita';
import { createSurvey } from 'library/api/surveys';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import FilePreview from 'library/common/commonComponents/FilePrewiev';
import Label from 'library/common/commonComponents/Label';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import ProviderPostPopup from 'library/common/commonComponents/Popups/ProviderPostPopup';
import SurveyBody from 'library/common/commonComponents/SurveyBody';
import useBlockShowing from 'library/common/commonHooks/useBlockShowing';
import useEditor from 'library/common/commonHooks/useEditor';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';
import { validateSurvey, changeOrderIfSurvey } from 'library/utilities/surveys';
import { checkLink } from 'library/utilities/checkLink';
import { checkIsProviderRelevant } from 'library/utilities/groups';

import styles from './surveysCreateInput.module.scss';
import { providerReducer } from '../CreatePostInput';

export function answersReducer(state, action) {
  switch (action.type) {
    case 'addAnswer':
      return state.concat({ optionName: '', id: Math.random() });
    case 'updateAnswerById':
      return state.map(answer =>
        answer.id === action.id
          ? {
              ...answer,
              optionName: action.optionName,
            }
          : answer,
      );
    case 'moveDown':
      const newStateDown = [...state];
      const tempItem = newStateDown[action.index];
      newStateDown[action.index] = newStateDown[action.index + 1];
      newStateDown[action.index + 1] = tempItem;
      return newStateDown;
    case 'moveUp':
      const newStateUp = [...state];
      const tempItem1 = newStateUp[action.index];
      newStateUp[action.index] = newStateUp[action.index - 1];
      newStateUp[action.index - 1] = tempItem1;
      return newStateUp;
    case 'resetAnswers':
      return [{ optionName: '', id: Math.random() }, { optionName: '', id: Math.random() }];
    case 'deleteAnswerById':
      return state.filter(answer => answer.id !== action.id);
    default:
      return state;
  }
}

export default function SurveysCreateInput({ groupInfo, activeKita, onCreate }) {
  const { t } = useTranslation();

  const canBePublic = false; // getCanCreatePublicPost(groupInfo);
  const [question, setQuestion] = useState({ value: '', raw: '' });
  const [multipleAnswerStatus, setMultipleAnswerStatus] = useState(false);
  const [displayRandomOrderStatus, setDisplayRandomOrderStatus] = useState(false);
  const [anonymousVotesStatus, setAnonymousVotesStatus] = useState(false);
  const [anonymousVotesForAllStatus, setAnonymousVotesForAllStatus] = useState(false);
  const [hideResultStatus, setHideResultStatus] = useState(false);
  const [allowUserAddSuggestionStatus, setAllowUserAddSuggestionStatus] = useState(false);
  const [endTimeStatus, setEndTimeStatus] = useState(false);
  const [endTimeDate, setEndTimeDate] = useState(Date.now());
  const [startTimeStatus, setStartTimeStatus] = useState(false);
  const [startTimeDate, setStartTimeDate] = useState(Date.now());
  const [isPublic, setIsPublic] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [reminders, setReminders] = useState([]);

  const [providers, dispatchProviders] = useReducer(providerReducer, []);

  let isProviderRelevant = checkIsProviderRelevant(activeKita, groupInfo);

  useEffect(() => {
    if (isProviderRelevant) {
      getAllKitasWithAllData().then(res => {
        // eslint-disable-next-line no-unused-vars
        const kitaArray = res.data.content.map(kita => {
          return {
            id: kita.id,
            label: kita.kitaName,
            providerId: kita.providerId,
            selected: false,
            kitaProviderStatus: kita.kitaProviderStatus,
          };
        });
        const providerArray = [];

        // eslint-disable-next-line array-callback-return
        kitaArray.map(kita => {
          if (kita.providerId === activeKita.kitaId && kita.kitaProviderStatus === 'approved') {
            providerArray.push(kita);
          }
        });
        dispatchProviders({ type: 'set', providers: providerArray });
      });
    }
  }, []);

  const [surveyOptions, dispatch] = useReducer(answersReducer, [
    { optionName: '', id: Math.random() },
    { optionName: '', id: Math.random() },
  ]);

  const { Editor, getEditorValue, resetEditorValue } = useEditor({
    placeholder: t('Surveys.Ask something'),
    onChange: () => {
      const value = getEditorValue();
      setQuestion({ value: value.textContent, raw: value });
    },
  });

  const { blockClassNames, showBlock, hideBlock } = useBlockShowing();
  const { files, uploadFile, deleteFiles } = useMultipleUpload();

  useEffect(() => {
    if (!canBePublic) {
      // setIsPublic(false);
    }
  }, [canBePublic]);

  const moreBtnDropdownOptions = getMoreBtnDropdownOptions(isPublic, setIsPublic, t);

  async function popUp() {
    setOpenPopup(true);
  }

  const onSubmit = () => {
    let receivers = [];
    if (providers) {
      receivers = providers.filter(provider => provider.selected).map(provider => provider.id);
    }

    return onSubmitHandler({
      groupId: groupInfo.groupId,
      multipleAnswerStatus,
      displayRandomOrderStatus,
      anonymousVotesStatus,
      anonymousVotesForAllStatus,
      hideResultStatus,
      allowUserAddSuggestionStatus,
      endTimeStatus,
      endTimeDate,
      startTimeStatus,
      startTimeDate,
      reminders,
      receivers,
      isPublic,
      question,
      surveyOptions,
      files,
      deleteFiles,
      setErrors,
      t,
      onCreate,
      dispatch,
      setQuestion,
      setMultipleAnswerStatus,
      setDisplayRandomOrderStatus,
      setAnonymousVotesStatus,
      setAnonymousVotesForAllStatus,
      setHideResultStatus,
      setAllowUserAddSuggestionStatus,
      setEndTimeStatus,
      setEndTimeDate,
      setStartTimeStatus,
      setStartTimeDate,
      setReminders,
      hideBlock,
      setIsSending,
      isSending,
      setOpenPopup,
      resetEditorValue,
    });
  };

  return (
    <div className={styles.container} onClick={showBlock}>
      <Editor />
      <div className={cn(styles.body, ...blockClassNames)}>
        <SurveyBody
          surveyOptions={surveyOptions}
          dispatch={dispatch}
          isEditing={false}
          multipleAnswerStatus={multipleAnswerStatus}
          anonymousVotesStatus={anonymousVotesStatus}
          anonymousVotesForAllStatus={anonymousVotesForAllStatus}
          hideResultStatus={hideResultStatus}
          displayRandomOrderStatus={displayRandomOrderStatus}
          allowUserAddSuggestionStatus={allowUserAddSuggestionStatus}
          endTimeStatus={endTimeStatus}
          endTimeDate={endTimeDate}
          startTimeStatus={startTimeStatus}
          startTimeDate={startTimeDate}
          setMultipleAnswerStatus={setMultipleAnswerStatus}
          setAnonymousVotesStatus={setAnonymousVotesStatus}
          setAnonymousVotesForAllStatus={setAnonymousVotesForAllStatus}
          setHideResultStatus={setHideResultStatus}
          setDisplayRandomOrderStatus={setDisplayRandomOrderStatus}
          setAllowUserAddSuggestionStatus={setAllowUserAddSuggestionStatus}
          setEndTimeStatus={setEndTimeStatus}
          setEndTimeDate={setEndTimeDate}
          setStartTimeStatus={setStartTimeStatus}
          setStartTimeDate={setStartTimeDate}
          reminders={reminders}
          setReminders={setReminders}
        />
        <div className={styles.errors}>
          {errors.map(error => (
            <div className={styles.error} key={error}>
              {error}
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {isProviderRelevant && (
              <ButtonWithLoader type='info' onClick={popUp}>
                <i className='fa fa-paper-plane' />
              </ButtonWithLoader>
            )}
            {!isProviderRelevant && (
              <ButtonWithLoader type='info' onClick={onSubmit} isLoading={isSending}>
                {t('Surveys.Ask')}
              </ButtonWithLoader>
            )}
            <UploadButton
              className={styles.uploadButton}
              onChange={uploadFile}
              isMultiple
              files={files}
            />
          </div>
          {openPopup && isProviderRelevant && (
            <ProviderPostPopup
              providers={providers}
              isOpened
              closePopup={() => setOpenPopup(false)}
              submit={onSubmit}
              isLoading={isSending}
              dispatchProviders={dispatchProviders}
            />
          )}
          <div className={styles.buttonsRight}>
            {isPublic && <Label type='info'>{t('Post.Public')}</Label>}

            {canBePublic && (
              <MoreBtn
                dropdownOptions={moreBtnDropdownOptions}
                faIcon='fa-cogs'
                className={styles.moreBtn}
              />
            )}
          </div>
        </div>

        <FilePreview newFiles={files} onDelete={deleteFiles} />
      </div>
    </div>
  );
}

export function getMoreBtnDropdownOptions(isPublic, setIsPublic, t) {
  return [
    {
      faIcon: isPublic ? 'fa-lock' : 'fa-unlock',
      title: isPublic ? t('Post.Make private') : t('Post.Make public'),
      onClick() {
        setIsPublic(!isPublic);
      },
    },
  ];
}

export async function onSubmitHandler({
  groupId,
  multipleAnswerStatus,
  displayRandomOrderStatus,
  anonymousVotesStatus,
  anonymousVotesForAllStatus,
  hideResultStatus,
  allowUserAddSuggestionStatus,
  endTimeStatus,
  endTimeDate,
  startTimeStatus,
  startTimeDate,
  reminders,
  receivers,
  isPublic,
  question,
  surveyOptions,
  files,
  deleteFiles,
  setErrors,
  t,
  onCreate,
  dispatch,
  setQuestion,
  setMultipleAnswerStatus,
  setDisplayRandomOrderStatus,
  setAnonymousVotesStatus,
  setAnonymousVotesForAllStatus,
  setHideResultStatus,
  setAllowUserAddSuggestionStatus,
  setEndTimeStatus,
  setEndTimeDate,
  setStartTimeStatus,
  setStartTimeDate,
  setReminders,
  hideBlock,
  setIsSending,
  isSending,
  setOpenPopup,
  resetEditorValue,
}) {
  const errors = validateSurvey({ t, question: question.value, surveyOptions });
  if (isSending) {
    return;
  }
  if (errors.length) {
    return setErrors(errors);
  }
  setErrors([]);

  setIsSending(true);

  const { data } = await createSurvey({
    groupId: groupId || 0,
    allowUserAddSuggestionStatus,
    endTimeStatus,
    endTimeDate,
    startTimeStatus,
    startTimeDate,
    reminders,
    anonymousVotesStatus,
    anonymousVotesForAllStatus,
    displayRandomOrderStatus,
    hideResultStatus,
    multipleAnswerStatus,
    files,
    privatePost: false,
    question: checkLink(question.raw),
    options: surveyOptions
      .map(answer => answer.optionName)
      .filter(answer => answer.trim().length > 0),
    receivers,
    moduleId: 4,
  }).catch(err => {
    setErrors([t(err.response.data.message)]);
    return { data: null };
  });
  if (data === null) {
    setIsSending(false);
    return;
  }
  onCreate(changeOrderIfSurvey(data));

  setIsSending(false);
  setOpenPopup(false);
  dispatch({ type: 'resetAnswers' });
  setQuestion({ value: '', raw: '' });
  setMultipleAnswerStatus(false);
  setDisplayRandomOrderStatus(false);
  setAnonymousVotesStatus(false);
  setAnonymousVotesForAllStatus(false);
  setHideResultStatus(false);
  setAllowUserAddSuggestionStatus(false);
  setEndTimeStatus(false);
  setEndTimeDate(Date.now());
  setStartTimeStatus(false);
  setStartTimeDate(Date.now());
  setReminders([]);
  hideBlock();
  deleteFiles();
  resetEditorValue();
}
