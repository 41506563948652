import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './employeeAbsencesOverview.module.scss';
import cn from 'classnames';
import ReviewButtons from 'library/common/commonComponents/Buttons/ReviewButtons';
import { useSelector } from 'react-redux';
import Tooltip from 'library/common/commonComponents/Tooltip';

export default function EmployeeAbsencesOverview({
  employeeAbsences,
  workingDaysPerUser,
  handleReviewButtonClick,
  handleSubmitUpdateAbsence,
}) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const user = useSelector(state => state.userReducer);
  const [editAbsence, setEditAbsence] = useState('');

  const formatStartEndDate = unformattedData => {
    const date = new Date(unformattedData);
    return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const getWorkingDaysCount = (start, end, userId) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    let count = 0;
    const curDate = new Date(startDate.getTime());
    if (workingDaysPerUser?.[userId]) {
      while (curDate <= endDate) {
        const dayOfWeek = curDate
          .toLocaleDateString('en-US', { weekday: 'long' })
          .toLocaleLowerCase();
        if (workingDaysPerUser[userId][dayOfWeek] !== 0) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
    }
    return count;
  };

  return (
    <section className={styles.holidaysHistorySection}>
      <h2>{t('Checkinout.AbsencesOverview')}</h2>

      <table className={styles.absencesTable}>
        <thead className={styles.holidaysTableHeader}>
          <tr>
            <th className={styles.statusColumn}>{t('Checkinout.Status')}</th>
            <th className={styles.nameColumn}>{t('Checkinout.Full Name')}</th>
            <th className={styles.periodColumn}>{t('Checkinout.Period')}</th>
            <th className={styles.daysColumn}>{t('Checkinout.Days')}</th>
            <th className={styles.typeColumn}>{t('Checkinout.Type')}</th>

            {user.administrationAccess ? <th className={styles.reviewColumn} /> : null}
          </tr>
        </thead>
        <tbody>
          {employeeAbsences
            ? employeeAbsences.map(absence => {
                return (
                  <tr key={absence.id}>
                    <td
                      className={cn(
                        styles.statusColumn,
                        absence.status === 'APPROVED' && styles.statusAccepted,
                        absence.status === 'REJECTED' && styles.statusDeclined,
                        absence.status === 'OPEN' && styles.statusOpen,
                      )}
                    >
                      <i
                        className={cn(
                          'fa',
                          'fa-2x',
                          absence.status === 'APPROVED' && 'fa-check-circle',
                          absence.status === 'REJECTED' && 'fa-minus-circle',
                          absence.status === 'OPEN' && 'fa-question-circle',
                        )}
                        aria-hidden='true'
                      />
                    </td>
                    <td>{`${absence.user.firstName} ${absence.user.lastName}`}</td>
                    <td className={cn(editAbsence === absence.id && styles.highlightField)}>
                      {editAbsence !== absence.id ? (
                        `${formatStartEndDate(absence.startDate)} - ${formatStartEndDate(
                          absence.endDate,
                        )}`
                      ) : (
                        <div className={styles.dateInputsContainer}>
                          <div className={styles.datePickerContainer}>
                            <div className={styles.dateStart}>
                              <Label type='input'>{t('Calendar.Start Date')}</Label>
                              <DatePicker
                                selected={startDate}
                                onChange={date => {
                                  setStartDate(date);
                                }}
                                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                                minDate={new Date().setDate(new Date().getDate() - 20)}
                              />
                            </div>

                            <div className={styles.containerFromTo}>
                              <div className={styles.fromToSign}>-</div>
                            </div>
                            <div className={styles.dateStart}>
                              <Label type='input'>{t('Calendar.End Date')}</Label>
                              <DatePicker
                                selected={endDate}
                                onChange={date => {
                                  setEndDate(date);
                                }}
                                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                                minDate={new Date().setDate(new Date().getDate() - 20)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {editAbsence === absence.id ? (
                        <div className={styles.confirmCancelContainer}>
                          <Button
                            className={styles.confirmCancelButton}
                            size='sm'
                            onClick={() => {
                              setEditAbsence('');
                              setStartDate('');
                              setEndDate('');
                            }}
                          >
                            <i className='fa fa-times' />
                            {t('Checkinout.Cancel')}
                          </Button>

                          <Button
                            className={styles.confirmCancelButton}
                            size='sm'
                            onClick={() => {
                              if (!startDate || !endDate) return;

                              handleSubmitUpdateAbsence(
                                absence.id,
                                absence.user.id,
                                startDate,
                                endDate,
                              );
                            }}
                            type='primary'
                          >
                            <i className='fa fa-check' />
                            {t('Checkinout.Confirm')}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          size='sm'
                          onClick={() => {
                            setStartDate(new Date(absence.startDate));
                            setEndDate(new Date(absence.endDate));
                            setEditAbsence(absence.id);
                          }}
                          className={styles.editAbsenceButton}
                        >
                          <i className='fa fa-pencil' />
                        </Button>
                      )}
                    </td>
                    <td className={styles.daysColumn}>
                      {getWorkingDaysCount(absence.startDate, absence.endDate, absence.user.id)}
                    </td>
                    <td className={styles.typeColumn}>
                      <Tooltip
                        text={
                          absence.vacation ? t('Checkinout.Vacation') : t('Checkinout.Sick note')
                        }
                        className={styles.tooltip}
                      >
                        <span>
                          <i
                            className={cn(
                              'fa',
                              'fa-lg',
                              absence.vacation ? 'fa-plane' : 'fa-frown-o',
                            )}
                            aria-label={
                              absence.vacation
                                ? t('Checkinout.Vacation')
                                : t('Checkinout.Sick note')
                            }
                          />
                        </span>
                      </Tooltip>
                    </td>
                    {user.administrationAccess && (
                      <td>
                        <ReviewButtons
                          handleReviewButtonClick={handleReviewButtonClick}
                          id={absence.id}
                          reviewStatus={absence.status}
                        />
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </section>
  );
}
