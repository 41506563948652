import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getGroupsOfKita, addUsersToGroup } from 'library/api/groups';
import {
  downloadExcel,
  selectedUsersBulkDeletionFromKita,
  getChildrenByUserId,
  deactivateUserInKita,
  deactivateKid,
  addKid,
  approveChildByKitaId,
  allChristusErloeserConsentsToExcel,
  allKitaKidListToExcel,
} from 'library/api/user';
import { inviteUsersAPI } from 'library/common/commonActions/groupActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Checkbox from 'library/common/commonComponents/Checkbox';
import HelpText from 'library/common/commonComponents/HelpText';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import InviteUsersToKitaPopup from 'library/common/commonComponents/Popups/InviteUsersToKitaPopup';
import Pagination from 'library/common/commonComponents/Pagination';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextCaret from 'library/common/commonComponents/TextCaret';
import Dropdown from 'library/common/commonComponents/Dropdowns/Dropdown';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import { downloadFile } from 'library/utilities/files';
import { invitationService } from 'modules/Administration/administrationActions';
import store from 'main/store/configureStore';

import UsersFrameListTable from '../UsersFrameListTable';
import UsersFrameImportPopup from './UsersFrameListResultsFrames/UsersFrameImportPopup/UsersFrameImportPopupContainer';
import MultiUserSelectionPopup from './UserSelectionPopup';
import styles from '../../usersFrameList.module.scss';

export default function UsersFrameListResults({
  activeKita,
  users,
  togglePermission,
  loadPage,
  isLoading,
  history,
  location,
  totalUsersCount,
  searchValue,
  setSearchValue,
  setSorting,
  sort,
  selectedOption,
  setSelectedOption,
  options,
  setEditingProfile,
  setDeletionProfile,
  kitas,
  selectedKita,
  setSelectedKita,
  superAdminStatus,
  falsePermissions,
  toggleAllPermissions,
  reloadPage,
  loggedInUser,
  setSelectedUsers,
  selectedUsers,
}) {
  const [isExportDropdownOpened, setIsExportDropdownOpened] = useState(false);
  const [isInvitaionModal, setIsInvitationModal] = useState(false);
  const [isImportModalOpened, setIsImportModalOpened] = useState(false);
  const [confirmPopupOpened, setConfirmPopupOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deletingUsers, setDeletingUsers] = useState([]);
  const [showOnlyParents, setShowOnlyParents] = useState(false);
  const [groupAdditionPopupOpened, setGroupAdditionPopupOpened] = useState(false);
  const [movePopupOpened, setMovePopupOpened] = useState(false);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const [moveUsers, setMoveUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [kitaGroups, setKitaGroups] = useState([]);
  const [moveSelectedKita, setMoveSelectedKita] = useState();
  const [moveUserErrorMsg, setMoveUserErrorMsg] = useState('');
  const [showMoveUserConfirmPopup, setShowMoveUserConfirmPopup] = useState(null);
  const [shouldDeactivatedUser, setShouldDeactivatedUser] = useState(false);
  const [userRoleChangePopupOpened, setUserRoleChangePopupOpened] = useState(false);
  const [userRole, setUserRole] = useState(null);

  const { t } = useTranslation();
  const { DropdownWrapper } = useDropdown(Dropdown);

  const multiActionDropdownOptions = [
    {
      faIcon: 'fa-exchange',
      title: t('Administration.Kita.Move'),
      onClick: () => handleMoveUsersToGroupButtonClick(),
    },
    {
      faIcon: 'fa-trash',
      title: t('Administration.Kita.Delete'),
      onClick: () => handleOpenConfirmPopup(),
    },
    {
      faIcon: 'fa-user-plus',
      title: t('Administration.Kita.AddToGroup'),
      onClick: () => handleAddUserToGroupButtonClick(),
    },
    {
      faIcon: 'fa-user-plus',
      title: t('Administration.Kita.Kita Admins'),
      onClick: () => handleUserRoleChangePopupOpen('kita-admin'),
    },
    {
      faIcon: 'fa-user',
      title: t('Administration.Kita.Employees'),
      onClick: () => handleUserRoleChangePopupOpen('kita-employee'),
    },
  ];

  const handleUserSelection = userId => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleDeletingUserSelection = deletingUser => {
    if (deletingUsers.includes(deletingUser)) {
      const tempUsers = deletingUsers.filter(user => user.user.id !== deletingUser.user.id);
      setDeletingUsers(tempUsers);
    } else {
      const user = users.find(user => user.user.id === deletingUser.user.id);
      setDeletingUsers([...deletingUsers, user]);
    }
  };

  const handleToggleAllUsersSelection = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map(user => user.user.id));
    }
  };

  const downloadTable = type =>
    downloadTableHandler({
      type,
      kitaId: selectedKita.value,
      sort,
    });

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleOpenConfirmPopup = () => {
    const tempUsers = selectedUsers.map(id => {
      const user = users.find(user => user.user.id === id);
      return user ? user : null;
    });
    setDeletingUsers(tempUsers);

    setConfirmPopupOpened(true);
  };

  kitas.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const handleSubmitBulkDeletion = () => {
    setIsSubmitting(true);
    const userIds = deletingUsers.map(user => user.user.id);
    selectedUsersBulkDeletionFromKita({ userIds, kitaId: selectedKita.value }).then(() => {
      setIsSubmitting(false);
      setConfirmPopupOpened(false);
      setSelectedUsers([]);
      setDeletingUsers([]);
      reloadPage();
    });
  };

  const handleCancelBulkDeletionPopup = () => {
    setConfirmPopupOpened(false);
    setSelectedUsers([]);
    setDeletingUsers([]);
  };

  const toggleParentDisplay = () => {
    if (showOnlyParents) {
      setShowOnlyParents(false);
    } else {
      setShowOnlyParents(true);
    }
  };

  const handleAddUserToGroupButtonClick = () => {
    //find kita groups
    getGroupsOfKita({
      page: 1,
      sort: { name: 'groupName', direction: 'asc' },
      searchValue: '',
      kitaId: selectedKita.value,
      visibility: false,
      onlyPortfolioGroups: false,
    }).then(({ data }) => {
      const groupData = data.content.map(item => {
        return {
          id: item.id,
          label: item.groupName,
          value: item.groupName,
        };
      });
      setKitaGroups(groupData);
    });
    setGroupAdditionPopupOpened(true);
  };

  const handleCancelGroupAdditionPopup = () => {
    setGroupAdditionPopupOpened(false);
    setSelectedGroup(null);
  };

  const handleAddUserToGroup = () => {
    setIsSubmitting(true);
    const userIds = selectedUsers;
    const groupId = selectedGroup.id;
    addUsersToGroup({ payload: userIds, groupId }).then(() => {
      setIsSubmitting(false);
      setGroupAdditionPopupOpened(false);
      setSelectedUsers([]);
      setSelectedGroup(null);
      reloadPage();
    });
  };

  const handleMoveUsersToGroupButtonClick = () => {
    setMovePopupOpened(true);
    setIsLoadingChildren(true);

    const tempUsers = selectedUsers.map(id => {
      const user = users.find(user => user.user.id === id);
      if (user) {
        return { ...user };
      }
      return null;
    });

    setMoveUsers(tempUsers);
    const promises = selectedUsers.map(id => {
      return getChildrenByUserId(true, id)
        .then(res => {
          setMoveUsers(prevMoveUsers => {
            const updatedMoveUsers = prevMoveUsers.map(moveUser => {
              if (moveUser.user.id === id) {
                return {
                  ...moveUser,
                  kids: res.data.map(kid => ({ ...kid, currentUser: moveUser.user })),
                };
              }
              return moveUser;
            });
            return updatedMoveUsers;
          });
        })
        .catch(error => {
          console.error(`Error fetching children for user ${id}:`, error);
        });
    });

    Promise.all(promises).then(() => {
      setIsLoadingChildren(false);
    });
  };

  const handleCancelMoveUserPopup = () => {
    setMovePopupOpened(false);
    setSelectedUsers([]);
    setMoveUsers([]);
    setMoveUserErrorMsg('');
    setIsSubmitting(false);
  };

  const handleSubmitMoveUser = () => {
    const hasEmptyKidGroup = moveUsers.some(user => user.kids.some(kid => !kid.selectedKidGroup));

    if (hasEmptyKidGroup) {
      setMoveUserErrorMsg('Please select a group for each kid.');
      return;
    }

    setShowMoveUserConfirmPopup(true);
  };

  const handleConfirmMoveAllUser = () => {
    setShowMoveUserConfirmPopup(false);
    setIsSubmitting(true);
    moveUsersSequentially();
  };

  const moveUsersSequentially = async () => {
    try {
      if (shouldDeactivatedUser) {
        // Deactivate users
        for (const us of moveUsers) {
          await deactivateUserInKita(us.user.id, true, false);
        }
      }

      if (shouldDeactivatedUser) {
        // Deactivate kids
        for (const kid of moveUsers.map(x => x.kids).flat()) {
          await deactivateKid(kid.id, true);
        }
      }

      // Send invitations
      for (const us of moveUsers) {
        await invitationService({
          email: us.user.email,
          langCode: us.user?.language?.langCode,
          kitaId: moveSelectedKita.value,
        });
      }

      // Add kids
      for (const kid of moveUsers.map(x => x.kids).flat()) {
        await inviteUsersAPI(
          {
            groupId: kid.selectedKidGroup?.id,
            userGroup: [kid.currentUser.id],
          },
          moveSelectedKita.value,
        );
        const kidRes = await addKid({
          ...kid,
          userId: kid.currentUser.id,
          newKitaId: moveSelectedKita.value,
          birthday: kid.birthday ? new Date(kid.birthday) : null,
          requestForCareDate: kid.requestForCareDate ? new Date(kid.requestForCareDate) : null,
          contractStartDate: kid.contractStartDate ? new Date(kid.contractStartDate) : null,
          contractEndDate: kid.contractEndDate ? new Date(kid.contractEndDate) : null,
          measlesVaccinationDate: kid.measlesVaccinationDate
            ? new Date(kid.measlesVaccinationDate)
            : null,
          group:
            kid.selectedKidGroup && kid.selectedKidGroup && kid.selectedKidGroup.id
              ? kid.selectedKidGroup.id
              : '',
          street: kid.street || '',
          zip: kid.zip || '',
          city: kid.city || '',
          country: kid.country || '',
          state: kid.state || '',
          currentUser: undefined,
          selectedKidGroup: undefined,
          parent1: undefined,
          parent1Status: undefined,
          bookingTimeMondayFrom: undefined,
          bookingTimeMondayTo: undefined,
          bookingTimeTuesdayFrom: undefined,
          bookingTimeTuesdayTo: undefined,
          bookingTimeWednesdayFrom: undefined,
          bookingTimeWednesdayTo: undefined,
          bookingTimeThursdayFrom: undefined,
          bookingTimeThursdayTo: undefined,
          bookingTimeFridayFrom: undefined,
          bookingTimeFridayTo: undefined,
          bookingHerbstferienTimeFrom: undefined,
          bookingHerbstferienTimeTo: undefined,
          bookingWeihnachtsferienTimeFrom: undefined,
          bookingWeihnachtsferienTimeTo: undefined,
          bookingWinterferienTimeFrom: undefined,
          bookingWinterferienTimeTo: undefined,
          bookingOsterferienTimeFrom: undefined,
          bookingOsterferienTimeTo: undefined,
          bookingPfingstferienTimeFrom: undefined,
          bookingPfingstferienTimeTo: undefined,
          bookingSommerferienTimeFrom: undefined,
          bookingSommerferienTimeTo: undefined,
          foodOrderedMonday: false,
          foodOrderedTuesday: false,
          foodOrderedWednesday: false,
          foodOrderedThursday: false,
          foodOrderedFriday: false,
          parent1Custody: false,
          parent2Custody: false,
        });

        await approveChildByKitaId(kid.currentUser.id, kidRes.data.id, moveSelectedKita.value);
      }

      // Handle success
      handleCancelMoveUserPopup();
      store.dispatch(
        showBottomNotification(t('Moved successful'), {
          isFail: false,
        }),
      );
    } catch (error) {
      if (error && error.response && error.response.data) {
        setMoveUserErrorMsg(error.response.data.message);
      } else {
        setMoveUserErrorMsg('Something went wrong');
      }
      setIsSubmitting(false);
    }
  };

  const removeMoveKidFromList = kidId => {
    const updatedMoveUsers = moveUsers.map(user => ({
      ...user,
      kids: user.kids.filter(kid => kid.id !== kidId),
    }));

    setMoveUsers(updatedMoveUsers);
  };

  const handleUserRoleChangePopupOpen = role => {
    setUserRole(role);
    setUserRoleChangePopupOpened(true);
  };

  const handleUserRoleChangeConfirm = () => {
    setSelectedUsers([]);
  };

  const setSelectedKidGroup = (selectedValue, kidId) => {
    const updatedMoveUsers = moveUsers.map(user => ({
      ...user,
      kids: user.kids.map(kid => {
        if (kid.id === kidId) {
          return {
            ...kid,
            selectedKidGroup: selectedValue,
          };
        }
        return kid;
      }),
    }));
    setMoveUsers(updatedMoveUsers);
  };

  const handleSetMoveKita = kita => {
    setMoveSelectedKita(kita);
    getGroupsOfKita({
      page: 1,
      sort: { name: 'groupName', direction: 'asc' },
      searchValue: '',
      kitaId: kita.value,
      visibility: false,
      onlyPortfolioGroups: false,
    }).then(({ data }) => {
      const groupData = data.content.map(item => {
        return {
          id: item.id,
          label: item.groupName,
          value: item.groupName,
        };
      });
      setKitaGroups(groupData);
    });
  };

  const handleChangeShouldDeactivatedUser = () => {
    setShouldDeactivatedUser(prevState => !prevState);
  };

  return (
    <>
      <div className={styles.container}>
        {isInvitaionModal && (
          <InviteUsersToKitaPopup
            isOpened
            closePopup={() => setIsInvitationModal(false)}
            invitationService={invitationService}
            superAdminStatus={superAdminStatus}
          />
        )}

        {isImportModalOpened && (
          <UsersFrameImportPopup
            isOpened
            closePopup={() => setIsImportModalOpened(false)}
            reloadPage={reloadPage}
            superAdminStatus={superAdminStatus}
          />
        )}

        <div className={styles.titleContainer}>
          <div className={styles.title}>{t('Administration.UserSection.Overview')}</div>
          <div className={`${styles.buttons} ${styles.titleButtons}`}>
            <Button
              className={styles.button}
              onClick={() => setIsImportModalOpened(true)}
              type='success'
              size='sm'
            >
              <i className='fa fa-users' /> {t('Administration.UserSection.Import')}
            </Button>
            <Button
              className={styles.button}
              onClick={() => setIsInvitationModal(true)}
              type='success'
              size='sm'
            >
              <i className='fa fa-plus' /> {t('Administration.UserSection.Add new user')}
            </Button>
            {selectedUsers.length > 0 && (
              <>
                <DropdownWrapper
                  dropdownOptions={multiActionDropdownOptions}
                  closeOnClick
                  render={dropdown => (
                    <div className={styles.dropdownContainer}>
                      <Button className={styles.button} type='light' size='sm'>
                        {t('Users.Actions')} <i className='fa fa-angle-down' />
                      </Button>
                      {dropdown}
                    </div>
                  )}
                />
              </>
            )}
            <Button
              type='light'
              size='sm'
              onClick={() => setIsExportDropdownOpened(!isExportDropdownOpened)}
              className={styles.button}
              isActive={isExportDropdownOpened}
            >
              <i className='fa fa-download' /> {t('Administration.Kita.Export')}{' '}
              <TextCaret color='#fff' />
            </Button>
            {isExportDropdownOpened && (
              <div
                onClick={() => setIsExportDropdownOpened(false)}
                className={styles.buttonsDropdown}
                style={{ zIndex: 999 }}
              >
                <Button size='sm' onClick={() => downloadTable('excel')}>
                  XLSX
                </Button>
                <Button size='sm' onClick={() => downloadTable('csv')}>
                  CSV
                </Button>
                <Button
                  size='sm'
                  onClick={() => {
                    allKitaKidListToExcel(selectedKita.value)
                      .then(res => {
                        downloadFile(res, 'Kita-KidList.xlsx', 'application/vnd.ms-excel');
                      })
                      .finally(() => {});
                  }}
                >
                  Adebis-Export
                </Button>
                {(window.location.hostname.toLowerCase().includes('dev.stramplerbande.org') ||
                  window.location.hostname.toLowerCase().includes('netzwerk.himmels.app')) && (
                  <Button
                    size='sm'
                    onClick={() => {
                      allChristusErloeserConsentsToExcel()
                        .then(res => {
                          downloadFile(
                            res,
                            'ChristusErloeserConsent.xlsx',
                            'application/vnd.ms-excel',
                          );
                        })
                        .finally(() => {});
                    }}
                  >
                    Einwilligung
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.text}>
          <HelpText>
            {t(
              // eslint-disable-next-line
              'Administration.UserSection.This overview contains a list of each registered user with actions to view, edit and delete users',
            )}
          </HelpText>
        </div>

        <div className={styles.filters}>
          <div className={styles.filtersSearch}>
            <Input
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className={styles.filtersSearchInput}
              placeholder={t('Administration.UserSection.Input placeholder')}
            />
          </div>

          <div className={styles.select}>
            <Select options={options} onSelect={setSelectedOption} selected={selectedOption} />
          </div>

          {superAdminStatus && (
            <div className={styles.filtersKita}>
              <Select
                options={kitas}
                onSelect={setSelectedKita}
                selected={selectedKita}
                openedClassName={styles.filtersKitaOpened}
              />
            </div>
          )}
        </div>

        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <>
            {totalUsersCount / 50 > 1 && (
              <Pagination
                currentPage={parseInt(location.pathname.slice(27) || 1, 10)}
                history={history}
                numberOfPages={totalUsersCount ? Math.ceil(totalUsersCount / 50) : 1}
                onPageClick={loadPage}
              />
            )}
            {totalUsersCount > 0 ? (
              <UsersFrameListTable
                falsePermissions={falsePermissions}
                toggleAllPermissions={toggleAllPermissions}
                users={users}
                setSorting={setSorting}
                sort={sort}
                setEditingProfile={setEditingProfile}
                setDeletionProfile={setDeletionProfile}
                togglePermission={togglePermission}
                selectedKita={selectedKita}
                loggedInUser={loggedInUser}
                handleUserSelection={handleUserSelection}
                selectedUsers={selectedUsers}
                allUsersAreSelected={selectedUsers.length === users.length}
                handleToggleAllUsersSelection={handleToggleAllUsersSelection}
                showOnlyParents={showOnlyParents}
                toggleParentDisplay={toggleParentDisplay}
              />
            ) : (
              <strong>{t('Administration.UserSection.No users found for the given filter')}</strong>
            )}
            {totalUsersCount / 50 > 1 && (
              <Pagination
                currentPage={parseInt(location.pathname.slice(27) || 1, 10)}
                history={history}
                numberOfPages={totalUsersCount ? Math.ceil(totalUsersCount / 50) : 1}
                onPageClick={loadPage}
              />
            )}
          </>
        )}
      </div>
      <Popup
        isOpened={confirmPopupOpened}
        closePopup={handleCancelBulkDeletionPopup}
        header={
          <h3>{`${t(
            'Administration.UserSection.You are about to delete the following users',
          )}:`}</h3>
        }
        body={
          <div>
            {selectedUsers &&
              selectedUsers.map(userId => {
                const user = users.find(user => user.user.id === userId);
                return (
                  <div className={styles.userItemContainer} key={user.id}>
                    <div className={styles.checkboxContainer}>
                      <Checkbox
                        checkboxTextClass={styles.titleCheckboxTitle}
                        isChecked={deletingUsers.includes(user)}
                        onChange={() => handleDeletingUserSelection(user)}
                        className={styles.checkbox}
                      />
                    </div>
                    <h4>{`${user.user.firstName} ${user.user.lastName}`}</h4>
                  </div>
                );
              })}
          </div>
        }
        footer={
          <div className={styles.deletionButtons}>
            <ButtonWithLoader
              type='danger'
              onClick={handleSubmitBulkDeletion}
              isLoading={isSubmitting}
              disabled={deletingUsers.length === 0}
            >
              {t('Administration.UserSection.Delete All')}
            </ButtonWithLoader>
            <Button type='primary' onClick={handleCancelBulkDeletionPopup}>
              {t('Administration.UserSection.Cancel')}
            </Button>
          </div>
        }
      />
      <Popup
        isOpened={groupAdditionPopupOpened}
        closePopup={handleCancelGroupAdditionPopup}
        header={<h3>{`${t('Administration.UserSection.PleaseSelectAGroup')}:`}</h3>}
        body={
          <div>
            <Select
              options={kitaGroups}
              onSelect={setSelectedGroup}
              selected={selectedGroup}
              openedClassName={styles.filtersKitaOpened}
            />
          </div>
        }
        footer={
          <div className={styles.actionButtons}>
            <ButtonWithLoader
              type='danger'
              onClick={handleAddUserToGroup}
              isLoading={isSubmitting}
              disabled={selectedGroup === null}
            >
              {t('Administration.UserSection.Add')}
            </ButtonWithLoader>
            <Button type='primary' onClick={handleCancelBulkDeletionPopup}>
              {t('Administration.UserSection.Cancel')}
            </Button>
          </div>
        }
      />
      <Popup
        isOpened={movePopupOpened}
        closePopup={handleCancelMoveUserPopup}
        header={
          <h3>{`${t(
            'Administration.UserSection.You are about to move parents user with child to an other institution',
          )}:`}</h3>
        }
        body={
          isLoadingChildren ? (
            <Loader className={styles.loader} />
          ) : (
            <div className={styles.moveUserGroupContainer}>
              <div className={styles.kitaSelection}>
                <h3 className={styles.requiredIndicator}>Institution</h3>
                <Select
                  options={kitas}
                  onSelect={setSelectedKita => handleSetMoveKita(setSelectedKita)}
                  selected={moveSelectedKita}
                />
              </div>
              {moveUsers &&
                moveUsers.map(user => (
                  <div className={styles.moveGroups} key={user.user.id}>
                    <h3>{`${user.user.firstName} ${user.user.lastName}`}</h3>
                    {user.kids && user.kids.length ? (
                      user.kids.map(kid => (
                        <div className={cn(styles.userItemContainer, styles.moveUser)} key={kid.id}>
                          <i
                            className={cn(styles.btnRemoveUser, 'fa fa-trash')}
                            onClick={() => removeMoveKidFromList(kid.id)}
                          />
                          <h5 className={styles.kidName}>{`${kid.firstName} ${kid.lastName}`}</h5>
                          <Select
                            className={styles.moveGroupSelect}
                            options={kitaGroups}
                            onSelect={selectedValue => setSelectedKidGroup(selectedValue, kid.id)}
                            selected={kid.selectedKidGroup}
                            openedClassName={styles.filtersKitaOpened}
                          />
                        </div>
                      ))
                    ) : (
                      <div className={cn(styles.userItemContainer, styles.moveUser)}>
                        <h5 className={styles.kidName}>No Kid</h5>
                      </div>
                    )}
                  </div>
                ))}
              {moveUserErrorMsg && <div className={styles.moveUserError}>{moveUserErrorMsg}</div>}
            </div>
          )
        }
        footer={
          <div className={styles.deletionButtons}>
            <ButtonWithLoader
              type='danger'
              onClick={handleSubmitMoveUser}
              isLoading={isSubmitting}
              disabled={moveUsers.length === 0}
            >
              {t('Administration.UserSection.Move All')}
            </ButtonWithLoader>
            <Button type='primary' onClick={handleCancelMoveUserPopup}>
              {t('Administration.UserSection.Cancel')}
            </Button>
          </div>
        }
      />

      <MultiUserSelectionPopup
        isOpen={userRoleChangePopupOpened}
        setOpen={setUserRoleChangePopupOpened}
        userRole={userRole}
        activeKita={activeKita}
        users={users.filter(user => selectedUsers.includes(user.user.id))}
        onConfirm={handleUserRoleChangeConfirm}
      />

      <Popup
        size={'extraSmall'}
        isOpened={showMoveUserConfirmPopup}
        closePopup={() => {
          setShowMoveUserConfirmPopup(false);
        }}
        header={
          <strong>
            {t(
              'Administration.UserSection.Confirm move parents/normal user with child to another institution',
            )}
          </strong>
        }
        body={
          <div>
            <Checkbox
              checkboxTextClass={styles.titleCheckboxTitle}
              isChecked={shouldDeactivatedUser}
              onChange={handleChangeShouldDeactivatedUser}
              className={styles.checkbox}
              name={t('Should the child and the parents be deactivated in the old institution?')}
            />
          </div>
        }
        footer={
          <Button className={styles.button} onClick={handleConfirmMoveAllUser}>
            {t('Administration.UserSection.Move')}
          </Button>
        }
      />
    </>
  );
}

export async function downloadTableHandler({ type, kitaId, ...filters }) {
  const { data } = await downloadExcel({ type, kitaId, ...filters });
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', type === 'excel' ? 'Users list.xlsx' : 'Users list.csv');
  document.body.appendChild(link);
  link.click();
}
