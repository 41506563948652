import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './usersFrameUserGroupsPopup.module.scss';
import Popup from '../../../../../../../../../library/common/commonComponents/Popups/Popup';
import Button from '../../../../../../../../../library/common/commonComponents/Buttons/Button';
import { getGroupsByUserIdAndKitaId } from '../../../../../../../../../library/api/user';
import Loader from '../../../../../../../../../library/common/commonComponents/Loader';
import ListItemWithLogoAvatar from '../../../../../../../../../library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import { getLetterInLogo } from '../../../../../../../../../library/utilities/groups';

export default function UsersFrameUserGroupsPopup({ user, selectedKita }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getGroupsByUserIdAndKitaId(user.user.id, selectedKita.value).then(res => {
        // filter out groups depending on the current tenant
        const filteredGroups = window.location.hostname.toLowerCase().includes('stramplerbande')
          ? res.data
          : res.data.filter(group => group.groupName !== 'Public group');

        setGroups(filteredGroups);
        setIsLoading(false);
      });
    }
  }, [isOpen]);
  const redirect = groupId => {
    window.location.href = '/groups/' + groupId;
  };
  return (
    <div>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('UsersFrame.User.Show groups')}
      </Button>
      <Popup
        isOpened={isOpen}
        closePopup={closePopup}
        size='small'
        header={<div> {t('UsersFrame.User.Groups')}</div>}
        footer={
          <div>
            <Button type='secondary' onClick={() => closePopup()}>
              {t('CreateGroup.Back')}
            </Button>
          </div>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={styles.groupHint}>{t('UsersFrame.GroupsText')}:</div>
            <div className={styles.groupContainer}>
              {groups.map(group => (
                <div className={styles.groupElement} onClick={() => redirect(group.id)}>
                  <ListItemWithLogoAvatar
                    groupInfo={{
                      logoUrl: group.groupLogoUrl,
                      groupLogoXCoordinate: group.groupLogoXCoordinate,
                      groupLogoYCoordinate: group.groupLogoYCoordinate,
                      groupLogoWidth: group.groupLogoWidth,
                      groupLogoAspect: group.groupLogoAspect,
                    }}
                    letter={getLetterInLogo(group.groupName)}
                    backgroundColor={group.colourCode}
                    className={styles.groupLogo}
                  />
                  <div className={styles.groupName}>{group.groupName}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}
