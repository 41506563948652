import { useEffect } from "react";

export function checkScrollLoading(loadFn, seed = 1) {
  const maxScrollDiffToLoad = window.innerHeight * seed;
  const pageHeight = document.body.offsetHeight;
  const pageScrollOffset = window.scrollY;

  if (pageHeight - pageScrollOffset < maxScrollDiffToLoad) {
    loadFn();
  }
}


export function useLoadingOnScroll(loadFnHandler) {
  useEffect(() => {
    window.addEventListener('scroll', loadFnHandler);

    return () => window.removeEventListener('scroll', loadFnHandler);
    // eslint-disable-next-line
  }, []);
}

export function checkPortalScrollLoading(loadFn, seed = 1) {
  const popupContainer = document.getElementById('kita-popup-container');
  const maxScrollDiffToLoad = popupContainer.offsetHeight * seed;
  const pageHeight = popupContainer.scrollHeight;
  const pageScrollOffset = popupContainer.scrollTop;

  if (pageHeight - pageScrollOffset < maxScrollDiffToLoad) {
    loadFn();
  }
}


export function useLoadingOnPortalScroll(loadFnHandler) {
  useEffect(() => {
    const popupContainer = document.getElementById('kita-popup-container');
    popupContainer.addEventListener('scroll', loadFnHandler);

    return () => popupContainer.removeEventListener('scroll', loadFnHandler);
    // eslint-disable-next-line
  }, []);
}
