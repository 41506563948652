import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  setCheckInOutActiveForParents,
  getCheckInOutKitaConfig,
  setCheckinGroupSelectionActive,
  setCheckInOutNotificationsActiveToParent
} from 'library/api/kids';
import {
  getAbsenceNotificationsActiveAdmin,
  getAbsenceNotificationsActiveEmployee,
  getAbsenceNotificationsActiveParent,
  getFoodOrderInformCancelation,
  getNoScreenshotsStatus,
  setAbsenceNotificationsActiveAdmin,
  setAbsenceNotificationsActiveEmployee,
  setAbsenceNotificationsActiveToParent,
  setFoodOrderInformCancelation,
  setNoScreenshotsStatus,
} from 'library/api/user';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { checkInOutActiveForParentsSettingChanged } from 'library/common/commonActions/moduleSettingsActions';

import CheckBox from 'library/common/commonComponents/Checkbox';
import Loader from 'library/common/commonComponents/Loader';

import styles from './module.module.scss';


export default function Module({
  moduleInfo,
  module,
  onSetAsDefault,
  onChangeActivate,
  onOpenSettings,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [curCheckInOutActiveForParents, setCurCheckInOutActiveForParents] = useState(false);
  const [curCheckinGroupSelectionActive, setCurCheckinGroupSelectionActive] = useState(false);
  const [
    curCheckInOutNotificationsActiveParent,
    setCurCheckInOutNotificationsActiveParent,
  ] = useState(false);
  const [curAbsenceNotificationsActiveAdmin, setCurAbsenceNotificationsActiveAdmin] = useState(
    true,
  );
  const [
    curAbsenceNotificationsActiveEmployee,
    setCurAbsenceNotificationsActiveEmployee,
  ] = useState(true);

  const [
    curAbsenceNotificationsActiveParent,
    setCurAbsenceNotificationsActiveParent,
  ] = useState(false);

  const [checkboxLoading, setCheckboxLoading] = useState({
    disableCheckinoutForParents: false,
    enableCheckinGroupSelection: false,
    disableCheckInOutNotificationsActiveParent: false,
    disableAbsenceNotifications: false,
    disableAbsenceNotificationsEmployee: false,
    messageToParent: false,
    informKitaAdminByCancelation: false,
    informGroupAdminByCancelation: false,
    informEmployeeByCancelation: false,
    noScreenshots: false,
  });

  const [informKitaAdminByCancelation, setInformKitaAdminByCancelation] = useState(false);
  const [informGroupAdminByCancelation, setInformGroupAdminByCancelation] = useState(false);
  const [informEmployeeByCancelation, setInformEmployeeByCancelation] = useState(false);

  const [noScreenshots, setNoScreenshots] = useState(false);


  const handleChangeActivate = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onChangeActivate(module);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (module.activeStatus && module.moduleKey === 'checkinout') {
      getCheckInOutKitaConfig().then(res => {
        if (res && res.data !== null) {
          setCurCheckInOutActiveForParents(res.data.checkinoutActiveForParents);
          setCurCheckinGroupSelectionActive(res.data.checkinGroupSelectionActive);
          setCurCheckInOutNotificationsActiveParent(res.data.checkinoutNotificationsActiveParent);
        }
      });
    }

    if (module.activeStatus && module.moduleKey === 'absence') {
      getAbsenceNotificationsActiveAdmin().then(res => {
        if (res && res.data !== null) {
          setCurAbsenceNotificationsActiveAdmin(res.data);
        }
      });

      getAbsenceNotificationsActiveEmployee().then(res => {
        if (res && res.data !== null) {
          setCurAbsenceNotificationsActiveEmployee(res.data);
        }
      });

      getAbsenceNotificationsActiveParent().then(res => {
        if (res && res.data !== null) {
          setCurAbsenceNotificationsActiveParent(res.data);
        }
      });
    }

    if (module.activeStatus && module.moduleKey === 'food_order') {
      getFoodOrderInformCancelation().then(res => {
        if (res && res.data !== null) {
          setInformKitaAdminByCancelation(!!res.data.kitaAdminActive);
          setInformGroupAdminByCancelation(!!res.data.groupAdminActive);
          setInformEmployeeByCancelation(!!res.data.employeeActive);
        }
      });

    }

    if (module.activeStatus && module.moduleKey === 'image_download') {
      getNoScreenshotsStatus().then(res => {
        if (res && res.data !== null) {
          setNoScreenshots(res.data);
        }
      });
    }
  }, []);

  const handleDisableCheckinoutForParents = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      disableCheckinoutForParents: true
    }));
    setCheckInOutActiveForParents(!curCheckInOutActiveForParents).then(()=> {
      store.dispatch(checkInOutActiveForParentsSettingChanged());
      setCurCheckInOutActiveForParents(!curCheckInOutActiveForParents);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        disableCheckinoutForParents: false
      }));
    });
  }

  const handleEnableCheckinGroupSelection = () => {
    setCheckboxLoading(prevState => ({
      ...prevState,
      enableCheckinGroupSelection: true
    }));
    setCheckinGroupSelectionActive(!curCheckinGroupSelectionActive).then(()=> {
      setCurCheckinGroupSelectionActive(!curCheckinGroupSelectionActive);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        enableCheckinGroupSelection: false
      }));
    });
  }

  const handleDisableCheckInOutNotificationsActiveParent = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      disableCheckInOutNotificationsActiveParent: true
    }));
    setCheckInOutNotificationsActiveToParent(!curCheckInOutNotificationsActiveParent).then(()=> {
      setCurCheckInOutNotificationsActiveParent(!curCheckInOutNotificationsActiveParent);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        disableCheckInOutNotificationsActiveParent: false
      }));
    });
  }

  const handleDisableAbsenceNotifications = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      disableAbsenceNotifications: true
    }));
    setAbsenceNotificationsActiveAdmin(!curAbsenceNotificationsActiveAdmin).then(()=> {
      setCurAbsenceNotificationsActiveAdmin(!curAbsenceNotificationsActiveAdmin);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        disableAbsenceNotifications: false
      }));
    });
  }

  const handleDisableAbsenceNotificationsEmployee = () => {
    setCheckboxLoading(prevState => ({
      ...prevState,
      disableAbsenceNotificationsEmployee: true
    }));
    setAbsenceNotificationsActiveEmployee(!curAbsenceNotificationsActiveEmployee).then(()=> {
      setCurAbsenceNotificationsActiveEmployee(!curAbsenceNotificationsActiveEmployee);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        disableAbsenceNotificationsEmployee: false
      }));
    });
  }

  const handleMessageToParent = () => {
    setCheckboxLoading(prevState => ({
      ...prevState,
      messageToParent: true
    }));
    setAbsenceNotificationsActiveToParent(!curAbsenceNotificationsActiveParent).then(()=> {
      setCurAbsenceNotificationsActiveParent(prev => !prev);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        messageToParent: false
      }));
    });
  }

  const handleInformKitaAdminByCancelation = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      informKitaAdminByCancelation: true
    }));
    setFoodOrderInformCancelation(!informKitaAdminByCancelation, 'admin').then(()=> {
      setInformKitaAdminByCancelation(!informKitaAdminByCancelation);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        informKitaAdminByCancelation: false
      }));
    });
  }

  const handleInformGroupAdminByCancelation = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      informGroupAdminByCancelation: true
    }));
    setFoodOrderInformCancelation(!informGroupAdminByCancelation, 'group').then(()=> {
      setInformGroupAdminByCancelation(!informGroupAdminByCancelation);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        informGroupAdminByCancelation: false
      }));
    });
  }

  const handleInformEmployeeByCancelation = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      informEmployeeByCancelation: true
    }));
    setFoodOrderInformCancelation(!informEmployeeByCancelation, 'employee').then(()=> {
      setInformEmployeeByCancelation(!informEmployeeByCancelation);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        informEmployeeByCancelation: false
      }));
    });
  }

  const handleEnableScreenshots = ()=> {
    setCheckboxLoading(prevState => ({
      ...prevState,
      enableScreenshots: true
    }));
    setNoScreenshotsStatus(!noScreenshots).then(()=> {
      setNoScreenshots(!noScreenshots);
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.SuccessSaving'), { isFail: false }),
      );
    })
    .catch(() => {
      store.dispatch(
        showBottomNotification(t('Administration.ModulesSection.ErrorSaving'), { isFail: true }),
      );
    })
    .finally(()=> {
      setCheckboxLoading(prevState => ({
        ...prevState,
        enableScreenshots: false
      }));
    });
  }

  return (
    <div className={styles.module} key={module.moduleId}>
      <div className={styles.leftSection}>
        <div className={styles.module__image}>
          <img src={moduleInfo.img} alt='' />
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightSection__header}>
          <div className={styles.module__title}>{moduleInfo.title}</div>
          {module.activeStatus && (
            <div className={styles.module__activated}>
              {t('Administration.ModulesSection.Activated')}
            </div>
          )}
        </div>
        <div className={styles.module__description}>{moduleInfo.description}</div>
        <div className={styles.module__buttonContainer}>
          {module.activeStatus &&
            module.moduleKey !== 'translations' &&
            module.moduleKey !== 'image_download' &&
            module.moduleKey !== 'employee_portfolio' &&
            module.moduleKey !== 'chat' &&
            module.moduleKey !== 'corona_test' &&
            module.moduleKey !== 'tax_consultant' && (
              <div
                className={styles.module__buttonContainer__bttn}
                onClick={() => onSetAsDefault(module)}
              >
                {t('Administration.ModulesSection.Set as default')}
              </div>
            )}
          <div className={styles.module__buttonContainer__bttn} onClick={handleChangeActivate}>
            {isSubmitting ? (
              <Loader dotColor='#fff' dotSize='8px' className={styles.buttonSubmitting} />
            ) : !module.activeStatus ? (
              t('Administration.ModulesSection.Enable')
            ) : (
              t('Administration.ModulesSection.Disable')
            )}
          </div>
          {module.activeStatus && module.moduleKey === 'checkinout' && (
            <>
              <CheckBox
                name={
                  curCheckInOutActiveForParents
                    ? t('Administration.ModulesSection.DisableCheckinoutForParents')
                    : t('Administration.ModulesSection.EnableCheckinoutForParents')
                }
                isChecked={curCheckInOutActiveForParents}
                onChange={handleDisableCheckinoutForParents}
                isLoading={checkboxLoading.disableCheckinoutForParents}
              />
              <CheckBox
                name={
                  curCheckinGroupSelectionActive
                    ? t('Administration.ModulesSection.DisableCheckinGroupSelection')
                    : t('Administration.ModulesSection.EnableCheckinGroupSelection')
                }
                isChecked={curCheckinGroupSelectionActive}
                onChange={handleEnableCheckinGroupSelection}
                isLoading={checkboxLoading.enableCheckinGroupSelection}
              />
              <CheckBox
                name={
                  curCheckInOutNotificationsActiveParent
                    ? t('Administration.ModulesSection.DisableCheckInOutNotificationsActiveParent')
                    : t('Administration.ModulesSection.EnableCheckInOutNotificationsActiveParent')
                }
                isChecked={curCheckInOutNotificationsActiveParent}
                onChange={handleDisableCheckInOutNotificationsActiveParent}
                isLoading={checkboxLoading.disableCheckInOutNotificationsActiveParent}
              />
            </>
          )}
          {module.activeStatus && module.moduleKey === 'absence' && (
            <>
              <CheckBox
                name={
                  curAbsenceNotificationsActiveAdmin
                    ? t('Administration.ModulesSection.DisableAbsenceNotifications')
                    : t('Administration.ModulesSection.EnableAbsenceNotifications')
                }
                isChecked={curAbsenceNotificationsActiveAdmin}
                onChange={handleDisableAbsenceNotifications}
                isLoading={checkboxLoading.disableAbsenceNotifications}
              />
              <CheckBox
                name={
                  curAbsenceNotificationsActiveEmployee
                    ? t('Administration.ModulesSection.DisableAbsenceNotificationsEmployee')
                    : t('Administration.ModulesSection.EnableAbsenceNotificationsEmployee')
                }
                isChecked={curAbsenceNotificationsActiveEmployee}
                onChange={handleDisableAbsenceNotificationsEmployee}
                isLoading={checkboxLoading.disableAbsenceNotificationsEmployee}
              />
              <CheckBox
                name={
                  t('Administration.ModulesSection.MessageToParent')
                }
                isChecked={curAbsenceNotificationsActiveParent}
                onChange={handleMessageToParent}
                isLoading={checkboxLoading.messageToParent}
              />
            </>
          )}
          {module.activeStatus && module.moduleKey === 'food_order' && (
            <>
              <CheckBox
                name={
                  informKitaAdminByCancelation
                    ? t('Administration.ModulesSection.DisableInformKitaAdminByCancelation')
                    : t('Administration.ModulesSection.EnableInformKitaAdminByCancelation')
                }
                isChecked={informKitaAdminByCancelation}
                onChange={handleInformKitaAdminByCancelation}
                isLoading={checkboxLoading.informKitaAdminByCancelation}
              />
              <CheckBox
                name={
                  informGroupAdminByCancelation
                    ? t('Administration.ModulesSection.DisableInformGroupAdminByCancelation')
                    : t('Administration.ModulesSection.EnableInformGroupAdminByCancelation')
                }
                isChecked={informGroupAdminByCancelation}
                onChange={handleInformGroupAdminByCancelation}
                isLoading={checkboxLoading.informGroupAdminByCancelation}
              />
              <CheckBox
                name={
                  informEmployeeByCancelation
                    ? t('Administration.ModulesSection.DisableInformEmployeeByCancelation')
                    : t('Administration.ModulesSection.EnableInformEmployeeByCancelation')
                }
                isChecked={informEmployeeByCancelation}
                onChange={handleInformEmployeeByCancelation}
                isLoading={checkboxLoading.informEmployeeByCancelation}
              />
            </>
          )}
          {module.activeStatus && module.moduleKey === 'image_download' && (
            <CheckBox
              name={
                noScreenshots
                  ? t('Administration.ModulesSection.NoScreenshots')
                  : t('Administration.ModulesSection.EnableScreenshots')
              }
              isChecked={noScreenshots}
              onChange={handleEnableScreenshots}
              isLoading={checkboxLoading.noScreenshots}
            />
          )}
          {module.activeStatus && module.configureStatus && (
            <div
              className={cn(
                styles.module__buttonContainer__bttn,
                styles.module__buttonContainer__bttn_configure,
              )}
              onClick={() => onOpenSettings(module)}
            >
              {t('Administration.ModulesSection.Configure')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
