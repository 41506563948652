import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { isActiveNavLink } from 'library/utilities/router';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import { isAwoWW } from 'library/api/tenantConfig';

import styles from './bottombarHamburgerDropdown.module.scss';

export default function BottombarHamburgerDropdown({ links, isOpened, closeDropdown }) {
  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: closeDropdown,
  });

  return (
    isOpened && (
      <div
        className={isAwoWW() ? styles.dropdown_awo : styles.dropdown}
        ref={outsideEventContainerRef}
      >
        {links.map(link =>
          link.faIcon !== 'fa-person' ? (
            <NavLink
              onClick={closeDropdown}
              to={link.to}
              key={link.to}
              isActive={isActiveNavLink.bind(this, link.to)}
              activeClassName={styles.active}
            >
              <div className={isAwoWW() ? styles.dropdown_awoItem : styles.dropdownItem}>
                {link.title}
              </div>
            </NavLink>
          ) : (
            <a
              className={styles.aLink}
              key={link.to}
              href={link.to} // Use href for external links
              target='_blank'
              rel='noopener noreferrer'
            >
              {link.title}
            </a>
          ),
        )}
      </div>
    )
  );
}
