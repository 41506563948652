import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import store from 'main/store/configureStore';
import styles from './galleryDefaultHeader.module.scss';

export default function GalleryDefaultHeader({ goBack, deleteAllImagesFromGallery, dispatch, imagesSize, selectFiles, onDownloadPictures }) {
  const { t } = useTranslation();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const handleDeleteAll = () => {
    setIsSubmiting(true);
    deleteAllImagesFromGallery({ galleryId: 0, filesDispatch: dispatch })
      .then(res => {
        if (res.status === 200) {
          store.dispatch(showBottomNotification(t('Gallery.DeleteAllSuccess'), { isFail: false }));
        } else {
          store.dispatch(showBottomNotification(t('Gallery.DeleteAllError'), { isFail: true }));
        }

        setIsSubmiting(false);
      })
      .catch(() => {
        store.dispatch(showBottomNotification(t('Gallery.DeleteAllError'), { isFail: true }));
        setIsSubmiting(false);
      });
  };

  const handleDeleteBtnClick = () => {
    setIsDeletePopupVisible(true);
  };
  const handleDownloadGallery = () => {
    onDownloadPictures();
  };

  return (
    <div className={styles.container}>
      <DeletePopup
        isOpened={isDeletePopupVisible}
        closePopup={() => setIsDeletePopupVisible(false)}
        onDeleteBtnClick={handleDeleteAll}
        headerText={t('Gallery.DeleteAllConfirmTitle')}
        bodyText={t('Gallery.DeleteAllConfirmText')}
        isSubmiting={isSubmiting}
      />
      <div className={styles.title}>
        <Trans i18nKey='Gallery.Default gallery header' />
      </div>

      <div className={styles.buttonContainer}>
        {(imagesSize !== 0) ?
          (
            <div className={styles.imagesWrapper}>
              <div className={styles.button} onClick={handleDeleteBtnClick}>
                <Button size='sm' type='danger'>
                  <i className={cn('fa fa-trash-o', styles.buttonIcon)} /> {t('Gallery.DeleteAll')}
                </Button>
              </div>
              {/*
                !!selectFiles.length && (
                  <div className={styles.button} onClick={handleDownloadGallery}>
                    <Button size='sm'>
                      <i className={cn('fa fa-cloud-download', styles.buttonIcon)} /> {t('Gallery.Download')}
                    </Button>
                  </div>
                )
              */}
            </div>
          ) :
          <div />
        }
        <div className={styles.button} onClick={goBack}>
          <Button size='sm'>
            <i className={cn('fa fa-arrow-left', styles.buttonIcon)} />{' '}
            {t('Gallery.Back to overview')}
          </Button>
        </div>
      </div>
    </div>
  );
}
