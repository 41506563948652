import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'use-react-router';
import cn from 'classnames';

import Button from 'library/common/commonComponents/Buttons/Button';
import TextCaret from 'library/common/commonComponents/TextCaret';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import { timeSince } from 'library/utilities/date';
import Checkbox from 'library/common/commonComponents/Checkbox';
import DropdownInPortal from 'library/common/commonComponents/DropdownInPortal';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';

import styles from '../../usersFrameList.module.scss';
import UsersFrameUserGroupsPopup from './UsersFrameUserGroupsPopup';
import { sendEmailForResetPassword } from "../../../../../../../Auth/ForgotPassword/forgotPasswordActions";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export default function UsersFrameListRow({
  user,
  setEditingProfile,
  setDeletionProfile,
  togglePermission,
  selectedKita,
  loggedInUser,
  handleUserSelection,
  userIsSelected,
  showLogin
}) {
  const { t } = useTranslation();

  const { history } = useRouter();

  const handleForgotPassword = async (email) => {
    const { success } = await store.dispatch(sendEmailForResetPassword(email));

    if (success === true) {
      store.dispatch(
        showBottomNotification(
          t('ProfilePanel.ForgotPasswordEmailSuccess'),
          { isFail: false },
        ),
      );
    }
    else {
      store.dispatch(
        showBottomNotification(
          t('ProfilePanel.ForgotPasswordEmailError'),
          { isFail: true },
        ),
      );
    }
  };

  const dropdownOptions = getDropdownOptions({
    t,
    history,
    user,
    setEditingProfile,
    setDeletionProfile,
    handleForgotPassword,
  });

  const [isOptionsDropdownOpened, setIsOptionsDropdownOpened] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    click: true,
    handler: () => setIsOptionsDropdownOpened(false),
  });


  return (
    <tr>
      {loggedInUser.administrationAccess && (
        <td>
          <div className={styles.headerTextContainer}>
            <Checkbox
              checkboxTextClass={styles.titleCheckboxTitle}
              isChecked={userIsSelected}
              onChange={handleUserSelection}
              className={cn(styles.titleCheckbox, styles.checkbox)}
            />
          </div>
        </td>
      )}
      <td>
        <div className={cn('notranslate', styles.avatar)}>
          <div className={styles.avatarBox}>
            <ProfileAvatar image={user} id={user.user.id} />
          </div>
          <div className={styles.avatarText} style={{ minWidth: "100px" }}>
            <div className={styles.groupName}>
              {user.user.lastName} {user.user.firstName}
            </div>
            {user && user.parent && user.parent.kidsNames && (
              <div className={styles.groupDesc}>{user.parent.kidsNames.toString()}</div>
            )}
          </div>
        </div>
      </td>
      <td className='notranslate'>
        <UsersFrameUserGroupsPopup user={user} selectedKita={selectedKita} />
      </td>
      {showLogin && <td className={styles.memberCount}>
        {user.lastAccess
          ? timeSince({ date: user.lastAccess, langCode: user.langCode })
          : t('Administration.UserSection.never')}
      </td>}
      <td className={styles.moreBtn}>
        <Button
          isActive={isOptionsDropdownOpened}
          onClick={() => setIsOptionsDropdownOpened(true)}
          className={styles.buttonsMarginLeft}
        >
          <div ref={outsideEventContainerRef}>
            <i className='fa fa-cog' />
            <TextCaret />
          </div>
        </Button>

        <DropdownInPortal
          dropdownOptions={dropdownOptions}
          isOptionsDropdownOpened={isOptionsDropdownOpened}
          setIsOptionsDropdownOpened={setIsOptionsDropdownOpened}
          outsideEventContainerRef={outsideEventContainerRef}
        />
      </td>
      <td>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checkboxTextClass={styles.titleCheckboxTitle}
            isChecked={user.pictures}
            onChange={() => togglePermission(user.user.id, 'pictures')}
            className={styles.checkbox}
          />
        </div>
      </td>
      <td>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checkboxTextClass={styles.titleCheckboxTitle}
            isChecked={user.videos}
            onChange={() => togglePermission(user.user.id, 'videos')}
            className={styles.checkbox}
          />
        </div>
      </td>
      <td>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checkboxTextClass={styles.titleCheckboxTitle}
            isChecked={user.others}
            onChange={() => togglePermission(user.user.id, 'others')}
            className={styles.checkbox}
          />
        </div>
      </td>
    </tr>
  );
}

export function getDropdownOptions({ t, history, user, setEditingProfile, setDeletionProfile, handleForgotPassword }) {
  return [
    {
      faIcon: 'fa-pencil',
      title: t('Post.Edit'),
      onClick: () => setEditingProfile({ ...user.user, adminRole: user.kitaRoles.roles.adminRole, employee: user.employee, qrCodeScanner: user.qrCodeScanner, userDeactivationStatus: user.deactivated, ableToEditUserProfiles: user.ableToEditUserProfiles }),
    },
    {
      isSeparator: true,
    },
    {
      faIcon: 'fa-trash',
      title: t('Post.Delete'),
      onClick: () => setDeletionProfile(user.user),
    },
    {
      isSeparator: true,
    },
    {
      faIcon: 'fa-user',
      title: t('Administration.UserSection.View Profile'),
      onClick: () => history.push(`/profile/${user.user.id}`),
    },
    {
      isSeparator: true,
    },
    {
      faIcon: 'fa-question',
      title: t('Administration.UserSection.ForgotPassword'),
      onClick: () => handleForgotPassword(user.user.email),
    },
  ];
}
