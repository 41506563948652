import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createComment } from 'library/api/comments';
import useEditor from 'library/common/commonHooks/useEditor';
import { checkLink } from 'library/utilities/checkLink';

export default function useCommentsInput({ user, id, comments, setComments, groupId }) {
  const { t } = useTranslation();

  const { Editor, focusOnEditor, getEditorValue, updateValue } = useEditor({
    placeholder: t('Post.Write a new comment'),
    onChange: () => {
      const value = getEditorValue();
      if (value.textContent.trim() !== '' && !value.querySelector('img')) {
        setError(null);
      }
    },
    groupId,
  });
  const [error, setError] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [progress, setProgress] = useState(null);

  const onSubmit = async (files, cb) => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    const value = getEditorValue();
    if (value.textContent.trim() === '' && !value.querySelector('img')) {
      setIsSubmiting(false);
      return setError(t('Post.Comment cannot be blank'));
    }
    setError(null);

    try {
      const commentPayload = {
        files,
        groupId: 0,
        likes: [],
        name: `${user.firstName} ${user.lastName}`,
        text: checkLink(value),
        userId: user.id,
        postId: id,
      };

      const result = (await createComment(
        commentPayload,
        files && files.length > 0 ? setProgress : () => {},
      ).catch(err => {
        setError(t(err.response.data.message));
      })).data;
      setProgress(null);
      const newComments = [
        ...comments,
        {
          ...commentPayload,
          id: result.id,
          isNew: true,
          files: result.files,
          isTranslationAllowed: result.isTranslationAllowed
        },
      ];
      setComments(newComments);
      if (cb) {
        cb();
      }
      setIsSubmiting(false);
    } catch (ex) {
      setIsSubmiting(false);
      // eslint-disable-next-line
      console.log(ex);
    }

    const newValue = document.createElement('div');
    updateValue(newValue);
  };

  return {
    Editor,
    error,
    onSubmit,
    focusOnEditor,
    progress,
    isSubmiting,
  };
}
