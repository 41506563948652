import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { getKidsStatusAndAbsencesByGroup } from 'library/api/kids';
import Loader from 'library/common/commonComponents/Loader';
import { useHistory } from 'react-router-dom';

import styles from './groupKidsListPopup.module.scss';

export default function GroupKidsListPopup({ isOpened, closePopup, title, groupId, groupModules }) {
  const { t } = useTranslation();
  const history = useHistory();

  const goChildPage = (kidId, parent1Id) => {
    history.push({
      pathname: '/administration/users/list/1',
      search: `?editChild=${kidId}&parentId=${parent1Id}`,
    });
  };

  const [initialUsers, setInitialUsers] = useState([]);
  const [initialUsersLoaded, setInitialUsersLoaded] = useState(false);

  useEffect(() => {
    setInitialUsersLoaded(true);
    getKidsStatusAndAbsencesByGroup(groupId).then(res => {
      setInitialUsers(res.data);
      setInitialUsersLoaded(true);
    });
  }, []);

  const hasCheckinOutModule = groupModules.find(
    module => module.moduleKey === 'checkinout' && module.activeStatus,
  );

  // either: "VACATION", "SICK", "CHECKOUT", "CHECKIN" or null
  const getKidsStatus = kid => {
    if (kid.kidAbsence) {
      return kid.kidAbsence.vacation ? 'VACATION' : 'SICK';
    } else if (hasCheckinOutModule) {
      return kid.checkInOutStatus;
    } else {
      return null;
    }
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={closePopup}
      header={title}
      footer={<Button onClick={closePopup}>{t('Popup.Close')}</Button>}
    >
      {initialUsersLoaded ? (
        <div>
          <div className={styles.container}>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={568}>
              {initialUsers.map(kid => {
                const groupId = kid.groupId ? kid.groupId : '';
                const kidStatus = getKidsStatus(kid);
                return (
                  <div key={kid.kidId} className={styles.itemContainer}>
                    <div className={styles.item}>
                      <div className={styles.itemAvatar}>
                        <img
                          key='bottomGroupLogo'
                          src={kid.groupLogoUrl ? `${kid.groupLogoUrl}` : defaultAvatar}
                          alt='logo'
                          style={{ width: '100%' }}
                          className={styles.logoImg}
                        />
                      </div>

                      {/* add styling according to checkin/out status and whne module is active */}
                      <div className={styles.itemInfo}>
                        <div
                          className={cn(
                            styles.itemName,
                            kidStatus != 'CHECKIN' && styles.itemNameCheckout,
                            'notranslate',
                          )}
                        >
                          {kid.name}
                        </div>
                        {kidStatus && (
                          <div
                            className={cn(
                              styles.itemStatus,
                              kidStatus === 'CHECKIN' && styles.itemStatusCheckin,
                              kidStatus === 'CHECKOUT' && styles.itemStatusCheckout,
                              kidStatus === 'VACATION' && styles.itemStatusVacation,
                              kidStatus === 'SICK' && styles.itemStatusSick,
                            )}
                          >
                            {kidStatus === 'CHECKIN' && t('Checkinout.Checked In')}
                            {kidStatus === 'CHECKOUT' && t('Checkinout.Checked Out')}
                            {kidStatus === 'VACATION' && 'Urlaub'} {/* TODO Translation*/}
                            {kidStatus === 'SICK' && 'Krank'} {/* TODO Translation*/}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      {groupId ? (
                        <Link to={`/groups/${groupId}`}>
                          <Button size='sm' isFullWidth={true} alignText='left'>
                            <i className='fa fa-lg fa-address-card' /> Portfolio
                            {/* TODO translation*/}
                          </Button>
                        </Link>
                      ) : null}

                      {kid.parent1Id ? (
                        <Button
                          size='sm'
                          isFullWidth={true}
                          alignText='left'
                          onClick={() => {
                            goChildPage(kid.kidId, kid.parent1Id);
                          }}
                        >
                          <i className='fa fa-lg fa fa-pencil-square-o' /> Bearbeiten
                          {/* TODO translation*/}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Popup>
  );
}
