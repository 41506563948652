import React from 'react';

import Pagination from 'library/common/commonComponents/Pagination';
import MembersTableHeader from '../MembersTableHeader';
import MembersTableRow from '../MembersTableRow';
import MembersEmpty from '../MembersEmpty';

import styles from './membersTable.module.scss';

export default function MembersTable({
  tableData,
  patchTableData,
  onCheckAllHandler,
  isCheckedAll,
  setSorting,
  currentPage,
  totalPages,
  onPageClick,
  reloadTable,
  userId,
  showBottomNotificationFromProps,
  user,
}) {
  return (
    <div>
      <table className={`${styles.table} ${styles.tableOfMembers}`}>
        <MembersTableHeader
          onCheckAllHandler={onCheckAllHandler}
          isCheckedAll={isCheckedAll}
          onClick={setSorting}
        />
        <tbody>
          {!tableData || !tableData.length ? (
            <MembersEmpty />
          ) : (
            tableData.map(item => (
              <MembersTableRow
                userId={userId}
                key={item.user.id}
                isCheckedAll={isCheckedAll}
                patchTableData={patchTableData}
                data={item}
                reloadTable={reloadTable}
                showBottomNotificationFromProps={showBottomNotificationFromProps}
                user={user}
              />
            ))
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          numberOfPages={totalPages}
          onPageClick={onPageClick}
          withoutRouting
        />
      ) : null}
    </div>
  );
}
