import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './membersTableHeader.module.scss';
import { getConfig } from 'library/api/kita';

export default function MembersTableHeader({ isCheckedAll, onCheckAllHandler, onClick }) {
  const { t } = useTranslation();

  const handleClick = item => {
    if (!item.title) {
      return;
    }
    const data = {
      sortBy: item.data,
    };
    onClick(data);
  };

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    getConfig().then(res => {
      const data = res.data;
      setShowLogin(data[0]);
    });
  }, []);

  const tableColumns = [
    {
      title: t('GroupPanel.Name'),
      data: 'user.lastName',
    },
    {
      title: t('GroupPanel.Member since'),
      data: 'createdAt',
    },
    {
      title: t('GroupPanel.Last Visit'),
      data: 'lastVisit',
    },
    {
      title: t('GroupPanel.Role'),
      data: 'groupRole.role.roleName',
    },
    {
      title: '',
      data: 'deleteButtons',
    },
  ];

  return (
    <thead>
      <tr>
        <th style={{ width: '30px' }}>
          <CheckBox isChecked={isCheckedAll} onChange={onCheckAllHandler} />
        </th>
        <th style={{ width: '30px' }} />
        <th
          className={styles.th}
          style={{ width: '30px' }}
          key={'user.lastName'}
          onClick={() =>
            handleClick({
              title: t('GroupPanel.Name'),
              data: 'user.lastName',
            })
          }
        >
          {t('GroupPanel.Name')}
        </th>
        <th
          className={styles.th}
          style={{ width: '30px' }}
          key={'createdAt'}
          onClick={() =>
            handleClick({
              title: t('GroupPanel.Member since'),
              data: 'createdAt',
            })
          }
        >
          {t('GroupPanel.Member since')}
        </th>
        {showLogin && (
          <th
            className={styles.th}
            style={{ width: '30px' }}
            key={'lastVisit'}
            onClick={() =>
              handleClick({
                title: t('GroupPanel.Last Visit'),
                data: 'lastVisit',
              })
            }
          >
            {t('GroupPanel.Last Visit')}
          </th>
        )}
        <th
          className={styles.th}
          style={{ width: '30px' }}
          key={'groupRole.role.roleName'}
          onClick={() =>
            handleClick({
              title: t('GroupPanel.Role'),
              data: 'groupRole.role.roleName',
            })
          }
        >
          {t('GroupPanel.Role')}
        </th>
        <th
          className={styles.th}
          style={{ width: '30px' }}
          key={'deleteButtons'}
          onClick={() =>
            handleClick({
              title: '',
              data: 'deleteButtons',
            })
          }
        >
          {''}
        </th>
      </tr>
    </thead>
  );
}
