import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import store from 'main/store/configureStore';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import moment from 'moment/min/moment-with-locales';
import { formatDate } from 'library/utilities/dateMoment';
import { markKidAbsenceReadToParent, markKidAbsenceRead, markMemberAbsenceRead } from '../../../../library/api/user';
import { showBottomNotification } from '../../../../library/common/commonActions/notificationsActions';
import loadingIndicator from '../../../../resources/images/loadingIndicator.gif';

import styles from './groupAbsence.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import Popup from 'library/common/commonComponents/Popups/Popup';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import RadioButton from 'library/common/commonComponents/RadioButton';

const onConfirmEvent = new Event('onAbsenceConfirm');
const currentDate = new Date();

const parseDate = dateString => {
  const dateTimeParts = dateString.split(' ');
  const datePart = dateTimeParts[0];
  const timePart = dateTimeParts[1];

  const dateParts = datePart.split('-');
  const timeParts = timePart.split(':');

  return new Date(
    parseInt(dateParts[0], 10),
    parseInt(dateParts[1], 10) - 1,
    parseInt(dateParts[2], 10),
    parseInt(timeParts[0], 10),
    parseInt(timeParts[1], 10),
  );
};

const confirmAbsence = (
  t,
  item,
  onConfirming,
  onConfirmed,
  groupId,
  shouldSendMessageToParents,
  symptom,
  time
) => {
  if (item.returnReceiptDate === null) {
    onConfirming(true);
    let promise;
    if (item.kid) {
      if(shouldSendMessageToParents) {
        promise = markKidAbsenceReadToParent(item.id, shouldSendMessageToParents, symptom, time);
      }
      else {
        promise = markKidAbsenceRead(item.id);
      }

    } else {
      promise = markMemberAbsenceRead(item.id, groupId);
    }
    promise
      .then(() => {
        onConfirming(false);
        onConfirmed(true);
        dispatchEvent(onConfirmEvent);
      })
      .catch(() => {
        onConfirming(false);
        onConfirmed(false);
        store.dispatch(
          showBottomNotification(t('AccountSettings.NotificationsSection.Error'), {
            isFail: true,
          }),
        );
      });
  }
};

const getBackgroundStyle = index => {
  if (index % 2 === 0) {
    return styles.indexEven;
  } else {
    return styles.indexOdd;
  }
};

const getStyle = (item, confirmed) => {
  if (confirmed) {
    return styles.returnReceipt;
  } else {
    if (currentDate.setHours(0, 0, 0, 0) > parseDate(item.endDate).setHours(0, 0, 0, 0)) {
      return '';
    }
    return styles.noReturnReceipt;
  }
};

const AbsenceRow = ({ item, userLanguage, index, setSelectedKidForPopup, groupId, messageToParent }) => {
  const langCode =
    userLanguage && userLanguage.length > 2 ? userLanguage.substring(0, 2).toLowerCase() : 'en';
  const { t } = useTranslation();
  const [isConfirming, setIsConfirming] = useState();
  const [confirmed, setConfirmed] = useState();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [selectedAbsenceData, setSelectedAbsenceData] = useState(null);
  const [symptom, setSymptom] = useState('');
  const [symptomTime, setSymptomTime] = useState('48h');

  const confirmAbsenceOnClick = (t, item, onConfirming, onConfirmed, groupId) => {
    if(messageToParent) {
      setShowMessagePopup(true);
      setSelectedAbsenceData({ t, item, onConfirming, onConfirmed, groupId });
    }
    else {
      confirmAbsence(t, item, setIsConfirming, setConfirmed, groupId)
    }
  };

  useLayoutEffect(() => {
    moment.locale(langCode);
  }, [langCode]);

  useLayoutEffect(() => {
    setConfirmed(item.returnReceiptDate !== null);
  }, [item]);

  if (confirmed === undefined) {
    return null;
  }

  return (
    <div style={{ display: 'contents' }}>
      <Popup
        isOpened={showMessagePopup}
        closePopup={() => setShowMessagePopup(false)}
        body={
          <div className={styles.symptomWrapper}>
            <div className={styles.containerEnable}>
              {t('Absences.AdditionalMessage.Message')}
            </div>
            <div className={styles.formControl}>
              <div className={cn(styles.label,styles.noMarginBottom)}>{t('Absences.AdditionalMessage.Time')}</div>
              <RadioButton
                checked={symptomTime === '48h'}
                label={t('Absences.AdditionalMessage.48h')}
                onClick={() => setSymptomTime('48h')}
              />
              <RadioButton
                checked={symptomTime === '24h'}
                label={t('Absences.AdditionalMessage.24h')}
                onClick={() => setSymptomTime('24h')}
              />
           </div>
           <div className={styles.formControl}>
              <div className={styles.label}>{t('Absences.AdditionalMessage.Symptom')}</div>
              <TextArea
                value={symptom}
                placeholder=''
                onChange={e =>
                  setSymptom(e.target.value)
                }
              />
           </div>
          </div>
        }
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonSubmit}>
              <Button
                onClick={() => {
                  confirmAbsence(
                    selectedAbsenceData.t,
                    selectedAbsenceData.item,
                    selectedAbsenceData.onConfirming,
                    selectedAbsenceData.onConfirmed,
                    selectedAbsenceData.groupId,
                    true,
                    symptom,
                    symptomTime
                  );
                  setShowMessagePopup(false);
                }}
                type='primary'
              >
                {t('Absences.AdditionalMessage.Yes')}
              </Button>
            </div>
            <div className={styles.buttonCancel}>
              <Button
                onClick={() => {
                  confirmAbsence(
                    selectedAbsenceData.t,
                    selectedAbsenceData.item,
                    selectedAbsenceData.onConfirming,
                    selectedAbsenceData.onConfirmed,
                    selectedAbsenceData.groupId,
                    false,
                  );
                  setShowMessagePopup(false);
                }}
              >
                {t('Absences.AdditionalMessage.No')}
              </Button>
            </div>
          </div>
        }
      >
      </Popup>
      <tr
        className={getBackgroundStyle(index) + ' ' + getStyle(item, confirmed)}
        onClick={() => {
          setSelectedKidForPopup(item);
        }}
      >
        <td style={{ paddingInlineStart: '10px' }} onClick={e => e.stopPropagation()}>
          {isConfirming ? (
            <img alt='loading' src={loadingIndicator} height='18' width='18' />
          ) : confirmed ? (
            <i className='fa fa-check-square-o fa-2x' />
          ) : (
            <>
              <ReactTooltip />
              <p data-tip={t('Absences.confirmAbsence')}>
                <i
                  className='fa fa-square-o fa-2x'
                  onClick={() => {
                    confirmAbsenceOnClick(t, item, setIsConfirming, setConfirmed, groupId);
                  }}
                />
              </p>
            </>
          )}
        </td>
        <td className={cn('notranslate', styles.textUnderlined)}>{item.firstName}</td>
        <td className={cn('notranslate', styles.textUnderlined)}>{item.lastName}</td>
        <td
          onClick={() => {
            setSelectedKidForPopup(item);
          }}
        >
          {item.reason}
        </td>
        <td
          onClick={() => {
            setSelectedKidForPopup(item);
          }}
        >
          {item.sickness}
        </td>
        <td>{formatDate(item.reportedDate)}</td>
        <td>
          <a className={getStyle} href={`/profile/${item.reportedById}`}>
            {item.reportedBy}
          </a>
        </td>
        <td>{formatDate(item.startDate)}</td>
        <td>{formatDate(item.endDate)}</td>
        <td>
          {item.returnReceiptDate !== null
            ? t('Absences.ReturnReceipt.Confirmed')
                .replace('{date}', formatDate(item.returnReceiptDate))
                .replace('{user}', item.returnReceiptName)
            : t('Absences.ReturnReceipt.NotConfirmed')}
        </td>
      </tr>
    </div>
  );
};

const mapStateToProps = ({ userReducer, groupReducer }) => ({
  userLanguage: userReducer.langCode,
  groupId: groupReducer.groupId,
});

export default connect(mapStateToProps)(AbsenceRow);
