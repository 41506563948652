import { useEffect, useState, useReducer, useRef, useMemo } from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';

import * as notificationsApi from 'library/api/notifications';
import * as postsApi from 'library/api/posts';
import { notify, updateNotificationsConfiguration } from 'library/utilities/notifications';
import { getUnreadMessagesCountPayload } from 'library/api/chat';
import store from 'main/store/configureStore';

import { goToNewKita } from 'library/common/commonActions/kitaActions';
import cachedRequest from './useCachedRequest';
import { goToKita } from '../commonActions/kitaActions';

export default function useNotificationsLoading({
  isOpened,
  filters = null,
  kitas = [],
  updateCountOfNotifications,
  updateCountOfTaxReceipt,
  activeKita,
  setUnreadMessagesCountsKitas,
}) {
  useEffect(() => {
    isNotificationsEnded.current = false;
    isNotificationsLoading.current = false;
    currentPaginationPage.current = 1;
  }, [isOpened]);
  const { history } = useReactRouter();

  const refScroll = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const isNotificationsEnded = useRef(false);
  const isNotificationsLoading = useRef(false);
  const currentPaginationPage = useRef(1);
  const [{ notifications, totalNotificationsCount }, dispatch] = useReducer(notificationsReducer, {
    notifications: [],
    totalNotificationsCount: 0,
  });

  const kitaIds = useMemo(() => kitas && kitas.map(kita => kita.kitaId), [kitas]);

  const { t } = useTranslation();
  useEffect(() => {
    if (!kitaIds) return;

    import('library/api/socket').then(({ addSocketListener }) =>
      addSocketListener(({ data, metadata }) => {
        handleMetadataChange(metadata);
        if (data.payload) {
          if (
            data.payload.type === 'existing-user-added-to-kita' ||
            data.payload.type === 'kita-request-approved' ||
            store.getState().kitaReducer.activeKita.kitaId === data.kitaId ||
            data.kitaId === null
          ) {
            addNotification(data);
            notify(data, t, readNotification);
          }
        }
      }),
    );
    // eslint-disable-next-line
  }, [kitaIds]);
  const getUnreadNotificationsCountData = notificationsApi.getUnreadNotificationsCountPayload(
    kitaIds,
  );

  useEffect(() => {
    if (kitaIds && kitaIds.length) {
      cachedRequest(getUnreadNotificationsCountData)
        .then(({ data }) => {
          updateCountOfNotifications(data);
        })
        .catch(err => console.log(err));
      postsApi.getUnreadTaxReceiptCount(kitaIds).then(({ data }) => {
        if (typeof updateCountOfTaxReceipt !== 'undefined') {
          updateCountOfTaxReceipt(data);
        }
      });
    }

    if (kitaIds && kitaIds.length) {
      cachedRequest(getUnreadMessagesCountPayload)
        .then(({ data }) => {
          const newUnreadMessagesCounts = {};
          for (const [key, value] of Object.entries(data)) {
            if (key !== '-1') newUnreadMessagesCounts[key] = value.count;
          }

          setUnreadMessagesCountsKitas(newUnreadMessagesCounts);
        })
        .catch(err => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitaIds, activeKita]);

  const notificationsPayload = {
    currentPaginationPage,
    isNotificationsEnded,
    dispatch,
    isNotificationsLoading,
    filters,
  };

  useEffect(() => {
    isNotificationsEnded.current = false;
    currentPaginationPage.current = 1;
    dispatch({ type: 'removeNotifications' });
    if (isOpened) {
      setIsLoading(true);
      loadNotifications(notificationsPayload).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [isOpened, filters]);

  const addNotification = notification =>
    addNotificationHandler({
      notification,
      updateCountOfNotifications,
      kitaIds,
    });

  const markAllAsRead = () =>
    markAllAsReadHandler({
      updateCountOfNotifications,
      kitaIds,
      activeKita,
    });
  const readNotification = notification => {
    readNotificationHandler({
      notification,
      history,
      updateCountOfNotifications,
      kitaIds,
    });
  };

  const loadPage = page =>
    loadPageHandler({ page, setIsLoading, dispatch, filters, loadFn: loadNotifications });

  const onNotificationsScroll = () =>
    onNotificationsScrollHandler({
      isLoading,
      isNotificationsLoading,
      notifications,
      isNotificationsEnded,
      refScroll,
      setIsLoading,
      notificationsPayload,
      loadFn: loadNotifications,
    });

  return {
    isLoading,
    notifications,
    onNotificationsScroll,
    markAllAsRead,
    addNotification,
    refScroll,
    totalNotificationsCount,
    loadPage,
    readNotification,
  };
}

export function notificationsReducer(state, action) {
  switch (action.type) {
    case 'prependNotification':
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
        totalNotificationsCount: state.totalNotificationsCount + 1,
      };
    case 'removeNotifications':
      return { ...state, notifications: [], totalNotificationsCount: 0 };
    case 'addNotifications':
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
      };
    case 'addNotification':
      return {
        ...state,
        notifications: [action.notification].concat(state.notifications),
        totalNotificationsCount: state.totalNotificationsCount + 1,
      };
    case 'markAllAsRead':
      return {
        ...state,
        notifications: state.notifications.map(notification => ({ ...notification, isRead: true })),
      };
    case 'setTotalNotificationsCount':
      return { ...state, totalNotificationsCount: action.value };
    default:
      return state;
  }
}

export const onNotificationsScrollHandler = ({
  isLoading,
  isNotificationsLoading,
  notifications,
  isNotificationsEnded,
  refScroll,
  setIsLoading,
  notificationsPayload,
  loadFn,
}) => {
  if (
    !isLoading &&
    !isNotificationsLoading.current &&
    notifications &&
    !isNotificationsEnded.current &&
    refScroll.current.scrollTop >
      (refScroll.current.clientHeight * notifications.length) / 10 -
        refScroll.current.clientHeight / 1.4
  ) {
    setIsLoading(true);
    loadFn(notificationsPayload);
    setIsLoading(false);
  }
};

export async function loadNotifications({
  currentPaginationPage,
  isNotificationsEnded,
  dispatch,
  isNotificationsLoading,
  filters,
}) {
  isNotificationsLoading.current = true;
  try {
    const {
      data: { content, totalElements },
    } = await notificationsApi.getNotifications(currentPaginationPage.current, 10, filters);

    if (content.length > 0) {
      dispatch({
        type: 'addNotifications',
        notifications: content,
      });
      dispatch({ type: 'setTotalNotificationsCount', value: totalElements });
    } else if (content.length < 10) {
      isNotificationsEnded.current = true;
    }
    currentPaginationPage.current += 1;
  } catch (ex) {
    // eslint-disable-next-line
    console.error(ex);
  } finally {
    isNotificationsLoading.current = false;
  }
}

export async function getUnreadNotificationsCount(kitaIds, updateCountOfNotifications) {
  if (kitaIds && kitaIds.length) {
    const { data } = await notificationsApi.getUnreadNotificationsCount(kitaIds);
    updateCountOfNotifications(data);
  }
}

export async function readNotificationHandler({
  notification,
  history,
  updateCountOfNotifications,
  kitaIds,
}) {
  if (
    !notification.isRead ||
    notification.payload.type === 'existing-user-added-to-kita' ||
    notification.payload.type === 'kita-request-approved'
  ) {
    await notificationsApi.patchNotification([{ id: notification.id, isRead: true }]);
    await getUnreadNotificationsCount(kitaIds, updateCountOfNotifications);
  }
  const {
    groupId,
    postId,
    postType,
    eventType,
    galleryId,
  } = notification.payload.notificationEntity;

  if (notification.eventType === 'CALENDAR') {
    history.push('/calendar');
    return;
  }

  if (notification.eventType === 'FOOD_ORDER_REMINDER') {
    if (notification.payload.notificationEntity.groupId) {
      history.push(`/groups/${notification.payload.notificationEntity.groupId}/essensbestellung`);
    }
    return;
  }

  if (notification.eventType === 'KID_CONTRACT_END') {
    return;
  }

  switch (notification.payload.type) {
    case 'super-admin-group-access':
      history.push(`/groups/${groupId}`);
      break;
    case 'user-added-to-kita':
      history.push('/');
      break;
    case 'kita-request-approved':
    case 'existing-user-added-to-kita':
      store.dispatch(goToNewKita(notification.payload.notificationEntity.kitaName));
      history.push('/');
      break;
    case 'global-notification':
      history.push('/');
      break;
    case 'new-friendship-request':
    case 'friendship-request-accepted':
      history.push(
        `/profile/${notification.payload &&
          notification.payload.actionBy &&
          notification.payload.actionBy.userId}`,
      );
      break;
    case 'kid-check-in-out':
      break;
    default:
      if (postId === null && postType.toUpperCase() === 'GALLERY') {
        history.push(`/groups/${groupId}/gallery?${galleryId}`);
      } else {
        history.push(`/groups/${groupId}/stream?${postId}`);
      }
  }

  if (notification.kitaId) {
    store.dispatch(goToKita(notification.kitaId));
  }
}

export async function markAllAsReadHandler({ updateCountOfNotifications, kitaIds, activeKita }) {
  await notificationsApi.markAllAsRead(activeKita.virtual);
  await getUnreadNotificationsCount(kitaIds, updateCountOfNotifications);
}

export async function addNotificationHandler({
  notification,
  updateCountOfNotifications,
  kitaIds,
}) {
  if (notification) {
    await getUnreadNotificationsCount(kitaIds, updateCountOfNotifications);
  }
}

export async function loadPageHandler({ page, setIsLoading, dispatch, filters, loadFn }) {
  dispatch({ type: 'removeNotifications' });
  setIsLoading(true);
  await loadFn({
    currentPaginationPage: { current: page },
    isNotificationsEnded: { current: false },
    isNotificationsLoading: { current: false },
    dispatch,
    filters,
  });
  setIsLoading(false);
}

export function handleMetadataChange(metadata) {
  const userId = store.getState().userReducer.id;
  if (!metadata) return;

  if (metadata.type === 'notification-settings-change') {
    updateNotificationsConfiguration(userId);
  }
}
