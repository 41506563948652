import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'main/store/configureStore';

import { getCalendarEventById } from 'library/api/calendar';
import { updateTask, deleteTask } from 'library/api/tasks';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useSelector } from 'react-redux';
import {
  getKidAbsenceByTimespan,
  getKidAbsenceByTimespanAndGroup,
  getOwnKidAbsenceByTimespan,
  getOwnKidAbsenceByTimespanAndGroup,
} from '../../api/user';

import moment from 'moment/min/moment-with-locales';
import { getGroupsWhereAdmin } from 'library/api/groups';

const useCalendarEvents = (
  getCalendarEvents,
  getCalendarSettings,
  deleteEvent,
  addEvent,
  updateEvent,
  defaultFilters,
  id,
  getEditableValue,
  calendarModulePlace,
  currentUserLanguage,
  groupAdminStatus,
) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [reloadEvents, setReloadEvents] = useState(false);
  const [calendarId, setCalendarId] = useState('');

  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    setIsLoading(true);
    getCalendarEvents(id, filters, calendarModulePlace, currentUserLanguage)
      .then(async res => {
        if (res.status === 200) {
          const absenceFilter = filters.filter(f => f.name === 'showAbsences');
          const showAbsences =
            absenceFilter != null && absenceFilter.length > 0 && absenceFilter[0].checked;

          const absenceEvents = [];
          if (showAbsences) {
            const curDate = filters.find(f => f.name === 'currentViewDate').value;
            const calendarStart = new Date(curDate.getFullYear(), curDate.getMonth() - 1, 0);
            const calendarEnd = new Date(curDate.getFullYear(), curDate.getMonth() + 2, 0);

            let absenceData = [];
            if (
              user.administrationAccess ||
              user.superAdminStatus ||
              groupAdminStatus ||
              user.employee
            ) {
              if (calendarModulePlace === 'dashboardCalendar') {
                absenceData = await getKidAbsenceByTimespan(
                  calendarStart.getTime(),
                  calendarEnd.getTime(),
                );
                absenceData = absenceData.data;
              } else if (calendarModulePlace === 'groupCalendar') {
                absenceData = await getKidAbsenceByTimespanAndGroup(
                  id,
                  calendarStart.getTime(),
                  calendarEnd.getTime(),
                );
                absenceData = absenceData.data;
              } else {
                absenceData = await getOwnKidAbsenceByTimespan(
                  calendarStart.getTime(),
                  calendarEnd.getTime(),
                );
                absenceData = absenceData.data;
              }
            } else {
              if (calendarModulePlace === 'groupCalendar') {
                absenceData = await getOwnKidAbsenceByTimespanAndGroup(
                  id,
                  calendarStart.getTime(),
                  calendarEnd.getTime(),
                );
                absenceData = absenceData.data;
              } else {
                // get all groups where user is admin and get absences for each group
                const adminGroups = (await getGroupsWhereAdmin()).data;
                const absencesPromises = adminGroups.map(async group => {
                  const absences = await getKidAbsenceByTimespanAndGroup(
                    group.id,
                    calendarStart.getTime(),
                    calendarEnd.getTime(),
                  );
                  return absences.data;
                });
                const absencesPerGroup = await Promise.all(absencesPromises);
                const absencesAllGroups = [].concat(...absencesPerGroup);

                absenceData = await getOwnKidAbsenceByTimespan(
                  calendarStart.getTime(),
                  calendarEnd.getTime(),
                );
                absenceData = [...absenceData.data, ...absencesAllGroups];
              }
            }

            absenceData.forEach(absence => {
              const event = {
                editable: false,
                title: t('Calendar.AbsenceEventTitle')
                  .replace('{firstName}', absence.kid ? absence.kid.firstName : '')
                  .replace('{lastName}', absence.kid ? absence.kid.lastName : '')
                  .replace('{sickness}', absence.sickness)
                  .replace('{reason}', absence.reason),
                startTime: '',
                endTime: '',
                color: absence?.vacation ? 'purple' : 'red',
                isAbsenceEvent: true,
                start: moment(absence.startDate, 'YYYY-MM-DD HH:mm')
                  .toDate()
                  .getTime(),
                end: moment(absence.endDate, 'YYYY-MM-DD HH:mm')
                  .toDate()
                  .getTime(),
                absence: absence,
              };
              absenceEvents.push(event);
            });
          }

          const generatedEvents = handleRepeatedEvents(
            res.data.content.filter(e => e.repeatType && e.repeatType !== 'none'),
          );

          let allEvents = res.data.content.concat(generatedEvents).concat(absenceEvents);

          if (!user.administrationAccess && !user.superAdminStatus) {
            allEvents = allEvents.filter(e => {
              return (
                !e.parentParticipants ||
                e.parentParticipants.length === 0 ||
                e.parentParticipants.filter(pp => pp.userId === user.id).length > 0
              );
            });
          }

          handleDuplicateEventNames(allEvents);

          setEvents(
            allEvents.length
              ? allEvents.map(event => {
                  const date = new Date(event.end);
                  const hour = date.getHours();
                  return event.isAbsenceEvent
                    ? event
                    : event.postType === 2
                    ? {
                        ...event,
                        editable: getEditableValue(event.userId, null, event.group),
                        title: event.canceled
                          ? `${event.title}(${t('Calendar.canceled')})`
                          : event.title,
                        group: event.group,
                        groupId: '',
                        startTime: '',
                        endTime: '',
                        color:
                          event.group &&
                          event.group.colorCode &&
                          calendarModulePlace === 'dashboardCalendar'
                            ? event.group.colorCode
                            : event.color,
                      }
                    : {
                        ...event,
                        title: `${t('Tasks.Deadline')}: ${event.title}`,
                        start: event.allDay
                          ? event.end
                          : hour
                          ? event.end - 1000 * 60 * 60
                          : date.setHours(0, 0),
                        end: hour ? event.end : date.setHours(1, 0),
                        editable: false,
                        group: event.group,
                        color: event.taskList && event.taskList.color,
                        groupId: '',
                        startTime: '',
                        endTime: '',
                      };
                })
              : [],
          );
          setCalendarId(res.data.id || id);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, [reloadEvents, filters, id]);

  useEffect(() => {
    getCalendarSettings(id, calendarModulePlace)
      .then(res => {
        if (res.status === 200) {
          setCalendarSettings(res.data || {});
        }
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [reloadEvents]);

  const handleDuplicateEventNames = allEvents => {
    const eventsDateMap = {};

    for (const ev of allEvents) {
      const start = new Date(ev.start);
      const end = new Date(ev.end);

      for (const d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        if (!(d in eventsDateMap)) {
          eventsDateMap[d] = [];
        }
        eventsDateMap[d].push(ev);
      }
    }
    for (const d in eventsDateMap) {
      const duplicates = eventsDateMap[d].filter(
        e =>
          eventsDateMap[d].filter(e2 => e2.title === e.title && e.groupId !== e2.groupId).length >
          0,
      );
      duplicates.forEach(
        e => (e.title += ` (${e.group ? e.group.groupName : t('Calendar.Profile Calendar')})`),
      );
    }
  };

  const convertDaysByteToArray = byteRep => {
    const result = [];
    for (let i = 0; i < 7; i++) {
      // eslint-disable-next-line no-bitwise
      if (((byteRep >> i) & 1) === 1) {
        result.push(i);
      }
    }
    return result;
  };

  const createShiftedEventCopy = (d, startDate, e) => {
    const diff = d - startDate;
    const newStart = d.getTime();
    // add the difference of the original start and the moved start date such that
    // original event length is persisted
    const newEnd = new Date(e.end + diff).getTime();
    const newEvent = Object.assign({}, e);
    newEvent.start = newStart;
    newEvent.end = newEnd;

    return newEvent;
  };

  const handleRepeatedEvents = repeatedEvents => {
    const result = [];
    const curDate = filters.find(f => f.name === 'currentViewDate').value;

    const calendarStart = new Date(curDate.getFullYear(), curDate.getMonth() - 1, 0);
    // compute repeated events until this date
    const calendarEnd = new Date(curDate.getFullYear(), curDate.getMonth() + 2, 0);

    repeatedEvents.forEach(e => {
      const { start, repeatEndDate, repeatType, repeatDays, repeatWeeklyInterval } = e;
      const days = convertDaysByteToArray(repeatDays);
      // the start date of the original event
      let startDate = new Date(start);
      // the end of the repetition
      let repeatEnd =
        !repeatEndDate || repeatEndDate === 0
          ? calendarEnd
          : new Date(repeatEndDate + 24 * 60 * 60 * 1000); // add 24 hours to end date to make it inclusive
      if (repeatEnd > calendarEnd) {
        repeatEnd = calendarEnd;
      }
      const extendedRepeatEnd = new Date(repeatEnd);
      extendedRepeatEnd.setDate(extendedRepeatEnd.getDate() + 3);

      const iterStart = new Date(startDate);

      switch (repeatType) {
        case 'daily':
          iterStart.setDate(iterStart.getDate() + 1);
          // generate daily copies of the original event up to the repetition end date
          for (let d = iterStart; d < repeatEnd; d.setDate(d.getDate() + 1)) {
            if (d >= calendarStart) {
              result.push(createShiftedEventCopy(d, startDate, e));
            }
          }
          break;
        case 'weekly':
          // eslint-disable-next-line no-case-declarations
          let startEventUnhandled = true;
          iterStart.setDate(iterStart.getDate());
          // generate weekly copies of the original event up to the repetition end date
          // for the specified days of the week
          for (let d = iterStart; d < repeatEnd; d.setDate(d.getDate() + 1)) {
            if (days.includes(d.getDay()) && d >= calendarStart) {
              const eCpy = createShiftedEventCopy(d, startDate, e);
              if (startEventUnhandled) {
                e.start = eCpy.start;
                e.end = eCpy.end;
                startDate = new Date(e.start);
                startEventUnhandled = false;
              } else {
                result.push(eCpy);
              }
            }

            if (d.getDay() === 0 && repeatWeeklyInterval > 1) {
              d.setDate(d.getDate() + 7 * (repeatWeeklyInterval - 1));
            }
          }
          break;
        case 'monthly':
          iterStart.setMonth(iterStart.getMonth() + 1);
          // generate monthly copies of the original event up to the repetition end date
          for (let d = iterStart; d < extendedRepeatEnd; d.setMonth(d.getMonth() + 1)) {
            const actualDate = new Date(d);
            if (d.getDate() !== startDate.getDate()) {
              actualDate.setDate(0);
            }
            if (actualDate >= repeatEnd) {
              break;
            }

            if (actualDate >= calendarStart) {
              result.push(createShiftedEventCopy(actualDate, startDate, e));
            }
          }
          break;
        case 'annually':
          iterStart.setFullYear(iterStart.getFullYear() + 1);
          // generate annually copies of the original event up to the repetition end date
          for (let d = iterStart; d < extendedRepeatEnd; d.setFullYear(d.getFullYear() + 1)) {
            if (d.getDate() === startDate.getDate() && d >= calendarStart) {
              result.push(createShiftedEventCopy(d, startDate, e));
            } else {
              const numDays = new Date(d.getFullYear(), d.getMonth(), 0).getDate();
              if (numDays >= startDate.getDate()) {
                const actualDate = new Date(d);
                actualDate.setDate(0);

                if (actualDate >= calendarStart) {
                  result.push(createShiftedEventCopy(actualDate, startDate, e));
                }
              }
            }
          }
          break;
        default:
          break;
      }
    });

    return result;
  };

  const patchEventsData = (action, event) => {
    const newEvents = events.slice();
    let index;
    switch (action) {
      case 'delete':
        index = newEvents.findIndex(newEvent => newEvent.id === event.id);
        if (index !== -1) {
          newEvents.splice(index, 1);
        }
        setEvents(newEvents);
        break;

      case 'add':
        setEvents(newEvents.concat(event));
        break;

      case 'update':
        index = newEvents.findIndex(newEvent => newEvent.id === event.id);
        if (index !== -1) {
          newEvents.splice(index, 1, event);
        }
        setEvents(newEvents);
        break;

      default:
    }
  };

  const onDeleteEvent = event => {
    if (event.postType === 2) {
      return deleteCalendarEvent(event);
    } else {
      return deleteCalendarTask(event);
    }
  };

  const deleteCalendarEvent = event => {
    return deleteEvent(event.id)
      .then(res => {
        if (res.status === 200) {
          patchEventsData('delete', event);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const deleteCalendarTask = event => {
    return deleteTask(event.id)
      .then(res => {
        if (res.status === 200) {
          patchEventsData('delete', event);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getEventInfo = eventClick => {
    return getCalendarEventById(eventClick.id)
      .then(res => {
        if (res.status === 200) {
          return {
            success: true,
            event: {
              ...res.data,
              editable: getEditableValue(res.data.user.id, null, eventClick.extendedProps.group),
              group: eventClick.extendedProps.group,
              comments: res.data.comments.reverse(),
              groupId: '',
              startTime: '',
              endTime: '',
            },
            error: '',
          };
        }
        return {
          success: false,
          error: 'error',
        };
      })
      .catch(err => {
        if (err.response) {
          switch (err.status || err.response.status) {
            case 401:
              return {
                success: false,
                error: 'Unauthorized',
              };
            default:
              return {
                success: false,
                error: 'error',
              };
          }
        }
      });
  };

  const onAddEvent = (event, modulePlace) => {
    const calendarIds = event.calendarId || calendarId;
    return addEvent(Array.isArray(calendarIds) ? calendarIds : [calendarIds], event, modulePlace)
      .then(res => {
        if (res.status === 200) {
          const resultData = Array.isArray(res.data) ? res.data : [res.data];
          const newEvents = [];
          for (const createdEvent of resultData) {
            const group = event.multiGroups
              ? event.multiGroups.find(g => g.groupId === createdEvent.groupId)
              : event.group;
            patchEventsData('add', {
              ...createdEvent,
              editable: getEditableValue(createdEvent.user.id, null, group),
              group,
              groupId: '',
              startTime: '',
              endTime: '',
            });

            newEvents.push({
              ...createdEvent,
              editable: getEditableValue(createdEvent.user.id, null, group),
              group,
              groupId: '',
              startTime: '',
              endTime: '',
            });
          }
          return {
            success: true,
            newEvents,
            error: '',
          };
        }
        setIsLoading(false);
        return {
          success: false,
          error: 'error',
        };
      })
      .catch(err => {
        console.log(err);
        switch (err.status || (err.response && err.response.status)) {
          case 401:
            setIsLoading(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          case 400:
            setIsLoading(false);
            store.dispatch(
              showBottomNotification(t(err.response.data.message), {
                isFail: true,
              }),
            );
            return {
              success: false,
              error: 'error',
            };
          case 403:
            setIsLoading(false);
            store.dispatch(
              showBottomNotification(
                "Current user is member of this group and isn't allowed for this action!",
                {
                  isFail: true,
                },
              ),
            );
            return {
              success: false,
              error: 'error',
            };
          default:
            setIsLoading(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const onUpdateEvent = event => {
    if (event.postType === 2) {
      return updateCalendarEvent(event);
    } else {
      return updateCalendarTask(event);
    }
  };

  const updateCalendarEvent = event => {
    return updateEvent(event.id, event)
      .then(res => {
        if (res.status === 200) {
          patchEventsData('update', {
            ...res.data,
            editable: getEditableValue(res.data.user.id, null, event.group),
            title: res.data.canceled
              ? `${res.data.title}(${t('Calendar.canceled')})`
              : res.data.title,
            group: event.group,
            groupId: '',
            startTime: '',
            endTime: '',
            edited: true,
          });
          return {
            success: true,
            event: {
              ...res.data,
              edited: true,
              editable: getEditableValue(res.data.user.id, null, event.group),
              group: event.group,
              groupId: '',
              startTime: '',
              endTime: '',
            },
            error: '',
          };
        }
        setIsLoading(false);
        return {
          success: false,
          error: res,
        };
      })
      .catch(err => {
        setIsLoading(false);
        return {
          success: false,
          error: err,
        };
      });
  };

  const updateCalendarTask = task => {
    return updateTask(task.id, task)
      .then(res => {
        if (res.status === 200) {
          const date = new Date(res.data.end);
          const hour = date.getHours();
          patchEventsData(res.data.isScheduling && res.data.addToCalendar ? 'update' : 'delete', {
            ...res.data,
            start: res.data.allDay
              ? res.data.end
              : hour
              ? res.data.end - 1000 * 60 * 60
              : date.setHours(0, 0),
            end: hour ? res.data.end : date.setHours(1, 0),
            title: `${t('Tasks.Deadline:')} ${res.data.title}`,
            editable: false,
            group: { id: res.data.groupId },
            color: res.data.taskList && res.data.taskList.color,
            postType: 5,
            groupId: '',
            startTime: '',
            endTime: '',
          });
          return {
            success: true,
            event:
              res.data.isScheduling && res.data.addToCalendar
                ? {
                    ...res.data,
                    title: `${t('Tasks.Deadline:')} ${res.data.title}`,
                    start: res.data.allDay
                      ? res.data.end
                      : hour
                      ? res.data.end - 1000 * 60 * 60
                      : date.setHours(0, 0),
                    end: hour ? res.data.end : date.setHours(1, 0),
                    editable: false,
                    group: { id: res.data.groupId },
                    color: res.data.taskList && res.data.taskList.color,
                    postType: 5,
                    groupId: '',
                    startTime: '',
                    endTime: '',
                  }
                : null,
            error: '',
          };
        }
        setIsLoading(false);
        return {
          success: false,
          error: res,
        };
      })
      .catch(err => {
        setIsLoading(false);
        return {
          success: false,
          error: err,
        };
      });
  };

  return {
    isLoading,
    events,
    calendarSettings,
    deleteEvent: onDeleteEvent,
    addEvent: onAddEvent,
    updateEvent: onUpdateEvent,
    reloadEvents: () => setReloadEvents(!reloadEvents),
    getEventInfo,
    filters,
    setFilters,
  };
};

export default useCalendarEvents;
