import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import KitaSidePanel from 'modules/KitaSidePanel/KitaSidePanelModuleContainer';
import Dashboard from 'modules/Dashboard/dashboardModule';
import Help from 'modules/Help/helpModuleContainer';
import LoggedInRegistration from 'modules/LoggedInRegistration/loggedInRegistrationContainer';
import Releasenotes from 'modules/Releasenotes/releasenotesModule';
import GfzConsent from 'modules/GfzConsent';
import KitaConsent from 'modules/KitaConsent';
import CaritasConsent from 'modules/CaritasConsent';
import ChristusErloeserConsent from 'modules/ChristusErloeserConsent';
import Videocall from 'modules/Videocall/videocallModule';
import GfzLink from 'modules/GfzLink';
import DataProtection from 'modules/DataProtection/DataProtectionModuleContainer';
import Imprint from 'modules/Imprint/ImprintContainer';
import Group from 'modules/Group/groupModule';
import Signature from 'modules/Signature/SignatureContainer';
import TaxConsultant from 'modules/TaxConsultant/TaxConsultantContainer';
import BgwFAQ from 'modules/Bgw';
import EducationalWork from 'modules/EducationalWork';
import GfzForms from 'modules/GfzForms';
import Search from 'modules/Search/SearchModuleContainer';
import Chat from 'modules/Chat/ChatContainer';
import ProfileContainer from 'modules/Profile/ProfileContainer';
import Authorization from 'modules/Auth/Authorization/AuthorizationContainer';
import Registration from 'modules/Auth/Registration/';
import AwowwInfoButton from 'modules/Awoww/InfoButton';
import Awoww from 'modules/Awoww';
import ForgotPassword from 'modules/Auth/ForgotPassword';
import InvalidEmailLink from 'modules/Auth/InvalidEmailLink/InvalidEmailLink';
import ResetPassword from 'modules/Auth/ResetPassword/ResetPasswordContainer';
import Administration from 'modules/Administration';
import AccountSettings from 'modules/AccountSettings/AccountSettingsContainer';
import Notifications from 'modules/Notifications/notificationsModule';
import Header from 'library/common/commonComponents/Header/HeaderContainer';
import MessagePopup from 'library/common/commonComponents/Popups/MessagePopup';
import LinkDisclaimer from 'library/common/commonComponents/LinkDisclaimer/LinkDisclaimerContainer';
import DashboardCalendar from 'modules/DashboardCalendar/DashboardCalendarContainer';
import { getAuthentication } from 'library/common/commonActions/authActions';
import { loadUserInfo } from 'library/common/commonActions/userActions';
import { updateNotificationsConfiguration } from 'library/utilities/notifications';
import DashboardSuggestionBox from 'modules/DashboardSuggestionBox/DashboardSuggestionBoxContainer';
import FoodOrderContainer from 'modules/FoodOrder/FoodOrderContainer';
import DefaultContractEditWrapper from 'modules/DefaultContractEditWrapper';
import ConfirmEmailContainer from 'modules/Auth/ConfirmEmail/confirmEmailContainer';
import EmailAlreadyConfirmed from 'modules/Auth/EmailAlreadyConfirmedPage/emailAlreadyConfirmed';
import QRCodeScanModule from 'modules/QRCodeScan';
import QRCodeContainer from 'modules/QRCodeScan/QRCodeContainer';
import PublicGroups from 'modules/PublicGroups';

const AppRouter = props => {
  useEffect(() => {
    props
      .getAuthentication()
      .then(token => {
        props.loadUserInfo(token.access_token);
        updateNotificationsConfiguration(props.userInfo.id);
      })
      .catch(() => false);
  }, []);
  const location = useLocation();
  const kitaIdsForConsent = [
    195,
    2945,
    2947,
    2949,
    2951,
    2953,
    3055,
    3065,
    3067,
    3069,
    3071,
    3073,
    3157,
    3625,
  ];
  const nextKitaIdsForConsent = [315, 3627];

  function showLoggedInConsentOrContent() {
    if (props.userInfo.qrCodeScanner) {
      return (
        <>
          <Route path='*' component={Header} />
          <Switch>
            <Route
              exact
              path='/qr-code'
              render={() => <QRCodeScanModule userInfo={props.userInfo} isAdminEditing={false} />}
            />
            <Redirect to='/qr-code' />
          </Switch>
        </>
      );
    }
    if (props.userInfo.consentRequired === true) {
      if (
        window.location.hostname.toLowerCase().includes('gfz-app.ch') ||
        window.location.hostname.toLowerCase().includes('staging.stramplerbande.org')
      ) {
        return (
          <>
            <Switch>
              <Route
                exact
                path='/gfzconsent'
                render={() => <GfzConsent userInfo={props.userInfo} isAdminEditing={false} />}
              />
              <Redirect to='/gfzconsent' />
            </Switch>
          </>
        );
      } else if (
        kitaIdsForConsent.includes(props.kitaInfo.activeKita.kitaId) ||
        window.location.hostname.toLowerCase().includes('staging2.safe2connect.org')
      ) {
        return (
          <>
            <Switch>
              <Route
                exact
                path='/kitaconsent'
                render={() => (
                  <KitaConsent
                    userInfo={props.userInfo}
                    isAdminEditing={false}
                    activeKita={props.kitaInfo.activeKita}
                  />
                )}
              />
              <Redirect to='/kitaconsent' />
            </Switch>
          </>
        );
      } else if (nextKitaIdsForConsent.includes(props.kitaInfo.activeKita.kitaId)) {
        return (
          <>
            <Switch>
              <Route
                exact
                path='/caritasconsent'
                render={() => (
                  <CaritasConsent
                    userInfo={props.userInfo}
                    isAdminEditing={false}
                    activeKita={props.kitaInfo.activeKita}
                  />
                )}
              />
              <Redirect to='/caritasconsent' />
            </Switch>
          </>
        );
      } else if (
        props.kitaInfo.activeKita.kitaId === 124 ||
        props.kitaInfo.activeKita.kitaId === 3621 ||
        props.kitaInfo.activeKita.kitaId === 3759
      ) {
        return (
          <>
            <Switch>
              <Route
                exact
                path='/christuserloeserconsent'
                render={() => (
                  <ChristusErloeserConsent
                    userInfo={props.userInfo}
                    isAdminEditing={false}
                    activeKita={props.kitaInfo.activeKita}
                  />
                )}
              />
              <Redirect to='/christuserloeserconsent' />
            </Switch>
          </>
        );
      }
    }
    return (
      <>
        {!(
          queryString.parse(location.search).mode &&
          queryString.parse(location.search).mode === 'kids'
        ) && (
          <>
            <Route path='*' component={KitaSidePanel} />
            <Route path='*' component={Header} />
          </>
        )}
        <Switch>
          {/* {props.userInfo.qrCodeScanner && (
             <Redirect to='/qr-code' />
          )} */}
          {props.kitaInfo.activeKita.kitaId === 108 || props.kitaInfo.activeKita.kitaId === 3627 ? (
            <Redirect exact from='/' to='/calendar' />
          ) : (
            <Redirect exact from='/' to='/dashboard' />
          )}
          {props.userInfo.defaultGroupId &&
            props.userInfo.defaultGroupId != null &&
            props.kitaInfo.kitas &&
            props.kitaInfo.kitas.length === 1 &&
            window.location.hostname.toLowerCase().includes('schlicker.safe2connect.org') && (
              <Redirect
                exact
                from='/dashboard'
                to={`/groups/${props.userInfo.defaultGroupId}/stream`}
              />
            )}
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/auth-privacy' component={DataProtection} />
          <Route exact path='/help' component={Help} />
          <Route exact path='/loggedinregistration' component={LoggedInRegistration} />
          <Route exact path='/awowwinfo' component={AwowwInfoButton} />
          <Route exact path='/releasenotes' component={Releasenotes} />
          <Route path='/groups/:id' component={Group} />
          <Route path='/profile/:id' component={ProfileContainer} />
          <Route path='/chat/:page' component={Chat} />
          <Redirect from='/chat' to='/chat/1' />
          <Route exact path='/calendar' component={DashboardCalendar} />
          <Route exact path='/public-groups' component={PublicGroups} />
          <Route exact path='/suggestionbox' component={DashboardSuggestionBox} />
          <Route exact path='/taxconsultant' component={TaxConsultant} />
          <Route exact path='/bgwfaq' component={BgwFAQ} />
          <Route exact path='/educationalwork' component={EducationalWork} />
          <Route exact path='/foodorder' component={FoodOrderContainer} />
          <Route exact path='/videocall' component={Videocall} />
          <Route exact path='/gfzforms' component={GfzForms} />
          <Route exact path='/gfzlink' component={GfzLink} />
          <Route path='/reset-password/:id' component={ResetPassword} />
          <Route path='/account-settings/:page?/:tab?' component={AccountSettings} />
          <Route path='/list-notifications/:page' component={Notifications} />
          {(props.userInfo.administrationAccess ||
            props.userInfo.ableToEditUserProfiles ||
            props.userInfo.employee) && (
            <Route path='/administration/:page?/:tab?' component={Administration} />
          )}
          <Route path='/search/:page' component={Search} />
          <Route
            exact
            path='/privacy'
            render={() => (
              <DataProtection isLoggedIn isAdmin={props.userInfo.administrationAccess} />
            )}
          />
          <Route
            exact
            path='/imprint'
            render={() => <Imprint isLoggedIn isAdmin={props.userInfo.administrationAccess} />}
          />
          <Route path='/signature/:document/:groupId' component={Signature} />
          <Route exact path='/forms/defaultContract/edit' component={DefaultContractEditWrapper} />
          <Redirect to='/' />
        </Switch>
      </>
    );
  }

  return (
    <div>
      {props.isLoggedIn ? (
        <>{showLoggedInConsentOrContent()}</>
      ) : (
        <Switch>
          <Route path='/public/awoww' component={Awoww} />
          <Route path='/login' component={Authorization} />
          <Route path='/confirm-email/:token' component={ConfirmEmailContainer} />
          <Route path='/registration/:token' component={Registration} />
          <Route path='/password-recovery' component={ForgotPassword} />
          <Route path='/recovery-link-error' component={InvalidEmailLink} />
          <Route path='/email-confirmed-error' component={EmailAlreadyConfirmed} />
          <Route path='/reset-password/:id' component={ResetPassword} />
          <Route exact path='/auth-privacy' component={DataProtection} />
          <Route exact path='/auth-imprint' component={Imprint} />
          {window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') ||
          window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org') ? (
            <Redirect to='/public/awoww' />
          ) : (
            <Redirect to='/login' />
          )}
        </Switch>
      )}
      <MessagePopup />
      {props.linkDisclaimerIsOpen && <LinkDisclaimer />}
      <div id='portal-root' />
    </div>
  );
};

AppRouter.defaultProps = {
  userInfo: {},
};

const mapStateToProps = state => ({
  isCreateGroupMode: state.groupReducer.isCreateGroupMode,
  user: state.authReducer.user,
  userInfo: state.userReducer,
  isLoggedIn: state.authReducer.isLoggedIn,
  linkDisclaimerIsOpen: state.disclaimerReducer.isOpen,
  kitaInfo: state.kitaReducer,
});

const mapDispatchToProps = {
  getAuthentication,
  loadUserInfo,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppRouter),
);
