import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import Loader from 'library/common/commonComponents/Loader';
import styles from './usersFrameKitaAdmins.module.scss';
import { getUserLogoUrl } from 'library/utilities/user';

import useCachedImage from 'library/common/commonHooks/useCachedImage';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';

export default function UsersFrameKitaAdmins({ activeKita, data, setEditingProfile }) {

    const [isLoading, setIsLoading] = useState(false);

    return (

        <div className={styles.container}>
            {!isLoading ? (
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}
                >
                    {data.data.content.map((item) => {
                        return (<><>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    flexDirection: 'row',
                                    height: '30px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setEditingProfile({ ...item.user, adminRole: item.kitaRoles.roles.adminRole, employee: item.employee, qrCodeScanner: item.qrCodeScanner, ableToEditUserProfiles: item.ableToEditUserProfiles });
                                }}><div style={{ width : '34px', height: '34px'}}>

                                    <ProfileAvatar image={item.user} id={item.user.id} />

                                </div><div style={{ width: '250px' }}>{`${item.user.firstName} ${item.user.lastName}`}</div><div style={{ width: '250px' }}>{item.user.email}</div>
                            </div></><hr /></>)
                    }
                    )}
                </div >
            ) : (
                <Loader />
            )
            }
        </div>
    );

}