import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { createKidDevelopmentDiaryEntry } from 'library/api/kidDevelopmentDiary';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import Input from 'library/common/commonComponents/Inputs/Input';
import { isBookingTimeOver } from 'library/utilities/date';
import store from 'main/store/configureStore';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';

import styles from './childRow.module.scss';

const ChildRow = ({ childObj, handleUpdateKidStatus, notifyToParentsOnCheckInOutActive }) => {
  const { t } = useTranslation();

  const [note, setNote] = useState('');
  const [time, setTime] = useState('');
  const [openNoteFiled, setOpenNoteFiled] = useState(false);

  useEffect(() => {
    const date = new Date();
    const hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const currentTime = `${hours}:${minutes}`;
    setTime(currentTime);
  }, []);

  const getStatus = () => {
    let status = '';
    if (childObj.kidAbsence) {
      status = childObj.kidAbsence.vacation ? 'VACATION' : 'SICK';
    } else {
      status = childObj.checkInOutStatus;
    }

    return (
      <div
        className={cn(
          styles.statusContainer,
          status === 'VACATION' && styles.statusVacation,
          status === 'SICK' && styles.statusSick,
          status === 'CHECKIN' && styles.statusCheckin,
          status === 'CHECKOUT' && styles.statusCheckout,
        )}
      >
        <div className={styles.statusIconWrapper} onClick={() => handleChangeKidStatus()}>
          <i
            className={cn(status === 'CHECKIN' ? 'fa fa-lg fa-folder' : 'fa fa-lg fa-folder-open')}
          />
        </div>
        <div className={styles.statusLabel}>
          {status === 'VACATION' && t('Checkinout.Vacation')}
          {status === 'SICK' && t('Checkinout.Sick')}
          {status === 'CHECKIN' && t('Checkinout.Checked In')}
          {status === 'CHECKOUT' && t('Checkinout.Checked Out')}
          {/* t('Checkinout.Absent' */}
        </div>
      </div>
    );
  };

  const getNewStatus = () => {
    return childObj.checkInOutStatus === 'CHECKIN' ? 'CHECKOUT' : 'CHECKIN';
  };

  const handleChangeKidStatus = async () => {
    if (childObj.kidAbsence !== null) {
      store.dispatch(
        showBottomNotification(t('Checkinout.Please update kid absence first'), {
          isFail: true,
        }),
      );
      return;
    }

    const newStatus = getNewStatus();
    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);
    const isoString = date.toISOString();
    await handleUpdateKidStatus({
      checkInOutType: newStatus,
      kidId: childObj.kidId,
      checkedDate: isoString,
      notifyToParents: notifyToParentsOnCheckInOutActive,
    });
  };

  const handleTimeChange = e => {
    const selectedTime = e.target.value;
    if (selectedTime < '06:00') {
      // setIsValidTime(false);
      setTime('06:00');
    } else if (selectedTime > '18:00') {
      // setIsValidTime(false);
      setTime('18:00');
    } else {
      // setIsValidTime(true);
      setTime(selectedTime);
    }
  };

  const handleOpenNoteField = () => {
    setOpenNoteFiled(true);
  };

  return (
    <div
      className={cn(
        styles.wrapper,
        !childObj.kidAbsence &&
        childObj.checkInOutStatus !== 'CHECKIN' &&
        isBookingTimeOver(childObj.bookingArrivalTime) &&
        styles.redBorder,
      )}
    >
      <div className={styles.photo}>
        <img src={childObj.groupLogoUrl || defaultAvatar} alt='' />
      </div>

      <div className={styles.name}>
        {childObj.name}
        {childObj.parent1Name !== null && (
          <span className={styles.parent}>{childObj.parent1Name}</span>
        )}
        {childObj.parent2Name !== null && (
          <span className={styles.parent}>{childObj.parent2Name}</span>
        )}
      </div>

      <div className={styles.group}>{childObj.groupName}</div>

      <div className={styles.statusWrap}>
        <Input readOnly type='time' value={childObj.bookingLeavingTime || ''} />
        {getStatus()}
        {openNoteFiled ? (
          <Input
            className={styles.note}
            value={note}
            placeholder={t('Checkinout.Note')}
            onChange={e => setNote(e.target.value)}
          />
        ) : (
          <div className={cn(styles.statusContainer, styles.statusCheckout)}>
            <div className={styles.statusIconWrapper} onClick={() => handleOpenNoteField()}>
              <i className='fa fa-lg fa-sticky-note' />
            </div>
            <div className={styles.statusLabel}>{t('Checkinout.Note')}</div>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <Input type='time' value={time} onChange={handleTimeChange} />
          <Button
            onClick={() => {
              createKidDevelopmentDiaryEntry(childObj.kidId, 'checkinout', note)
                .then(() => {
                  setNote('');
                  store.dispatch(
                    showBottomNotification(t('BottomNotification.SuccessfullySaved'), {
                      isFail: false,
                    }),
                  );
                })
                .catch(() => {
                  store.dispatch(
                    showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), {
                      isFail: true,
                    }),
                  );
                });
            }}
          >
            <i className='fa fa-arrow-right' />
          </Button>
        </div>
      </div>
      <div className={styles.info}>
        <p>{childObj.note || ''}</p>
      </div>
    </div>
  );
};

ChildRow.propTypes = {
  childObj: PT.shape({
    kidId: PT.number.isRequired,
    name: PT.string.isRequired,
    groupLogoUrl: PT.string,
    parent1Name: PT.string,
    parent2Name: PT.string,
    checkInOutStatus: PT.string.isRequired,
  }).isRequired,
  handleUpdateKidStatus: PT.func.isRequired,
  notifyToParentsOnCheckInOutActive: PT.bool.isRequired,
};

export default ChildRow;
