import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllEmployeeInformation, updateEmployeeInformation } from 'library/api/employee';
import EmployeeItem from './EmployeeItem';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import styles from './userFrameEmployeeNumberHolidays.module.scss';
import cn from 'classnames';

export default function UserFrameEmployeeNumberHolidays() {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllEmployeeInformation()
      .then(res => {
        setEmployeeList(res.data);
      })
      .catch(err => {
        console.err(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSaveButton = async (setIsLoading, params) => {
    setIsLoading(true);
    try {
      await updateEmployeeInformation(params);
      const { data } = await getAllEmployeeInformation();
      setEmployeeList(data);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.Vacation and break setting')}</div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.Here you can change the number of holidays every employee has per year and how legal break should be considered',
          )}
        </HelpText>
      </div>

      {!isLoading ? (
        <div className={styles.employeeList}>
          {employeeList.map((data, index) => {
            return (
              <EmployeeItem
                key={data.id}
                data={data}
                setIsLoading={setIsLoading}
                className={cn(index % 2 === 0 && styles.withBackground)}
                handleSaveButton={handleSaveButton}
              />
            );
          })}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
