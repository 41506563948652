import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tabs from 'library/common/commonComponents/Tabs';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import { getPendingApprovals, getPendingKidsForCurrentKita } from 'library/api/user';
import { getUsersTabCount, getKitaUsersId, getFinalListOfPendingChildren } from 'library/api/user';
import UsersFrameList from './usersFrameFrames/UsersFrameList/UsersFrameListContainer';
import UsersFrameChildren from './usersFrameFrames/UsersFrameChildren/UsersFrameChildrenContainer';
import UsersFrameAuthorizedToPickUp from './usersFrameFrames/UsersFrameAuthorizedToPickUp/UserFrameAuthorizedToPickUpContainer';
import UsersFrameRegistration from './usersFrameFrames/UsersFrameRegistration/UsersFrameRegistrationContainer';
import UsersFrameApproval from './usersFrameFrames/UsersFrameApproval/UserFrameApprovalContainer';
import { getFilters } from 'library/api/user';

import UsersFrameChildOverview from './usersFrameFrames/UsersFrameChildOverview/UsersFrameChildOverviewContainer';

import { setActiveKita } from 'library/utilities/kitaEffects';

import styles from './usersFrame.module.scss';
import UsersFrameEmployeeWrapper from './usersFrameFrames/UsersFrameEmployeeWrapper';
import UsersFrameKitaAdmins from './usersFrameFrames/UsersFrameKitaAdmins/UsersFrameKitaAdminsContainer';
import UsersFrameKitaAdminsWrapper from './usersFrameFrames/UsersFrameKitaAdminsWrapper/UsersFrameKitaAdminsWrapper';

export function UsersFrame({ history, match, location, activeKita, user }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [tabCount, setTabCount] = useState([]);
  const [kitaUsers, setKitaUsers] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);

  //const { ...tableProps } = useUsersRegistrationList({ isPending: true, activeKita });

  useSiteTitle('AdministrationUsers');

  const [selectedKita, setSelectedKita] = useState(activeKita.kitaId);

  const isSafeToConnectType = (activeKita.kitaTypeTranslations || []).includes('Safe2Connect');

  useEffect(() => {
    if (user.administrationAccess) {
      loadUsers();
      loadCount();
    }
  }, []);

  const loadUsers = () =>
    loadUsersHandler({
      setUsers,
      setIsLoading,
    });

  const loadCount = async () => {
    const pendingKidsData = await getPendingKidsForCurrentKita();
    setPendingCount(pendingKidsData.data.length);
    getUsersTabCount(selectedKita).then(data => {
      setTabCount(data);
    });
  };

  const [editingProfile, setEditingProfile] = useState(null);

  let tabs = [
    {
      title: `${t('Administration.Users')} (${tabCount.data ? tabCount.data[0] : 0})`,
      component: <UsersFrameList location={location} history={history} loggedInUser={user} />,
      path: 'list',
    },
  ];

  if (user.administrationAccess) {
    tabs = [
      ...tabs,
      ...[
        {
          title: `${t('Administration.ChildOverview')} (${tabCount.data ? tabCount.data[1] : 0})`,
          component: <UsersFrameChildOverview activeKita={activeKita} />,
          path: 'child-overview',
        },
        {
          title: `${t('Administration.Pending registrations')}  (${
            tabCount.data ? tabCount.data[2] : 0
          })`,
          component: <UsersFrameRegistration location={location} history={history} />,
          path: 'pending',
        },
        {
          title: `${t('Administration.Pending Approvals')} (${users.length})`,
          component: <UsersFrameApproval users={users} isLoading={isLoading} setUsers={setUsers} />,
          path: 'waiting-for-approval',
        },
        {
          title: `${t('Administration.Pending Child Approvals')} (${pendingCount})`,
          component: <UsersFrameChildren activeKita={activeKita} />,
          path: 'waiting-for-kid-approval',
        },
        {
          title: `${t('Administration.Pending authorized to pick up approval')}`,
          component: <UsersFrameAuthorizedToPickUp activeKita={activeKita} />,
          path: 'waiting-for-authorized-approval',
        },
        {
          title: `${t('Administration.Employee')} (${tabCount.data ? tabCount.data[3] : 0})`,
          component: <UsersFrameEmployeeWrapper />,
          path: 'employee-wrapper',
        },
        {
          title: `${t('Administration.Kita Admins')}`,
          component: (
            <UsersFrameKitaAdminsWrapper
              activeKita={activeKita}
              editingProfile={editingProfile}
              setEditingProfile={setEditingProfile}
              user={user}
            />
          ),
          path: 'kita-admins-wrapper',
        },
      ],
    ];
  }

  if (window.location.hostname.toLowerCase().includes('ivs.safe2connect.org')) {
    tabs = tabs.filter(el => el.path !== 'waiting-for-kid-approval');
  }

  if (isSafeToConnectType) {
    tabs = tabs.filter(
      el => el.path !== 'waiting-for-kid-approval' && el.path !== 'child-overview',
    );
  }

  const onTabChange = path => {
    history.push(`/administration/users/${path}`);
  };

  return (
    <div className={styles.container}>
      <Tabs
        tabs={tabs}
        currentTab={match.params.tab ? match.params.tab : tabs[0].path}
        onChange={onTabChange}
      />
    </div>
  );
}

export async function loadUsersHandler({ setUsers, setIsLoading }) {
  setIsLoading(true);
  const { data } = await getPendingApprovals();
  setUsers(data);
  setIsLoading(false);
}

export default connect(state => ({
  activeKita: state.kitaReducer.activeKita,
}))(UsersFrame);
