import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8095)}/api`;
const zuulURL = `${config.API_BASE_URI}/zuul${getPort(8095)}/api`;

export const getChatInfo = (chatId, currentPage, isNotification) => {
  const params = chatId
    ? {
        page: currentPage - 1,
        chatId,
        onlyLast: isNotification,
      }
    : { page: currentPage - 1, onlyLast: isNotification };

  return axios.get(URL + `/chat`, { params });
};

export const addNewChat = async (newChatInfo, kitaId, files, setProgress) => {
  const userIds = newChatInfo.users.reduce((result, item) => {
    return (result = result.concat(+item.id));
  }, []);

  newChatInfo.groups.forEach(groupItem => {
    groupItem.forEach(user => userIds.push(user.id));
  });

  const payload = {
    kitaId,
    message: newChatInfo.message,
    subject: newChatInfo.subject,
    userIds,
    files,
  };

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + '/chat/files', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      if(setProgress) {
        setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
      }
    },
  });
};

export const getChatMeassges = (chatId, firstMessageId) => {
  const payload = firstMessageId
    ? {
        sort: ['id', 'desc'],
        size: 10,
        page: 1,
        filter: [`id:lt:${firstMessageId}`],
        graphql: '*',
      }
    : {
        sort: ['id', 'desc'],
        size: 10,
        page: 1,
        graphql: '*',
      };
  return axios.post(URL + `/chat/${chatId}/messages`, payload);
};

export const searchChatMessages = searchTerm => {
  return axios.get(URL + `/chat/messages/search`, { params: { searchTerm: searchTerm } });
};

export const leaveChat = chatId => {
  return axios.delete(URL + `/chat/${chatId}/leave`);
};

export const checkUserFreeSpaceForChat = ({ filesSize, chatId, userId }) =>
  axios.get(URL + `/chat/file/storage`, { params: { filesSize, chatId, userId } });

export const sendMessage = (chatId, message, files, setProgress) => {
  const payload = {
    message,
    files,
  };

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + `/chat/${chatId}/message/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};


export const sendAutoMessage = (chatId, message) => {
  const payload = {
    message
  };

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });

  return axios.post(zuulURL + `/chat/${chatId}/automessage/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const editMessage = (chatId, messageId, message, files, setProgress, deletedFileIds) => {
  const payload = {
    message,
    files,
    deletedFileIds,
  };

  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else if ((key === 'deletedFileIds' && payload[key].length > 0) || key !== 'deletedFileIds') {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + `/chat/${chatId}/message/${messageId}/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};

export const deleteMessage = (chatId, messageId) =>
  axios.delete(URL + `/chat/${chatId}/message/${messageId}`);

export const leaveChatAll = () => axios.delete(URL + '/chat/all/leave');

export const addUser = (chatId, newUsers) => {
  const userIds = newUsers.reduce((result, item) => {
    return (result = result.concat(+item.id));
  }, []);
  return axios.post(URL + `/chat/${chatId}/add-users`, userIds);
};

export const markMessageAsRead = chatId => axios.delete(URL + `/chat/${chatId}/mark-as-read`);

export const getMessageReadStatus = chatMessageId =>
  axios.get(URL + `/chat/getreturnreceiptgroupuserschatmessage/${chatMessageId}`);

export const getUnreadMessagesCountPayload = {
  method: 'get',
  url: `${URL}/chat/unread-count`,
};

export function isAllowedToSendMessages(moduleList, currentUser, userList = []) {
  const chatModule = moduleList.find(element => {
    return element.moduleKey === 'chat';
  });
  if (
    chatModule &&
    chatModule !== 'undefined' &&
    chatModule.activeStatus &&
    chatModule.activeStatus === true
  ) {
    return true;
  }

  const userListContainsAdminOrEmployee = userList.find(
    user => user.administrationAccess || user.employee,
  );

  return (
    currentUser.administrationAccess === true ||
    currentUser.employee === true ||
    userListContainsAdminOrEmployee
  );
}

export const getEmployeeAbsenceTimeRange = (kitaId) => axios.get(`${URL}/chat/employeeAbsenceTimeRange/${kitaId}`);

export const postEmployeeAbsenceTimeRange = data => axios.post(`${URL}/chat/employeeAbsenceTimeRange`, data);

export const disableEmployeeAbsenceTimeRange = (id) => axios.post(`${URL}/chat/disableEmployeeAbsenceTimeRange/${id}`);
