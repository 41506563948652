import sBInstance from 'library/api/axios';
import { URLS } from 'library/common/commonConstants/commonConstants';
import store from 'main/store/configureStore';
import { removeAuthentication, deleteDeviceToken } from 'library/common/commonActions/authActions';
import Storage from 'library/utilities/storage';

export const logout = () => {
  if (window.rnTempOnLogout) {
    deleteDeviceToken().finally(data => {
      return sBInstance.post(URLS.logout).then(res => {
        switch (res.status) {
          case 200:
            Storage.setItem('rememberMe', false);
            store.dispatch(
              removeAuthentication({
                access_token: res.data.access_token,
                refresh_token: res.data.refresh_token,
                tkval: res.data.tkval,
              }),
            );
            return true;
          default:
            break;
        }
      });
    });
  } else {
    return sBInstance.post(URLS.logout).then(res => {
      switch (res.status) {
        case 200:
          Storage.setItem('rememberMe', false);
          store.dispatch(
            removeAuthentication({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
              tkval: res.data.tkval,
            }),
          );
          return true;
        default:
          break;
      }
    });
  }
};
