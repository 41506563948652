import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { isAllowedToSendMessages, searchChatMessages } from 'library/api/chat';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Card from 'library/common/commonComponents/Card';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import Pagination from 'library/common/commonComponents/Pagination';
import Popup from 'library/common/commonComponents/Popups/Popup';
import TagInput from 'library/common/commonComponents/Inputs/TagInput';
import Tooltip from 'library/common/commonComponents/Tooltip';
import ChatListItem from './ChatListFrames/ChatListItem';
import CreateNewChat from './ChatListFrames/CreateNewChat/CreateNewChatContainer';
import CreateNewAbsence from 'library/common/commonComponents/Absence';

import styles from './chatList.module.scss';

export default function ChatList({
  isLoading,
  selectedChatId,
  onChatClick,
  onAddNewChat,
  onAddNewAbsence,
  onLeaveChat,
  onLeaveChatAll,
  currentPage,
  onPageClick,
  totalPages,
  history,
  user,
  isKitaVirtual,
  currentWatsonLang,
  isNotOnlyPublicKitaMember,
  modules,
  timeRangeAbsenceInfo,
  onDeactiveTimeRangeAbsence
}) {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [isAbsenceOpened, setIsAbsenceOpened] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState(null);
  const [isLeaveAllOpen, setIsLeaveAllOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState({ users: [] });
  const [contentFilter, setContentFilter] = useState(undefined);
  const [filteredUsers, setFilteredUsers] = useState({ users: [] });
  const [filter, setFilter] = useState([]);
  const [error, setError] = useState(false);
  const location = useLocation();
  const chatsList = useSelector(state => state.chatListPopupReducer.chatsList);
  // used for filtering chats by content
  const [allowedChatIDsList, setAllowedChatIDsList] = useState([]);
  const isEmployee = user.employee;

  let newContentFilter;

  // used for filtering chats by content, if a content filter is set
  const filteredChatsList =
    allowedChatIDsList && contentFilter
      ? chatsList.filter(chat => allowedChatIDsList.includes(chat.id))
      : chatsList;

  const makeNumber = id => {
    let element = 0;
    for (let index = 0; index < id.length; index++) {
      element += id[index] * 10 ** (id.length - 1 - index);
    }
    return element;
  };

  const handleClose = (filterusers, saved) => {
    setError(false);
    if (saved) {
      setUsers(filterusers);
    } else {
      setUsers(filteredUsers);
    }
    setIsFiltered(false);
  };

  const handleFilterContentPopupSubmit = () => {
    if (!newContentFilter) {
      setContentFilter(undefined);
      return;
    }

    setContentFilter(newContentFilter);

    searchChatMessages(newContentFilter).then(result => {
      const chatMessages = result.data;
      const chatIDs = chatMessages.reduce(
        (ids, message) => (ids.includes(message.chatId) ? ids : [...ids, message.chatId]),
        [],
      );

      setAllowedChatIDsList(chatIDs);
    });
  };

  const handleFilterContentInputChange = data => {
    newContentFilter = data.target.value;
  };

  const handleFilterUserChange = data => {
    setError(false);
    const newUsers = data.users.slice();
    const authUser = [user];
    const index = newUsers.length - 1;
    const alreadyInChat =
      index !== -1 && authUser.find(item => `${item.id}` === `${newUsers[index].id}`);
    if (alreadyInChat) {
      newUsers.splice(index, 1);
    }
    setUsers({ ...data, users: newUsers });
  };

  const handleFilterUserSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    handleFilterContentPopupSubmit();
    if (users.users.length === 0) {
      setFilter([]);
      setFilteredUsers({ users: [] });
      handleClose({ users: [] }, true);
      return;
    }
    setIsSubmitting(true);
    const oldUser = users;
    const newFilter = users.users.map(item => makeNumber(item.id));
    setFilter(newFilter);
    setFilteredUsers(oldUser);
    setIsSubmitting(false);
    handleClose(oldUser, true);
  };

  const handleLeaveAll = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onLeaveChatAll(user.id);
    setIsSubmitting(false);
    setIsLeaveAllOpen(false);
  };

  const handleLeaveChat = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onLeaveChat(user.id, deleteChatId);
    setIsSubmitting(false);
    setDeleteChatId(null);
  };

  const handleClickChat = item => () => {
    onChatClick(item);
    if (location && location.state && location.state.selectedChatId === 0) {
      location.state.selectedChatId = item.id;
    }
  };

  const handleDeleteClick = chatId => {
    setDeleteChatId(chatId);
  };

  const closeDeletePopup = () => {
    setDeleteChatId(null);
    setIsLeaveAllOpen(false);
  };

  const filteredChats = useMemo(() => {
    if (filter.length === 0) {
      return chatsList;
    }

    return chatsList.filter(chat => {
      chat.users
        .map(elements => filter.find(filtering => filtering === elements.id))
        .find(finder => finder !== undefined);
    });
  }, [chatsList, filter]);

  const handleSetTimeRangeAbsence = () => {
    if(timeRangeAbsenceInfo.timeRangeAbsence) {
      onDeactiveTimeRangeAbsence()
    }
    else {
      setIsAbsenceOpened(true);
    }
  }

  useEffect(() => {
    if (history.location.createNewMessageTo) {
      setIsOpened(true);
    }
  }, []);

  return (
    <div className={cn(styles.container, selectedChatId !== 0 ? styles.chatSelected : undefined)}>
      <Card
        title={<Trans i18nKey='Chat.Conversations' />}
        titleClassName={styles.bodyTitle}
        titleChilderen={
          <div>
            {!isKitaVirtual && isNotOnlyPublicKitaMember && isAllowedToSendMessages(modules, user) && (
              <div className={styles.headerContainer}>
                <div className={styles.chatBtnWrapper}>
                  <Button onClick={() => setIsOpened(true)} className={styles.createButton}>
                    <i className='fa fa-plus' />
                    <span className={styles.buttonText}>{t('Chat.New message')}</span>
                  </Button>
                  <Tooltip
                    className={styles.tooltipDeleteAll}
                    text={t('Chat.Delete all conversations')}
                    isVisibleCondition
                  >
                    <Button
                      onClick={() => setIsLeaveAllOpen(true)}
                      className={styles.deleteButton}
                      type='primary'
                    >
                      <i className='fa fa-trash' />
                    </Button>
                  </Tooltip>
                </div>
                <div className={styles.chatBtnWrapper}>
                  {
                    isEmployee && (
                      <ButtonWithLoader
                        type='primary'
                        isLoading={timeRangeAbsenceInfo.isLoading}
                        className={cn(styles.createButton, timeRangeAbsenceInfo.isLoading ? styles.createButtonLoading: null)}
                        onClick={handleSetTimeRangeAbsence}
                      >
                          {!timeRangeAbsenceInfo.timeRangeAbsence && !timeRangeAbsenceInfo.isLoading && (<i className='fa fa-plus' />)}
                          {t(timeRangeAbsenceInfo.timeRangeAbsence ? 'Chat.DiactiveAbsence': 'Chat.NewAbsence')}
                      </ButtonWithLoader>
                    )
                  }
                  <Tooltip
                    className={styles.tooltip}
                    text={t('Chat.Filter')}
                    isVisibleCondition
                  >
                    <Button onClick={() => setIsFiltered(true)} className={cn({ [styles.filterButtonNoEmployee]: !isEmployee }, styles.filterButton)}>
                      <i className='fa fa-sliders' />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.chatListContainer}>
            {filteredChats.length > 0 ? (
              filteredChats.map(item => (
                <ChatListItem
                  activeChatId={selectedChatId}
                  key={item.id}
                  id={item.id}
                  isActive={selectedChatId === item.id}
                  onClick={handleClickChat(item)}
                  onDelete={handleDeleteClick}
                  lastMessage={item.lastMessage}
                  chatName={item.subject}
                  userList={item.users || []}
                  langCode={user.langCode}
                  isAnonymous={item.anonymousCreator}
                  isSuggestionBox={item.suggestionBox}
                  chatSubjectLanguage={item.watsonLanguage}
                  currentWatsonLang={currentWatsonLang}
                  avatars
                />
              ))
            ) : (
              <div className={styles.emptyContainer}>{t('Chat.There are no messages yet')}</div>
            )}
          </div>
        )}
      </Card>
      <CreateNewChat
        isOpened={isOpened}
        onSubmit={onAddNewChat}
        onClose={() => setIsOpened(false)}
        user={user}
        createNewChatRecipientsPreset={
          history.location.createNewMessageTo ? [history.location.createNewMessageTo] : []
        }
      />
      <CreateNewAbsence
        isOpened={isAbsenceOpened}
        onSubmit={onAddNewAbsence}
        onClose={() => setIsAbsenceOpened(false)}
      />
      <Popup
        isOpened={isFiltered}
        closePopup={handleClose}
        size='small'
        header={t('Chat.Filter participants')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={handleClose}>{t('Chat.Cancel')}</Button>
            </div>
            <div className={styles.buttonSaveFilterUser}>
              <ButtonWithLoader
                onClick={handleFilterUserSubmit}
                className={styles.buttonWithLoader}
                isLoading={isSubmitting}
              >
                {t('Chat.Save')}
              </ButtonWithLoader>
            </div>
          </div>
        }
      >
        <div className={styles.filterUserContainer}>
          <TagInput
            value={users}
            onDataChange={handleFilterUserChange}
            errors={
              error === 'filterUser'
                ? { invite: t('Chat.Recipient cannot be blank') }
                : { invite: '' }
            }
            showLabel={false}
            userId={user.Id}
          />
          <div className={styles.popupHeader}>{t('Chat.Filter messages')}</div>
          <Input onChange={handleFilterContentInputChange} showLabel={false} userId={user.Id} />
        </div>
      </Popup>
      <Popup
        isOpened={isLeaveAllOpen || deleteChatId}
        closePopup={closeDeletePopup}
        size='small'
        header={<Trans i18nKey={`Chat.Confirm delete${isLeaveAllOpen ? ' all' : ''}`} />}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button onClick={closeDeletePopup}>{t('Chat.Cancel')}</Button>
            </div>
            <div className={styles.buttonLeave}>
              <ButtonWithLoader
                onClick={isLeaveAllOpen ? handleLeaveAll : handleLeaveChat}
                className={styles.buttonWithLoader}
                isLoading={isSubmitting}
              >
                {t(`Chat.Delete ${isLeaveAllOpen ? 'all conversations' : 'selected conversation'}`)}
              </ButtonWithLoader>
            </div>
          </div>
        }
      >
        <div className={styles.confirmText}>
          {t(`Chat.Do you really want to delete ${isLeaveAllOpen ? 'all' : 'conversation'}`)}
        </div>
      </Popup>

      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          numberOfPages={totalPages}
          onPageClick={onPageClick}
          history={history}
          className={styles.paginationContainer}
          hiddenOnMobile
        />
      ) : null}
    </div>
  );
}
