import React, { useState, useEffect } from 'react';
import SkyWrapper from './SkyWrapper';
import CityPicker from './CityPicker';
import NameInput from './NameInput';
import Overview from './Overview';
import { useHistory } from 'react-router-dom';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './awoww.module.scss';


export default function Awoww() {

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedPlaces, setSelectedPlaces] = useState([]);


  const titleArray = [
    <>Herzlich<br />
      willkommen</>,
    <>Gib deinen<br />
      Standort an</>,
    <>Gib deinen<br />
      Namen an</>,
    <>Übersicht</>
  ];


  const bodyArray = [<></>,
  <CityPicker selectedPlaces={selectedPlaces} setSelectedPlaces={setSelectedPlaces} />,
  <>
    <NameInput
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
    />
  </>,
  <>
    <Overview firstName={firstName} lastName={lastName} selectedPlaces={selectedPlaces} />
  </>,
  <></>,
  <></>
  ];

  const footerArray = [
    <>
      <Button
        className={styles.awowwsinglebottombutton}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}>
        <b>Weiter zur Ortsauswahl</b>
      </Button>
    </>,
    <>
      <div
        className={styles.buttonContainer}>
        <Button
          className={styles.awowwdoublebottombuttonLeft}
          type='awoww'
          onClick={() => {
            history.push({
              pathname: "/login",
            });
          }}>
          <b>Weiter zum Login</b>
        </Button>
        <Button
          className={styles.awowwdoublebottombuttonRight}
          type='awoww'
          onClick={() => {
            history.push({
              pathname: "/login",
              state: { autologin: true }
            });
          }}>
          <b>Weiter zum öffentlichen Bereich</b>
        </Button>
      </div>
    </>,
    <>
      <Button
        className={styles.awowwdoublebottombuttonLeft}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}>
        <b>Zurück</b>
      </Button>
      <Button
        className={styles.awowwdoublebottombuttonRight}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}>
        <b>Zur Übersicht</b>
      </Button>
    </>,
    <>
      <Button
        className={styles.awowwdoublebottombuttonLeft}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}>
        <b>Zurück</b>
      </Button>
      <Button
        className={styles.awowwdoublebottombuttonRight}
        type='awoww'
        onClick={() => {
          history.push(`/login`);
        }}>
        <b>Fertig</b>
      </Button>
    </>
  ];

  return (
    <div>
      <SkyWrapper
        title={titleArray[currentPage]}
        body={bodyArray[currentPage]}
        footer={footerArray[currentPage]}

      />
    </div>

  );

}
