import React, { useEffect } from 'react';
import cn from 'classnames';

import Portal from 'library/common/commonComponents/Portal';

import styles from './popup.module.scss';

Popup.defaultProps = {
  showHeader: true,
  showCloseButton: true,
};

export default function Popup({
  size,
  isOpened,
  closePopup,
  header,
  body,
  footer,
  children,
  showHeader,
  showCloseButton,
}) {
  useEffect(() => {
    if (!isOpened) {
      document.body.style.overflow = 'unset';
    } else if (isOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <Portal isOpened={isOpened}>
      <div id='kita-popup-container' className={styles.outer}>
        <div className={cn(styles.modal, getClassBySize(size))}>
          {showCloseButton && (
            <div data-test='popup-close' onClick={closePopup} className={styles.cross}>
              ×
            </div>
          )}
          {showHeader && <div className={styles.header}>{header}</div>}
          {body && <div className={styles.body}>{body}</div>}
          {children}
          <div className={styles.footer}>{footer}</div>
        </div>
      </div>
    </Portal>
  );
}

function getClassBySize(size) {
  switch (size) {
    case 'ChildHistoryTab':
      return styles.sizeChildHistoryTab;
    case 'large':
      return styles.sizeLarge;
    case 'normal':
      return styles.sizeNormal;
    case 'normalAndMin':
      return styles.sizeNormalAndMin;
    case 'medium':
      return styles.sizeMedium;
    case 'small':
      return styles.sizeSmall;
    case 'extraSmall':
      return styles.sizeExtraSmall;
    default:
      return styles.sizeNormal;
  }
}
