import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'library/common/commonComponents/Checkbox';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import useLanguages from 'library/common/commonHooks/useLanguages';
import * as at from 'library/common/commonConstants/userConstants';
import { getLanguages } from 'library/api/user';

import {
  changeWatsonLanguage,
  changeWatsonLanguages,
  changeWatsonUserConsent,
} from 'library/common/commonActions/userActions';
import axios from 'library/api/axios';
import config, { getPort } from 'main/config';
import { store } from 'main/store/configureStore';

import {
  deleteUserGoogleLanguage,
  toggleUserConsentPost,
  updateUserGoogleLanguage,
} from 'library/api/settings';

import styles from './languageFrame.module.scss';

export default function LanguagesFrame({ showBottomNotification }) {
  const { currentWatsonUserConsent } = store.getState().userReducer;
  let currentLang = store.getState().userReducer.currentWatsonLang;
  store.subscribe(() => {
    currentLang = store.getState().userReducer.currentWatsonLang;
  });

  useSiteTitle('Languages');
  const [watsonOptions, setWatsonOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState();
  const [teanantName, setTeanantName] = useState('Stramplerbande GmbH');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`${config.API_BASE_URI}${getPort(8091)}/api/dynamic/languages`).then(res => {
      if (res.data && res.data.languages) {
        const filtered = res.data.languages.filter(lang => lang.supportedAsTarget);
        filtered.sort((a, b) => {
          if (a.language === "de")
          {
            return -1;
          }
          else if (b.language === "de")
          {
            return 1;
          }
          if (a.languageName < b.languageName) return -1;
          if (a.languageName > b.languageName) return 1;
          return 0;
        });
        setWatsonOptions(filtered);
        if (currentLang) {
          setCurrentOption(currentLang);
        }
        if (!store.getState().userReducer.currentWatsonLangs) {
          store.dispatch(changeWatsonLanguages(filtered));
        }
        setLoading(false);
      }
    });
    const titleParts = document.location.hostname.split('.');
    if (!titleParts.includes('stramplerbande') && titleParts.length > 1) {
      setTeanantName(titleParts[1].charAt(0).toUpperCase() + titleParts[1].slice(1));
    }
  }, []);

  const handleChangeUserConsent = () => {
    toggleUserConsentPost().then(({ data }) => {
      if (!data) {
        updateUserGoogleLanguage(null);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      store.dispatch(changeWatsonUserConsent(data));
      showBottomNotification(t('BottomNotifications.Saved'));
    });
  };

  const removeLanguage = () => {
    deleteUserGoogleLanguage().then(() => {
      store.dispatch(changeWatsonLanguage(null));
      showBottomNotification(t('BottomNotifications.Saved'));
      setCurrentOption('null');
    });
  };

  const mapWatsonLangToStaticLang = (lang) => {
    switch (lang)
    {
      case "nl":
        return null;
      case "nb":
        return "nl";
      case "zh-TW":
        return "zh";
      case "fr-CA":
        return "fr";
      default:
        return lang;
    }
  };

  const { changeLanguage } = useLanguages();
  const changeLang = e => {
    if (e.target.value !== 'null') {
      const selectedLanguage = mapWatsonLangToStaticLang(e.target.value);
      Promise.all([axios(at.URLS.staticLanguage), getLanguages()]).then(responses => {
        const languagesArray = responses[1];
        const containedInStaticLanguages = selectedLanguage != null && languagesArray.data.find(
          element => element.langCode === selectedLanguage,
        );
        let chosenLanguage = 'en';
        if (containedInStaticLanguages) {
          chosenLanguage = selectedLanguage;
        }
        changeLanguage({
          preventDefault: () => {},
          target: { value: chosenLanguage },
        }, containedInStaticLanguages ? containedInStaticLanguages.id : 1, false);
      });

      updateUserGoogleLanguage(e.target.value);
      store.dispatch(changeWatsonLanguage(e.target.value));
      setCurrentOption(e.target.value);
      showBottomNotification(t('BottomNotifications.Saved'));
    } else {
      removeLanguage();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.text}>
          {t('LanguagesFrame.We want to give the user of Stramplerbande the opportunity', {
            teanant: teanantName,
          })}
        </p>
        <p className={styles.text}>
          {t('LanguagesFrame.For privacy reasons', { teanant: teanantName })}
        </p>
        <p className={styles.text}>
          {t('LanguagesFrame.For information on the purpose')}
          <a href='https://www.ibm.com/privacy?lnk=flg-priv-usen/'>
            :&nbsp;https://www.ibm.com/privacy?lnk=flg-priv-usen
          </a>
          .
        </p>
        <p className={styles.text}>
          {t('LanguagesFrame.To join this group', { teanant: teanantName })}
        </p>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.checkboxes}>
            <div>
              <Checkbox
                className={styles.checkbox}
                name={t('LanguagesFrame.I agree')}
                isChecked={currentWatsonUserConsent}
                onChange={handleChangeUserConsent}
              />
              <select
                onChange={changeLang}
                value={currentOption}
                disabled={!currentWatsonUserConsent}
              >
                <option value='null'>{t('LanguagesFrame.Select language')}</option>
                {watsonOptions.map(op => (
                  <option value={op.language} key={op.language}>
                    {op.languageName} ({op.nativeLanguageName})
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
