import React, { useState, useEffect } from 'react';
import QrReader from 'modern-react-qr-reader'
import { checkInOutKid } from 'library/api/kids';
import Popup from 'library/common/commonComponents/Popups/Popup'

import { Trans, useTranslation } from 'react-i18next';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import store from 'main/store/configureStore';
import styles from './qrcode.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import { checkInOut, getCheckInOutDetails } from 'library/api/employee';

export default function QRCodeScanModule(userInfo, isAdminEditing) {

    const { t } = useTranslation();
    const [naturalID, setNaturalID] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [breakText, setBreakText] = useState("")
    const [checkText, setCheckText] = useState("")
    const [showBreak, setShowBreak] = useState(true)
    const [showWelcome, setShowWelcome] = useState(false)

    const handleScan = (data) => {
        if (data) {
            const id = data.split(' ');
            setNaturalID(id[0]);
            const date = new Date();
            if (id[1] == 'kid') {
                const payload = {
                    checkInOutType: "CHECKIN",
                    checkedDate: date,
                    kidId: 121212121212,
                    naturalID: id[0]
                }
                checkInOutKid(payload).then(() => {
                    store.dispatch(
                        showBottomNotification(t('QRCode.Kid checkinout successful'), { isFail: false }),
                    );
                });
            } else {
                getCheckInOutDetails(id[0]).then((res) => {
                    setEmployeeDetails(res);
                    if (res) {
                        setShowBreak((res.data.checkInOutCategory == null || res.data.checkInOutCategory == "AUTOCHECKOUT") && res.data.checkInOutType == "CHECKOUT" ? false : true)
                        setBreakText(res.data.checkInOutCategory == "BREAK" ? t('QRCode.Finish break') : t('QRCode.Start break'));
                        setCheckText(res.data.checkInOutType == "CHECKIN" ? t('QRCode.Check out') : t('QRCode.Check in'));
                        if (res.data.checkInOutCategory == "BREAK" && res.data.checkInOutType == "CHECKOUT") {
                            setShowWelcome(res.data.checkInOutCategory == "BREAK" && res.data.checkInOutType == "CHECKOUT");
                            postCheckInOut("Break");
                        }
                    }
                    setOpenPopup(true);
                })

            }
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const toggleScanner = () => {
        setIsOpen(!isOpen);
    };

    const postCheckInOut = (checkInOutCategory) => {
        var payload = null
        if (checkInOutCategory == "Break") {
            payload = {
                checkInOutCategory: employeeDetails.data.checkInOutCategory == null ? "BREAK" : null,
                checkInOutType: employeeDetails.data.checkInOutType == "CHECKIN" ? "CHECKOUT" : "CHECKIN",
                userId: 121212121212,
                naturalID: naturalID
            }
        } else {
            payload = {
                checkInOutCategory: null,
                checkInOutType: employeeDetails.data.checkInOutType == "CHECKIN" ? "CHECKOUT" : "CHECKIN",
                userId: 121212121212,
                naturalID: naturalID
            }
        }
        checkInOut(payload).then(() => {
            if (!showWelcome) {
                setOpenPopup(false);

                store.dispatch(
                    showBottomNotification(t('QRCode.Employee checkinout successful'), { isFail: false }),
                );
            }
        })
    }

    const closePopup = () => {
        setOpenPopup(false);
        setShowWelcome(false);
    }

    return (
        <><><div className={styles.wrapper}>
            <Button onClick={toggleScanner} type='primary'>
                {isOpen ? t('QRCode.Close scanner') : t('QRCode.Open scanner')}
            </Button>


        </div><div className={styles.cameraWrapper}>
                {isOpen && (
                    <QrReader
                        delay={300}
                        facingMode={"user"}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '33%' }} />
                )}
            </div></><Popup
                isOpened={openPopup}
                closePopup={() => closePopup()}
                header={showWelcome ? t('QRCode.Welcome Back') : t('QRCode.Chose an option')}
                footer={!showWelcome && <div className={styles.buttonsContainer}>
                    {showBreak && <div className={styles.buttonSubmit}>
                        <Button onClick={() => postCheckInOut("Break")} type='primary'>{breakText}</Button>
                    </div>}
                    <div className={styles.buttonSubmit}>
                        <Button onClick={() => postCheckInOut("Checkin/Out")} type='primary'>{checkText}</Button>
                    </div>
                </div>}
            >

            </Popup></>
    );
};