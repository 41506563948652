import React, { useState, useMemo, useContext } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import ColorPickerButton from 'library/common/commonComponents/Buttons/ColorPickerButton';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Label from 'library/common/commonComponents/Label';
import Popup from 'library/common/commonComponents/Popups/Popup';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import { createOneTaskListWithPersonPDF } from 'library/utilities/files';
// import { GroupContext } from 'modules/Group/groupModule';

import TaskList from './TaskListsFrames/TaskList/TaskListContainer';
import FineshedLists from './TaskListsFrames/FineshedLists';

import styles from './taskLists.module.scss';

export default function TaskLists({
  isOpenedByDefault = [],
  openedTasksByDefault = [],
  taskLists,
  onDeleteTaskList,
  onAddTaskList,
  onUpdateTaskList,
  onAddTask,
  onDeleteTask,
  onUpdateTask,
  onUpdateTaskStatus,
  manageTaskLists,
  manageTasks,
  proceedUnassignedTask,
  canAddToCalendar,
  isLikeFeatureAvailableInGroup
}) {
  const { t } = useTranslation();
  const activeKita = useSelector(state => state.kitaReducer.activeKita);
  // const currentUser = useSelector(state => state.userReducer);
  // const { groupInfo } = useContext(GroupContext);

  const [addEditTaskListIsOpened, setAddEditTaskListIsOpened] = useState(false);
  const [deleteTaskListIsOpened, setDeleteTaskListIsOpened] = useState(false);
  const [isOpened, setIsOpened] = useState(isOpenedByDefault);
  const [error, setError] = useState('');
  const [newTask, setNewTask] = useState({
    taskListTitle: '',
    color: '#377702',
    hideWhenCompleted: true,
  });

  const hiddenTaskLists = useMemo(
    () => taskLists.filter(taskList => taskList.isCompleted && taskList.hideWhenCompleted),
    [taskLists],
  );

  const unHiddenTaskLists = useMemo(
    () => taskLists.filter(taskList => !taskList.isCompleted || !taskList.hideWhenCompleted),
    [taskLists],
  );

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    mouseDown: true,
    touchStart: true,
    handler: () => setIsColorPickerVisible(false),
  });

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (newTask.taskListTitle.trim() === '') {
      setIsSubmitting(false);
      return setError(t('Tasks.Name cannot be blank'));
    }
    const duplicate = taskLists.find(
      taskList =>
        taskList.taskListTitle.toLowerCase() === newTask.taskListTitle.toLowerCase() &&
        taskList.id !== newTask.id,
    );
    if (
      duplicate ||
      newTask.taskListTitle.toLowerCase() === t('Tasks.Other Tasks').toLowerCase() ||
      newTask.taskListTitle.toLowerCase() === t('Tasks.Finished Lists').toLowerCase()
    ) {
      setIsSubmitting(false);
      return setError(t('Tasks.The given name is already in use'));
    }
    if (addEditTaskListIsOpened === 'add') {
      await onAddTaskList(newTask);
    } else if (addEditTaskListIsOpened === 'edit') {
      await onUpdateTaskList(newTask);
    }
    setIsSubmitting(false);
    setAddEditTaskListIsOpened(false);
    setNewTask({
      taskListTitle: '',
      color: '#377702',
      hideWhenCompleted: true,
    });
  };

  const handleClosePopup = () => {
    setError('');
    setNewTask({
      taskListTitle: '',
      color: '#377702',
      hideWhenCompleted: true,
    });
    setAddEditTaskListIsOpened(false);
    setDeleteTaskListIsOpened(false);
  };

  const handleEditTaskList = taskList => {
    setNewTask(taskList);
    setAddEditTaskListIsOpened('edit');
  };

  const handleTitleChange = e => {
    setError('');
    setNewTask({ ...newTask, taskListTitle: e.target.value });
  };

  const handleDeleteTaskList = taskList => {
    setNewTask(taskList);
    setDeleteTaskListIsOpened(true);
  };

  const handleConfirmDeleteTaskList = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    await onDeleteTaskList(newTask);
    setIsSubmitting(false);
    handleClosePopup();
  };

  const handleDownloadOneTaskListAsPDF = (taskList) => {
    createOneTaskListWithPersonPDF(taskList, activeKita.kitaName, t);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('Tasks.Task Lists')}</div>
        {manageTaskLists && (
          <div className={styles.addTasklistButtonContainer}>
            <Button
              onClick={() => setAddEditTaskListIsOpened('add')}
              className={styles.addTasklistButton}
            >
              <i className='fa fa-plus' />
              {t('Tasks.Add Task List')}
            </Button>
          </div>
        )}
        <div className={styles.description}>{t('Here you can manage your task lists')}</div>
      </div>
      <div className={styles.toggleTasklistButtonContainer}>
        <Button
          onClick={() =>
            setIsOpened(isOpened.length > 0 ? [] : unHiddenTaskLists.map(taskList => taskList.id))
          }
          className={styles.toggleTasklistButton}
        >
          {isOpened.length > 0 ? (
            <i className='fa fa-toggle-on' />
          ) : (
            <i className='fa fa-toggle-off' />
          )}
          {t('Tasks.Toggle lists')}
        </Button>
      </div>
      <div className={styles.taskListsContainer}>
        {unHiddenTaskLists.map(taskList => (
          <TaskList
            openedTasks={openedTasksByDefault}
            isOpened={isOpened.find(taskListId => taskListId == taskList.id)}
            key={taskList.id}
            {...taskList}
            onEditTaskList={() => handleEditTaskList(taskList)}
            onDeleteTaskList={() => handleDeleteTaskList(taskList)}
            onCreatePDFForTaskList={() => handleDownloadOneTaskListAsPDF(taskList)}
            onAddTask={onAddTask}
            onDeleteTask={onDeleteTask}
            onUpdateTask={onUpdateTask}
            onUpdateTaskStatus={onUpdateTaskStatus}
            manageTaskLists={manageTaskLists}
            manageTasks={manageTasks}
            proceedUnassignedTask={proceedUnassignedTask}
            taskLists={taskLists}
            canAddToCalendar={canAddToCalendar}
            isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
          />
        ))}
        <FineshedLists
          hiddenTaskLists={hiddenTaskLists}
          onEditTaskList={handleEditTaskList}
          onDeleteTaskList={handleDeleteTaskList}
          isOpened={false}
          manageTaskLists={manageTaskLists}
        />
      </div>
      <div className={styles.addEditTaskListContainer}>
        <Popup
          isOpened={addEditTaskListIsOpened}
          closePopup={handleClosePopup}
          header={
            addEditTaskListIsOpened === 'add' ? (
              <Trans i18nKey='Tasks.Create task list' />
            ) : (
              <Trans i18nKey='Tasks.Edit task list' />
            )
          }
          footer={
            <div className={styles.addEditButtonsContainer}>
              <div className={styles.buttonSave}>
                <ButtonWithLoader onClick={handleSubmit} type='primary' isLoading={isSubmitting}>
                  {t('Tasks.Save')}
                </ButtonWithLoader>
              </div>
              <div className={styles.buttonCancel}>
                <Button onClick={handleClosePopup}>{t('Tasks.Cancel')}</Button>
              </div>
            </div>
          }
        >
          <div className={styles.addEditWrapper}>
            <Label type='input'>{t('Tasks.Title and Color')}</Label>
            <div className={styles.inputWithColorPicker} ref={outsideEventContainerRef}>
              <Input
                error={error}
                value={newTask.taskListTitle}
                placeholder={t('Tasks.Title of your task')}
                onChange={e => handleTitleChange(e)}
                maxLength={100}
              />
              {error && (
                <div className={styles.error_msg}>
                  <InputErrorMsg errorMsg={error} />
                </div>
              )}
              <div className={styles.colorPicker}>
                <div
                  className={styles.label}
                  onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
                >
                  <div className={styles.current} style={{ background: newTask.color }} />
                </div>
                {isColorPickerVisible && (
                  <ColorPickerButton
                    color={newTask.color}
                    className={styles.pickerComponent}
                    onClick={c => setNewTask({ ...newTask, color: c.hex })}
                  />
                )}
              </div>
            </div>
            <CheckBox
              className={styles.checkbox}
              name={t('Tasks.Hide this list once all tasks are completed')}
              isChecked={newTask.hideWhenCompleted}
              onChange={() =>
                setNewTask({ ...newTask, hideWhenCompleted: !newTask.hideWhenCompleted })
              }
            />
          </div>
        </Popup>
      </div>
      <div className={styles.deleteTaskListContainer}>
        <Popup
          size='extraSmall'
          isOpened={deleteTaskListIsOpened}
          header={<Trans i18nKey='Tasks.Confirm Action' />}
          closePopup={handleClosePopup}
          footer={
            <div className={styles.deleteButtonsContainer}>
              <div className={styles.buttonCancel}>
                <Button onClick={handleClosePopup}>{t('Tasks.Cancel')}</Button>
              </div>
              <div className={styles.buttonConfirm}>
                <ButtonWithLoader
                  onClick={handleConfirmDeleteTaskList}
                  type='primary'
                  isLoading={isSubmitting}
                >
                  {t('Tasks.Confirm')}
                </ButtonWithLoader>
              </div>
            </div>
          }
        >
          <div className={styles.deleteWrapper}>
            {t('Tasks.Do you really want to perform this action')}
          </div>
        </Popup>
      </div>
    </div>
  );
}
