import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RecordRTC from 'recordrtc';
import cn from 'classnames';

import styles from './recordButton.module.scss';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { handleRNMessageHelper } from 'library/utilities/handleRNMessageHelper';

RecordButton.defaultProps = {
  allowedExtensions: ['*'],
};

export default function RecordButton({ uploadFile, insText, voiceForPost }) {
  const { t } = useTranslation();

  const [isListening, setIsListening] = useState(false);
  const [currentRecordRTC, setCurrentRecordRTC] = useState(false);

  const startStopSpeechRecognitionInApp = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'voice_recorder'
      }),
    );
  };

  const start = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        setIsListening(true);
        const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        const recordRTC = new StereoAudioRecorder(stream, {
          mimeType: 'audio/wav',
          numberOfAudioChannels: 1,
        });
        recordRTC.record();
        setCurrentRecordRTC(recordRTC);
      })
      .catch(error => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.AccessMicrophoneDenied'), { isFail: true }),
        );
      });
  };

  const nativeAppStop = e => {
    if (!e || !e.data) {
      return;
    }

    let parts = e.data.split(';');
    let mime = parts[0].split(':')[1];
    let data = parts[1].split(',')[1];
    let bytes = window.atob(data);
    let buff = new ArrayBuffer(bytes.length);
    let arr = new Uint8Array(buff);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    onStop(new Blob([arr], { type: mime }));

    [window, document].forEach(el => {
      el.removeEventListener('message', handleRNMessageHelper('native.voicerecorder', nativeAppStop));
    });
  };

  const handleStart = () => {
    if (window.ReactNativeWebView) {
      [window, document].forEach(el => el.addEventListener('message', handleRNMessageHelper('native.voicerecorder', nativeAppStop)));
      startStopSpeechRecognitionInApp();
      setIsListening(true);
    } else {
      start();
    }
  };

  const handleStop = () => {
    if (window.ReactNativeWebView) {
      setIsListening(false);
      startStopSpeechRecognitionInApp();
    } else {
      setIsListening(false);
      currentRecordRTC.stop(onStop.bind(this));
    }
  };

  const onStop = blob => {
    const fileName = `Sprachnachricht_${new Date().getTime()}.wav`;
    const file = new File([blob], fileName, {
      type: blob.type,
    });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    const files = dataTransfer.files;
    uploadFile(files);
    if (!voiceForPost) {
      insText('Sprachnachricht');
    }
  };

  return (
    <div style={voiceForPost ? null : { position: 'absolute', right: '10px' }}>
      {isListening === false ? (
        <div
          className={cn(styles.upload, voiceForPost ? styles.voiceForPost : null)}
          onClick={handleStart}
        >
          <label>
            <i style={{ display: 'block' }} className='fa fa-microphone' />
          </label>
        </div>
      ) : (
        <div
          className={cn(styles.upload, voiceForPost ? styles.voiceForPost : null)}
          style={{ color: 'red' }}
          onClick={handleStop}
        >
          <label>
            <i className='fa fa-circle' />
          </label>
        </div>
      )}
    </div>
  );
}
