import React from 'react';

export default function FeedFilterRadioItem({ isChecked, onClick, text }) {
  return (
    <div onClick={isChecked ? () => {} : onClick}>
      <i className={isChecked ? 'fa fa-dot-circle-o' : 'fa fa-circle-o'} />
      {text}
    </div>
  );
}
