import React, { useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tabs from 'library/common/commonComponents/Tabs';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Popup from 'library/common/commonComponents/Popups/Popup';
import {
  editKitaUser,
  deactivateUserInKita,
  getKitaAdminsForReceivingPrivateMessage,
} from 'library/api/user';
import { validateEmail } from 'library/utilities/validation';
import UserEditingAccount from './userEditingFrames/UserEditingAccount';
import UserEditingGeneral from './userEditingFrames/UserEditingGeneral';
import UserEditingCommunication from './userEditingFrames/UserEditingCommunication';
import UserChildren from './userEditingFrames/UserChildren/UserChildrenContainer';
import GfzConsent from 'modules/GfzConsent';
import KitaConsent from 'modules/KitaConsent';
import CaritasConsent from 'modules/CaritasConsent';
import ChristusErloeserConsent from 'modules/ChristusErloeserConsent';

import styles from './userEditing.module.scss';
import UserEditingSignatureImage from './userEditingFrames/UserEditingSignatureImage';
import useKitaFields from 'library/common/commonHooks/useKitaFields';
import { getConfig } from 'library/api/kita';
import { addNewChat } from 'library/api/chat';

export default function UserEditing({
  isAdminEditing,
  kitaId,
  editingProfile,
  setEditingProfile,
  patchUserInfo,
  setDeletionProfile,
  user,
  history,
  editChildId,
  setQueryChildIdState,
  setQueryUserIdState,
  activeKita,
  currentUserActiveKita,
}) {
  const { kitaFields, kitaCustomFields } = useKitaFields(activeKita);
  const isSafeToConnectType = (activeKita.kitaTypeTranslations || []).includes('Safe2Connect');

  const { t } = useTranslation();
  const [editingFields, setEditingFields] = useState({
    ...editingProfile,
    initialEmail: editingProfile.email,
  });
  const [userNameError, setUserNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [disablePopupOpen, setDisablePopupOpen] = useState(false);
  const [activatePopupOpen, setActivatePopupOpen] = useState(false);
  const [disableAllPopupOpen, setDisableAllPopupOpen] = useState(false);
  const [activateAllPopupOpen, setActivateAllPopupOpen] = useState(false);
  const [qrCodeScanner, setQrCodeScanner] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [locationHost] = useState(window.location.hostname);

  const isGFZ = locationHost.toLowerCase().includes('gfz-app.ch');
  const isKitaAdminOrEmployee = user.administrationAccess || user.employee;

  const shouldDisableContactSettings = isGFZ && !isKitaAdminOrEmployee;

  useEffect(() => {
    setQrCodeScanner(editingProfile.qrCodeScanner);
  }, [editingProfile]);

  const [signature, setSignature] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [receivePrivateMessage, setReceivePrivateMessage] = useState(false);
  const [groupMandatoryField, setGroupMandatoryField] = useState(false);
  const [kitaAdminsReceivingMessage, setKitaAdminsReceivingMessage] = useState([]);

  useEffect(() => {
    getConfig().then(res => {
      const data = res.data;
      setSignature(data[1]);
      setCommunication(data[2]);
      setReceivePrivateMessage(data[3]);
      setGroupMandatoryField(data[4]);

      let tabsTemp = tabs;
      if (data[1] === false) {
        tabsTemp = tabsTemp.filter(tab => tab.path !== 'usersignature');
      }
      if (data[2] === false) {
        tabsTemp = tabsTemp.filter(tab => tab.path !== 'communication');
      }
      console.log(tabsTemp);

      setCurrentTab(
        editChildId != null || history.location.automaticallyDisplayKidCreationScreen != null
          ? tabsTemp.findIndex(tb => tb.path === 'user-children' || tb.path === 'children')
          : 0,
      );
    });
    getKitaAdminsForReceivingPrivateMessage(-1, activeKita.kitaId).then(res => {
      const data = res.data;
      if (data && data.content?.length) {
        const kitaAdmins = data.content.map(x => x.user);
        setKitaAdminsReceivingMessage(kitaAdmins);
      }
    });
  }, []);

  const tabs = useMemo(() => {
    let tabArray = ((user.administrationAccess && isAdminEditing) || !isAdminEditing) ? communication ? [
      {
        title: t('Administration.UserSection.General'),
        component: (
          <UserEditingGeneral
            editingFields={editingFields}
            setEditingFields={setEditingFields}
            firstNameError={firstNameError}
            lastNameError={lastNameError}
            userNameError={userNameError}
            user={user}
            shouldDisableContactSettings={shouldDisableContactSettings}
            currentUserActiveKita={currentUserActiveKita}
          />
        ),
        path: 'general',
      },
      {
        title: t('Administration.UserSection.Communication'),
        component: (
          <UserEditingCommunication
            editingFields={editingFields}
            setEditingFields={setEditingFields}
            shouldDisableContactSettings={shouldDisableContactSettings}
          />
        ),
        path: 'communication',
      },
      {
        title: t('Administration.UserSection.Children'),
        component: isAdminEditing ? (
          <UserChildren
            user={editingProfile}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing
            editChildId={editChildId}
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ) : (
          <UserChildren
            user={user}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing={false}
            editChildId={editChildId}
            automaticallyDisplayKidCreationScreen={
              history.location.automaticallyDisplayKidCreationScreen
            }
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ),
        path: isAdminEditing ? 'children' : 'user-children',
      },
    ] : [
      {
        title: t('Administration.UserSection.General'),
        component: (
          <UserEditingGeneral
            editingFields={editingFields}
            setEditingFields={setEditingFields}
            firstNameError={firstNameError}
            lastNameError={lastNameError}
            userNameError={userNameError}
            user={user}
            shouldDisableContactSettings={shouldDisableContactSettings}
            currentUserActiveKita={currentUserActiveKita}
          />
        ),
        path: 'general',
      },
      {
        title: t('Administration.UserSection.Children'),
        component: isAdminEditing ? (
          <UserChildren
            user={editingProfile}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing
            editChildId={editChildId}
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ) : (
          <UserChildren
            user={user}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing={false}
            editChildId={editChildId}
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ),
        path: isAdminEditing ? 'children' : 'user-children',
      },
    ] : (!user.administrationAccess && user.employee && isAdminEditing) ? [
      {
        title: t('Administration.UserSection.Children'),
        component: isAdminEditing ? (
          <UserChildren
            user={editingProfile}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing
            editChildId={editChildId}
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ) : (
          <UserChildren
            user={user}
            history={history}
            activeKita={activeKita}
            kitaId={kitaId}
            isAdminEditing={false}
            editChildId={editChildId}
            automaticallyDisplayKidCreationScreen={
              history.location.automaticallyDisplayKidCreationScreen
            }
            kitaFields={kitaFields}
            kitaCustomFields={kitaCustomFields}
            groupMandatoryField={groupMandatoryField}
          />
        ),
        path: isAdminEditing ? 'children' : 'user-children',
      },
    ] : [];
    if (user && user.id && editingProfile && editingProfile.id && user.id === editingProfile.id && signature) {
      tabArray.push({
        title: t('Administration.UserSection.User signature'),
        component: <UserEditingSignatureImage />,
        path: 'usersignature',
      });
    }

    if (window.location.hostname.toLowerCase().includes('ivs.safe2connect.org')) {
      tabArray = tabArray.filter(el => el.path !== 'user-children' && el.path !== 'children');
    }

    if (
      window.location.hostname.toLowerCase().includes('gfz-app.ch') ||
      window.location.hostname.toLowerCase().includes('staging2.safe2connect.org')
    ) {
      if (isAdminEditing || (!isAdminEditing && user.administrationAccess === false)) {
        tabArray.push({
          title: t('Administration.UserSection.GfzConsent'),
          component: isAdminEditing ? (
            <GfzConsent type={'profileFrame'} isAdminEditing={true} userInfo={editingProfile} />
          ) : (
            <GfzConsent type={'profileFrame'} isAdminEditing={false} userInfo={user} />
          ),
          path: isAdminEditing ? 'gfz-consent' : 'user-gfz-consent',
        });
      }
    }

    if (
      (activeKita && activeKita.id && (activeKita.id === 3627 || activeKita.id === 315)) ||
      (activeKita && activeKita.kitaId && (activeKita.kitaId === 3627 || activeKita.kitaId === 315))
    ) {
      if (isAdminEditing || (!isAdminEditing && user.administrationAccess === false)) {
        tabArray.push({
          title: t('Administration.UserSection.CaritasConsent'),
          component: isAdminEditing ? (
            <CaritasConsent
              type={'profileFrame'}
              isAdminEditing={true}
              userInfo={editingProfile}
              activeKita={activeKita}
            />
          ) : (
            <CaritasConsent
              type={'profileFrame'}
              isAdminEditing={false}
              userInfo={user}
              activeKita={activeKita}
            />
          ),
          path: isAdminEditing ? 'caritas-consent' : 'user-caritas-consent',
        });
      }
    }

    if (
      (activeKita &&
        activeKita.id &&
        (activeKita.id === 124 || activeKita.id === 3621 || activeKita.id === 3759)) ||
      (activeKita &&
        activeKita.kitaId &&
        (activeKita.kitaId === 124 || activeKita.kitaId === 3621 || activeKita.id === 3759))
    ) {
      if (isAdminEditing || (!isAdminEditing && user.administrationAccess === false)) {
        tabArray.push({
          title: t('Administration.UserSection.ChristusErloeserConsent'),
          component: isAdminEditing ? (
            <ChristusErloeserConsent
              type={'profileFrame'}
              isAdminEditing={true}
              userInfo={editingProfile}
              activeKita={activeKita}
              currentUserActiveKita={currentUserActiveKita}
            />
          ) : (
            <ChristusErloeserConsent
              type={'profileFrame'}
              isAdminEditing={false}
              userInfo={user}
              activeKita={activeKita}
            />
          ),
          path: isAdminEditing ? 'christuseloeser-consent' : 'user-christuserloeser-consent',
        });
      }
    }

    if (
      window.location.hostname.toLowerCase().includes('localhost') ||
      (activeKita && activeKita.id && activeKita.id === 3975) ||
      (activeKita && activeKita.kitaId && activeKita.kitaId === 3975)
    ) {
      tabArray.push({
        title: t('Administration.UserSection.KitaConsent'),
        component: isAdminEditing ? (
          <KitaConsent
            type={'profileFrame'}
            isAdminEditing={true}
            userInfo={editingProfile}
            activeKita={activeKita}
          />
        ) : (
          <KitaConsent
            type={'profileFrame'}
            isAdminEditing={false}
            userInfo={user}
            activeKita={activeKita}
          />
        ),
        path: isAdminEditing ? 'kitaconsent' : 'userkitaconsent',
      });
    }

    if (user.administrationAccess) {
      tabArray.unshift({
        title: t('Administration.UserSection.Account'),
        component: (
          <UserEditingAccount
            editingFields={{
              ...editingFields,
              adminRole:
                editingFields.adminRole ||
                editingFields.superAdminStatus ||
                editingFields.kitaAdminStatus,
            }}
            setEditingFields={setEditingFields}
            userNameError={userNameError}
            emailError={emailError}
            setDeletionProfile={setDeletionProfile}
            isSuperAdmin={user.superAdminStatus}
            hasAdministrationAccess={user.administrationAccess}
            user={user}
            qrCodeScanner={qrCodeScanner}
            isSelf={user.email === editingProfile.email}
          />
        ),
        path: 'account',
      });
    }

    if (isSafeToConnectType) {
      tabArray = tabArray.filter(tab => tab.path !== 'children' && tab.path !== 'user-children');
    }

    return tabArray;
  }, [
    isSafeToConnectType,
    editingFields,
    user,
    editingProfile,
    editChildId,
    setQueryChildIdState,
    setQueryUserIdState,
    activeKita,
    currentUserActiveKita,
    kitaFields,
  ]);

  const [currentTab, setCurrentTab] = useState(0);

  const tryNavigateToTab = tabIndex => {
    const isChildrenTab =
      tabs[tabIndex].path === 'user-children' || tabs[tabIndex].path === 'children';
    if (isChildrenTab && activeKita.virtual) {
      store.dispatch(
        showBottomNotification(
          t('Administration.UserSection.You cannot edit children in a virtual Kita'),
          { isFail: true },
        ),
      );
    } else {
      setCurrentTab(tabIndex);
    }
  };

  const checkIfAdminToAdmin = () => {
    if (editingProfile.adminRole || editingProfile.kitaAdminStatus) {
      if ((user.superAdminStatus || user.administrationAccess) && user.id != editingProfile.id) {
        return false;
      } else {
        store.dispatch(showBottomNotification(t('Administration.You cannot deactivate yourself'), { isFail: true }));
        return true
      }
    }
    return false;
  };

  if (window.location.hostname.toLowerCase().includes('matchand.de')) {
    tabs = tabs.filter(
      tab =>
        tab.path !== 'children' &&
        tab.path !== 'user-children' &&
        tab.path !== 'kitaconsent' &&
        tab.path !== 'userkitaconsent' &&
        tab.path !== 'user-gfz-consent' &&
        tab.path !== 'gfz-consent' &&
        tab.path !== 'usersignature',
    );
  }

  const saveAccount = () =>
    saveAccountHandler({
      editingFields,
      kitaId,
      setUserNameError,
      setEmailError,
      setFirstNameError,
      setLastNameError,
      t,
      setEditingProfile,
      isLoading,
      setIsLoading,
      isAdminEditing,
      patchUserInfo,
      activeKita,
      history,
      receivePrivateMessage,
      editingProfile,
      kitaAdminsReceivingMessage,
      user,
    });

  return (
    <div>
      {isAdminEditing && (
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {t('Administration.UserSection.Edit user')}{' '}
            <span className='notranslate'>{editingProfile.firstName}</span>{' '}
            {editingProfile.lastName}
          </div>

          {user.administrationAccess && (
            <Button
              onClick={() => {
                setEditingProfile(null);
                setQueryChildIdState(null);
                setQueryUserIdState(null);
              }}
            >
              <i className={cn('fa fa-arrow-left', styles.buttonIcon)} />{' '}
              {t('Gallery.Back to overview')}
            </Button>
          )}
        </div>
      )}
      <Tabs
        type='secondary'
        tabs={tabs}
        currentTab={tabs[currentTab].path}
        onChange={(_, index) => tryNavigateToTab(index)}
      />

      <div className={styles.buttons}>
        {tabs[currentTab].path !== 'user-children' &&
          tabs[currentTab].path !== 'children' &&
          tabs[currentTab].path !== 'gfz-consent' &&
          tabs[currentTab].path !== 'user-gfz-consent' &&
          tabs[currentTab].path !== 'paymentinformation' && (
            <ButtonWithLoader
              type='primary'
              className={styles.button}
              onClick={saveAccount}
              isLoading={isLoading}
            >
              {t('Post.Save')}
            </ButtonWithLoader>
          )}
        {tabs[currentTab].path !== 'user-children' &&
          tabs[currentTab].path !== 'children' &&
          tabs[currentTab].path !== 'gfz-consent' &&
          tabs[currentTab].path !== 'user-gfz-consent' &&
          tabs[currentTab].path !== 'paymentinformation' &&
          !editingProfile.userDeactivationStatus && (
            <>
              <Button
                className={styles.button}
                onClick={() => {
                  if (!checkIfAdminToAdmin()) {
                    setDisablePopupOpen(true);
                  }
                }}
              >
                {t('Post.Deactivate')}
              </Button>
              <Button
                className={styles.button}
                onClick={() => {
                  if (!checkIfAdminToAdmin()) {
                    setDisableAllPopupOpen(true);
                  }
                }}
              >
                {t('Post.Deactivate All')}
              </Button>
              <Popup
                size={'extraSmall'}
                isOpened={disableAllPopupOpen}
                closePopup={() => {
                  setDisableAllPopupOpen(false);
                }}
                header={<strong>{t('Administration.UserSection.Confirm disable all user')}</strong>}
                footer={
                  <Button
                    className={styles.button}
                    onClick={() => {
                      deactivateUserInKita(editingFields.id, true, true).then(() => {
                        store.dispatch(
                          showBottomNotification(t('Administration.UserSection.Success'), {
                            isFail: false,
                          }),
                        );
                        setDisableAllPopupOpen(false);
                        setEditingProfile(null);
                      });
                    }}
                  >
                    {t('Post.Deactivate')}
                  </Button>
                }
              />
              <Popup
                size={'extraSmall'}
                isOpened={disablePopupOpen}
                closePopup={() => {
                  setDisablePopupOpen(false);
                }}
                header={<strong>{t('Administration.UserSection.Confirm disable user')}</strong>}
                footer={
                  <Button
                    className={styles.button}
                    onClick={() => {
                      deactivateUserInKita(editingFields.id, true, false).then(() => {
                        store.dispatch(
                          showBottomNotification(t('Administration.UserSection.Success'), {
                            isFail: false,
                          }),
                        );
                        setDisablePopupOpen(false);
                        setEditingProfile(null);
                      });
                    }}
                  >
                    {t('Post.Deactivate')}
                  </Button>
                }
              />
            </>
          )}
        {tabs[currentTab].path !== 'user-children' &&
          tabs[currentTab].path !== 'children' &&
          tabs[currentTab].path !== 'gfz-consent' &&
          tabs[currentTab].path !== 'user-gfz-consent' &&
          tabs[currentTab].path !== 'paymentinformation' &&
          editingProfile.userDeactivationStatus && (
            <>
              <Button
                className={styles.button}
                onClick={() => {
                  if (!checkIfAdminToAdmin()) {
                    setActivatePopupOpen(true);
                  }
                }}
              >
                {t('Post.Activate')}
              </Button>
              <Button
                className={styles.button}
                onClick={() => {
                  if (!checkIfAdminToAdmin()) {
                    setActivateAllPopupOpen(true);
                  }
                }}
              >
                {t('Post.Activate All')}
              </Button>
              <Popup
                size={'extraSmall'}
                isOpened={activateAllPopupOpen}
                closePopup={() => {
                  setActivateAllPopupOpen(false);
                }}
                header={
                  <strong>{t('Administration.UserSection.Confirm Activate all user')}</strong>
                }
                footer={
                  <Button
                    className={styles.button}
                    onClick={() => {
                      deactivateUserInKita(editingFields.id, false, true).then(() => {
                        store.dispatch(
                          showBottomNotification(t('Administration.UserSection.Success'), {
                            isFail: false,
                          }),
                        );
                        setActivateAllPopupOpen(false);
                        setEditingProfile(null);
                      });
                    }}
                  >
                    {t('Post.Activate')}
                  </Button>
                }
              />
              <Popup
                size={'extraSmall'}
                isOpened={activatePopupOpen}
                closePopup={() => {
                  setActivatePopupOpen(false);
                }}
                header={<strong>{t('Administration.UserSection.Confirm Enable user')}</strong>}
                footer={
                  <Button
                    className={styles.button}
                    onClick={() => {
                      deactivateUserInKita(editingFields.id, false, false).then(() => {
                        store.dispatch(
                          showBottomNotification(t('Administration.UserSection.Success'), {
                            isFail: false,
                          }),
                        );
                        setActivatePopupOpen(false);
                        setEditingProfile(null);
                      });
                    }}
                  >
                    {t('Activate User')}
                  </Button>
                }
              />
            </>
          )}
        {setDeletionProfile &&
          tabs[currentTab].path !== 'children' &&
          tabs[currentTab].path !== 'gfz-consent' &&
          tabs[currentTab].path !== 'user-gfz-consent' &&
          tabs[currentTab].path !== 'paymentinformation' && (
            <Button
              type='danger'
              onClick={() => {
                setDeletionProfile(editingProfile);
                setEditingProfile(null);
              }}
            >
              {t('Post.Delete')}
            </Button>
          )}
      </div>
    </div>
  );
}

function compareChanged(obj1, obj2) {
  const keysToCompare = ['street', 'city', 'countryId', 'zip', 'state'];

  return keysToCompare.every(key => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    return value1 === value2 || (!value1 && !value2);
  });
}

function getChangedFields(obj1, obj2, t) {
  const changedFields = [];
  const keysToCompare = ['street', 'city', 'countryId', 'zip', 'state'];
  const labelObj = {
    street: t('Administration.UserSection.Street'),
    city: t('Administration.UserSection.City'),
    countryId: t('Administration.UserSection.Country'),
    zip: t('Administration.UserSection.Zip'),
    state: t('Administration.UserSection.State'),
  };

  keysToCompare.forEach(key => {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (!(value1 === value2 || (!value1 && !value2))) {
      changedFields.push({
        key: key,
        value: key === 'countryId' ? obj2['countryName'] : value2,
        label: labelObj[key],
      });
    }
  });

  return changedFields;
}

export async function saveAccountHandler({
  editingFields,
  kitaId,
  setUserNameError,
  setEmailError,
  setFirstNameError,
  setLastNameError,
  t,
  setEditingProfile,
  isLoading,
  setIsLoading,
  isAdminEditing,
  patchUserInfo,
  activeKita,
  history,
  receivePrivateMessage,
  editingProfile,
  kitaAdminsReceivingMessage,
  user,
}) {
  if (isLoading) {
    return;
  }
  let isSuccess = true;
  if (editingFields.userName.trim().length === 0) {
    isSuccess = false;
    setUserNameError(t('Administration.UserSection.Username cannot be blank'));
  } else {
    setUserNameError('');
  }

  if (editingFields.email.trim().length === 0) {
    isSuccess = false;
    setEmailError(t('Administration.UserSection.Email cannot be blank'));
  } else if (!validateEmail(editingFields.email)) {
    isSuccess = false;
    setEmailError(t('Administration.UserSection.Email is wrong'));
  } else {
    setEmailError('');
  }

  if (editingFields.firstName.trim().length === 0) {
    isSuccess = false;
    setFirstNameError(t('Administration.UserSection.First name cannot be blank'));
  } else {
    setFirstNameError('');
  }

  if (editingFields.lastName.trim().length === 0) {
    isSuccess = false;
    setLastNameError(t('Administration.UserSection.Last name cannot be blank'));
  } else {
    setLastNameError('');
  }

  if (!isSuccess) return;

  setIsLoading(true);

  const { data } = await editKitaUser({
    editingFields: {
      ...editingFields,
      countryId:
        editingFields.countryId != null
          ? editingFields.countryId
          : editingFields.country !== null
          ? editingFields.country.id
          : 0,
    },
    kitaId,
    userId: editingFields.id,
    isAdminEditing,
  });

  const isMemberOrEmployee = !(user.superAdminStatus || user.administrationAccess) || user.employee;
  if (receivePrivateMessage && kitaAdminsReceivingMessage.length && isMemberOrEmployee) {
    const isNoChanged = compareChanged(editingProfile, editingFields);
    if (!isNoChanged) {
      const changedFields = getChangedFields(editingProfile, editingFields, t);
      let messageContent = '';
      changedFields.forEach(x => {
        messageContent += `<p>${x.label}: ${x.value}</p>`;
      });
      const fullName = `${editingFields.firstName} ${editingFields.lastName}`;
      const chatInfo = {
        subject: `${t('Administration.UserSection.DearKitaAdmin')}, User ${fullName} ${t(
          'Administration.UserSection.PersonalProfileChanged',
        )}`,
        message: messageContent,
        users: [...kitaAdminsReceivingMessage],
        groups: [],
      };
      await addNewChat(chatInfo, kitaId, []);
    }
  }
  if (typeof data !== 'string') {
    setIsLoading(false);
    if (data.message.indexOf('userName') !== -1) {
      setUserNameError(t('Administration.UserSection.Please select some other username'));
    } else if (data.message.indexOf('email') !== -1) {
      setEmailError(t('Administration.UserSection.Please select some other email'));
    }
  } else {
    if (!isAdminEditing) {
      patchUserInfo({
        firstName: editingFields.firstName,
        lastName: editingFields.lastName,
        title: editingFields.title,
      });
    }
    setEditingProfile(null);
  }
  setIsLoading(false);

  if (activeKita?.description === 'Betreuungsverträge' && !isAdminEditing) {
    history.push('/dashboard');
  }
}
