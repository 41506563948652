import { connect } from 'react-redux';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

import UsersFrameKitaAdmins from '.';

export default connect(
  null,
  { showBottomNotification },
)(UsersFrameKitaAdmins);
