import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupContext } from 'modules/Group/groupModule';
import { timeSince, getUpdatedTime } from 'library/utilities/date';
import {
  deleteGroupMember,
  updateGroupMemberRole,
} from 'library/common/commonActions/groupActions';
import Tooltip from 'library/common/commonComponents/Tooltip';
import ConfirmPopup from 'library/common/commonComponents/Popups/ConfirmPopup';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Select from 'library/common/commonComponents/Inputs/Select';
import ProfileAvatar from 'library/common/commonComponents/ProfileAvatar';
import MemberCard from '../MemberCard';

import styles from './membersTableRow.module.scss';
import { getConfig } from 'library/api/kita';

export default function MembersTableRow({
  data,
  patchTableData,
  reloadTable,
  userId,
  showBottomNotificationFromProps,
  user: activeUser,
}) {
  const { t } = useTranslation();
  const groupContext = useContext(GroupContext);
  const { groupInfo, reloadGroupInfo, patchGroup } = groupContext;
  const [confirmIsOpened, setConfirmIsOpened] = useState(false);

  const roleOptions = [
    { value: '1', adminRole: true, label: t('GroupPanel.Administrators') },
    { value: '2', adminRole: false, label: t('GroupPanel.Members') },
  ];

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    getConfig().then(res => {
      const data = res.data;
      setShowLogin(data[0]);
    });
  }, []);

  const checkUserIsLastAdmin = () => {
    if (data.groupRole.role.adminRole) {
      const adminMembers = groupInfo.userList.filter(user => user.groupAdminStatus === true);
      return adminMembers.length === 1 && adminMembers.find(user => user.id === `${data.user.id}`);
    }
    return false;
  };

  const deleteItem = () => {
    if (checkUserIsLastAdmin()) {
      showBottomNotificationFromProps(
        t('GroupBody.Members.Operation failed! Please assign other admin'),
        { isFail: true },
      );
      setConfirmIsOpened(false);
      return;
    }

    const userIds = `userIds=${data.user.id}`;

    return deleteGroupMember(groupInfo.groupId, userIds)
      .then(res => {
        if (res.status === 200) {
          if (userId === data.user.id) {
            reloadGroupInfo();
          } else {
            patchTableData(data.user.id, 'delete');
            const newMembers = groupInfo.userList.slice();
            const index = newMembers.findIndex(user => user.id === `${data.user.id}`);

            if (index) {
              newMembers.splice(index, 1);
            }
            patchGroup({
              memberCount: groupInfo.memberCount - 1,
              userList: newMembers,
            });
            reloadTable();
          }
        } else if (res.status === 422 || res.response.status === 422) {
          showBottomNotificationFromProps(
            t('GroupBody.Members.Operation failed! Please assign other admin'),
            { isFail: true },
          );
        } else {
          console.log(res);
        }
        setConfirmIsOpened(false);
      })
      .catch(err => {
        setConfirmIsOpened(false);
        return console.log(err);
      });
  };

  const updateRoleItem = e => {
    if (checkUserIsLastAdmin()) {
      showBottomNotificationFromProps(
        t('GroupBody.Members.Operation failed! Please assign other admin'),
        { isFail: true },
      );
      return;
    }

    updateGroupMemberRole(groupInfo.groupId, data.user.id, e.value)
      .then(res => {
        if (res.status === 200) {
          if (userId === data.user.id) {
            reloadGroupInfo();
            showBottomNotificationFromProps(t('GroupBody.Members.Saved'));
          } else {
            const role = roleOptions.find(item => item.value === e.value);
            patchTableData(data.user.id, { groupRole: { role: { adminRole: role.adminRole } } });
            patchGroup({
              userList: groupInfo.userList.map(user => {
                if (user.id === `${data.user.id}`) {
                  user.groupAdminStatus = e.value === '1';
                }
                return user;
              }),
            });
            showBottomNotificationFromProps(t('GroupBody.Members.Saved'));
          }
        } else if (res.status === 422 || res.response.status === 422) {
          showBottomNotificationFromProps(
            t('GroupBody.Members.Operation failed! Please assign other admin'),
            { isFail: true },
          );
        } else {
          console.log(res);
        }
      })
      .catch(err => {
        return console.log(err);
      });
  };

  return (
    <tr>
      <td>
        <CheckBox
          isChecked={data.checked}
          onChange={() => patchTableData(data.user.id, { checked: !data.checked })}
        />
      </td>
      <td className={styles.photoWrapper}>
        <div className={styles.photo}>
          <ProfileAvatar image={data.user} id={data.user.id} />
        </div>
      </td>
      <td>
        <MemberCard member={data.user} />
      </td>
      <td title={getUpdatedTime(data.createdAt)}>
        {getUpdatedTime(data.createdAt, activeUser.langCode)}
      </td>
      {showLogin && (
        <td title={getUpdatedTime(data.lastVisit)}>
          {data.lastVisit
            ? timeSince({ date: data.lastVisit, langCode: activeUser.langCode })
            : 'never'}
        </td>
      )}
      <td className={styles.role}>
        <Select
          className={styles.role__select}
          options={roleOptions}
          onSelect={e => updateRoleItem(e)}
          selected={
            roleOptions.find(item => item.adminRole === data.groupRole.role.adminRole) || {
              label: '',
            }
          }
        />
      </td>
      <td>
        <div className={styles.remove} onClick={() => setConfirmIsOpened(true)}>
          <Tooltip text={t('GroupPanel.Remove from group')} isVisibleCondition>
            <i className='fa fa-times' />
          </Tooltip>
        </div>
        <div className={styles.confirmWindow}>
          <ConfirmPopup
            text={t('GroupPanel.Are you really sure?')}
            closePopup={() => setConfirmIsOpened(false)}
            onConfirm={deleteItem}
            isOpened={confirmIsOpened}
          />
        </div>
      </td>
    </tr>
  );
}
