import React, { useState, useEffect } from 'react';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import styles from './defaultContractEdit.module.scss';
import {
  getAllKidsWithNameAndIdFromSelectedKita,
  getContractStatusMapForKids,
} from '../../library/api/formEditor';

import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { useTranslation } from 'react-i18next';
import Table from 'library/common/commonComponents/Table';

export default function DefaultContractOverview() {
  const { t } = useTranslation();

  const [kidOverview, setKidOverview] = useState([]);

  const [statusMap, setStatusMap] = useState({});

  function randomStatus(id) {
    if (id % 3 === 0) {
      return <span style={{ color: 'red' }}>Status: Offen</span>;
    } else if (id % 3 === 1) {
      return <span style={{ color: 'green' }}>Status: Unterschrieben</span>;
    } else {
      return <span style={{ color: 'orange' }}>Status: Abgelehnt</span>;
    }
  }

  const getStatus = kidId => {
    if (statusMap && kidId in statusMap) {
      switch (statusMap[kidId]) {
        case 'OPEN':
          return <span style={{ color: 'red' }}>Status: Offen</span>;
        case 'SIGNED':
          return <span style={{ color: 'green' }}>Status: Unterschrieben</span>;
        default:
          return <span>Status: Unbekannt</span>;
      }
    }

    return <span>Status: Unbekannt</span>;
  };

  useEffect(() => {
    getAllKidsWithNameAndIdFromSelectedKita().then(data => {
      setKidOverview(data.data);
    });
  }, []);

  useEffect(() => {
    if (!kidOverview || kidOverview.length === 0) {
      return;
    }

    getContractStatusMapForKids(kidOverview.map(k => k.id))
      .then(res => {
        setStatusMap(res.data);
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('DefaultContractOverview.StatusLoadError'), { isFail: true }),
        );
      });
  }, [kidOverview]);

  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>{'Verträge Übersicht'}</div> */}
      {/* <HelpText>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus</HelpText> */}

      <Table
        header={['Name', 'Status']}
        rows={kidOverview.map(kid => {
          return [`${kid.firstName} ${kid.lastName}`, getStatus(kid.id)];
        })}
      />
    </div>
  );
}
