import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useEditor from 'library/common/commonHooks/useEditor';
import Select from 'library/common/commonComponents/Inputs/Select';
import Input from 'library/common/commonComponents/Inputs/Input';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import { checkLink } from 'library/utilities/checkLink';

import styles from './createEventParticipation.module.scss';

export default function CreateEventParticipation({ createEventState, createEventDispatch }) {
  const { t } = useTranslation();
  const participantOptions = [
    { value: 'INVITED_PARTICIPANTS_ONLY', label: t('Calendar.Invited participants only') },
    { value: 'NO_PARTICIPANTS', label: t('Calendar.No participants') },
    { value: 'EVERYBODY_CAN_PARTICIPATE', label: t('Calendar.Everybody can participate') },
    { value: 'KIDS_CAN_PARTICIPATE', label: t('Calendar.Kids can participate') },
  ];
  const [participantOption, setParticipantOption] = useState(
    createEventState.participationEnum === 'EVERYBODY_CAN_PARTICIPATE'
      ? participantOptions[1]
      : createEventState.participationEnum === 'KIDS_CAN_PARTICIPATE'
      ? participantOptions[2]
      : participantOptions[0],
  );

  const setParticipants = option => {
    createEventDispatch({ type: 'setCanParticipate', payload: option });
    setParticipantOption(option);
  };

  const wrappedMessage = document.createElement('div');
  wrappedMessage.innerHTML = createEventState.info;
  const { Editor, getEditorValue } = useEditor({
    message: wrappedMessage,
    placeholder: t('Calendar.Participant Info'),
    onChange: () => {
      const value = getEditorValue();
      createEventDispatch({ type: 'setParticipantInfo', payload: checkLink(value) });
    },
    className: styles.textareaContent,
  });

  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <Select
          options={participantOptions}
          onSelect={setParticipants}
          selected={participantOption}
        />
      </div>
      <>
        {(participantOption.value === 'EVERYBODY_CAN_PARTICIPATE' ||
          participantOption.value === 'KIDS_CAN_PARTICIPATE') && (
          <>
            <div className={styles.inputContainer}>
              <Label type='input'>{t('Calendar.Maximum number')}</Label>
              <Input
                value={createEventState.maxNumberOfParticipants}
                type='number'
                onChange={e =>
                  createEventDispatch({ type: 'setMaxParticipants', payload: e.target.value })
                }
              />
            </div>
          </>
        )}
        {participantOption.value === 'EVERYBODY_CAN_PARTICIPATE' && (
          <>
            <div className={styles.checkboxContainer}>
              <CheckBox
                className={styles.checkbox}
                name={t('Calendar.Allow decline')}
                isChecked={createEventState.isAllowDecline}
                onChange={() => createEventDispatch({ type: 'setAllowDecline' })}
              />
              <CheckBox
                className={styles.checkbox}
                name={t('Calendar.Allow maybe')}
                isChecked={createEventState.isAllowMaybe}
                onChange={() => createEventDispatch({ type: 'setAllowMaybe' })}
              />
            </div>
            {false && (
              <div className={styles.textareaContainer}>
                <Editor />
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}
