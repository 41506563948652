import {
  SET_CREATE_KITA_MODE,
  SET_NEW_KITA,
  SET_ACTIVE_KITA,
  SET_MODULES,
  SET_IS_OPENED,
  SET_KITAS,
  GO_TO_NEW_KITA_SIGNAL,
  GO_TO_KITA_SIGNAL,
} from 'library/common/commonConstants/kitaConstants';

export const setCreateKitaMode = flag => dispatch => {
  dispatch({
    type: SET_CREATE_KITA_MODE,
    payload: flag,
  });
};

export const setNewKita = newKita => dispatch => {
  dispatch({
    type: SET_NEW_KITA,
    payload: newKita,
  });
};

export const setActiveKita = newKita => dispatch => {
  dispatch({
    type: SET_ACTIVE_KITA,
    payload: newKita,
  });
};

export const setKitaModules = modules => dispatch => {
  dispatch({
    type: SET_MODULES,
    modules,
  });
};

export const setIsOpened = isOpened => dispatch => {
  dispatch({
    type: SET_IS_OPENED,
    isOpened,
  });
};

export const setKitas = kitas => dispatch => {
  dispatch({
    type: SET_KITAS,
    kitas,
  });
};

export const goToNewKita = kitaName => dispatch => {
  dispatch({
    type: GO_TO_NEW_KITA_SIGNAL,
    kitaName,
  });
};

export const goToKita = kitaId => dispatch => {
  dispatch({
    type: GO_TO_KITA_SIGNAL,
    kitaId,
  });
};
