import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import EditGalleryPopup from 'library/common/commonComponents/Popups/EditGalleryPopup';
import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import DeletePopup from 'library/common/commonComponents/Popups/DeletePopup';
import Loader from 'library/common/commonComponents/Loader';
import useGalleryFilesLoading from 'library/common/commonHooks/useGalleryFilesLoading';
import Card from 'library/common/commonComponents/Card';
import MoreBtn from 'library/common/commonComponents/Buttons/MoreBtn';
import TextCaret from 'library/common/commonComponents/TextCaret';
import WithWatsonTranslate from 'library/common/commonComponents/WithWatsonTranslate/version';
import GalleryAddButton from '../GalleryAddButton';
import GalleryItemImage from '../GalleryItemImage';
import GalleryItemHeader from '../GalleryItemHeader';
import GalleryFilter from '../GalleryFilter/GalleryFilterContainer';
import { movePictures } from 'library/api/galleries';

import styles from '../../gallery.module.scss';

export default function NonDefaultGallery({
  isCreateGalleryPopupVisible,
  setIsCreateGalleryPopupVisible,
  editGallery,
  activeGallery,
  groupInfo,
  galleries,
  isDeletePopupVisible,
  setIsDeletePopupVisible,
  deleteGallery,
  setOpenedGalleryId,
  dropdownOptions,
  progress,
  filesInputRef,
  deleteImage,
  editImageDescription,
  uploadFilesToGallery,
  isProfilePage,
  user,
  activeKita,
  isBazaar,
  deleteAllImagesFromGallery,
}) {
  const { t } = useTranslation();
  const {
    isLoading,
    images,
    dispatch,
    onFileInputChange,
    updateFilters,
    onFiltersLoad,
  } = useGalleryFilesLoading({
    groupId: groupInfo.groupId,
    galleryId: activeGallery.id,
    uploadFilesToGallery,
    privateGallery: activeGallery.privateGallery,
    user,
    activeKita,
    isBazaar,
  });

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [galleryFiles, setGalleryFiles] = useState([]);

  const handleDeleteBtnClick = async () => {
    setIsSubmiting(true);
    await deleteGallery(activeGallery.id);
    setIsSubmiting(false);
    setIsDeletePopupVisible(false);
    setOpenedGalleryId(null);
  };

  const handleFileInputChange = async file => {
    if (isSubmiting) {
      return;
    }
    setIsSubmiting(true);
    await onFileInputChange(file);
    setIsSubmiting(false);
  };

  const onSelectFile = (file) => {
    const updatedFiles = galleryFiles.map((f) =>
      f.id === file.id ? { ...f, selected: !f.selected } : f
    );
    setGalleryFiles(updatedFiles);
  };

  const onMovePictures = async (originalGalleryId, galleryId, fileIds) => {
    await movePictures(originalGalleryId, galleryId, fileIds);
    const updateGalleryFiles = galleryFiles.filter(x => !fileIds.includes(x.id));
    setGalleryFiles(updateGalleryFiles);
  }

  const onDownloadPictures = async() => {
    const images = galleryFiles.filter(x => x.selected)
    if (images.length) {
      const zip = new JSZip();

      const fetchAndAddImage = async (item, index) => {
        const response = await fetch(item.path);
        const blob = await response.blob();
        zip.file(`${item.fileId}`, blob);
      };

      await Promise.all(images.map((item, index) => fetchAndAddImage(item, index)));

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${activeGallery.galleryName}.zip`);
      });
    }
  }

  useEffect(()=> {
    if (images) {
      setGalleryFiles(images);
    }
  },[images])

  return (
    <>
      {isCreateGalleryPopupVisible && (
        <EditGalleryPopup
          title={<Trans i18nKey='Gallery.Edit gallery' />}
          isOpened={isCreateGalleryPopupVisible}
          closePopup={() => setIsCreateGalleryPopupVisible(false)}
          createGallery={payload => editGallery({ id: activeGallery.id, payload })}
          group={groupInfo}
          galleries={galleries}
          initialData={activeGallery}
        />
      )}
      {isDeletePopupVisible && (
        <DeletePopup
          isOpened={isDeletePopupVisible}
          closePopup={() => setIsDeletePopupVisible(false)}
          onDeleteBtnClick={handleDeleteBtnClick}
          headerText={<Trans i18nKey='Gallery.Deletion.Title' />}
          bodyText={t('Gallery.Deletion.Text')}
          isSubmiting={isSubmiting}
        />
      )}
      <Card className={styles.wrapper}>
        <GalleryItemHeader
          gallery={activeGallery}
          goBack={() => setOpenedGalleryId(null)}
          user={user}
          isBazaar={isBazaar}
          deleteAllImagesFromGallery={deleteAllImagesFromGallery}
          dispatch={dispatch}
          imagesSize={images.length}
          selectFiles={galleryFiles.filter(x=>x.selected)}
          galleries={galleries}
          onMovePictures={onMovePictures}
          onDownloadPictures={onDownloadPictures}
        />
        {groupInfo.addModifyGallery &&
          (isSubmiting ? (
            <Loader dotColor='#7a7a7a' dotSize='10px' className={styles.headerMoreBtnSubmitting} />
          ) : (
            <MoreBtn
              dropdownOptions={dropdownOptions}
              faIcon='fa-cog'
              className={styles.headerMoreBtn}
            >
              <TextCaret color='#555' />
            </MoreBtn>
          ))}
        {progress && (
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
              <div className={styles.progressBarValue} style={{ width: progress + '%' }} />
            </div>
          </div>
        )}
        <div className={styles.container}>
          <GalleryFilter
            updateFilters={updateFilters}
            groupId={groupInfo.groupId}
            galleryId={activeGallery.id}
            onFiltersLoad={onFiltersLoad}
            isProfilePage={isProfilePage}
          />
          <DragAndDrop
            canUpload={groupInfo.createPostStatus && groupInfo.addModifyGallery}
            onDrop={files => handleFileInputChange({ target: { files } })}
          >
            <div className={styles.galleryDescription}>
              {activeGallery.isTranslationAllowed ? (
                <WithWatsonTranslate
                  data={{
                    text: activeGallery.description,
                    entityId: activeGallery.id,
                    entityType: 'gallery',
                  }}
                  Component={({ html }) => (
                    <span dangerouslySetInnerHTML={{ __html: sanitize(html) }} />
                  )}
                />
              ) : (
                activeGallery.description
              )}
            </div>
            <div className={styles.galleryContainer}>
              {groupInfo.createPostStatus && groupInfo.addModifyGallery && (
                <label>
                  {!isSubmiting && (
                    <input
                      type='file'
                      ref={filesInputRef}
                      className={styles.inputFile}
                      onChange={handleFileInputChange}
                      multiple
                      accept='image/*,video/mp4,video/x-m4v,video/*'
                    />
                  )}
                  <GalleryAddButton
                    text={t('Gallery.Click or drop files here')}
                    onClick={() => {}}
                  />
                </label>
              )}

              {galleryFiles.map((file, index) => (
                <GalleryItemImage
                  user={user}
                  isTranslationAllowed={activeGallery.isTranslationAllowed}
                  key={file.id}
                  file={file}
                  index={index}
                  permanent={file.permanent}
                  postId={file.posts ? file.posts.id : file.comments.posts.id}
                  galleryFiles={images}
                  isBazaar={isBazaar}
                  deleteImage={async image => {
                    await deleteImage(image);
                    dispatch({ type: 'deleteImage', id: image.fileId });
                  }}
                  editImageDescription={async image => {
                    await editImageDescription(image);
                    dispatch({ type: 'updateDescription', image });
                  }}
                  groupInfo={groupInfo}
                  isSelectFile={file.selected}
                  onSelectFile={()=> onSelectFile(file)}
                />
              ))}
              {isLoading && <Loader className={styles.loader} />}
            </div>
          </DragAndDrop>
        </div>
      </Card>
    </>
  );
}
