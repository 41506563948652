import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEmployeeAbsences,
  postEmployeeAbsence,
  reviewEmployeeAbsence,
  getEmployeeInformation,
  getAllEmployeeInformation,
  updateEmployeeAbsence,
} from 'library/api/employee';
import HelpText from 'library/common/commonComponents/HelpText';
import Loader from 'library/common/commonComponents/Loader';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './userFrameEmployeeAbsences.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import EmployeeHolidayAvatar from './EmployeeHoldayAvatar';
import { getWorkingTimeByDay } from 'library/utilities/employeeCheckInOut';
import Popup from 'library/common/commonComponents/Popups/Popup';
import RadioButton from 'library/common/commonComponents/RadioButton';
import EmployeeAbsencesOverview from './EmployeeAbsencesOverview';

export default function EmployeeAbsenceContainer() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [absenceType, setAbsenceType] = useState('');
  const [employeeAbsences, setEmployeeAbsences] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [workingDaysPerUser, setWorkingDaysPerUser] = useState(null);
  const [error, setError] = useState('');
  const [sickNoteWarningOpen, setSickNoteWarningOpen] = useState(false);
  const user = useSelector(state => state.userReducer);
  const { kitaId } = useSelector(state => state.kitaReducer.activeKita);

  const getAbsencesAndEmployeeInfo = useCallback(() => {
    setIsLoading(true);

    Promise.all([getEmployeeAbsences(kitaId), getEmployeeInformation(user.id)])
      .then(([employeeAbsenceRes, employeeInfoRes]) => {
        setEmployeeAbsences(employeeAbsenceRes.data);
        setEmployeeInfo(employeeInfoRes.data);

        if (!user.administrationAccess) {
          setWorkingDaysPerUser({
            [employeeInfoRes.data.user.id]: getWorkingTimeByDay(employeeInfoRes.data),
          });
        }
      })
      .catch(err => {
        console.error(err);
      });

    if (user.administrationAccess) {
      getAllEmployeeInformation()
        .then(res => {
          const calculatedWorkingDays = {};
          res.data.forEach(employeeInfo => {
            calculatedWorkingDays[employeeInfo.user.id] = getWorkingTimeByDay(
              employeeInfo.employeeInformation,
            );
          });
          setWorkingDaysPerUser(calculatedWorkingDays);
        })
        .catch(err => {
          console.error(err);
        });
    }
    setIsLoading(false);
  }, [kitaId, user.id]);

  useEffect(() => {
    getAbsencesAndEmployeeInfo();
  }, [getAbsencesAndEmployeeInfo]);

  // calculate number of days between two dates
  const getDaysCount = () => {
    const diffTime = new Date(endDate) - new Date(startDate);
    return Math.round(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 because we count the start date as well
  };

  const handleSickNote = () => {
    if (getDaysCount() >= 3) {
      setSickNoteWarningOpen(true);
    } else {
      handleRequestAbsence();
    }
  };

  const handleRequestAbsence = async () => {
    if (!startDate || !endDate) {
      setError('dates');
      return;
    }

    if (!absenceType) {
      setError('type');
      return;
    }

    try {
      setIsLoading(true);

      await postEmployeeAbsence({
        userId: user.id,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        vacation: absenceType === 'vacation',
        kitaId,
      });

      const res = await getEmployeeAbsences(kitaId);
      setEmployeeAbsences(res.data);

      setStartDate('');
      setEndDate('');
      setAbsenceType('');
      setIsLoading(false);
      setError('');
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
      setIsLoading(false);
    }
  };

  const handleReviewButtonClick = async (absenceId, status) => {
    try {
      await reviewEmployeeAbsence(absenceId, status);
      getAbsencesAndEmployeeInfo();
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    }
  };

  const handleSubmitUpdateAbsence = async (absenceId, userId, startDate, endDate) => {
    try {
      setIsLoading(true);
      await updateEmployeeAbsence(absenceId, {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        userId,
      });
      const res = await getEmployeeAbsences(kitaId);
      setEmployeeAbsences(res.data);
    } catch (err) {
      console.error(err);
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <div className={styles.container}>
      <Popup
        size='small'
        isOpened={sickNoteWarningOpen}
        closePopup={() => {
          setSickNoteWarningOpen(false);
        }}
        header={<strong>{t('CheckInOut.Sick note warning')}</strong>}
        body={t('CheckInOut.TheSickNoteDurationIsLongerThanThreeDays')}
        footer={
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonCancel}>
              <Button
                onClick={() => {
                  setSickNoteWarningOpen(false);
                  handleRequestAbsence();
                }}
              >
                {t('CheckInOut.Send sick note')}
              </Button>
            </div>
          </div>
        }
      />

      <EmployeeHolidayAvatar employeeInfo={employeeInfo} />

      <section>
        <h2>{t('Checkinout.RequestAbsence')}</h2>
        <div className={styles.text}>
          <HelpText>
            {t('Checkinout.Here you can request absences like sick note or vacation')}
          </HelpText>
        </div>
        <div className={styles.dateInputsContainer}>
          <div className={styles.datePickerContainer}>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.Start Date')}</Label>
              <DatePicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                minDate={new Date().setDate(new Date().getDate() - 20)} // 20 days in the past
              />
            </div>
            <div className={styles.dateStart}>
              <Label type='input'>{t('Calendar.End Date')}</Label>
              <DatePicker
                selected={endDate}
                onChange={date => {
                  setEndDate(date);
                }}
                langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
                minDate={new Date().setDate(new Date().getDate() - 20)} // 20 days in the past
              />
            </div>
          </div>

          <div className={styles.absenceTypeContainer}>
            <Label type='input'>{t('Checkinout.Type of Absence')}</Label>
            <RadioButton
              checked={absenceType === 'sickNote'}
              label={t('Checkinout.Sick note')}
              onClick={() => setAbsenceType('sickNote')}
            />
            <RadioButton
              checked={absenceType === 'vacation'}
              label={t('Checkinout.Vacation')}
              onClick={() => setAbsenceType('vacation')}
            />
          </div>

          {error ? (
            <p className={cn(styles.dateInputError)}>
              {error === 'dates' && t('Checkinout.Start and end date has to be selected')}
              {error === 'type' && t('Checkinout.Absence type has to be selected')}
            </p>
          ) : null}

          <Button
            type='primary'
            disabled={isLoading}
            onClick={() => {
              absenceType === 'vacation' ? handleRequestAbsence() : handleSickNote();
            }}
          >
            {t('Checkinout.Send absence request')}
          </Button>
        </div>
      </section>
      {isLoading ? (
        <Loader />
      ) : (
        <EmployeeAbsencesOverview
          employeeAbsences={employeeAbsences}
          handleReviewButtonClick={handleReviewButtonClick}
          workingDaysPerUser={workingDaysPerUser}
          handleSubmitUpdateAbsence={handleSubmitUpdateAbsence}
        />
      )}
    </div>
  );
}
