import React, { useState } from 'react';
import styles from './reviewButtons.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import { useTranslation } from 'react-i18next';
import Loader from 'library/common/commonComponents/Loader';

export default function ReviewButtons({ handleReviewButtonClick, id, reviewStatus }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleButtonClick = async status => {
    try {
      setIsLoading(true);
      await handleReviewButtonClick(id, status);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <Button
            onClick={() => {
              handleButtonClick('APPROVED');
            }}
            className={styles.acceptButton}
            disabled={isLoading || reviewStatus === 'APPROVED'}
          >
            {t('Checkinout.Approve')}
          </Button>
          <Button
            onClick={() => {
              handleButtonClick('REJECTED');
            }}
            className={styles.denyButton}
            disabled={isLoading || reviewStatus === 'REJECTED'}
          >
            {t('Checkinout.Decline')}
          </Button>
        </>
      ) : (
        <Loader dotColor='#44deec' dotSize='8px' className={styles.loader} />
      )}
    </>
  );
}
