import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { isActiveNavLink } from 'library/utilities/router';

import styles from './bottombarNavItem.module.scss';

export default function BottombarNavItem({ withLink, to, isActive, ...props }) {
  return withLink ? (
    <NavLink
      isActive={(match, location) => {
        return isActive || isActiveNavLink(to, match, location);
      }}
      activeClassName={styles.active}
      to={to}
    >
      <BottombarNavItemElement {...props} />
    </NavLink>
  ) : (
    <div className={isActive ? styles.active : ''}>
      <BottombarNavItemElement {...props} />
    </div>
  );
}

const BottombarNavItemElement = ({ onClick, title, faIcon, children, isGroupPage, imageIcon }) => {
  return !isGroupPage ? (
    <div className={styles.bottombarNavItem} onClick={onClick}>
      {typeof imageIcon !== 'undefined' ? (
        <img src={imageIcon} style={{ width: '25px' }} />
      ) : (
        <i className={cn('fa', faIcon)} />
      )}

      <div className={styles.title}>{title}</div>

      {children}
    </div>
  ) : (
    <div className={styles.bottombarNavItem} onClick={onClick}>
      {title}
      {children}
    </div>
  );
};
