import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import cn from 'classnames';

import { exportGroupMemberEmailList } from 'library/api/groups';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import DropdownInPortal from 'library/common/commonComponents/DropdownInPortal';
// eslint-disable-next-line
import ListItemWithLogoAvatar from 'library/common/commonComponents/ListItems/ListItemWithLogo/listItemWithLogoFrames/ListItemWithLogoElement/listItemWithLogoElementFrames/ListItemWithLogoAvatar';
import MembersItem from 'library/common/commonComponents/Toggleable/Members/membersFrames/MembersItem';
import TextCaret from 'library/common/commonComponents/TextCaret';
import { useOutsideEvent } from 'library/common/commonHooks/useOutsideEvent';
import { downloadFile } from 'library/utilities/files';
import { getLetterInLogo } from 'library/utilities/groups';
import store from 'main/store/configureStore';

import styles from '../../groupsFrameList.module.scss';

export default function GroupsFrameListRow({ group }) {
  const { t } = useTranslation();
  const { history } = useReactRouter();

  const [isOptionsDropdownOpened, setIsOptionsDropdownOpened] = useState(false);
  const outsideEventContainerRef = useOutsideEvent({
    click: true,
    handler: () => setIsOptionsDropdownOpened(false),
  });

  const handleExportEmailList = async groupId => {
    exportGroupMemberEmailList(groupId).then(res => {
      downloadFile(res, 'Email-List.csv');
      store.dispatch(
        showBottomNotification(t('Administration.SpacesSection.EmailListExportSuccess'), {
          isFail: false,
        }),
      );
    });
  };

  const dropdownOptions = getDropdownOptions({
    groupId: group.id,
    history,
    t,
    exportEmails: handleExportEmailList,
  });

  return (
    <tr>
      <td>
        <div className={cn('notranslate', styles.avatar)}>
          <ListItemWithLogoAvatar
            groupInfo={group}
            letter={getLetterInLogo(group.groupName)}
            backgroundColor={group.colorCode}
            className={styles.avatarBox}
          />
          <div className={styles.avatarText}>
            <div className={styles.groupName}>{group.groupName}</div>
            {group.groupDescription && (
              <div className={styles.groupDesc}>{group.groupDescription}</div>
            )}
          </div>
        </div>
      </td>
      <td className={styles.memberCount}>{group.groupMembers ? group.groupMembers.length : t('Administration.No members')}</td>
      <td>
        <div className={styles.admins}>
          {group.groupMembers?.filter(member => member.groupRole.adminRole)
            .map(member => (
              <MembersItem member={member.user} key={member.id} />
            ))}
        </div>
      </td>
      <td>
        <div className={styles.moreBtn}>
          <Button
            isActive={isOptionsDropdownOpened}
            onClick={() => setIsOptionsDropdownOpened(true)}
            className={styles.buttonsMarginLeft}
          >
            <div ref={outsideEventContainerRef}>
              <i className='fa fa-cog' />
              <TextCaret />
            </div>
          </Button>

          <DropdownInPortal
            dropdownOptions={dropdownOptions}
            isOptionsDropdownOpened={isOptionsDropdownOpened}
            setIsOptionsDropdownOpened={setIsOptionsDropdownOpened}
            outsideEventContainerRef={outsideEventContainerRef}
          />
        </div>
      </td>
    </tr>
  );
}

export function getDropdownOptions({ groupId, history, t, exportEmails }) {
  const url = `/groups/${groupId}`;
  return [
    {
      faIcon: 'fa-pencil',
      title: t('Post.Edit'),
      onClick: () => history.push(`${url}/settings`),
    },
    {
      faIcon: 'fa-user',
      title: t('Administration.SpacesSection.Manage Members'),
      onClick: () => history.push(`${url}/members`),
    },
    {
      faIcon: 'fa-rocket',
      title: t('Administration.SpacesSection.Manage Modules'),
      onClick: () => history.push(`${url}/modules`),
    },
    {
      faIcon: 'fa-trash-o',
      title: t('Post.Delete'),
      onClick: () => history.push(`${url}/settings`),
    },
    {
      faIcon: 'fa-inbox',
      title: t('Administration.SpacesSection.Open Group'),
      onClick: () => history.push(url),
    },
    {
      faIcon: 'fa-download',
      title: t('Administration.SpacesSection.Export emails'),
      onClick: () => exportEmails(groupId),
    },
  ];
}
