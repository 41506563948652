import React, { useState, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  endOfDay,
  subSeconds,
  subMinutes,
  setHours,
  getHours,
  getMinutes,
  setMinutes,
  setDate,
  getDate,
  getMonth,
  setMonth,
  setYear,
  getYear,
} from 'date-fns';

import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import Tabs from 'library/common/commonComponents/Tabs';

import CreateEventBasic from './CalendarCreateEditEventFrames/CreateEventBasic';
import CreateEventParticipation from './CalendarCreateEditEventFrames/CreateEventParticipation';
import CreateEventFiles from './CalendarCreateEditEventFrames/CreateEventFiles';

import styles from './calendarCreateEditEvent.module.scss';

const createEmptyReminder = state => {
  return {
    ...state,
    reminders: [
      ...state.reminders,
      {
        id: -1,
        type: 'days',
        value: 1,
      },
    ],
  };
};

export function reducer(state, action) {
  switch (action.type) {
    case 'setTitle':
      return { ...state, title: action.payload };
    case 'setColor':
      return { ...state, color: action.payload };
    case 'setEventType':
      return { ...state, eventType: +action.payload.value };
    case 'setDescription':
      return { ...state, description: action.payload };
    case 'setPublic':
      return { ...state, isPublic: !state.isPublic };
    case 'setAllDay':
      return {
        ...state,
        allDay: !state.allDay,
        end: subSeconds(endOfDay(new Date(state.end)), 1).getTime(),
      };
    case 'setAllDayToFixedValue':
      return {
        ...state,
        allDay: action.payload,
      };
    case 'setStartDate':
      return {
        ...state,
        start: action.payload
          ? setYear(
            setMonth(
              setDate(new Date(state.start), getDate(action.payload)),
              getMonth(action.payload),
            ),
            getYear(action.payload),
          ).getTime()
          : state.start,
      };
    case 'setStartHoursDate':
      return {
        ...state,
        start: action.payload
          ? setMinutes(
            setHours(new Date(state.start), getHours(action.payload)),
            getMinutes(action.payload),
          ).getTime()
          : state.start,
      };
    case 'setEndDate':
      return {
        ...state,
        end: action.payload
          ? setYear(
            setMonth(
              setDate(new Date(state.end), getDate(action.payload)),
              getMonth(action.payload),
            ),
            getYear(action.payload),
          ).getTime()
          : state.end,
      };
    case 'setEndHoursDate':
      return {
        ...state,
        end: action.payload
          ? setMinutes(
            setHours(new Date(state.end), getHours(action.payload)),
            getMinutes(action.payload),
          ).getTime()
          : state.end,
      };
    case 'setLocation':
      return { ...state, location: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setCanParticipate':
      return {
        ...state,
        participationInfo: { ...state.participationInfo, participationEnum: action.payload.value },
      };
    case 'setMaxParticipants':
      return {
        ...state,
        participationInfo: { ...state.participationInfo, maxNumberOfParticipants: +action.payload },
      };
    case 'setAllowDecline':
      return {
        ...state,
        participationInfo: {
          ...state.participationInfo,
          isAllowDecline: !state.participationInfo.isAllowDecline,
        },
      };
    case 'setAllowMaybe':
      return {
        ...state,
        participationInfo: {
          ...state.participationInfo,
          isAllowMaybe: !state.participationInfo.isAllowMaybe,
        },
      };
    case 'setParticipantInfo':
      return { ...state, participationInfo: { ...state.participationInfo, info: action.payload } };
    case 'setFiles':
      return { ...state, files: action.payload };
    case 'setDeletedFileIds':
      return { ...state, deletedFileIds: state.deletedFileIds.concat(action.payload) };
    case 'setTimeZone':
      return { ...state, timeZone: action.payload.value };
    case 'toggleRepeat':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          type: state.repeat.type === 'none' ? 'daily' : 'none',
          days: [],
        },
      };
    case 'setRepeatType':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          type: action.payload,
          days:
            action.payload === 'weekly'
              ? state.repeat.days.length === 0 && state.start
                ? [...state.repeat.days, new Date(state.start).getDay()]
                : state.repeat.days
              : state.repeat.days,
        },
      };
    case 'toggleRepeatDay':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          days: state.repeat.days.includes(action.payload)
            ? state.repeat.days.filter(d => d !== action.payload)
            : [...state.repeat.days, action.payload],
        },
      };
    case 'setRepeatDays':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          days: action.payload,
        },
      };
    case 'setRepeatEnd':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          end: action.payload,
        },
      };
    case 'setRepeatWeeklyInterval':
      return {
        ...state,
        repeat: {
          ...state.repeat,
          weekInterval: action.payload,
        },
      };
    case 'setReminders':
      return {
        ...state,
        reminders: action.payload,
      };
    case 'toggleReminder':
      return state.reminders.length > 0
        ? {
          ...state,
          reminders: [],
        }
        : createEmptyReminder(state);
    case 'addReminder':
      return createEmptyReminder(state);
    case 'removeReminder':
      return {
        ...state,
        reminders: state.reminders.filter((r, i) => i !== action.payload),
      };
    case 'updateReminderType':
      return {
        ...state,
        reminders: state.reminders.map((r, i) => {
          return {
            ...r,
            type: i === action.payload.index ? action.payload.type : r.type,
          };
        }),
      };
    case 'updateReminderValue':
      return {
        ...state,
        reminders: state.reminders.map((r, i) => {
          return {
            ...r,
            value: i === action.payload.index ? action.payload.value : r.value,
          };
        }),
      };
    case 'setParentParticipants':
      return {
        ...state,
        parentParticipants: action.payload,
      };
    default:
      return state;
  }
}

export default function CalendarCreateEvent({
  isOpened,
  onClose,
  eventData,
  calendarSettings,
  onSubmit,
  canBePublic,
  isEditing,
  langCode,
}) {


  const endDate = eventData.id
    ? new Date(eventData.end || eventData.date)
    : eventData.end
      ? subMinutes(new Date(eventData.end), 1)
      : endOfDay(new Date(eventData.date));
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, {
    title: eventData.title || '',
    color: eventData.color || '#6fdbe8',
    eventType: eventData.eventType ? eventData.eventType.id : 0,
    description: eventData.description || '',
    location: eventData.location || '',
    isPublic: eventData.isPublic || false,
    allDay: eventData.allDay,
    start: eventData.start
      ? eventData.start.getTime
        ? eventData.start.getTime()
        : eventData.start
      : eventData.date.getTime(),
    end: endDate.getTime(),
    participationInfo: {
      participationEnum: eventData.participationInfo
        ? eventData.participationInfo.participationEnum
        : calendarSettings.participationMode || 'EVERYBODY_CAN_PARTICIPATE',
      maxNumberOfParticipants: eventData.participationInfo
        ? eventData.participationInfo.maxNumberOfParticipants || ''
        : '',
      isAllowDecline: eventData.participationInfo
        ? eventData.participationInfo.isAllowDecline
        : calendarSettings.participationStateDeclineAllowed,
      isAllowMaybe: eventData.participationInfo
        ? eventData.participationInfo.isAllowMaybe
        : calendarSettings.participationStateMaybeAllowed,
      info: eventData.participationInfo ? eventData.participationInfo.info : '',
    },
    parentParticipants: eventData.parentParticipants
      ? eventData.parentParticipants.map(pp => `${pp.userId}`)
      : [],
    files: eventData.files || [],
    deletedFileIds: [],
    timeZone: '(UTC+02:00) Europe/Berlin',
    repeat: {
      type: eventData.repeatType || 'none',
      days: eventData.repeatDays ? convertDaysByteToArray(eventData.repeatDays) : [],
      end:
        !eventData.repeatEndDate || eventData.repeatEndDate === 0
          ? null
          : new Date(eventData.repeatEndDate),
      weekInterval: eventData.repeatWeeklyInterval || 1,
    },
    reminders: eventData.reminders
      ? eventData.reminders.map(r => {
        return {
          id: r.id,
          type: r.type,
          value: r.value,
        };
      })
      : [],
    error: '',
  });
  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function convertDaysByteToArray(byteRep) {
    const result = [];
    for (let i = 0; i < 7; i++) {
      // eslint-disable-next-line no-bitwise
      if (((byteRep >> i) & 1) === 1) {
        result.push(i);
      }
    }
    return result;
  }

  function sliceGroupName(groupName) {
    if (groupName.length > 15) {
      groupName = groupName.slice(0, 15) + '...';
      return groupName;
    }
    return groupName;
  }

  function getGroupIdOfCalendarEvent() {
    //eventData.multiGroups gets the groupId for dashboard selection and calendarSettings for groupSelection
    //only get the groupId if exactly one group is selected
    //if profile calendar or multiple groups are selected 0 will  be returned
    console.log()
    if (eventData && eventData.multiGroups && Array.isArray(eventData.multiGroups) && eventData.multiGroups.length === 1) {
      return eventData.multiGroups[0].groupId;
    }
    return calendarSettings.groupId;
  }

  const tabs = [
    {
      title: t('Calendar.Basic'),
      path: 'basic',
      component: (
        <CreateEventBasic
          createEventState={state}
          createEventDispatch={dispatch}
          eventTypes={calendarSettings.eventTypes}
          canBePublic={canBePublic}
          langCode={langCode}
          convertDaysByteToArray={convertDaysByteToArray}
          groupId={getGroupIdOfCalendarEvent()}
        />
      ),
    },
    {
      title: t('Calendar.Participation'),
      path: 'participation',
      component: (
        <CreateEventParticipation
          createEventState={state.participationInfo}
          createEventDispatch={dispatch}
        />
      ),
    },
    {
      title: t('Calendar.Files'),
      path: 'files',
      component: <CreateEventFiles createEventState={state} createEventDispatch={dispatch} />,
    },
  ];

  const submit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (state.title.trim() === '') {
      dispatch({ type: 'setError', payload: 'title' });
      setActiveTab(0);
    } else if (state.start > state.end) {
      dispatch({ type: 'setError', payload: 'date' });
      setActiveTab(0);
    } else if (state.repeat.type === 'weekly' && state.repeat.days.length === 0) {
      dispatch({ type: 'setError', payload: 'repetitionDay' });
      setActiveTab(0);
    } else if (
      state.repeat.type === 'weekly' &&
      (Number.isNaN(state.repeat.weekInterval) ||
        parseInt(state.repeat.weekInterval, 10) <= 0 ||
        parseInt(state.repeat.weekInterval, 10) > 99)
    ) {
      dispatch({ type: 'setError', payload: 'repeatWeeklyInterval' });
      setActiveTab(0);
    } else {
      await onSubmit({ ...state, group: eventData.group, multiGroups: eventData.multiGroups });
    }
    setIsSubmitting(false);
  };

  const makeGroupList = groups => {
    if (!groups || groups.length === 0) {
      return '';
    }

    return groups.map(x => x.groupName || t('Calendar.Personal profile calendar')).join(', ');
  };

  return (
    <Popup
      isOpened={isOpened}
      closePopup={onClose}
      header={
        isEditing ? (
          <Trans i18nKey='Calendar.Edit event' />
        ) : (
          <Trans i18nKey='Calendar.Create event' />
        )
      }
      footer={
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonSave}>
            <ButtonWithLoader onClick={submit} type='primary' isLoading={isSubmitting}>
              {t('Calendar.Save')}
            </ButtonWithLoader>
          </div>
          <div>
            <Button onClick={onClose}>{t('Calendar.Cancel')}</Button>
          </div>
        </div>
      }
    >
      {eventData && eventData.group && eventData.group.groupName && (
        <div style={{ marginLeft: '15px', color: 'red', fontWeight: 'bold' }}>
          {t('Calendar.Group name') + ' ' + sliceGroupName(eventData.group.groupName)}
        </div>
      )}

      {eventData && eventData.group && !eventData.group.groupName && (
        <div style={{ marginLeft: '15px', color: 'red', fontWeight: 'bold' }}>
          {t('Calendar.Personal profile calendar')}
        </div>
      )}

      {eventData && eventData.multiGroups && (
        <div style={{ marginLeft: '15px', color: 'red', fontWeight: 'bold' }}>
          {makeGroupList(eventData.multiGroups)}
        </div>
      )}

      <div className={styles.wrapper}>
        <Tabs
          tabs={tabs}
          onChange={(_, index) => setActiveTab(index)}
          currentTab={tabs[activeTab].path}
        />
      </div>
    </Popup>
  );
}
