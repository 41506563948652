import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';

import DatePicker from 'library/common/commonComponents/DatePicker';
import { getTimeZoneList } from 'library/api/calendar';
import Select from 'library/common/commonComponents/Inputs/Select';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Label from 'library/common/commonComponents/Label';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';

import styles from './createEditTaskSched.module.scss';

export default function CreateEditTaskScheduling({
  createEditTaskState,
  createEditTaskdispatch,
  canAddToCalendar,
  user,
}) {
  const { t } = useTranslation();
  const [timeZoneIsOpen, setTimeZoneIsOpen] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([
    {
      value: createEditTaskState.timeZone,
      label: createEditTaskState.timeZone,
    },
  ]);
  const [timeZoneOption, setTimeZoneOption] = useState(timeZoneOptions[0]);
  const [timeZoneListIsDownloaded, setTimeZoneListIsDownloaded] = useState(false);
  const handleOpenTimeZoneList = () => {
    if (!timeZoneListIsDownloaded) {
      getTimeZoneList()
        .then(res => {
          if (res.status === 200) {
            setTimeZoneOptions(res.data.map(item => ({ value: item, label: item })));
            const opton =
              res.data.find(item => item === createEditTaskState.timeZone) || res.data[0];
            setTimeZoneOption({ value: opton, label: opton });
            setTimeZoneListIsDownloaded(true);
            setTimeZoneIsOpen(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setTimeZoneIsOpen(!timeZoneIsOpen);
    }
  };
  const handleSetTimeZone = option => {
    createEditTaskdispatch({ type: 'setTimeZone', payload: option });
    setTimeZoneOption(option);
    setTimeZoneIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <CheckBox
          className={styles.checkbox}
          name={t('Tasks.All Day')}
          isChecked={createEditTaskState.allDay}
          onChange={() => createEditTaskdispatch({ type: 'setAllDay' })}
        />
      </div>
      <div className={styles.dateAndTimeInputsContainer}>
        <div className={styles.dateInputsContainer}>
          <div
            className={cn(
              styles.dateStart,
              createEditTaskState.error.startDateBlank && styles.dateEndError,
            )}
          >
            <Label type='input'>{t('Tasks.Start Date')}</Label>
            <DatePicker
              selected={createEditTaskState.start}
              onChange={date => {
                createEditTaskdispatch({
                  type: 'setError',
                  payload: { ...createEditTaskState.error, startDateBlank: false },
                });
                createEditTaskdispatch({ type: 'setStart', payload: date });
              }}
              langCode={user.langCode}
            />
            {createEditTaskState.error.startDateBlank && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={t('Tasks.Start Date cannot be blank')} />
              </div>
            )}
          </div>
          <div
            className={cn(
              styles.dateEnd,
              createEditTaskState.error.endDateBlank
                ? styles.dateEndError
                : createEditTaskState.error.startMoreEnd && styles.dateEndError,
            )}
          >
            <Label type='input'>{t('Tasks.End Date')}</Label>
            <DatePicker
              selected={createEditTaskState.end}
              onChange={date => {
                createEditTaskdispatch({
                  type: 'setError',
                  payload: {
                    ...createEditTaskState.error,
                    endDateBlank: false,
                    startMoreEnd: false,
                  },
                });
                createEditTaskdispatch({ type: 'setEnd', payload: date });
              }}
              langCode={user.langCode}
            />
            {(createEditTaskState.error.endDateBlank || createEditTaskState.error.startMoreEnd) && (
              <div className={styles.error_msg}>
                <InputErrorMsg
                  errorMsg={
                    createEditTaskState.error.startMoreEnd
                      ? t('Tasks.End time must be after start time')
                      : t('Tasks.End Date cannot be blank')
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={cn(
            styles.timeInputsContainer,
            createEditTaskState.allDay && styles.timeInputsContainerDisabled,
          )}
        >
          <div
            className={cn(
              styles.timeStart,
              createEditTaskState.error.startTimeBlank && styles.dateEndError,
            )}
          >
            <Label type='input'>{t('Tasks.Start Time')}</Label>
            <DatePicker
              disabled={createEditTaskState.allDay}
              selected={createEditTaskState.start}
              onChange={date => {
                createEditTaskdispatch({
                  type: 'setError',
                  payload: { ...createEditTaskState.error, startTimeBlank: false },
                });
                createEditTaskdispatch({ type: 'setStart', payload: date });
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
              dateFormat='HH:mm'
            />
            {createEditTaskState.error.startTimeBlank && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={t('Tasks.Start Time cannot be blank')} />
              </div>
            )}
          </div>
          <div
            className={cn(
              styles.timeEnd,
              createEditTaskState.error.endTimeBlank && styles.dateEndError,
            )}
          >
            <Label type='input'>{t('Tasks.End Time')}</Label>
            <DatePicker
              disabled={createEditTaskState.allDay}
              selected={createEditTaskState.end}
              onChange={date => {
                createEditTaskdispatch({
                  type: 'setError',
                  payload: {
                    ...createEditTaskState.error,
                    endTimeBlank: false,
                    startMoreEnd: false,
                  },
                });
                createEditTaskdispatch({ type: 'setEnd', payload: date });
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Time'
              timeFormat='HH:mm'
              dateFormat='HH:mm'
            />
            {createEditTaskState.error.endTimeBlank && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={t('Tasks.End Time cannot be blank')} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.timezoneContainer}>
        <div className={styles.timezoneTitle} onClick={handleOpenTimeZoneList}>
          {createEditTaskState.timeZone}
        </div>
        {timeZoneIsOpen && (
          <div className={styles.timezoneDropdown}>
            <Select
              options={timeZoneOptions}
              onSelect={handleSetTimeZone}
              selected={timeZoneOption}
              className={styles.timezoneSelect}
              openedClassName={styles.timezoneSelectOpen}
            />
          </div>
        )}
      </div>
      {canAddToCalendar && (
        <div className={styles.checkboxContainer}>
          <CheckBox
            className={styles.checkbox}
            name={t('Tasks.Add schedule to the group calendar')}
            isChecked={createEditTaskState.addToCalendar}
            onChange={() => createEditTaskdispatch({ type: 'setAddToCalendar' })}
          />
        </div>
      )}
    </div>
  );
}
