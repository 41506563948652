import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import MembersFrame from './GroupBodyMembersFrames/Members';

import styles from './groupBodyMembers.module.scss';

export default function GroupBodyMembers() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: t('GroupBody.Members.Members'),
      component: <MembersFrame />,
    },
  ];
  return (
    <Card title={<Trans i18nKey='GroupBody.Members.Title' />} className={styles.body}>
      <Tabs tabs={tabs} onChange={setActiveTab} current={activeTab} />
    </Card>
  );
}
