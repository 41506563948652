import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import Checkbox from 'library/common/commonComponents/Checkbox';
import { findCountOfTenant } from '../../../../../api/kids';
import styles from '../../userEditing.module.scss';

export default function UserEditingAccount({
  editingFields,
  setEditingFields,
  userNameError,
  emailError,
  isSuperAdmin,
  hasAdministrationAccess,
  user,
  qrCodeScanner,
  isSelf = false,
}) {
  const { t } = useTranslation();
  const [role, setRole] = useState([]);
  const [countOfTenant, setCountOfTenant] = useState(0);

  var roleOptions = [
    { label: t('Administration.UserSection.Kita user'), value: false },
    { label: t('Administration.UserSection.Kita admin'), value: true },
  ];

  var roleOptionsSuperAdmin = [
    { label: t('Administration.UserSection.Kita user'), value: false },
    { label: t('Administration.UserSection.Kita admin'), value: true },
    { label: t('Administration.UserSection.QR code'), value: null },
  ];

  useEffect(() => {
    findCountOfTenant().then(res => {
      setCountOfTenant(res.data);
    });
  }, []);

  useEffect(() => {
    (isSuperAdmin && countOfTenant > 2) || (user.administrationAccess && countOfTenant > 2)
      ? setRole(roleOptionsSuperAdmin)
      : setRole(roleOptions);
    setSelectedRole(
      (isSuperAdmin && countOfTenant > 2) || (user.administrationAccess && countOfTenant > 2)
        ? qrCodeScanner
          ? roleOptionsSuperAdmin[2]
          : roleOptions.find(option => option.value === editingFields.adminRole) || roleOptions[0]
        : roleOptions.find(option => option.value === editingFields.adminRole) || roleOptions[0],
    );
  }, [countOfTenant]);

  const [selectedRole, setSelectedRole] = useState(
    (isSuperAdmin && countOfTenant > 2) || (user.administrationAccess && countOfTenant > 2)
      ? qrCodeScanner
        ? roleOptionsSuperAdmin[2]
        : roleOptions.find(option => option.value === editingFields.adminRole) || roleOptions[0]
      : roleOptions.find(option => option.value === editingFields.adminRole) || roleOptions[0],
  );

  useEffect(() => {
    setEditingFields({
      ...editingFields,
      adminRole: selectedRole.value,
      qrCodeScanner: selectedRole.value == null ? true : false,
    });
  }, [selectedRole]);

  function userIsAllowedToEditEmail() {
    if (editingFields && editingFields.initialEmail) {
      if (
        (editingFields.initialEmail.includes('@stramplerbande') ||
          editingFields.initialEmail.includes('@link-it-isi')) &&
        !isSuperAdmin
      ) {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      {(!user.administrationAccess || user.id == editingFields.id) && (
        <div className={styles.formGroup}>
          <Label type='input'>{t('Administration.UserSection.Username')} *</Label>
          <Input
            error={userNameError}
            value={editingFields.userName}
            type='text'
            maxLength={50}
            onChange={e => setEditingFields({ ...editingFields, userName: e.target.value })}
            disabled={
              isSuperAdmin ||
              window.location.hostname.toLowerCase().includes('gfz-app.ch') ||
              user.administrationAccess
                ? false
                : true
            }
          />
          {userNameError && (
            <div className={styles.error}>
              <InputErrorMsg errorMsg={userNameError} />
            </div>
          )}
        </div>
      )}

      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Email')} *</Label>
        <Input
          error={emailError}
          value={editingFields.email}
          type='text'
          onChange={e => setEditingFields({ ...editingFields, email: e.target.value })}
          disabled={!userIsAllowedToEditEmail()}
        />
        {emailError && (
          <div className={styles.error}>
            <InputErrorMsg errorMsg={emailError} />
          </div>
        )}
      </div>

      <div className={styles.formGroup}>
        <Label type='input'>{t('Administration.UserSection.Role')}</Label>
        <Select
          options={role}
          selected={selectedRole}
          onSelect={setSelectedRole}
          disabled={isSelf || !hasAdministrationAccess}
        />
        {isSelf && <div>Die eigene Rolle kann nicht geändert werden</div>}
      </div>
      <div className={styles.formGroup} style={{ display: 'flex' }}>
        <div className={styles.formGroup}>
          <Checkbox
            className={styles.checkbox}
            name={t('UserEditingAccount.Employee')}
            onChange={() =>
              setEditingFields({ ...editingFields, employee: !editingFields.employee })
            }
            isChecked={editingFields.employee}
            isDisabled={!hasAdministrationAccess}
          />
        </div>
        {hasAdministrationAccess && (
          <div className={styles.formGroup}>
            <Checkbox
              className={styles.checkbox}
              name={t('UserEditingAccount.Member can edit user profiles')}
              onChange={() =>
                setEditingFields({
                  ...editingFields,
                  ableToEditUserProfiles: !editingFields.ableToEditUserProfiles,
                })
              }
              isChecked={editingFields.ableToEditUserProfiles}
            />
          </div>
        )}
      </div>
    </div>
  );
}
