import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import styles from './kitaPanelWrapper.module.scss';

export function KitaPanelWrapper({ children, isOpened }) {
  return (
    <div className={cn(styles.kitaPanelWrapper, { [styles.kitaPanelWrapperWide]: !isOpened })}>
      {children}
    </div>
  );
}

export default connect(state => ({
  isOpened: state.kitaReducer.isOpened,
}))(KitaPanelWrapper);
