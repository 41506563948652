import React from 'react';
import cn from 'classnames';

import DragAndDrop from 'library/common/commonComponents/DragAndDrop';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import FilePrewiev from 'library/common/commonComponents/FilePrewiev';
import useMultipleUpload from 'library/common/commonHooks/useMultipleUpload';

import PublicationTimeButton from '../../Buttons/PublicationTimeButton';
import Button from '../../Buttons/Button';

import styles from './editorWithButton.module.scss';

export default function EditorWithButton({
  Editor,
  error,
  onSubmit,
  btnText,
  closeBtnText,
  className,
  messgeFiles,
  isSubmiting,
  isInProfileStream,
  publicationTime,
  setPublicationTime,
  onCloseEditor,
  groupId,
}) {
  const { files, uploadFile, deleteFiles, setFiles } = useMultipleUpload(messgeFiles);
  return (
    <DragAndDrop onDrop={uploadFile}>
      <div className={cn(styles.editorContainer, className)}>
        {Editor && <Editor />}
        <div className={styles.editorContainerButtons}>
          {publicationTime && setPublicationTime && (
            <PublicationTimeButton
              className={styles.editPublicationTime}
              onChange={pt => setPublicationTime(pt)}
              value={publicationTime}
            />
          )}
          <UploadButton
            className={styles.uploadButton}
            onChange={uploadFile}
            isMultiple
            isInProfileStream={isInProfileStream}
            groupId={groupId}
            files={files}
          />
          <ButtonWithLoader
            onClick={() => onSubmit(files, deleteFiles)}
            size='sm'
            type='default'
            className={styles.editorContainerSubmit}
            isLoading={isSubmiting}
          >
            {btnText}
          </ButtonWithLoader>
          {closeBtnText && onCloseEditor && (
            <Button size='sm' onClick={onCloseEditor}>
              {closeBtnText}
            </Button>
          )}
        </div>
      </div>
      {error && <div className={styles.editorError}>{error}</div>}
      <FilePrewiev
        newFiles={files}
        onDelete={deleteFiles}
        onFilesSort={updatedFiles => setFiles(updatedFiles)}
      />
    </DragAndDrop>
  );
}
