import React, { useState } from 'react';
import styles from './historyEntryRow.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDateObjectToTimeString } from 'library/utilities/dateMoment';
import cn from 'classnames';
import { formatMinutesToTimeString, parseGermanDate } from 'library/utilities/employeeCheckInOut';
import Tooltip from 'library/common/commonComponents/Tooltip';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Label from 'library/common/commonComponents/Label';
import store from 'main/store/configureStore';
import { formatDateOnlyTime, returnTimeDifferenceInMinutes } from 'library/utilities/dateMoment';
import { getTotalTimeOfAbsenceCheckInOutsInMilis } from 'library/utilities/employeeCheckInOut';
import { updateCheckInOutTime } from 'library/api/employee';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';

export default function HistoryEntryRow({ workingDay, fetchEmployeeWorkingDays }) {
  const [showEntries, setShowEntries] = useState(false);
  const [editCheckInOut, setEditCheckInOut] = useState('');
  const [time, setTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();

  // booleans for readability
  const isToday = workingDay.date === new Date().toLocaleDateString('de-DE');
  const isFuture = parseGermanDate(workingDay.date) > new Date();
  const hasWorkingDay = !!workingDay.entry.id;

  const getStatus = entry => {
    switch (entry.checkInOutType) {
      case 'CHECKIN':
        return (
          <span className={styles.statusIn}>
            <i className='fa fa-sign-in' aria-hidden='true' />
            {t('Checkinout.IsCheckedIn')}
          </span>
        );
      case 'CHECKOUT':
        return (
          <span className={styles.statusOut}>
            <i className='fa fa-sign-out' aria-hidden='true' />
            {t('Checkinout.IsCheckedOut')}
          </span>
        );
      default:
        return (
          <span className={styles.statusAbsent}>
            <i className='fa fa-times' aria-hidden='true' />
            {t('Checkinout.Absent')}
          </span>
        );
    }
  };

  const getDurationInMinutes = checkInOutPair => {
    // return when only checkedin
    if (checkInOutPair.length < 2) {
      return false;
    }
    const checkInTime = formatDateOnlyTime(new Date(checkInOutPair[0].actionDate));
    const checkOutTime = formatDateOnlyTime(new Date(checkInOutPair[1].actionDate));
    return returnTimeDifferenceInMinutes(checkInTime, checkOutTime);
  };

  // used to calculate working time of today
  const getDayWorkingTimeInMinutes = groupedCheckInOut => {
    const totalDayMinutes = groupedCheckInOut.reduce((accumulator, checkInOutPair) => {
      // when checkinoutpair is not complete, don't add to total
      if (checkInOutPair.length === 2) {
        return accumulator + getDurationInMinutes(checkInOutPair);
      } else {
        return accumulator;
      }
    }, 0);
    return totalDayMinutes;
  };

  const breakElement = (checkInOutPair, index, array) => {
    if (checkInOutPair.length !== 2) {
      return null;
    } else if (checkInOutPair[1].checkInOutCategory !== 'BREAK') {
      return null;
    } else {
      return (
        <div className={cn(styles.historyDateGroup, styles.breakContainer)}>
          {t('EmployeeCheckinout.Pause')}
          {/* add break duration when it's not the last element */}
          {index + 1 !== array.length ? (
            <>
              <i className='fa fa-clock-o' />
              {/* get duration of break which is last element of current checkInOutPair and first element of next checkInOutPair */}
              {formatMinutesToTimeString(
                getDurationInMinutes([checkInOutPair[1], array[index + 1][0]]),
              )}
            </>
          ) : null}
        </div>
      );
    }
  };

  const absenceElement = absence => {
    return (
      <div className={cn(styles.badge, absence.vacation ? styles.vacation : styles.sick)}>
        {absence.vacation ? (
          <>
            <i className='fa fa-plane' />
            {t('Checkinout.Vacation')}
          </>
        ) : (
          <>
            <i className='fa fa-frown-o' />
            {t('EmployeeCheckinout.Sick')}
          </>
        )}
      </div>
    );
  };

  const publicHolidayElement = publicHoliday => {
    return (
      <div className={cn(styles.badge, styles.publicHoliday)}>
        <i className='fa fa-calendar' />
        {publicHoliday.title}
      </div>
    );
  };

  const hasUnapprovedCheckInOuts = checkInOuts => {
    if (!checkInOuts || checkInOuts.length === 0) return false;
    const unapproved = checkInOut => checkInOut.reviewStatus === 'OPEN';
    return checkInOuts.some(unapproved);
  };

  const getWeekdayFromGermanDateString = germanDate => {
    const [day, month, year] = germanDate.split('.');
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('de-DE', { weekday: 'long' });
  };

  const getIsWorkingTime = () => {
    if (workingDay.entry?.groupedCheckedInOuts === null) return '00:00';

    if (isToday)
      return formatMinutesToTimeString(
        getDayWorkingTimeInMinutes(workingDay.entry.groupedCheckedInOuts),
      );

    return formatMinutesToTimeString(
      (workingDay.entry.timeWorked +
        getTotalTimeOfAbsenceCheckInOutsInMilis(workingDay.entry.checkInOuts)) /
        (1000 * 60),
    );
  };

  const handleConfirmEdit = async checkInOut => {
    const [hours, minutes] = time.split(':').map(Number);
    const newActionDate = new Date(checkInOut.actionDate);
    newActionDate.setHours(hours, minutes, 0, 0);
    try {
      await updateCheckInOutTime({
        userId: checkInOut.userId,
        checkInOutType: checkInOut.checkInOutType,
        checkInOutId: checkInOut.id,
        checkedDate: newActionDate,
      });
      fetchEmployeeWorkingDays();
      setEditCheckInOut('');
      setErrorMessage('');
      store.dispatch(
        showBottomNotification(t('EmployeeCheckinout.Working time updated'), 'success'),
      );
    } catch ({ response }) {
      const errorMessage = response?.data?.message || 'An unknown error occurred';

      setErrorMessage(errorMessage);
    }
  };

  return (
    <div
      className={cn(
        styles.historyDateContainer,
        workingDay.entry?.groupedCheckedInOuts !== null ? styles.clickable : null,
        isToday ? styles.todayContainer : styles.notTodayContainer,
      )}
      key={workingDay.date}
    >
      <div
        className={styles.historyDateHeader}
        onClick={() => {
          if (workingDay.entry?.groupedCheckedInOuts === null) return;
          setShowEntries(!showEntries);
        }}
      >
        <div className={styles.date}>
          {`${getWeekdayFromGermanDateString(workingDay.date)} ${workingDay.date}`}
        </div>

        {!isFuture && (hasWorkingDay || isToday) && (
          <div className={styles.dayWorkingTime}>
            <span className={cn(styles.dateMetricWrapper)}>
              <span className={styles.labelDateMetric}>
                {t('EmployeeCheckinout.Is working time')}
              </span>
              <span className={styles.dateMetric}>
                <span className={cn(getIsWorkingTime() === '00:00' && styles.grayedOut)}>
                  {getIsWorkingTime()}
                </span>
                {hasUnapprovedCheckInOuts(workingDay.entry.checkInOuts) && (
                  <Tooltip text={t('EmployeeCheckinout.There are unapproved working time entries')}>
                    <i className='fa fa-info-circle' aria-hidden='true' />
                  </Tooltip>
                )}
              </span>
            </span>
            <span className={styles.separator} />
            <span className={styles.dateMetricWrapper}>
              <span className={styles.labelDateMetric}>
                {t('EmployeeCheckinout.Should working time')}
              </span>
              {formatMinutesToTimeString(workingDay.entry.workingTime / (60 * 1000))}
            </span>
            <span className={styles.separator} />
            <span className={styles.dateMetricWrapper}>
              <span className={styles.labelDateMetric}>{t('EmployeeCheckinout.Overtime')}</span>
              {isToday ? '...' : formatMinutesToTimeString(workingDay.entry.overtime / (60 * 1000))}
            </span>
          </div>
        )}

        {!!workingDay.entry.employeeAbsence && absenceElement(workingDay.entry.employeeAbsence)}

        {!!workingDay.entry.publicHoliday && publicHolidayElement(workingDay.entry.publicHoliday)}

        {workingDay.entry.groupedCheckedInOuts !== null && (
          <i className={showEntries ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
        )}
      </div>
      {showEntries && (
        <div className={styles.historyDateBody}>
          {workingDay.entry.groupedCheckedInOuts.map((checkInOutPair, index, array) => {
            return (
              <div key={checkInOutPair[0].actionDate}>
                <div className={styles.historyDateGroup}>
                  {/* heading of checkinoutpair */}
                  <div className={styles.checkInOutduration}>
                    {checkInOutPair.length === 2 && (
                      <span className={styles.durationCaption}>
                        <i className='fa fa-lg fa-clock-o' />
                        {formatMinutesToTimeString(getDurationInMinutes(checkInOutPair))}
                      </span>
                    )}
                    {checkInOutPair[0].checkInOutCategory === 'MANUAL' && (
                      <span className={cn(styles.badge, styles.manualEntry)}>
                        {checkInOutPair[0].reviewStatus === 'APPROVED' ? (
                          <>
                            <i className='fa fa-check' aria-hidden='true' />
                            {t('EmployeeCheckinout.Manual approved entry')}
                          </>
                        ) : (
                          <>
                            <i className='fa fa-question' aria-hidden='true' />
                            {t('EmployeeCheckinout.Manual not approved entry')}
                          </>
                        )}
                      </span>
                    )}

                    {checkInOutPair[0].checkInOutCategory !== 'MANUAL' &&
                      checkInOutPair[0].reviewStatus === 'OPEN' && (
                        <span className={cn(styles.badge, styles.timeEntryUnapproved)}>
                          <i className='fa fa-question' aria-hidden='true' />
                          {t('EmployeeCheckinout.Time entry is not approved yet')}
                        </span>
                      )}
                  </div>

                  {checkInOutPair.map(entry => {
                    return (
                      <div key={entry.id}>
                        <div className={styles.historyDateEntry}>
                          <div className={styles.entryStatus}>{getStatus(entry)}</div>
                          <div className={styles.entryTimestamp}>
                            {editCheckInOut === entry.id ? (
                              <div className={styles.editCheckInOutControls}>
                                <Label htmlFor='time' type='input'>
                                  {t('EmployeeCheckinout.New working time')}
                                </Label>
                                <div className={styles.timeInputContainer}>
                                  <Input
                                    className={styles.timeInput}
                                    type='time'
                                    value={time}
                                    onChange={e => {
                                      setTime(e.target.value);
                                    }}
                                    id='time'
                                    min='00:00'
                                    max='23:59'
                                    error={errorMessage}
                                  />
                                  <div className={styles.editCheckInOutButtonGroup}>
                                    <Button
                                      onClick={() => {
                                        setEditCheckInOut('');
                                        setErrorMessage('');
                                      }}
                                      className={styles.editCheckInOutButton}
                                    >
                                      <i
                                        className={cn('fa fa-times', styles.cancelBtn)}
                                        aria-label='Cancel edit working time'
                                      />
                                    </Button>
                                    <Button
                                      onClick={() => handleConfirmEdit(entry)}
                                      className={styles.editCheckInOutButton}
                                    >
                                      <i
                                        className={cn('fa fa-check', styles.approveBtn)}
                                        aria-label='Confirm edit working time'
                                      />
                                    </Button>
                                  </div>
                                </div>
                                {!!errorMessage && (
                                  <p className={styles.errorMessageContainer}>{errorMessage}</p>
                                )}
                              </div>
                            ) : (
                              formatDateObjectToTimeString(entry.actionDate)
                            )}
                          </div>

                          {editCheckInOut !== entry.id && (
                            <Button
                              size='sm'
                              onClick={() => {
                                const date = new Date(entry.actionDate);
                                const hours = date.getHours();
                                const minutes = date.getMinutes();

                                setTime(
                                  `${hours
                                    .toString()
                                    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
                                );
                                setEditCheckInOut(entry.id);
                              }}
                              className={styles.editCheckInOutButton}
                            >
                              <i className='fa fa-pencil' aria-label='Edit working time' />
                            </Button>
                          )}

                          <div className={styles.executivePerson}>
                            {`${t('EmployeeCheckinout.Initiated by')}: ${entry.initiator}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {// add reason if checkinout is manual
                  checkInOutPair[0].checkInOutCategory === 'MANUAL' && (
                    <div className={styles.manualCheckInOutNote}>
                      <i className='fa fa-sticky-note' aria-hidden='true' />
                      {t(checkInOutPair[0].note)}
                    </div>
                  )}
                </div>
                {breakElement(checkInOutPair, index, array)}
              </div>
            );
          })}

          {workingDay.entry.legalBreak !== 0 ? (
            <div className={cn(styles.historyDateGroup, styles.legalBreakContainer)}>
              <i className={cn('fa fa-balance-scale', styles.scale)} aria-hidden='true' />
              {t('EmployeeCheckinout.Legal break')}
              <i className={cn('fa fa-clock-o', styles.clock)} aria-hidden='true' />
              {`${Math.round(workingDay.entry.legalBreak / (1000 * 60))}min`}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
