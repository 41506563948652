import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  isImage,
  isVideo,
  isAudio,
  getFileIconByName,
  getTypeForReactNativePreview,
} from 'library/utilities/files';
import useGallery from 'library/common/commonHooks/useGallery';
import PlayBlock from 'library/common/commonComponents/PlayBlock';
import * as filesApi from 'library/api/files';
import { getToken } from 'library/utilities/token';
import { store } from 'main/store/configureStore';

import MessageWithFilesText from './messageWithFilesFrames/MessageWithFilesText';

import styles from './messageWithFiles.module.scss';

export default function MessageWithFiles({
  files,
  allImages,
  text,
  entityId,
  entityType,
  isTranslationAllowed,
  messageEdited,
  groupId,
  isSigned,
  isChatMessages
}) {
  const { Gallery, openGallery } = useGallery(files);

  const imageFiles = useMemo(() => files.filter(file => isImage(file.fileId)), [files]);
  const [showAllImages, setShowAllImages] = useState(imageFiles.length <= 4);

  const { t } = useTranslation();

  const images = useMemo(() => {
    return showAllImages ? imageFiles : imageFiles.slice(0, 4);
  }, [imageFiles, showAllImages]);

  if (!files || !files.length) {
    return (
      text && (
        <MessageWithFilesText
          text={text}
          entityId={entityId}
          entityType='message'
          isTranslationAllowed={isTranslationAllowed}
          messageEdited={messageEdited}
        />
      )
    );
  }
  let videos = files.filter(file => isVideo(file.fileId));

  const audioFiles = files.filter(file => isAudio(file.fileId));
  const others = files.filter(
    file => !isImage(file.fileId) && !isVideo(file.fileId) && !isAudio(file.fileId),
  );

  const openFile = (shouldShowImage, newFile, newImage) =>
    openFileHandler(shouldShowImage, newFile, openGallery, newImage);

  const user = store.getState().userReducer;
  const group = store.getState().groupReducer;

  const isAdminStatus = user.administrationAccess || user.superAdminStatus;

  const imageDownloadModule = (group.groupAdminStatus || window.location.pathname === '/dashboard')
    ? store.getState().kitaReducer.modules.find(mod => mod.moduleKey === 'image_download')
    : group?.groupModuleList?.find(mod => mod.moduleKey === 'image_download');

  const downloadAvailable = isAdminStatus ? true : imageDownloadModule ? imageDownloadModule.activeStatus : false;

  return (
    <div className={styles.container}>
      <Gallery />
      <div className={styles.textContainer}>
        {text && (
          <MessageWithFilesText
            text={text}
            entityId={entityId}
            entityType='message'
            isTranslationAllowed={isTranslationAllowed}
            messageEdited={messageEdited}
          />
        )}
      </div>

      <div className={styles.imagesContainer}>
        {images.length > 0 &&
          images.map((newImage, index) => (
            <>
              <div className={styles.img} onClick={(!showAllImages && index === 3) ? null : () => openFile(true, allImages, newImage)} key={newImage.fileId}>

                <img
                  src={newImage.path}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt={newImage.fileId}
                  title={newImage.fileId}
                />
                {!showAllImages && index === 3 && (
                  <div className={styles.overlay} onClick={() => { setShowAllImages(true) }}>+{files.filter(file => isImage(file.fileId)).length - 3}</div>
                )}
              </div>

            </>
          ))}
      </div>
      <div>
        {videos.length > 0 &&
          videos.map(newFile => (
            <div key={newFile.fileId}>
              <PlayBlock file={newFile} />
              <div className={styles.fileName}>
                <div className={styles.icon}>
                  <i className={cn('fa', getFileIconByName(newFile.fileId))} />
                </div>

                <div
                  className={styles.name}
                  onClick={() => {
                    if (downloadAvailable) {
                      openFile(false, newFile);
                    }
                  }}
                >
                  {newFile.fileId}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div>
        {audioFiles.length > 0 &&
          audioFiles.map(newFile => (
            <div key={newFile.id} className={isChatMessages && styles.chatMessageWrapper}>
              <audio src={newFile.path} controls />
            </div>
          ))}
      </div>
      {others.length > 0 && (
        <div className={styles.filesContainer}>
          {others.map(newFile => (
            <div className={styles.info} key={newFile.fileId}>
              <div className={styles.icon}>
                <i className={cn('fa', getFileIconByName(newFile.fileId))} />
              </div>
              {entityType === '11' && isSigned !== true ? (
                <div
                  className={styles.name}
                  onClick={() =>
                    window.open(
                      `/signature/${newFile.id}/${groupId}?referrer=${encodeURIComponent(
                        window.location.href,
                      )}`,
                      '_self',
                    )
                  }
                >
                  {newFile.fileId}
                </div>
              ) : (
                <div className={styles.name} onClick={() => openFile(false, newFile)}>
                  {newFile.fileId}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export const openFileHandler = async (shouldShowImage, file, openGallery, newImage) => {
  let newFile;
  if (typeof file !== 'undefined' && file !== null) {
    newFile = file;
  } else {
    newFile = newImage;
  }
  const { path, id, fileId } = newFile;
  if (shouldShowImage) {
    if (typeof file !== 'undefined' && file !== null) {
      let index;
      for (let i = 0; i < file.length; i++) {
        if (file[i].id === newImage.id) {
          index = i;
          break;
        }
      }
      openGallery(file, { index });
    } else {
      openGallery([newImage]);
    }
  } else {
    if (window.outerWidth >= 1024) {
      window.open(path, '_blank');
    } else {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: getTypeForReactNativePreview(fileId, true),
            file: fileId,
            id,
            path,
            token: getToken().access_token,
          }),
        );
        return null;
      }
      const { data } = await filesApi.downloadFile(id);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
