import React from 'react';

export default function FeedFilterCheckboxItem({ isChecked, text, onClick }) {
  return (
    <div onClick={onClick}>
      <i className={isChecked ? 'fa fa-check-square-o' : 'fa fa-square-o'} />
      {text}
    </div>
  );
}
