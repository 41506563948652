import React, { useRef, useEffect } from 'react';
import i18n from 'i18next';
import { EditorState, Plugin } from 'prosemirror-state';
import { EditorView, DecorationSet, Decoration } from 'prosemirror-view';
import { Schema, DOMParser } from 'prosemirror-model';
import { schema, marks } from 'prosemirror-schema-basic';
import { exampleSetup, buildMenuItems } from 'prosemirror-example-setup';
import { MenuItem } from 'prosemirror-menu';
import { addListNodes } from 'prosemirror-schema-list';

import store from 'main/store/configureStore';
import { addMentionNodes, addTagNodes, getMentionsPlugin } from 'library/common/commonComponents/ProsemirrorMentions';
import { createMenuItem, createDropdownItem, createEmojisItem } from 'library/utilities/editor';
import Storage from 'library/utilities/storage';
import { getUsersByName, getUsersByNameForChat } from 'library/api/tagging';
import emojis from 'resources/others/emojis.json';
import { getUserLogoUrl } from 'library/utilities/user';
import { setEmojisAreLoaded } from 'library/common/commonActions/editorActions';

import './styles/useEditor.scss';

function mentionItem({ name, id }) {
  const item = `
		<img src="${getUserLogoUrl(id)}">
		${name}
	`;
  return '<div class="suggestion-item prosemirror-mention-item">' + item + '</div>';
}

let emojiImages = [];
let emojisLoaded = false;

function createEmojisInBackground() {
  if (!emojisLoaded) {
    emojiImages = emojis.map((name) => {
      const image = document.createElement('img');
      image.src = name;

      return image;
    });

    emojisLoaded = true;
  }
}

const getSuggestionsHTML = items => {
  const setZIndex = () => {
    const tag = document.querySelector('.suggestion-item-list');
    if (tag) {
      const parent = tag.parentElement;
      parent.style.zIndex = 98;
    }
  }

  setTimeout(() => setZIndex());

  return '<div class="suggestion-item-list prosemirror-mention-container">' +
    items.map(item => item.attention
      ? `<div class='prosemirror-mention-attention'>${item.attention}</div>`
      : item.isLoading
        ? `<div>
					<div class="prosemirror-mention-loader">
						<div class="prosemirror-mention-loader-dot"></div>
						<div class="prosemirror-mention-loader-dot"></div>
						<div class="prosemirror-mention-loader-dot"></div>
					</div>
				</div>`
        : mentionItem(item)
    )
      .join('') +
    '</div>';
}

const underline = {
  attrs: {
    style: { default: `text-decoration: underline;` }
  },
  parseDOM: [{style: `text-decoration=underline`, attrs: { style: `text-decoration: underline` } }],
  toDOM(node) {
    return ["span", {style: node.attrs.style}, 0]
  }
}

const link = {
  attrs: {
    target: {
      default: '_blank'
    },
    href: {},
  },
  toDOM(node) {
    let href = '';
    const httpReg = new RegExp('^http', 'ig');
    const isHaveHttps = node.attrs.href.match(httpReg);
    if (!isHaveHttps) href = `https://${node.attrs.href}`;
    // eslint-disable-next-line prefer-destructuring
    else href = node.attrs.href;
    return ["a", {
      target: node.attrs.target,
      href,
    }, 0]
   },
  parseDOM: [{tag: "a", getAttrs(dom) {
    return {href: dom.href}
  }}],
};

const definedColors = [
  'transparent',
  '#555',
  'black',
  'red',
  'pink',
  'purple',
  '#602f6b',
  'indigo',
  'blue',
  'lightblue',
  'cyan',
  'teal',
  'green',
  'lightgreen',
  'lime',
  'yellow',
  'orange',
  'orangered',
  'brown',
  'grey',
  'darkgrey',
]

const colors = definedColors.reduce((current, colorItem) => {
  current[colorItem] = {
    attrs: {
      style: { default: `color: ${colorItem};` }
    },
    excludes: definedColors.join(" "),
    parseDOM: [{style: `color=${colorItem}`, attrs: { style: `color: ${colorItem}` } }],
    toDOM(node) {
      return ["span", {style: node.attrs.style}, 0]
    }
  }

  return current
}, {})

const backgroundColors = definedColors.reduce((current, colorItem) => {
  current['background' + colorItem] = {
    attrs: {
      style: { default: `background-color: ${colorItem};` }
    },
    excludes: definedColors.map(definedColor => 'background' + definedColor).join(" "),
    parseDOM: [{style: `background-color=${colorItem}`, attrs: { style: `background-color: ${colorItem}` } }],
    toDOM(node) {
      return ["span", {style: node.attrs.style}, 0]
    }
  }

  return current
}, {})

const emojisNodeSpec = {
  attrs: {type: {default: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f600.png"}},
  inline: true,
  group: "inline",
  toDOM: node => [
    "img",
    {
      "emoji-type": node.attrs.type,
      src: node.attrs.type,
      title: node.attrs.type,
      class: "ProseMirror-emoji"
    }
  ],
  parseDOM: [{
    tag: "img[emoji-type]",
    getAttrs: dom => {
      const type = dom.getAttribute("emoji-type")
      return emojis.indexOf(type) > -1 ? {type} : false
    }
  }]
}


const mySchema = new Schema({
  nodes: addTagNodes(
    addMentionNodes(
      addListNodes(schema.spec.nodes, "paragraph block*", "block")
    )
  )
    .addBefore("image", "emoji", emojisNodeSpec),
  marks: { ...marks, underline, link, ...colors, ...backgroundColors },
});

const emojiType = mySchema.nodes.emoji

function insertEmoji(type) {
  return function(state, dispatch) {
    const {$from} = state.selection
    const index = $from.index()
    if (!$from.parent.canReplaceWith(index, index, emojiType))
      return false
    if (dispatch)
      dispatch(state.tr.replaceSelectionWith(emojiType.create({type})))
    return true
  }
}

const menu = buildMenuItems(mySchema)


menu.fullMenu[0] = [
  ...menu.fullMenu[0].slice(0, 1),
  createMenuItem({
    mark: mySchema.marks.underline,
    title: 'Toggle underline',
    iconClass: 'fa-underline'
  }),
  ...menu.fullMenu[0].slice(1)
]
menu.fullMenu[0].unshift(
  createDropdownItem(
    definedColors.slice(1).map(definedColor => createMenuItem({
      mark: mySchema.marks[definedColor],
      isColor: true,
    })),
    'Schriftfarbe',
  ),
  createDropdownItem(
    definedColors.map(definedColor => createMenuItem({
      mark: mySchema.marks['background' + definedColor],
      // eslint-disable-next-line
      isColor: true,
    })),
    'Hintergrundfarbe',
  )
)

const textTypeItems = menu.fullMenu[1][1].content.slice()
textTypeItems.splice(1, 1)

menu.fullMenu[1] = textTypeItems

/* menu.fullMenu[0][0].options.title = 'Color';
menu.fullMenu[0][1].options.title = 'Background color';
menu.fullMenu[0][2].spec.title = 'Toggle strong style';
menu.fullMenu[0][3].spec.title = 'Toggle underline';
menu.fullMenu[0][4].spec.title = 'Toggle emphasis';
menu.fullMenu[0][5].spec.title = 'Toggle code font';
menu.fullMenu[0][6].spec.title = 'Add or remove link';
menu.fullMenu[1][0].spec.title = 'Change to paragraph';
menu.fullMenu[1][0].spec.label = 'Plain';
menu.fullMenu[1][1].options.label = 'Heading';
menu.fullMenu[1][1].content[0].spec.label = 'Level 1';
menu.fullMenu[1][1].content[0].spec.title = 'Change to heading 1';
menu.fullMenu[1][1].content[1].spec.label = 'Level 2';
menu.fullMenu[1][1].content[1].spec.title = 'Change to heading 2';
menu.fullMenu[1][1].content[2].spec.label = 'Level 3';
menu.fullMenu[1][1].content[2].spec.title = 'Change to heading 3';
menu.fullMenu[1][1].content[3].spec.label = 'Level 4';
menu.fullMenu[1][1].content[3].spec.title = 'Change to heading 4';
menu.fullMenu[1][1].content[4].spec.label = 'Level 5';
menu.fullMenu[1][1].content[4].spec.title = 'Change to heading 5';
menu.fullMenu[1][1].content[5].spec.label = 'Level 6';
menu.fullMenu[1][1].content[5].spec.title = 'Change to heading 6';
menu.fullMenu[2][0].spec.title = 'Undo last change';
menu.fullMenu[2][1].spec.title = 'Redo last undone change';
menu.blockMenu[0][0].spec.title = 'Wrap in bullet list';
menu.blockMenu[0][1].spec.title = 'Wrap in ordered list';
menu.blockMenu[0][2].spec.title = 'Wrap in block quote';
menu.blockMenu[0][5].spec.title = 'Select parent node'; */

menu.fullMenu[0][0].options.title = 'Schriftfarbe';
menu.fullMenu[0][1].options.title = 'Hintergrundfarbe';
menu.fullMenu[0][2].spec.title = 'Fett';
menu.fullMenu[0][3].spec.title = 'Unterstrichen';
menu.fullMenu[0][4].spec.title = 'Kursiv';
menu.fullMenu[0][5].spec.title = 'Code Schriftart';
menu.fullMenu[0][6].spec.title = 'Link hinzufügen oder entfernen';
menu.fullMenu[1][0].spec.title = 'Zu Paragraph ändern';
menu.fullMenu[1][0].spec.label = 'Unformatiert';
menu.fullMenu[1][1].options.label = 'Überschrift';
menu.fullMenu[1][1].content[0].spec.label = 'Level 1';
menu.fullMenu[1][1].content[0].spec.title = 'Ändern zu Überschrift 1';
menu.fullMenu[1][1].content[1].spec.label = 'Level 2';
menu.fullMenu[1][1].content[1].spec.title = 'Ändern zu Überschrift 2';
menu.fullMenu[1][1].content[2].spec.label = 'Level 3';
menu.fullMenu[1][1].content[2].spec.title = 'Ändern zu Überschrift 3';
menu.fullMenu[1][1].content[3].spec.label = 'Level 4';
menu.fullMenu[1][1].content[3].spec.title = 'Ändern zu Überschrift 4';
menu.fullMenu[1][1].content[4].spec.label = 'Level 5';
menu.fullMenu[1][1].content[4].spec.title = 'Ändern zu Überschrift 5';
menu.fullMenu[1][1].content[5].spec.label = 'Level 6';
menu.fullMenu[1][1].content[5].spec.title = 'Ändern zu Überschrift 6';
menu.fullMenu[2][0].spec.title = 'Letzte Änderung rückgängig machen';
menu.fullMenu[2][1].spec.title = 'Letzte rückgängig gemachte Änderung wiederherstellen';
menu.blockMenu[0][0].spec.title = 'Aufzählungsliste';
menu.blockMenu[0][1].spec.title = 'Geordnete Liste';
menu.blockMenu[0][2].spec.title = 'Zitat';
menu.blockMenu[0][5].spec.title = 'Elternknoten wählen';

export default function useEditor({ message, placeholder, onChange, groupId, className, isChat, onOpen, onClose }) {
  const selectedIdsRef = { current: [] };
  message = message || document.createElement('div');
  placeholder = placeholder || '';

  function formPlugins() {
    return [
      mentionPlugin(groupId, selectedIdsRef, isChat),
      ...exampleSetup({ schema: mySchema, menuContent: menu.fullMenu }),
      placeholderPlugin(placeholder)
    ];
  }

  const view = useRef(null);
  const editorRef = useRef(null);
  const plugins = formPlugins();

  function getEditorValue() {
    const tag = document.querySelector('.suggestion-item-list');
    if (tag) {
      const parent = tag.parentElement;
      parent.parentElement.removeChild(parent);
    }
    if (editorRef.current) {
      const allSelectedUsers = [];
      const mentionNodes = editorRef.current.querySelectorAll('.prosemirror-mention-node');
      mentionNodes.forEach(item =>
        allSelectedUsers.push(item.dataset.mentionId)
      );
      selectedIdsRef.current = allSelectedUsers;
    }
    const container = editorRef.current.querySelector('.ProseMirror');
    const placeholderWidget = container.querySelector('.ProseMirror-widget');
    if (placeholderWidget) {
      placeholderWidget.parentNode.removeChild(placeholderWidget);
    }

    return container;
  }
  function resetEditorValue() {
    if (editorRef.current) {
      const container = editorRef.current.querySelector('.ProseMirror');
      container.innerHTML = '';
    }
  }

    function insText(text) {
        const { from, to } = view.current.state.selection;
        const { tr } = view.current.state;
        tr.insertText(text, 0, 1);
        view.current.dispatch(tr);
      }


  if (onChange) {
    plugins.push(onChangePlugin(onChange, editorRef))
  }

  function focusOnEditor() {
    setTimeout(() => {
      if (view.current) {
        view.current.focus();
        editorRef.current.querySelector('.ProseMirror').click();
      }
    }, 400);
  }

  function EditorFunc() {
    const storageMessage = Storage.getItem(`editor/message/${placeholder}`);
    if (storageMessage) {
      message.innerHTML = storageMessage;
    }
    Storage.removeItem(`editor/message/${placeholder}`);
    useEffect(() => {
      view.current = new EditorView(editorRef.current, {
        state: EditorState.create({
          doc: DOMParser.fromSchema(mySchema).parse(message),
          plugins,
        }),
      });

      const input = editorRef.current.querySelector('.ProseMirror');
      if (className) {
        input.classList.add(className)
      }
      input.classList.add('notranslate');
      input.addEventListener('click', addVisibleClass);
      input.addEventListener('focus', addVisibleClass);
      input.addEventListener('blur', removeVisibleClass);

      setTimeout(() => {
        if (!store.getState().editorReducer.emojisAreLoaded) {
          createEmojisInBackground(true);
          menu.fullMenu[0] = [...menu.fullMenu[0].slice(0, 5), createEmojisItem(
            emojis.map((name, index) => {
              return new MenuItem({
                title: "Insert emoji",
                label: "Insert emoji",
                enable(state) { return insertEmoji(name)(state) },
                run: insertEmoji(name),
                icon: {
                  dom: emojiImages[index],
                }
              })
            })
          ), ...menu.fullMenu[0].slice(5)];
          view.current.update({
            state: EditorState.create({
              doc: DOMParser.fromSchema(mySchema).parse(message),
              plugins,
            }),
          });
          store.dispatch(setEmojisAreLoaded(true));
        }
      }, 20);

      return () => {
        input.removeEventListener('click', addVisibleClass);
        input.removeEventListener('focus', addVisibleClass);
        input.removeEventListener('blur', removeVisibleClass);

        view.current.destroy();
      };

      function show() {
        const menuBar = editorRef.current.querySelector('.ProseMirror-menubar');
        menuBar.classList.add('ProseMirror-menubar-visible');

        if (onOpen) {
          onOpen(menuBar.offsetHeight);
        }
        if (view.current) {
          view.current.focus();
          editorRef.current.querySelector('.ProseMirror').click();
        }
      }

      function addVisibleClass(event) {
        handleLinkClick(event);
        show();
      }

      function handleLinkClick(event) {
        // Check if Ctrl or Command key is pressed
        const isCtrlKey = event.ctrlKey || event.metaKey;

        // Continue only if the Ctrl key is pressed
        if (!isCtrlKey) {
          return;
        }

        let target = event.target;

        while (target && target !== input) {
          if (target.nodeName === 'A' && target.hasAttribute('href')) {
            const linkHref = target.getAttribute('href');

            // Open the link in a new window
            window.open(linkHref, '_blank');

            event.preventDefault(); // Prevent the default behavior of opening the link
            return;
          }

          target = target.parentNode;
        }
      }

      function removeVisibleClass() {
        editorRef.current
          .querySelector('.ProseMirror-menubar')
          .classList.remove('ProseMirror-menubar-visible');

        const suggestionList = document.querySelector('.suggestion-item-list')
        if (suggestionList) {
          setTimeout(() => suggestionList.parentElement.removeChild(suggestionList), 100)
        }
        if (onClose) {
          onClose();
        }
      }
    }, []);

    return <div ref={editorRef} />;
  }

  const Editor = useRef(EditorFunc);

  function updateValue(newValue) {
    view.current.update({
      state: EditorState.create({
        doc: DOMParser.fromSchema(mySchema).parse(newValue),
        plugins,
      }),
    });
  }

  return {
    Editor: Editor.current,
    updateValue,
    getEditorValue,
    focusOnEditor,
    resetEditorValue,
    insText,
  };
}

let activeText = null;

const mentionPlugin = (groupId, alreadySelectedUsers, isChat) =>
  getMentionsPlugin({
    getSuggestions: async (_, text, done) => {
      try {
        activeText = text;
        if (text.length < 2) {
          done([{ attention: i18n.t('Editor.Please type at least 2 characters') }]);
        } else {
          done([{ isLoading: true }]);
          const request = isChat ? getUsersByNameForChat : getUsersByName;
          const params = isChat ?
            { search: text, alreadySelectedUsers: alreadySelectedUsers.current } :
            `${text}${groupId ? `?groupId=${groupId}` : ''}`;

          request(params).then(({ data }) => {
            if (text === activeText) {
              const users = data.map(el => ({
                ...el,
                name: `${el.firstName} ${el.lastName}`,
              }));

              // Using try-catch here because of error,
              // that we're getting after we starting to search users
              // and then removes @-symbol and string to search
              try {
                done(users.length > 0 ? users : [{ attention: i18n.t('Editor.No Result') }]);
              } catch (ex) { return null }
            }
          });
        }
      } catch (ex) { return null }
    },
    getSuggestionsHTML,
    activeClass: 'prosemirror-mention-item-active',
    delay: 0,
    hashtagTrigger: false,
    allowSpace: false
  });

const placeholderPlugin = text =>
  new Plugin({
    props: {
      decorations(state) {
        const { doc } = state;
        if (doc.childCount === 1 && doc.firstChild.isTextblock && doc.firstChild.content.size === 0)
          return DecorationSet.create(doc, [Decoration.widget(1, document.createTextNode(text))]);
      },
    },
  });


const onChangePlugin = (onChange, editorRef) => new Plugin({
  state: {
    init() { },
    apply(tr) {
      if (tr.docChanged) {
        requestAnimationFrame(() => onChange(editorRef.current.querySelector('.ProseMirror').innerHTML));
      }
    },
  },
});
