import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { putModule } from 'library/api/groups';

import AddModulesPopup from 'library/common/commonComponents/Popups/AddModulesPopup';
import GroupCreationPopup from 'library/common/commonComponents/Popups/GroupCreationPopup';
import InviteUsersPopup from 'library/common/commonComponents/Popups/InviteUsersPopup';
import { createGroupAPI } from 'library/common/commonActions/groupActions';

export default function CreateGroup({
  setCreateGroupMode,
  showBottomNotification,
  history,
  activeKita,
}) {
  const { t } = useTranslation();

  const activateSelectedModules = ({ modules, groupId }) => {
    console.log('activatinv', modules);
    return new Promise((resolve, reject) => {
      const requests = [];
      for (const module of modules) {
        requests.push(putModule({ groupId, moduleAction: 'ENABLE', moduleId: module }));
      }
      Promise.all(requests)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  };

  const [currentTab, setCurrentTab] = useState(0);
  const [blockRequest, setBlockRequest] = useState(false);
  const [groupData, setGroupData] = useState({
    title: '',
    description: '',
    color: '#4c7201',
    visibility: activeKita.defaultGlobalGroupVisibility,
    joinPolicy: activeKita.groupJoinPoliciesId,
    modules: [],
    users: [],
  });
  const [errors, setErrors] = useState({
    title: '',
    description: '',
  });

  const cancel = () => {
    setCreateGroupMode(false);
  };
  const onDataChange = data => {
    setErrors({
      title: '',
      description: '',
      invite: '',
    });
    setGroupData({ ...groupData, ...data });
  };
  console.log(groupData);
  const createGroup = () => {
    setBlockRequest(false);
    const errorsTemplate = {
      title: '',
      description: '',
    };

    const data = {
      colourCode: groupData.color,
      groupDescription: groupData.description,
      groupJoinPolicies: groupData.joinPolicy,
      groupModules: groupData.modules.filter(mod => typeof mod === 'number'),
      groupName: groupData.title,
      privacy: groupData.visibility,
      userGroups: groupData.users.map(item => item.id),
    };
    return createGroupAPI(data).then(res => {
      if (res.status === 200) {
        showBottomNotification(t('CreateGroup.Space was successfull created'));
        setGroupData({
          ...groupData,
          modules: groupData.modules.filter(mod => typeof mod === 'number'),
          ...{ id: res.data.id },
        });
        activateSelectedModules({
          modules: data.groupModules.filter(mod => typeof mod === 'number'),
          groupId: res.data.id,
        })
          .then(() => {
            cancel();
            history.push(`/groups/${res.data.id}/stream`);
          })
          .catch(() => {
            showBottomNotification(t('BottomNotifications.Something went wrong'), {
              isFail: true,
            });
          });
      } else if (res.status === 208) {
        setBlockRequest(true);
        setCurrentTab(0);
        setErrors({ ...errorsTemplate, ...{ title: t(res.reason) } });
      }
    });
  };
  const validateForm = (index, isValidAction) => {
    let isError = false;
    let errorsTemplate = {
      title: '',
      description: '',
    };

    if (index === 0) {
      if (!groupData.title.trim().length) {
        errorsTemplate = {
          ...errorsTemplate,
          ...{ title: t('CreateGroup.Space name field is required') },
        };
        isError = true;
      } else if (groupData.title.trim().length < 2) {
        errorsTemplate = {
          ...errorsTemplate,
          ...{ title: t('CreateGroup.Name should contain at least 2 characters') },
        };
        isError = true;
      }
    }
    if (!isError) {
      return isValidAction();
    } else {
      setErrors(errorsTemplate);
    }
  };
  const getCurrentTab = function getCurrentTab() {
    switch (currentTab) {
      case 0:
        return (
          <GroupCreationPopup
            isOpened
            blockRequest={blockRequest}
            closePopup={cancel}
            value={groupData}
            errors={errors}
            onDataChange={onDataChange}
            next={() => validateForm(0, () => setCurrentTab(1))}
          />
        );
      case 1:
        return (
          <AddModulesPopup
            isOpened
            closePopup={cancel}
            value={groupData}
            errors={errors}
            onDataChange={onDataChange}
            back={() => setCurrentTab(0)}
            next={() => validateForm(1, () => setCurrentTab(2))}
          />
        );
      case 2:
        return (
          <InviteUsersPopup
            isOpened
            closePopup={cancel}
            value={groupData}
            errors={errors}
            onDataChange={onDataChange}
            back={() => setCurrentTab(1)}
            next={() => validateForm(2, createGroup)}
            isOnGroupCreation
          />
        );

      default:
        return null;
    }
  };
  return <div>{getCurrentTab()}</div>;
}
