import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { setLanguage } from 'library/common/commonActions/changeLanguageActions';
import BackgroundWrapper from 'library/common/commonComponents/to-refactor/commonBackgroundWrapper';
import Card from 'library/common/commonComponents/to-refactor/commonCard';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import Input from 'library/common/commonComponents/to-refactor/commonInput';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Storage from 'library/utilities/storage';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useLanguages from 'library/common/commonHooks/useLanguages';

import LanguageDropDown from 'library/common/commonComponents/Dropdowns/LanguageDropdown';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import { registrationService, checkVerificationCode } from './registrationActions';

import styles from './index.module.scss';

const errorsTemplate = {
  userName: '',
  password: '',
  passwordConfirmation: '',
  kitaCode: '',
  firstName: '',
  lastName: '',
};

export function Registration(props) {
  useSiteTitle('Registration');

  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [organization, setOrganization] = useState('');
  const [kitaCode, setKitaCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBounceAnimation, setIsBounceAnimation] = useState(true);
  const [isInputError, setIsInputError] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isTokenVerificationStarted, setIsTokenVerificationStarted] = useState(false);
  const [inputError, setInputError] = useState('');
  const [errors, setErrors] = useState(errorsTemplate);
  const [isChecked, toggleIsChecked] = useState(false);
  const [gdprCheckboxState, setgdprCheckboxState] = useState(true);

  const { t } = useTranslation();

  const stateHandlers = {
    password: setPassword,
    userName: setUserName,
    passwordConfirmation: setPasswordConfirmation,
    firstName: setFirstName,
    lastName: setLastName,
    organization: setOrganization,
    kitaCode: setKitaCode,
    isDisabled: setIsDisabled,
    isBounceAnimation: setIsBounceAnimation,
  };
  // eslint-disable-next-line
  useEffect(() => {
    if (window.location.search) {
      const code = window.location.search.split('=')[1];
      stateHandlers.kitaCode(code);
    }
    if (!isTokenVerificationStarted) {
      setIsTokenVerificationStarted(true);
      props.checkVerificationCode(props.match.params.token).then(data => {
        setIsTokenVerified(true);
        if (data) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInputChange = event => {
    resetErrorInput();
    event.preventDefault();
    const { name, value } = event.target;
    stateHandlers[name](value);
  };

  const setDisabledFlag = () => {
    setIsDisabled(false);
    setIsInputError(false);
  };

  const resetErrorInput = () => {
    setErrors(errorsTemplate);
    setIsInputError('');
  };

  const setErrorOnInput = () => {
    let validationFailed = false;
    let errorsTmp = errorsTemplate;
    resetErrorInput();

    if (!userName) {
      errorsTmp = { ...errorsTmp, ...{ userName: t('Registration.Username cannot be blank') } };
      validationFailed = true;
    }
    if (!password) {
      errorsTmp = { ...errorsTmp, ...{ password: t('Registration.Password cannot be blank') } };
      validationFailed = true;
    }
    if (password.length < 8) {
      errorsTmp = {
        ...errorsTmp,
        ...{ password: t('Registration.Password length should be 8 or more') },
      };
      validationFailed = true;
    }
    if (!passwordConfirmation) {
      errorsTmp = {
        ...errorsTmp,
        ...{ passwordConfirmation: t('Registration.Please, confirm your password') },
      };
      validationFailed = true;
    }
    if (passwordConfirmation !== password) {
      errorsTmp = {
        ...errorsTmp,
        ...{ passwordConfirmation: t('Registration.Passwords do not match') },
      };
      validationFailed = true;
    }
    if (firstName === '') {
      errorsTmp = { ...errorsTmp, ...{ firstName: t('Registration.First name cannot be blank') } };
      validationFailed = true;
    }
    if (lastName === '') {
      errorsTmp = { ...errorsTmp, ...{ lastName: t('Registration.Last name cannot be blank') } };
      validationFailed = true;
    }
    if (kitaCode === '' && !isChecked) {
      errorsTmp = { ...errorsTmp, ...{ kitaCode: t('Registration.KITA code cannot be blank') } };
      validationFailed = true;
    }

    if (
      organization === '' &&
      (window.location.hostname.toLowerCase().includes('staging1.safe2connect.org') || window.location.hostname.toLowerCase().includes('staging2.safe2connect.org') ||
        window.location.hostname.toLowerCase().includes('indag.safe2connect.org') || window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org'))
    ) {
      errorsTmp = {
        ...errorsTmp,
        ...{ organization: t('Registration.Organization cannot be blank') },
      };
      validationFailed = true;
    }
    if (validationFailed) {
      setIsDisabled(true);
      setIsInputError(true);
      setIsBounceAnimation(false);
      setDisabledFlag();
      setErrors(errorsTmp);
      return false;
    }
    setIsDisabled(true);
    setIsInputError(false);
    setIsBounceAnimation(false);
    return true;
  };
  const registration = () => {
    if (setErrorOnInput()) {
      const data = {
        userName,
        password,
        firstName,
        lastName,
        organization,
        kitaCode: !isChecked ? kitaCode : null,
        verificationCode: props.match.params.token,
        langCode: Storage.getItem('locale'),
      };
      props.registrationService(data).then(res => {
        if (res.result) {
          setIsInputError(false);
          setIsDisabled(false);
          props.showBottomNotificationFromProps(t('BottomNotifications.RegistrationCompleted'));
          props.history.push('/login');
        } else {
          switch (res.status) {
            case 404:
              setErrors({ ...errors, kitaCode: t(res.reason) });
              break;
            case 406:
              setErrors({ ...errors, ...{ kitaCode: t(res.reason) } });
              break;
            case 208:
              setErrors({ ...errors, ...{ userName: t(res.reason) } });
              break;
            case 422:
              setErrors({ ...errors, ...{ password: t(res.reason) } });
              break;
            default:
              setInputError(t(res.reason));
          }
          setIsInputError(true);
          setDisabledFlag();
        }
      });
    }
  };
  const handleCheckbox = () => {
    toggleIsChecked(!isChecked);
  };

  const onSubmit = !isDisabled ? registration : undefined;

  const { selectedLanguage, changeLanguage } = useLanguages(value => {
    props.setLanguage(value);
    resetErrorInput();
  });

  return (
    <BackgroundWrapper>
      <div className={styles.wrapper}>
        <div className='container'>
          <Card
            className={cn(
              styles.registration_container,
              'animated',
              { bounceIn: isBounceAnimation },
              { shake: isInputError },
            )}
          >
            <CardHeading className={styles.registration_heading}>
              <span className={styles.registration_heading_text_sign_in}>
                <strong>{`${t('Registration.Account')} `}</strong>
                {t('Registration.registration')}
              </span>
            </CardHeading>
            {isTokenVerified && (
              <div>
                <CardHeading className={styles.registration_heading_2}>
                  <span>
                    {isTokenValid ? (
                      t('Registration.Account')
                    ) : (
                      <div className={cn(styles.form__label, styles.form__label_text)}>
                        {t('Registration.This link is invalid')}
                        <br />
                        {t('Registration.Please sign-up again for new link')}
                      </div>
                    )}
                  </span>
                </CardHeading>
                {isTokenValid && (
                  <form onSubmit={registration} className='form-group mb-0'>
                    <div className={styles.form__item}>
                      <div className={cn(styles.form__label, styles.form__label_required)}>
                        {t('Registration.Username')}
                      </div>
                      <Input
                        className={styles.form}
                        placeholder=''
                        name='userName'
                        error={errors.userName !== ''}
                        value={userName}
                        type='text'
                        onChange={handleInputChange}
                        onEnterDown={onSubmit}
                        maxLength={50}
                      />
                      {errors.userName !== '' && (
                        <div className={styles.error_msg}>
                          <InputErrorMsg errorMsg={errors.userName} />
                        </div>
                      )}
                    </div>
                    <div className={styles.form__item}>
                      <div className={cn(styles.form__label, styles.form__label_required)}>
                        {t('Registration.New password')}
                      </div>
                      <Input
                        className={styles.form}
                        placeholder=''
                        name='password'
                        error={errors.password !== ''}
                        value={password}
                        type='password'
                        onChange={handleInputChange}
                        onEnterDown={onSubmit}
                      />
                      {errors.password !== '' && (
                        <div className={styles.error_msg}>
                          <InputErrorMsg errorMsg={errors.password} />
                        </div>
                      )}
                    </div>
                    <div className={styles.form__item}>
                      <div className={cn(styles.form__label, styles.form__label_required)}>
                        {t('Registration.Confirm new password')}
                      </div>
                      <Input
                        className={styles.form}
                        placeholder=''
                        name='passwordConfirmation'
                        error={errors.passwordConfirmation !== ''}
                        value={passwordConfirmation}
                        type='password'
                        onChange={handleInputChange}
                        onEnterDown={onSubmit}
                      />
                      {errors.passwordConfirmation !== '' && (
                        <div className={styles.error_msg}>
                          <InputErrorMsg errorMsg={errors.passwordConfirmation} />
                        </div>
                      )}
                    </div>
                    {!isChecked && (
                      <div className={styles.form__item}>
                        <div className={cn(styles.form__label, styles.form__label_required)}>
                          {t('Registration.KITA code')}
                        </div>
                        <Input
                          className={styles.form}
                          placeholder=''
                          name='kitaCode'
                          error={errors.kitaCode !== ''}
                          value={kitaCode}
                          type='text'
                          onChange={handleInputChange}
                          onEnterDown={onSubmit}
                        />
                        {errors.kitaCode !== '' && (
                          <div className={styles.error_msg}>
                            <InputErrorMsg errorMsg={errors.kitaCode} />
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      <CheckBox
                        name={t("Registration.I don't have the Kita code")}
                        isChecked={isChecked}
                        onChange={handleCheckbox}
                      />
                    </div>
                    <CardHeading className={styles.registration_heading_2}>
                      <span>{t('Registration.General')}</span>
                    </CardHeading>
                    <div className={styles.form__item}>
                      <div className={cn(styles.form__label, styles.form__label_required)}>
                        {t('Registration.First name')}
                      </div>
                      <Input
                        className={styles.form}
                        placeholder=''
                        name='firstName'
                        error={errors.firstName !== ''}
                        value={firstName}
                        type='text'
                        onChange={handleInputChange}
                        onEnterDown={onSubmit}
                        maxLength={255}
                      />
                      {errors.firstName !== '' && (
                        <div className={styles.error_msg}>
                          <InputErrorMsg errorMsg={errors.firstName} />
                        </div>
                      )}
                    </div>
                    <div className={styles.form__item}>
                      <div className={cn(styles.form__label, styles.form__label_required)}>
                        {t('Registration.Last name')}
                      </div>
                      <Input
                        className={styles.form}
                        placeholder=''
                        name='lastName'
                        error={errors.lastName !== ''}
                        value={lastName}
                        type='text'
                        onChange={handleInputChange}
                        onEnterDown={onSubmit}
                        maxLength={255}
                      />
                      {errors.lastName !== '' && (
                        <div className={styles.error_msg}>
                          <InputErrorMsg errorMsg={errors.lastName} />
                        </div>
                      )}
                    </div>
                    {(window.location.hostname
                      .toLowerCase()
                      .includes('staging1.safe2connect.org') ||
                      window.location.hostname
                        .toLowerCase()
                        .includes('indag.safe2connect.org') ||
                      window.location.hostname
                        .toLowerCase()
                        .includes('staging2.safe2connect.org') || window.location.hostname.toLowerCase().includes('awo-immer-dabei.safe2connect.org')) && (
                        <>
                          <div className={styles.form__item}>
                            <div className={cn(styles.form__label, styles.form__label_required)}>
                              {t('Registration.Organization')}
                            </div>
                            <Input
                              className={styles.form}
                              placeholder=''
                              name='organization'
                              error={
                                errors.organization !== '' &&
                                typeof errors.organization !== 'undefined'
                              }
                              value={organization}
                              type='text'
                              onChange={handleInputChange}
                              onEnterDown={onSubmit}
                              maxLength={255}
                            />
                          </div>
                          <div>
                            {errors.organization !== '' && (
                              <div className={styles.error_msg}>
                                <InputErrorMsg errorMsg={errors.organization} />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                  </form>
                )}

                <div className={cn(styles.registration_btn_container)}>
                  <br />
                  <input
                    name='isGoing'
                    type='checkbox'
                    onChange={() => {
                      setgdprCheckboxState(!gdprCheckboxState);
                    }}
                    style={{ height: '18px', width: '18px' }}
                    defaultChecked={!gdprCheckboxState}
                  />
                  {'  '}
                  {t('Signup.Accept data protection checkbox')}
                  {'  '}
                  <a href='/auth-privacy'>{t('Signup.Accept data protection')}</a>
                  <br />
                  <br />
                </div>
                <div className={cn(styles.registration_btn_container)}>
                  {isTokenValid ? (
                    <ButtonWithLoader
                      type='primary'
                      onClick={!isDisabled ? registration : undefined}
                      isLoading={isDisabled}
                      disabled={gdprCheckboxState}
                    >
                      {t('Registration.Create account')}
                    </ButtonWithLoader>
                  ) : (
                    <ButtonWithLoader
                      type='primary'
                      onClick={!isDisabled ? () => props.history.push('/login') : undefined}
                      isLoading={isDisabled}
                      disabled={gdprCheckboxState}
                    >
                      {t('Registration.Sign up')}
                    </ButtonWithLoader>
                  )}
                </div>
                {inputError !== '' && (
                  <div className={styles.error_msg}>
                    <InputErrorMsg errorMsg={inputError} />
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>

        <div>
          <LanguageDropDown
            chooseLanguageText={t('Registration.Choose Language')}
            submitLanguage={changeLanguage}
            selectedLanguage={selectedLanguage}
          />
        </div>
      </div>
    </BackgroundWrapper>
  );
}

Registration.defaultProps = {
  checkVerificationCode,
};

const mapDispatchToProps = {
  showBottomNotificationFromProps: showBottomNotification,
  setLanguage,
  registrationService,
};

export default connect(
  null,
  mapDispatchToProps,
)(Registration);
