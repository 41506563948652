export const NorthRhineWestphaliaConstants = [
  {
    "PLZ": 32683,
    "Stadt": "Barntrup",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33333,
    "Stadt": "Gütersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33334,
    "Stadt": "Gütersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33335,
    "Stadt": "Gütersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33332,
    "Stadt": "Gütersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33330,
    "Stadt": "Gütersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48493,
    "Stadt": "Wettringen Kr. Steinfurt",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48607,
    "Stadt": "Ochtrup",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48599,
    "Stadt": "Gronau/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48691,
    "Stadt": "Vreden",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51597,
    "Stadt": "Morsbach",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51789,
    "Stadt": "Lindlar",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51766,
    "Stadt": "Engelskirchen",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51709,
    "Stadt": "Marienheide",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51702,
    "Stadt": "Bergneustadt",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51688,
    "Stadt": "Wipperfürth",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51674,
    "Stadt": "Wiehl",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51588,
    "Stadt": "Nümbrecht",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51580,
    "Stadt": "Reichshof",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48703,
    "Stadt": "Stadtlohn",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51545,
    "Stadt": "Waldbröl",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42499,
    "Stadt": "Hückeswagen",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42477,
    "Stadt": "Radevormwald",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48683,
    "Stadt": "Ahaus",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41569,
    "Stadt": "Rommerskirchen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48619,
    "Stadt": "Heek",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41564,
    "Stadt": "Kaarst",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40789,
    "Stadt": "Monheim am Rhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47279,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Bissingheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48739,
    "Stadt": "Legden",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48629,
    "Stadt": "Metelen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48624,
    "Stadt": "Schöppingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48612,
    "Stadt": "Horstmar",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48366,
    "Stadt": "Laer",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48565,
    "Stadt": "Steinfurt",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48356,
    "Stadt": "Nordwalde",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48341,
    "Stadt": "Altenberge",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59955,
    "Stadt": "Winterberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59964,
    "Stadt": "Medebach",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59889,
    "Stadt": "Eslohe/Sauerland",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59872,
    "Stadt": "Meschede",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48329,
    "Stadt": "Havixbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57392,
    "Stadt": "Schmallenberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48720,
    "Stadt": "Rosendahl",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48727,
    "Stadt": "Billerbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48653,
    "Stadt": "Coesfeld",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48301,
    "Stadt": "Nottuln",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57489,
    "Stadt": "Drolshagen",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57482,
    "Stadt": "Wenden",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48712,
    "Stadt": "Gescher",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57439,
    "Stadt": "Attendorn",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57413,
    "Stadt": "Finnentrop",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57399,
    "Stadt": "Kirchhundem",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57368,
    "Stadt": "Lennestadt",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48734,
    "Stadt": "Reken",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46342,
    "Stadt": "Velen",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46359,
    "Stadt": "Heiden",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46354,
    "Stadt": "Südlohn",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46325,
    "Stadt": "Borken/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46414,
    "Stadt": "Rhede/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41836,
    "Stadt": "Hückelhoven",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32839,
    "Stadt": "Steinheim/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32832,
    "Stadt": "Augustdorf",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32805,
    "Stadt": "Horn-Bad Meinberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47051,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Altstadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46514,
    "Stadt": "Schermbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46569,
    "Stadt": "Hünxe",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46562,
    "Stadt": "Voerde/Niederrhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47495,
    "Stadt": "Rheinberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46519,
    "Stadt": "Alpen",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46509,
    "Stadt": "Xanten",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46419,
    "Stadt": "Isselburg",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46459,
    "Stadt": "Rees",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46446,
    "Stadt": "Emmerich am Rhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57462,
    "Stadt": "Olpe",
    "Stadtteil": "",
    "Landkreis": "Kreis Olpe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57339,
    "Stadt": "Erndtebrück",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57334,
    "Stadt": "Bad Laasphe",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57319,
    "Stadt": "Bad Berleburg",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57299,
    "Stadt": "Burbach/Siegerland",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57290,
    "Stadt": "Neunkirchen/Siegerland",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57271,
    "Stadt": "Hilchenbach",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57258,
    "Stadt": "Freudenberg/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57250,
    "Stadt": "Netphen",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57234,
    "Stadt": "Wilnsdorf",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57223,
    "Stadt": "Kreuztal",
    "Stadtteil": "",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40221,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Hafen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40219,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Unterbilk",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40225,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Bilk",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40223,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Flehe",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40217,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40237,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40212,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40211,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40477,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40476,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40721,
    "Stadt": "Düsseldorf",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42799,
    "Stadt": "Leichlingen/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40239,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Düsseltal",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40878,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40472,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40885,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40883,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40882,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40880,
    "Stadt": "Ratingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40235,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Flingern Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40233,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Flingern Süd",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40231,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Lierenfeld",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40227,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Oberbilk",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40215,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Friedrichstadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45891,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Erle",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45886,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Ückendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45884,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Rotthausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40213,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Carlstadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40229,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Eller",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40591,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Wersten",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40210,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Stadtmitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40479,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Pempelfort",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40468,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Unterrath",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40474,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Golzheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40472,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Lichtenbroich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42781,
    "Stadt": "Haan",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52538,
    "Stadt": "Selfkant",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52525,
    "Stadt": "Waldfeucht",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40545,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Oberkassel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52385,
    "Stadt": "Nideggen",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52134,
    "Stadt": "Herzogenrath",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52511,
    "Stadt": "Geilenkirchen",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48308,
    "Stadt": "Senden/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52531,
    "Stadt": "Übach-Palenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59969,
    "Stadt": "Hallenberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52396,
    "Stadt": "Heimbach/Eifel",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52159,
    "Stadt": "Roetgen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48249,
    "Stadt": "Dülmen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52393,
    "Stadt": "Hürtgenwald",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52152,
    "Stadt": "Simmerath",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52372,
    "Stadt": "Kreuzau",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52391,
    "Stadt": "Vettweiß",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52399,
    "Stadt": "Merzenich",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52459,
    "Stadt": "Inden",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45888,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Bulmke-Hüllen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52379,
    "Stadt": "Langerwehe",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45889,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Bismarck",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52249,
    "Stadt": "Eschweiler",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52477,
    "Stadt": "Alsdorf/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52146,
    "Stadt": "Würselen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48324,
    "Stadt": "Sendenhorst",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52499,
    "Stadt": "Baesweiler",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52457,
    "Stadt": "Aldenhoven",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52441,
    "Stadt": "Linnich",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52428,
    "Stadt": "Jülich",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52445,
    "Stadt": "Titz",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52382,
    "Stadt": "Niederzier",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50189,
    "Stadt": "Elsdorf/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50181,
    "Stadt": "Bedburg",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52388,
    "Stadt": "Nörvenich",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50374,
    "Stadt": "Erftstadt",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48317,
    "Stadt": "Drensteinfurt",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50389,
    "Stadt": "Wesseling",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50321,
    "Stadt": "Brühl/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50354,
    "Stadt": "Hürth",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59387,
    "Stadt": "Ascheberg/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49549,
    "Stadt": "Ladbergen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32369,
    "Stadt": "Rahden",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32351,
    "Stadt": "Stemwede",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59394,
    "Stadt": "Nordkirchen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42579,
    "Stadt": "Heiligenhaus",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42489,
    "Stadt": "Wülfrath",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40822,
    "Stadt": "Mettmann",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40699,
    "Stadt": "Erkrath",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52076,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52078,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52072,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52064,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52074,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52066,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52080,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52068,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52062,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52070,
    "Stadt": "Aachen",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48477,
    "Stadt": "Hörstel",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48369,
    "Stadt": "Saerbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48361,
    "Stadt": "Beelen",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48351,
    "Stadt": "Everswinkel",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48346,
    "Stadt": "Ostbevern",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59368,
    "Stadt": "Werne",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48336,
    "Stadt": "Sassenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48291,
    "Stadt": "Telgte",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45141,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Nordviertel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48282,
    "Stadt": "Emsdetten",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45127,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Stadtkern",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45147,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Holsterhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48268,
    "Stadt": "Greven/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45131,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Rüttenscheid",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46240,
    "Stadt": "Bottrop",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46238,
    "Stadt": "Bottrop",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46236,
    "Stadt": "Bottrop",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46242,
    "Stadt": "Bottrop",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46244,
    "Stadt": "Bottrop",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48231,
    "Stadt": "Warendorf",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45133,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Bredeney",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45359,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Bedingrade",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45134,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Rellinghausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45277,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Überruhr-Hinsel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59348,
    "Stadt": "Lüdinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50226,
    "Stadt": "Frechen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45468,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45470,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45481,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45479,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45472,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45473,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45476,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45478,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45475,
    "Stadt": "Mülheim an der Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50259,
    "Stadt": "Pulheim",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44787,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Mitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40470,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Mörsenbroich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45309,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Schonnebeck",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45307,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Kray",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44869,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Eppendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44867,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Sevinghausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44803,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Altenbochum",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44801,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Querenburg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44799,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Wiemelhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44797,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Stiepel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44795,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Weitmar",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44789,
    "Stadt": "Bochum",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45326,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Altenessen-Süd",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44793,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Hamme",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45356,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Bochold",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45329,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Altenessen-Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44866,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Günnigfeld",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45143,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Altendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45276,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Steele",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45279,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Freisenbruch",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45327,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Katernberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45139,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Ostviertel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45144,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45130,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45145,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Frohnhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45128,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Südviertel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45138,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Südostviertel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45355,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Borbeck-Mitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45357,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Gerschede",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45136,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Bergerhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45149,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Margarethenhöhe",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45289,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Burgaltendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45239,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Heidhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45259,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Heisingen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45257,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Kupferdreh",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44791,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Harpen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44809,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Hofstede",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44805,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Hiltrop",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44807,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Grumme",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59399,
    "Stadt": "Olfen",
    "Stadtteil": "",
    "Landkreis": "Kreis Coesfeld",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44879,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Dahlhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50997,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Godorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50968,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Bayenthal",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45899,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Beckhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45525,
    "Stadt": "Hattingen",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45529,
    "Stadt": "Hattingen",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45527,
    "Stadt": "Hattingen",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45897,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58456,
    "Stadt": "Witten",
    "Stadtteil": "Witten-Westherbede",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58455,
    "Stadt": "Witten",
    "Stadtteil": "Witten-Heven",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58452,
    "Stadt": "Witten",
    "Stadtteil": "Witten-Mitte",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47119,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Laar",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42553,
    "Stadt": "Velbert",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42555,
    "Stadt": "Velbert",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42551,
    "Stadt": "Velbert",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42549,
    "Stadt": "Velbert",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47138,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Obermeiderich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47137,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Mittelmeiderich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49545,
    "Stadt": "Tecklenburg",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47058,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Duissern",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47166,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Obermarxloh",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47139,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Beeckerwerth",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47167,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Neuhmühl",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47059,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Neuenkamp",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47057,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Neudorf-Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47169,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Röttgersbach",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47055,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Wanheimerort",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47249,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Buchholz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49525,
    "Stadt": "Lengerich/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45219,
    "Stadt": "Essen/Ruhr",
    "Stadtteil": "Essen/Ruhr-Kettwig",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47269,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Rahm",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44652,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44651,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44649,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44653,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44625,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44629,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44628,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44627,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44623,
    "Stadt": "Herne",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47179,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Aldenrade",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42399,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42329,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42327,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42389,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42369,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42349,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42289,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42287,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42285,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42283,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42281,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42279,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42277,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42275,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42113,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42111,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42109,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42119,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42117,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42107,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42105,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42115,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42103,
    "Stadt": "Wuppertal",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40625,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Gerresheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40627,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Vennhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47259,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Hüttenheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40599,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Hassels",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40597,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Benrath",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49504,
    "Stadt": "Lotte",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42853,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42899,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42897,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42859,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42857,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42855,
    "Stadt": "Remscheid",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40723,
    "Stadt": "Hilden",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40724,
    "Stadt": "Hilden",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40721,
    "Stadt": "Hilden",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59609,
    "Stadt": "Anröchte",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40547,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Lörick",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59597,
    "Stadt": "Erwitte",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42659,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42651,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42653,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42719,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42655,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42657,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42699,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42697,
    "Stadt": "Solingen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40593,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Urdenbach",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58454,
    "Stadt": "Witten",
    "Stadtteil": "Witten-Stockum",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59590,
    "Stadt": "Geseke",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45883,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Heßler",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45968,
    "Stadt": "Gladbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45966,
    "Stadt": "Gladbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45964,
    "Stadt": "Gladbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45894,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Buer",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45701,
    "Stadt": "Herten",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45699,
    "Stadt": "Herten",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44894,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Werne",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40589,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Itter",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59379,
    "Stadt": "Selm",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59514,
    "Stadt": "Welver",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40595,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Hellerhof",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40629,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Ludenberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45892,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Resse",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45879,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Neustadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45896,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Hassel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40489,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Wittlaer",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47178,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Alt-Walsum",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45881,
    "Stadt": "Gelsenkirchen",
    "Stadtteil": "Gelsenkirchen-Schalke-Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59510,
    "Stadt": "Lippetal",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32479,
    "Stadt": "Hille",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59505,
    "Stadt": "Bad Sassendorf",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40549,
    "Stadt": "Düsseldorf",
    "Stadtteil": "Düsseldorf-Heerdt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47447,
    "Stadt": "Moers",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47445,
    "Stadt": "Moers",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47443,
    "Stadt": "Moers",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47441,
    "Stadt": "Moers",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47239,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Rumeln-Kaldenhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47229,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Friemersheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41539,
    "Stadt": "Dormagen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41542,
    "Stadt": "Dormagen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41541,
    "Stadt": "Dormagen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41540,
    "Stadt": "Dormagen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47228,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Bergheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47226,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Rheinhausen-Mitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59494,
    "Stadt": "Soest",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41239,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Hockstein",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47199,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Baerl",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41472,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41470,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41469,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41468,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41466,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41464,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41462,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41460,
    "Stadt": "Neuss",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41238,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Schelsen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41236,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Rheydt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41751,
    "Stadt": "Viersen",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41749,
    "Stadt": "Viersen",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41748,
    "Stadt": "Viersen",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41747,
    "Stadt": "Viersen",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59329,
    "Stadt": "Wadersloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41069,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Ohler",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47198,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Hochheide",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40670,
    "Stadt": "Meerbusch",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40668,
    "Stadt": "Meerbusch",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40667,
    "Stadt": "Meerbusch",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59320,
    "Stadt": "Ennigerloh",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44581,
    "Stadt": "Castrop-Rauxel",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44577,
    "Stadt": "Castrop-Rauxel",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44579,
    "Stadt": "Castrop-Rauxel",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44575,
    "Stadt": "Castrop-Rauxel",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47839,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Hüls",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47829,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Uerdingen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47809,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Oppum",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59302,
    "Stadt": "Oelde",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47807,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Fischeln",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47805,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Dießem / Lehmheide",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47804,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Benrad-Süd",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47803,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Inrath / Kliedbruch",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47802,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Hülser Berg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47800,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Bockum",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47799,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Cracau",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47623,
    "Stadt": "Kevelaer",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47624,
    "Stadt": "Kevelaer",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47626,
    "Stadt": "Kevelaer",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47625,
    "Stadt": "Kevelaer",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47627,
    "Stadt": "Kevelaer",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47798,
    "Stadt": "Krefeld",
    "Stadtteil": "Krefeld-Stadtmitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59269,
    "Stadt": "Beckum",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41199,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Sassrath",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41189,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Wickrath-Mitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41179,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Rheindahlen-Land",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41169,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Hardter Wald",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41068,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Venn",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41066,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Bettrath-Hoven",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41065,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Lürrip",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41063,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Windberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41061,
    "Stadt": "Mönchengladbach",
    "Stadtteil": "Mönchengladbach-Gladbach",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59192,
    "Stadt": "Bergkamen",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41517,
    "Stadt": "Grevenbroich",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41516,
    "Stadt": "Grevenbroich",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41515,
    "Stadt": "Grevenbroich",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48429,
    "Stadt": "Rheine",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48432,
    "Stadt": "Rheine",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48431,
    "Stadt": "Rheine",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59174,
    "Stadt": "Kamen",
    "Stadtteil": "Kamen-Südkamen",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45731,
    "Stadt": "Waltrop",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58313,
    "Stadt": "Herdecke",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44892,
    "Stadt": "Bochum",
    "Stadtteil": "Bochum-Langendreer",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48485,
    "Stadt": "Neuenkirchen Kr.Steinfurt",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58636,
    "Stadt": "Iserlohn",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58642,
    "Stadt": "Iserlohn",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58644,
    "Stadt": "Iserlohn",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58640,
    "Stadt": "Iserlohn",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58638,
    "Stadt": "Iserlohn",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51107,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Rath/Heumar",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50126,
    "Stadt": "Bergheim/Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50127,
    "Stadt": "Bergheim/Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50129,
    "Stadt": "Bergheim/Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53129,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Dottendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53111,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Zentrum",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46487,
    "Stadt": "Wesel",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46485,
    "Stadt": "Wesel",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46483,
    "Stadt": "Wesel",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45739,
    "Stadt": "Oer-Erkenschwick",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58300,
    "Stadt": "Wetter/Ruhr",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45549,
    "Stadt": "Sprockhövel",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51467,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50672,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50678,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50937,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50823,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50668,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50674,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50931,
    "Stadt": "Köln",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51467,
    "Stadt": "Bergisch Gladbach",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51427,
    "Stadt": "Bergisch Gladbach",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51465,
    "Stadt": "Bergisch Gladbach",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51469,
    "Stadt": "Bergisch Gladbach",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51429,
    "Stadt": "Bergisch Gladbach",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51381,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51371,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51379,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51375,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51373,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51377,
    "Stadt": "Leverkusen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53721,
    "Stadt": "Siegburg",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58849,
    "Stadt": "Herscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58769,
    "Stadt": "Nachrodt-Wiblingwerde",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58762,
    "Stadt": "Altena",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58579,
    "Stadt": "Schalksmühle",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53797,
    "Stadt": "Lohmar",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58566,
    "Stadt": "Kierspe",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45721,
    "Stadt": "Haltern am See",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58553,
    "Stadt": "Halver",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58540,
    "Stadt": "Meinerzhagen",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58339,
    "Stadt": "Breckerfeld",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53604,
    "Stadt": "Bad Honnef",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51065,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Buchforst",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51143,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Porz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51145,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Urbach",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51067,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Holweide",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51069,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Dünnwald",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51147,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Wahn",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51149,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Westhoven",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51061,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Stammheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45711,
    "Stadt": "Datteln",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51103,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Kalk",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51109,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Brück",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51105,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Poll",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51063,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Mülheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50667,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Altstadt/Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50933,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Müngersdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50999,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Sürth",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50827,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Bickendorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50739,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Bilderstöckchen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50859,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Lövenich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50679,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Deutz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50939,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Klettenberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50825,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Ehrenfeld",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50737,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Weidenpesch",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50677,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Neustadt/Süd",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50996,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Hahnwald",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50670,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Neustadt/Nord",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50858,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Weiden",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50767,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Lindweiler",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50676,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Altstadt/Süd",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50735,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Riehl",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50969,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Zollstock",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50829,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Bocklemünd/Mengenich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50765,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Chorweiler",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50935,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Sülz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50769,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Merkenich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50733,
    "Stadt": "Köln",
    "Stadtteil": "Köln-Nippes",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50169,
    "Stadt": "Kerpen/Rhein-Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50170,
    "Stadt": "Kerpen/Rhein-Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 50171,
    "Stadt": "Kerpen/Rhein-Erft",
    "Stadtteil": "",
    "Landkreis": "Rhein-Erft-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52353,
    "Stadt": "Düren",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52351,
    "Stadt": "Düren",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52349,
    "Stadt": "Düren",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52355,
    "Stadt": "Düren",
    "Stadtteil": "",
    "Landkreis": "Kreis Düren",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53840,
    "Stadt": "Troisdorf",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53842,
    "Stadt": "Troisdorf",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53844,
    "Stadt": "Troisdorf",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53123,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Lessenich/Meßdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53127,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Lengsdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53121,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Dransdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53113,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Gronau",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53115,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Weststadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53119,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Tannenbusch",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53117,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Buschdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53225,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Geislar",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53229,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Pützchen/Bechlinghoven",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53227,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Limperich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53175,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Friesdorf",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53173,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Villenviertel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53179,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Mehlem",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53125,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Röttgen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53177,
    "Stadt": "Bonn",
    "Stadtteil": "Bonn-Schweinheim",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49536,
    "Stadt": "Lienen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52223,
    "Stadt": "Stolberg/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52224,
    "Stadt": "Stolberg/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52222,
    "Stadt": "Stolberg/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46045,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Oberhausen-Mittel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32469,
    "Stadt": "Petershagen/Weser",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32791,
    "Stadt": "Lage/Lippe",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49509,
    "Stadt": "Recke",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49497,
    "Stadt": "Mettingen",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49492,
    "Stadt": "Westerkappeln",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32699,
    "Stadt": "Extertal",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48496,
    "Stadt": "Hopsten",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58710,
    "Stadt": "Menden/Sauerland",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58708,
    "Stadt": "Menden/Sauerland",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58706,
    "Stadt": "Menden/Sauerland",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58135,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58089,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58093,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58091,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58095,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58099,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58097,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58119,
    "Stadt": "Hagen",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53859,
    "Stadt": "Niederkassel",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53332,
    "Stadt": "Bornheim/Rhein",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46145,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Sterkrade-Mitte",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46149,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Buschhausen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46147,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Holten",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46047,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Dümpten",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46117,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Eisenheim-Stemmersberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46119,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Rothebusch",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46049,
    "Stadt": "Oberhausen",
    "Stadtteil": "Oberhausen-Lirich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53919,
    "Stadt": "Weilerswist",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59071,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59073,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59067,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59077,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59069,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59063,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59065,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59075,
    "Stadt": "Hamm",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32694,
    "Stadt": "Dörentrup",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53909,
    "Stadt": "Zülpich",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 52156,
    "Stadt": "Monschau",
    "Stadtteil": "",
    "Landkreis": "Städteregion Aachen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53937,
    "Stadt": "Schleiden",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53902,
    "Stadt": "Bad Münstereifel",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53894,
    "Stadt": "Mechernich",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41849,
    "Stadt": "Wassenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48143,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Dom",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41844,
    "Stadt": "Wegberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41812,
    "Stadt": "Erkelenz",
    "Stadtteil": "",
    "Landkreis": "Kreis Heinsberg",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32689,
    "Stadt": "Kalletal",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47906,
    "Stadt": "Kempen",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47929,
    "Stadt": "Grefrath",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47669,
    "Stadt": "Wachtendonk",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47638,
    "Stadt": "Straelen",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59519,
    "Stadt": "Möhnesee",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59469,
    "Stadt": "Ense",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58840,
    "Stadt": "Plettenberg",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58809,
    "Stadt": "Neuenrade",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32657,
    "Stadt": "Lemgo",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58802,
    "Stadt": "Balve",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58791,
    "Stadt": "Werdohl",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58739,
    "Stadt": "Wickede (Ruhr)",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58675,
    "Stadt": "Hemer",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 34431,
    "Stadt": "Marsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46348,
    "Stadt": "Raesfeld",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 37696,
    "Stadt": "Marienmünster",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 42929,
    "Stadt": "Wermelskirchen",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59457,
    "Stadt": "Werl",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 37688,
    "Stadt": "Beverungen",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59439,
    "Stadt": "Holzwickede",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58730,
    "Stadt": "Fröndenberg/Ruhr",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58239,
    "Stadt": "Schwerte",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51399,
    "Stadt": "Burscheid/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51519,
    "Stadt": "Odenthal",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51515,
    "Stadt": "Kürten",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51491,
    "Stadt": "Overath",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51503,
    "Stadt": "Rösrath",
    "Stadtteil": "",
    "Landkreis": "Rheinisch-Bergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51570,
    "Stadt": "Windeck",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58285,
    "Stadt": "Gevelsberg",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 37671,
    "Stadt": "Höxter",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58332,
    "Stadt": "Schwelm",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58256,
    "Stadt": "Ennepetal",
    "Stadtteil": "",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59425,
    "Stadt": "Unna",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59427,
    "Stadt": "Unna",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59423,
    "Stadt": "Unna",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 40764,
    "Stadt": "Langenfeld/Rheinland",
    "Stadtteil": "",
    "Landkreis": "Kreis Mettmann",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58515,
    "Stadt": "Lüdenscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58511,
    "Stadt": "Lüdenscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58507,
    "Stadt": "Lüdenscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58509,
    "Stadt": "Lüdenscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58513,
    "Stadt": "Lüdenscheid",
    "Stadtteil": "",
    "Landkreis": "Märkischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53819,
    "Stadt": "Neunkirchen-Seelscheid",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53879,
    "Stadt": "Euskirchen",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53881,
    "Stadt": "Euskirchen",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51645,
    "Stadt": "Gummersbach",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51647,
    "Stadt": "Gummersbach",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 51643,
    "Stadt": "Gummersbach",
    "Stadtteil": "",
    "Landkreis": "Oberbergischer Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32609,
    "Stadt": "Hüllhorst",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32602,
    "Stadt": "Vlotho",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46535,
    "Stadt": "Dinslaken",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46539,
    "Stadt": "Dinslaken",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46537,
    "Stadt": "Dinslaken",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32584,
    "Stadt": "Löhne",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32457,
    "Stadt": "Porta Westfalica",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32289,
    "Stadt": "Rödinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32278,
    "Stadt": "Kirchlengern",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32257,
    "Stadt": "Bünde",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 34439,
    "Stadt": "Willebadessen",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32139,
    "Stadt": "Spenge",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32130,
    "Stadt": "Enger",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59199,
    "Stadt": "Bönen",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59757,
    "Stadt": "Arnsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59755,
    "Stadt": "Arnsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59821,
    "Stadt": "Arnsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59759,
    "Stadt": "Arnsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59823,
    "Stadt": "Arnsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32120,
    "Stadt": "Hiddenhausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53804,
    "Stadt": "Much",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53809,
    "Stadt": "Ruppichteroth",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53783,
    "Stadt": "Eitorf",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49479,
    "Stadt": "Ibbenbüren",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 49477,
    "Stadt": "Ibbenbüren",
    "Stadtteil": "",
    "Landkreis": "Kreis Steinfurt",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53757,
    "Stadt": "Sankt Augustin",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59557,
    "Stadt": "Lippstadt",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59556,
    "Stadt": "Lippstadt",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59555,
    "Stadt": "Lippstadt",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59558,
    "Stadt": "Lippstadt",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53773,
    "Stadt": "Hennef/Sieg",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45768,
    "Stadt": "Marl/Westfalen",
    "Stadtteil": "Marl/Westfalen-Polsum",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45770,
    "Stadt": "Marl/Westfalen",
    "Stadtteil": "Marl/Westfalen-Sinsen-Lenkerbeck",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45772,
    "Stadt": "Marl/Westfalen",
    "Stadtteil": "Marl/Westfalen-Chemiezone",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53639,
    "Stadt": "Königswinter",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 34434,
    "Stadt": "Borgentreich",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46397,
    "Stadt": "Bocholt",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46395,
    "Stadt": "Bocholt",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46399,
    "Stadt": "Bocholt",
    "Stadtteil": "",
    "Landkreis": "Kreis Borken",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48147,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Rumphorst",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59229,
    "Stadt": "Ahlen",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59227,
    "Stadt": "Ahlen",
    "Stadtteil": "",
    "Landkreis": "Kreis Warendorf",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44536,
    "Stadt": "Lünen",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44534,
    "Stadt": "Lünen",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44532,
    "Stadt": "Lünen",
    "Stadtteil": "",
    "Landkreis": "Kreis Unna",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46282,
    "Stadt": "Dorsten",
    "Stadtteil": "Dorsten-Feldmark",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46282,
    "Stadt": "Dorsten",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46284,
    "Stadt": "Dorsten",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46286,
    "Stadt": "Dorsten",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46284,
    "Stadt": "Dorsten",
    "Stadtteil": "Dorsten-Holsterhausen",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48149,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Kreutz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48151,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Aaseestadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48145,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Mauritz-West",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48155,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Hafen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48157,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Gelmer-Dyckburg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48167,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Angelmodde",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48159,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Sprakel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48153,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Josef",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48165,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Hiltrup",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48161,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Nienberge",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45661,
    "Stadt": "Recklinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45663,
    "Stadt": "Recklinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45659,
    "Stadt": "Recklinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45657,
    "Stadt": "Recklinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 45665,
    "Stadt": "Recklinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 34414,
    "Stadt": "Warburg",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46286,
    "Stadt": "Dorsten",
    "Stadtteil": "Dorsten-Rhade",
    "Landkreis": "Kreis Recklinghausen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32825,
    "Stadt": "Blomberg/Lippe",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32816,
    "Stadt": "Schieder-Schwalenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32676,
    "Stadt": "Lügde",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 46499,
    "Stadt": "Hamminkeln",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47053,
    "Stadt": "Duisburg",
    "Stadtteil": "Duisburg-Hochfeld",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53343,
    "Stadt": "Wachtberg",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53340,
    "Stadt": "Meckenheim/Rhein",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53359,
    "Stadt": "Rheinbach",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53913,
    "Stadt": "Swisttal",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53347,
    "Stadt": "Alfter",
    "Stadtteil": "",
    "Landkreis": "Rhein-Sieg-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53925,
    "Stadt": "Kall",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53947,
    "Stadt": "Nettersheim",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53940,
    "Stadt": "Hellenthal",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53949,
    "Stadt": "Dahlem b. Kall",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 53945,
    "Stadt": "Blankenheim/Ahr",
    "Stadtteil": "",
    "Landkreis": "Kreis Euskirchen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41334,
    "Stadt": "Nettetal",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41379,
    "Stadt": "Brüggen/Niederrhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41366,
    "Stadt": "Schwalmtal/Niederrhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41372,
    "Stadt": "Niederkrüchten",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41363,
    "Stadt": "Jüchen",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 41352,
    "Stadt": "Korschenbroich",
    "Stadtteil": "",
    "Landkreis": "Rhein-Kreis Neuss",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47877,
    "Stadt": "Willich",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47918,
    "Stadt": "Tönisvorst",
    "Stadtteil": "",
    "Landkreis": "Kreis Viersen",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47647,
    "Stadt": "Kerken",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32361,
    "Stadt": "Preußisch Oldendorf",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32339,
    "Stadt": "Espelkamp",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32312,
    "Stadt": "Lübbecke",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47509,
    "Stadt": "Rheurdt",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47506,
    "Stadt": "Neukirchen-Vluyn",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47475,
    "Stadt": "Kamp-Lintfort",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47661,
    "Stadt": "Issum",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47608,
    "Stadt": "Geldern",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47665,
    "Stadt": "Sonsbeck",
    "Stadtteil": "",
    "Landkreis": "Kreis Wesel",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47652,
    "Stadt": "Weeze",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47589,
    "Stadt": "Uedem",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47546,
    "Stadt": "Kalkar",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47551,
    "Stadt": "Bedburg-Hau",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47533,
    "Stadt": "Kleve/Niederrhein",
    "Stadtteil": "Kleve/Niederrhein-Wardhausen",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47574,
    "Stadt": "Goch",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 47559,
    "Stadt": "Kranenburg/Niederrhein",
    "Stadtteil": "",
    "Landkreis": "Kreis Kleve",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59939,
    "Stadt": "Olsberg",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59929,
    "Stadt": "Brilon",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59909,
    "Stadt": "Bestwig",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59846,
    "Stadt": "Sundern/Sauerland",
    "Stadtteil": "",
    "Landkreis": "Hochsauerlandkreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59602,
    "Stadt": "Rüthen",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 59581,
    "Stadt": "Warstein",
    "Stadtteil": "",
    "Landkreis": "Kreis Soest",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33175,
    "Stadt": "Bad Lippspringe",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32429,
    "Stadt": "Minden/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32425,
    "Stadt": "Minden/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32427,
    "Stadt": "Minden/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32423,
    "Stadt": "Minden/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 58453,
    "Stadt": "Witten",
    "Stadtteil": "Witten-Annen",
    "Landkreis": "Ennepe-Ruhr-Kreis",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 48163,
    "Stadt": "Münster",
    "Stadtteil": "Münster-Amelsbüren",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57072,
    "Stadt": "Siegen",
    "Stadtteil": "Siegen-Seelbach",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57076,
    "Stadt": "Siegen",
    "Stadtteil": "Siegen-Weidenau",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57074,
    "Stadt": "Siegen",
    "Stadtteil": "Siegen-Feuersbach",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57078,
    "Stadt": "Siegen",
    "Stadtteil": "Siegen-Meiswinkel",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 57080,
    "Stadt": "Siegen",
    "Stadtteil": "Siegen-Eiserfeld",
    "Landkreis": "Kreis Siegen-Wittgenstein",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32545,
    "Stadt": "Bad Oeynhausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32549,
    "Stadt": "Bad Oeynhausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32547,
    "Stadt": "Bad Oeynhausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Minden-Lübbecke",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32760,
    "Stadt": "Detmold",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32758,
    "Stadt": "Detmold",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32756,
    "Stadt": "Detmold",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32105,
    "Stadt": "Bad Salzuflen",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32107,
    "Stadt": "Bad Salzuflen",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32108,
    "Stadt": "Bad Salzuflen",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44328,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Scharnhorst",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33098,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33100,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33104,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33102,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33161,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33154,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33106,
    "Stadt": "Paderborn",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33790,
    "Stadt": "Halle/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33689,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Dalbke",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33428,
    "Stadt": "Harsewinkel",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33165,
    "Stadt": "Lichtenau/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33142,
    "Stadt": "Büren",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33415,
    "Stadt": "Verl",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33739,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Jöllenbeck",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33014,
    "Stadt": "Bad Driburg",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44139,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Mengede",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44229,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Bittermark",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44267,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Benninghofen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44269,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Schüren",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44329,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Lanstrop",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44309,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Brackel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44147,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Hafen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44263,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Hörde",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44357,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Oestrich",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44379,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Kirchlinde",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44388,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Lütgendortmund",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44143,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Kaiserbrunnen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44135,
    "Stadt": "Dortmund",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44319,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Wickede",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44287,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Aplerbeck",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44359,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Nette",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44265,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Hacheney",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44339,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Brechten",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44141,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Westfalendamm",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44289,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Sölderholz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44137,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Westfalenhalle",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44227,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Persebeck-Kruckel-Schnee",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44225,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Barop",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44145,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Nordmarkt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44149,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Oespel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 44369,
    "Stadt": "Dortmund",
    "Stadtteil": "Dortmund-Deusen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32051,
    "Stadt": "Herford",
    "Stadtteil": "Herford-Herringhausen",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32049,
    "Stadt": "Herford",
    "Stadtteil": "Herford-Falkendiek",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32049,
    "Stadt": "Herford",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32052,
    "Stadt": "Herford",
    "Stadtteil": "",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 32052,
    "Stadt": "Herford",
    "Stadtteil": "Herford-Herford-Stadt",
    "Landkreis": "Kreis Herford",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33605,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Stieghorst",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33607,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Kammerratsheide",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33719,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Heeperholz",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33758,
    "Stadt": "Schloß Holte-Stukenbrock",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33181,
    "Stadt": "Bad Wünnenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33129,
    "Stadt": "Delbrück",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33178,
    "Stadt": "Borchen",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33039,
    "Stadt": "Nieheim",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33829,
    "Stadt": "Borgholzhausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33609,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Baumheide",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33611,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Schildesche",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33617,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Bethel",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33619,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Großdornberg",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33613,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Sudbrack",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33803,
    "Stadt": "Steinhagen/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33824,
    "Stadt": "Werther/Westfalen",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33378,
    "Stadt": "Rheda-Wiedenbrück",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33602,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Altstadt",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33659,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Schillingshof",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33615,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Bielefelder Westen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33034,
    "Stadt": "Brakel",
    "Stadtteil": "",
    "Landkreis": "Kreis Höxter",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33604,
    "Stadt": "Bielefeld",
    "Stadtteil": "",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33729,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Altenhagen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33184,
    "Stadt": "Altenbeken",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33813,
    "Stadt": "Oerlinghausen",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33818,
    "Stadt": "Leopoldshöhe",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33161,
    "Stadt": "Hövelhof",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33189,
    "Stadt": "Schlangen",
    "Stadtteil": "",
    "Landkreis": "Kreis Lippe",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33649,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Holtkamp",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33699,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Hillegossen",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33397,
    "Stadt": "Rietberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33647,
    "Stadt": "Bielefeld",
    "Stadtteil": "Bielefeld-Brackwede",
    "Landkreis": "",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33154,
    "Stadt": "Salzkotten",
    "Stadtteil": "",
    "Landkreis": "Kreis Paderborn",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33775,
    "Stadt": "Versmold",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33442,
    "Stadt": "Herzebrock-Clarholz",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  },
  {
    "PLZ": 33449,
    "Stadt": "Langenberg",
    "Stadtteil": "",
    "Landkreis": "Kreis Gütersloh",
    "Bundesland": "Nordrhein-Westfalen"
  }];
