import React, { useState } from 'react';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './employeeItem.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';
import DatePicker from 'library/common/commonComponents/DatePicker';
import cn from 'classnames';
import { getLegalBreakTimeFromWorktimInMin } from 'library/utilities/employeeCheckInOut';

export default function EmployeeItem({ workingHours, user, handleSaveWorkingHours, className }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageIndex, setErrorMessageIndex] = useState(null);
  const [showWorkingHours, setShowWorkingHours] = useState(false);
  const [groupedWorkingHours, setGroupedWorkingHours] = useState([
    {
      weekday: t('Administration.Monday'),
      time: [
        {
          name: 'mondayStartTime',
          value: formatTimeObject(workingHours?.mondayStartTime),
          secondShift: 'mondaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.mondaySecondStartTime),
          available: 'mondayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.mondayAvailableStartTime),
        },
        {
          name: 'mondayEndTime',
          value: formatTimeObject(workingHours?.mondayEndTime),
          secondShift: 'mondaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.mondaySecondEndTime),
          available: 'mondayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.mondayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.mondayAvailableStartTime),
        formatTimeObject(workingHours?.mondayAvailableEndTime),
        formatTimeObject(workingHours?.mondayStartTime),
        formatTimeObject(workingHours?.mondayEndTime),
        formatTimeObject(workingHours?.mondaySecondStartTime),
        formatTimeObject(workingHours?.mondaySecondEndTime),
      ),

      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.mondayAvailableStartTime),
        formatTimeObject(workingHours?.mondayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Tuesday'),
      time: [
        {
          name: 'tuesdayStartTime',
          value: formatTimeObject(workingHours?.tuesdayStartTime),
          secondShift: 'tuesdaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.tuesdaySecondStartTime),
          available: 'tuesdayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.tuesdayAvailableStartTime),
        },
        {
          name: 'tuesdayEndTime',
          value: formatTimeObject(workingHours?.tuesdayEndTime),
          secondShift: 'tuesdaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.tuesdaySecondEndTime),
          available: 'tuesdayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.tuesdayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.tuesdayAvailableStartTime),
        formatTimeObject(workingHours?.tuesdayAvailableEndTime),
        formatTimeObject(workingHours?.tuesdayStartTime),
        formatTimeObject(workingHours?.tuesdayEndTime),
        formatTimeObject(workingHours?.tuesdaySecondStartTime),
        formatTimeObject(workingHours?.tuesdaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.tuesdayAvailableStartTime),
        formatTimeObject(workingHours?.tuesdayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Wednesday'),
      time: [
        {
          name: 'wednesdayStartTime',
          value: formatTimeObject(workingHours?.wednesdayStartTime),
          secondShift: 'wednesdaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.wednesdaySecondStartTime),
          available: 'wednesdayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.wednesdayAvailableStartTime),
        },
        {
          name: 'wednesdayEndTime',
          value: formatTimeObject(workingHours?.wednesdayEndTime),
          secondShift: 'wednesdaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.wednesdaySecondEndTime),
          available: 'wednesdayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.wednesdayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.wednesdayAvailableStartTime),
        formatTimeObject(workingHours?.wednesdayAvailableEndTime),
        formatTimeObject(workingHours?.wednesdayStartTime),
        formatTimeObject(workingHours?.wednesdayEndTime),
        formatTimeObject(workingHours?.wednesdaySecondStartTime),
        formatTimeObject(workingHours?.wednesdaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.wednesdayAvailableStartTime),
        formatTimeObject(workingHours?.wednesdayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Thursday'),
      time: [
        {
          name: 'thursdayStartTime',
          value: formatTimeObject(workingHours?.thursdayStartTime),
          secondShift: 'thursdaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.thursdaySecondStartTime),
          available: 'thursdayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.thursdayAvailableStartTime),
        },
        {
          name: 'thursdayEndTime',
          value: formatTimeObject(workingHours?.thursdayEndTime),
          secondShift: 'thursdaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.thursdaySecondEndTime),
          available: 'thursdayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.thursdayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.thursdayAvailableStartTime),
        formatTimeObject(workingHours?.thursdayAvailableEndTime),
        formatTimeObject(workingHours?.thursdayStartTime),
        formatTimeObject(workingHours?.thursdayEndTime),
        formatTimeObject(workingHours?.thursdaySecondStartTime),
        formatTimeObject(workingHours?.thursdaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.thursdayAvailableStartTime),
        formatTimeObject(workingHours?.thursdayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Friday'),
      time: [
        {
          name: 'fridayStartTime',
          value: formatTimeObject(workingHours?.fridayStartTime),
          secondShift: 'fridaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.fridaySecondStartTime),
          available: 'fridayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.fridayAvailableStartTime),
        },
        {
          name: 'fridayEndTime',
          value: formatTimeObject(workingHours?.fridayEndTime),
          secondShift: 'fridaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.fridaySecondEndTime),
          available: 'fridayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.fridayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.fridayAvailableStartTime),
        formatTimeObject(workingHours?.fridayAvailableEndTime),
        formatTimeObject(workingHours?.fridayStartTime),
        formatTimeObject(workingHours?.fridayEndTime),
        formatTimeObject(workingHours?.fridaySecondStartTime),
        formatTimeObject(workingHours?.fridaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.fridayAvailableStartTime),
        formatTimeObject(workingHours?.fridayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Saturday'),
      time: [
        {
          name: 'saturdayStartTime',
          value: formatTimeObject(workingHours?.saturdayStartTime),
          secondShift: 'saturdaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.saturdaySecondStartTime),
          available: 'saturdayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.saturdayAvailableStartTime),
        },
        {
          name: 'saturdayEndTime',
          value: formatTimeObject(workingHours?.saturdayEndTime),
          secondShift: 'saturdaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.saturdaySecondEndTime),
          available: 'saturdayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.saturdayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.thursdayAvailableStartTime),
        formatTimeObject(workingHours?.saturdayAvailableEndTime),
        formatTimeObject(workingHours?.saturdayStartTime),
        formatTimeObject(workingHours?.saturdayEndTime),
        formatTimeObject(workingHours?.saturdaySecondStartTime),
        formatTimeObject(workingHours?.saturdaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.saturdayAvailableStartTime),
        formatTimeObject(workingHours?.saturdayAvailableEndTime),
      ),
    },
    {
      weekday: t('Administration.Sunday'),
      time: [
        {
          name: 'sundayStartTime',
          value: formatTimeObject(workingHours?.sundayStartTime),
          secondShift: 'sundaySecondStartTime',
          secondShiftValue: formatTimeObject(workingHours?.sundaySecondStartTime),
          available: 'sundayAvailableStartTime',
          availableTime: formatTimeObject(workingHours?.sundayAvailableStartTime),
        },
        {
          name: 'sundayEndTime',
          value: formatTimeObject(workingHours?.sundayEndTime),
          secondShift: 'sundaySecondEndTime',
          secondShiftValue: formatTimeObject(workingHours?.sundaySecondEndTime),
          available: 'sundayAvailableEndTime',
          availableTime: formatTimeObject(workingHours?.sundayAvailableEndTime),
        },
      ],
      workingHoursDiff: subtractLegalBreakOfTimeString(
        formatTimeObject(workingHours?.thursdayAvailableStartTime),
        formatTimeObject(workingHours?.sundayAvailableEndTime),
        formatTimeObject(workingHours?.sundayStartTime),
        formatTimeObject(workingHours?.sundayEndTime),
        formatTimeObject(workingHours?.sundaySecondStartTime),
        formatTimeObject(workingHours?.sundaySecondEndTime),
      ),
      availableTimeDiff: calculateTimeDifference(
        formatTimeObject(workingHours?.sundayAvailableStartTime),
        formatTimeObject(workingHours?.sundayAvailableEndTime),
      ),
    },
  ]);
  const [activeFrom, setActiveFrom] = useState(formatDateObject(workingHours?.activeFrom));

  function formatTimeObject(time) {
    if (time === null) {
      return '';
    }
    if (typeof time === 'object') {
      const hours = time.hour.toString().padStart(2, 0);
      const minutes = time.minute.toString().padStart(2, 0);
      return `${hours}:${minutes}`;
    }
    return time;
  }

  function formatDateObject(date) {
    if (!date) return null;
    const { year, monthValue, dayOfMonth } = date;
    return new Date(year, monthValue - 1, dayOfMonth);
  }

  function minsFromTimeString(timeString) {
    if (!timeString) return 0;

    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  }

  const handleWorkingTimeChange = (dayIndex, timeIndex, value, property) => {
    const newGroupedWorkingHours = [...groupedWorkingHours];
    newGroupedWorkingHours[dayIndex].time[timeIndex][property] = value;

    if (property === 'availableTime') {
      newGroupedWorkingHours[dayIndex].availableTimeDiff = calculateTimeDifference(
        newGroupedWorkingHours[dayIndex].time[0].availableTime,
        newGroupedWorkingHours[dayIndex].time[1].availableTime,
      );
    }

    newGroupedWorkingHours[dayIndex].workingHoursDiff = subtractLegalBreakOfTimeString(
      newGroupedWorkingHours[dayIndex].time[0].availableTime,
      newGroupedWorkingHours[dayIndex].time[1].availableTime,
      newGroupedWorkingHours[dayIndex].time[0].value,
      newGroupedWorkingHours[dayIndex].time[1].value,
      newGroupedWorkingHours[dayIndex].time[0].secondShiftValue,
      newGroupedWorkingHours[dayIndex].time[1].secondShiftValue,
    );

    setGroupedWorkingHours(newGroupedWorkingHours);
  };

  function addTimeStrings(time1, time2, time3) {
    // return '00:00' if time1 and time2 is empty
    if (!time1?.trim() && !time2?.trim() && !time3?.trim()) return '00:00';

    let totalMinutes = 0;
    let totalHours = 0;

    totalMinutes += minsFromTimeString(time1);
    totalMinutes += minsFromTimeString(time2);
    totalMinutes += minsFromTimeString(time3);

    totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    // Format the result string
    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  function subtractLegalBreakOfTimeString(
    availableStart,
    availableEnd,
    firstShiftStart,
    firstShiftEnd,
    secondShiftStart,
    secondShiftEnd,
  ) {
    const availableTimeDiff = calculateTimeDifference(
      formatTimeObject(availableStart),
      formatTimeObject(availableEnd),
    );
    const firstShiftDiff = calculateTimeDifference(
      formatTimeObject(firstShiftStart),
      formatTimeObject(firstShiftEnd),
    );
    const secondShiftDiff = calculateTimeDifference(
      formatTimeObject(secondShiftStart),
      formatTimeObject(secondShiftEnd),
    );

    const time = addTimeStrings(availableTimeDiff, firstShiftDiff, secondShiftDiff);

    if (!time?.trim()) {
      return '00:00';
    }

    // calculate the total time between shifts
    const dates = [];
    const addDatesToArr = (start, end) => {
      if (!!start && !!end) {
        const startHours = parseInt(start.split(':')[0]);
        const startMinutes = parseInt(start.split(':')[1]);
        const endHours = parseInt(end.split(':')[0]);
        const endMinutes = parseInt(end.split(':')[1]);

        const startDate = new Date(new Date().setHours(startHours, startMinutes));
        const endDate = new Date(new Date().setHours(endHours, endMinutes));
        dates.push(startDate, endDate);
      }
    };
    addDatesToArr(availableStart, availableEnd);
    addDatesToArr(firstShiftStart, firstShiftEnd);
    addDatesToArr(secondShiftStart, secondShiftEnd);
    // sort all dates ascending
    dates.sort((a, b) => a - b);

    let totalAbsentTime = 0;
    for (let i = 1; i < dates.length; i++) {
      if (i % 2 === 0) {
        const differenceInMs = dates[i] - dates[i - 1];
        totalAbsentTime += differenceInMs / 60000;
      }
    }

    let totalMinutes = minsFromTimeString(time);

    // subtract the absent time from legal break time calculated from worktime, if negative set to 0
    const totalMinsLegalBreak = Math.max(
      getLegalBreakTimeFromWorktimInMin(totalMinutes) - totalAbsentTime,
      0,
    );

    // calculate legal break for each shift
    const availableTimeLegalBreak = getLegalBreakTimeFromWorktimInMin(
      minsFromTimeString(availableTimeDiff),
    );
    const firstTimeLegalBreak = getLegalBreakTimeFromWorktimInMin(
      minsFromTimeString(firstShiftDiff),
    );
    const secondTimeLegalBreak = getLegalBreakTimeFromWorktimInMin(
      minsFromTimeString(secondShiftDiff),
    );
    // max legal break can be 45 minutes
    const shiftLegalBreak = Math.min(
      availableTimeLegalBreak + firstTimeLegalBreak + secondTimeLegalBreak,
      45,
    );

    // use the max legal break, so when shifts are longer than 6 hours a legal break is guaranteed to be subtracted
    // should not exceed 45 minutes
    const legalBreak = Math.min(Math.max(totalMinsLegalBreak, shiftLegalBreak), 45);
    totalMinutes -= legalBreak;

    const totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  }

  function calculateTimeDifference(startTime, endTime) {
    if (startTime?.length > 0 && endTime?.length > 0 && startTime && endTime) {
      // Split the time strings into hours and minutes
      const startHours = parseInt(startTime.split(':')[0]);
      const startMinutes = parseInt(startTime.split(':')[1]);
      const endHours = parseInt(endTime.split(':')[0]);
      const endMinutes = parseInt(endTime.split(':')[1]);

      // Calculate the difference in minutes
      let minuteDifference = endHours * 60 + endMinutes - (startHours * 60 + startMinutes);

      // Handle negative difference (when end time is before start time)
      if (minuteDifference < 0) {
        minuteDifference += 24 * 60; // Add total minutes in a day
      }

      // Convert minutes back to hours and minutes for display
      const hoursDifference = Math.floor(minuteDifference / 60);
      const remainingMinutes = minuteDifference % 60;

      // Format the difference string (e.g., "02:00")
      const formattedDifference = `${hoursDifference
        .toString()
        .padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;

      // **Return the formatted string directly**
      return formattedDifference;
    }
  }

  const isValid = (day, dayIndex) => {
    const startFirstShift = day.time[0].value;
    const endFirstShift = day.time[1].value;
    const startSecondShift = day.time[0].secondShiftValue;
    const endSecondShift = day.time[1].secondShiftValue;
    const startAvailable = day.time[0].availableTime;
    const endAvailable = day.time[1].availableTime;

    const parseTime = time => parseInt(time.replace(':', ''), 10);

    const isValidTimeRange = (start, end) => {
      if (!!start && !!end) {
        return true;
      }
      // both start and end must be set
      if (!!start || !!end) {
        setErrorMessage(
          t('Administration.UserSection.Both start and end working time must be set.'),
        );
        setErrorMessageIndex(dayIndex);
        return false;
      }

      const startInt = parseTime(start);
      const endInt = parseTime(end);
      if (endInt - startInt <= 0) {
        setErrorMessage(t('Administration.End of working time has to be be later than start time'));
        setErrorMessageIndex(dayIndex);
        return false;
      }

      return true;
    };

    if (
      !isValidTimeRange(startFirstShift, endFirstShift) ||
      !isValidTimeRange(startSecondShift, endSecondShift) ||
      !isValidTimeRange(startAvailable, endAvailable)
    ) {
      return false;
    }

    const hasFirstShift = !!startFirstShift;
    const hasSecondShift = !!startSecondShift;
    const hasAvailableTime = !!startAvailable;

    if (!hasFirstShift && hasSecondShift) {
      setErrorMessage(t('Administration.First shift must be set when second shift is set'));
      setErrorMessageIndex(dayIndex);
      return false;
    }

    if (hasFirstShift && hasSecondShift) {
      if (parseTime(endFirstShift) > parseTime(startSecondShift)) {
        setErrorMessage(
          t('Administration.First shift must be before second shift and cannot overlap'),
        );
        setErrorMessageIndex(dayIndex);
        return false;
      }
    }

    if (hasAvailableTime) {
      if (
        hasFirstShift &&
        parseTime(startAvailable) < parseTime(endFirstShift) &&
        parseTime(endAvailable) > parseTime(startFirstShift)
      ) {
        setErrorMessage(t('Administration.Available time shift cannot overlap with first shift'));
        setErrorMessageIndex(dayIndex);
        return false;
      }
      if (
        hasSecondShift &&
        parseTime(startAvailable) < parseTime(endSecondShift) &&
        parseTime(endAvailable) > parseTime(startSecondShift)
      ) {
        setErrorMessage(t('Administration.Available time shift cannot overlap with second shift'));
        setErrorMessageIndex(dayIndex);
        return false;
      }
    }

    return true;
  };

  const formatDateToIso = date => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSaveButton = () => {
    const newWorkingHours = { userId: user.id, activeFrom: formatDateToIso(activeFrom) };
    let error = false;
    groupedWorkingHours.every((day, dayIndex) => {
      if (!isValid(day, dayIndex)) {
        error = true;
        return false;
      } else {
        day.time.forEach(
          timeEntry => (
            (newWorkingHours[timeEntry.name] = timeEntry.value),
            (newWorkingHours[timeEntry.secondShift] = timeEntry.secondShiftValue),
            (newWorkingHours[timeEntry.available] = timeEntry.availableTime)
          ),
        );
        return true;
      }
    });
    if (error) {
      return;
    }
    handleSaveWorkingHours(newWorkingHours);
  };

  const getTotalHours = property => {
    let totalMinutes = 0;
    let totalHours = 0;

    groupedWorkingHours.forEach(day => {
      if (!day[property]) return;
      totalMinutes += parseInt(day[property].split(':')[0]) * 60;
      totalMinutes += parseInt(day[property].split(':')[1]);
    });

    totalHours = Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className={cn(className, styles.employeeItemContainer)}>
      <div className={styles.employee} onClick={() => setShowWorkingHours(!showWorkingHours)}>
        <div>
          <img
            className={styles.profileAvatar}
            src={user.modifiedProfileLogoUrl || defaultAvatar}
          />
        </div>
        <div className={styles.profileName}>{`${user.firstName} ${user.lastName}`}</div>

        <div className={styles.weeklyWorkingTime}>
          <i className='fa fa-lg fa-clock-o' />
          <div>
            <span className={styles.workingTimeLabel}>
              {t('Administration.Hours per week') + ' ' + getTotalHours('workingHoursDiff')}
            </span>
            <br />
            {`(${t('Administration.Of which available hours')} ${getTotalHours(
              'availableTimeDiff',
            )})`}
          </div>
        </div>

        <div className={styles.expandIcon}>
          <i className={showWorkingHours ? 'fa fa-lg fa-chevron-up' : 'fa fa-lg fa-chevron-down'} />
        </div>
      </div>

      {showWorkingHours ? (
        <>
          <div className={styles.weekContainer}>
            {groupedWorkingHours.map((day, dayIndex) => {
              return (
                <div
                  className={cn(
                    styles.weekdayContainer,
                    dayIndex === errorMessageIndex && styles.highlightError,
                  )}
                  key={day.weekday}
                >
                  <div className={styles.weekdayHeading}>
                    <span className={styles.weekdayLabel}>{day.weekday}</span>
                    <span className={styles.weekdayHours}>
                      <span className={styles.workingTimeLabel}>
                        {t('TimeSettings.Working Hours') + ' ' + day.workingHoursDiff}
                      </span>
                      {!!day.availableTimeDiff &&
                        day.availableTimeDiff !== '00:00' &&
                        ` (${t('Administration.Of which available hours')} ${
                          day.availableTimeDiff
                        })`}
                    </span>
                  </div>

                  <div className={styles.weekdayRow}>
                    <fieldset>
                      <legend>{t('Administration.Working time shift') + ' 1'}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].name}>
                            {t('Administration.UserSection.Start of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].value}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 0, e.target.value, 'value');
                            }}
                            id={day.time[0].name}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].name}>
                            {t('Administration.UserSection.End of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].value}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 1, e.target.value, 'value');
                            }}
                            id={day.time[1].name}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{t('Administration.Working time shift') + ' 2'}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].secondShift}>
                            {t('Administration.UserSection.Start of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].secondShiftValue}
                            onChange={e => {
                              handleWorkingTimeChange(
                                dayIndex,
                                0,
                                e.target.value,
                                'secondShiftValue',
                              );
                            }}
                            id={day.time[0].secondShift}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].secondShift}>
                            {t('Administration.UserSection.End of work')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].secondShiftValue}
                            onChange={e => {
                              handleWorkingTimeChange(
                                dayIndex,
                                1,
                                e.target.value,
                                'secondShiftValue',
                              );
                            }}
                            id={day.time[1].secondShift}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{t('TimeSettings.Verfügungszeit')}</legend>
                      <div className={styles.workingTime}>
                        <div>
                          <label htmlFor={day.time[0].available}>
                            {t('AccountSettings.AbsenceSettings.From time')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[0].availableTime}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 0, e.target.value, 'availableTime');
                            }}
                            id={day.time[0].available}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                        <div>
                          <label htmlFor={day.time[1].available}>
                            {t('AccountSettings.AbsenceSettings.To time')}
                          </label>
                          <Input
                            type='time'
                            value={day.time[1].availableTime}
                            onChange={e => {
                              handleWorkingTimeChange(dayIndex, 1, e.target.value, 'availableTime');
                            }}
                            id={day.time[1].available}
                            min='00:00'
                            max='23:59'
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.datePickerWrapper}>
            <i className='fa fa-lg fa-calendar' aria-hidden='true' />
            <label htmlFor='activeFrom'>{t('Administration.Time recording start')}</label>
            <DatePicker
              id='activeFrom'
              selected={activeFrom}
              onChange={date => setActiveFrom(date)}
              dateFormat='dd.MM.yyyy'
              dropdownMode='select'
              langCode={user.currentWatsonLang}
              minDate={new Date()}
            />
          </div>

          {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
          <Button onClick={handleSaveButton} type='primary' className={styles.saveButton}>
            <i className='fa fa-check' aria-hidden='true' /> {t('Administration.UserSection.Save')}
          </Button>
        </>
      ) : null}
    </div>
  );
}
