import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'library/common/commonComponents/Buttons/Button';
import HelpText from 'library/common/commonComponents/HelpText';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import Pagination from 'library/common/commonComponents/Pagination';
import Loader from 'library/common/commonComponents/Loader';
import GroupsFrameListTable from './groupsFrameListFrames/GroupsFrameListTable';
import useGroupsList from './hooks/useGroupsList';

import styles from './groupsFrameList.module.scss';

export default function GroupsFrameList({
  setCreateGroupMode,
  kitas,
  selectedKita,
  setSelectedKita,
  superAdminStatus,
  onlyPortfolioGroups,
}) {
  const {
    groups,
    setPage,
    isLoading,
    history,
    location,
    totalGroupCount,
    searchValue,
    setSearchValue,
    setSorting,
    sort,
    options,
    selectedOption,
    setSelectedOption,
  } = useGroupsList(selectedKita, onlyPortfolioGroups);

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.title}>Overview</div>
      <div className={styles.text}>
        <HelpText>
          {t(
            // eslint-disable-next-line
            'Administration.SpacesSection.This overview contains a list of each space with actions to view, edit and delete groups',
          )}
        </HelpText>
      </div>
      <Button
        type='success'
        size='sm'
        onClick={() => setCreateGroupMode(true)}
        className={styles.button}
      >
        <i className='fa fa-plus' /> {t('Administration.SpacesSection.Add new group')}
      </Button>

      <div className={styles.filters}>
        <div className={styles.filtersSearch}>
          <Input
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={styles.filtersSearchInput}
            placeholder={t('Administration.SpacesSection.Input placeholder')}
          />
        </div>
        <div className={styles.filtersVisibility}>
          <Select options={options} onSelect={setSelectedOption} selected={selectedOption} />
        </div>
        {superAdminStatus && (
          <div className={styles.filtersKita}>
            <Select options={kitas} onSelect={setSelectedKita} selected={selectedKita} />
          </div>
        )}
      </div>

      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          {totalGroupCount / 50 > 1 && (
            <Pagination
              currentPage={parseInt(location.pathname.slice(23), 10)}
              history={history}
              numberOfPages={totalGroupCount ? Math.ceil(totalGroupCount / 50) : 1}
              onPageClick={setPage}
            />
          )}
          <GroupsFrameListTable groups={groups} sort={sort} setSorting={setSorting} />
        </>
      )}
    </div>
  );
}
