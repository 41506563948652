import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { sanitize } from 'dompurify';

import { getImprint, createImprint, getAllKitasWithAllData } from 'library/api/kita';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Button from 'library/common/commonComponents/Buttons/Button';
import AppLogo from 'library/common/commonComponents/AppLogo';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Loader from 'library/common/commonComponents/Loader';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CreateKita from 'library/common/commonComponents/CreateKita/CreateKitaContainer';
import store from 'main/store/configureStore';
import Card from 'library/common/commonComponents/Card';
import ImprintFeed from 'modules/DataProtection/DataProtectionFeed';

import styles from './ImprintModule.module.scss';

export default function ImprintModule({ isLoggedIn, isAdmin, activeKita, user }) {
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { history } = useReactRouter();
  const { t } = useTranslation();

  if (
    (window.location.hostname.toLowerCase().includes('awoconnect.safe2connect.org') ||
      window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org')) &&
    user.superAdminStatus === false
  ) {
    isAdmin = false;
  }

  const [imprint, setImprint] = useState([]);
  const [stand, setStand] = useState(false);
  const [own, setOwn] = useState(false);
  const [both, setBoth] = useState(false);
  const [option, setOption] = useState('stand');
  const [kitas, setKitas] = useState([]);

  const [currentKita, setCurrentKita] = useState();

  const linkItIsiDefaultDataProtectionResponsibleBody = {
    dPCompany: 'Link IT isi GmbH',
    dPName: 'Sebastian Kopp',
    dPStreet: 'Kleestraße 21-23',
    dPZip: '90461',
    dPCity: 'Nürnberg',
    dPPhone: '+49 911 14609820',
    dPMail: 'info@link-it-isi.de',
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      getAllKitasWithAllData().then(res => {
        setKitas(res.data.content);
      });

      getImprint().then(res => {
        setImprint(res.data);
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    kitas.map(kita => {
      if (activeKita.kitaId === kita.id) {
        setCurrentKita(kita);
      }
    });
  });

  useEffect(() => {
    if (imprint.setting === 'own') {
      setOption('own');
    } else if (imprint.setting === 'both') {
      setOption('both');
    } else {
      setOption('stand');
    }
  }, [imprint]);

  if (activeKita.virtual === true) {
    return (
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div
                style={{
                  background: '#fff',
                  padding: '30px',
                  boxShadow: !isLoggedIn && '0 0 15px #627d92',
                  margin: !isLoggedIn && '50px 0',
                }}
              >
                {t('DataProtection.Please select a institution to see the imprint')}
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper>
    );
  }
  const onStandCheckHandler = () => {
    if (stand === false) {
      setStand(true);
      setOwn(false);
      setBoth(false);
      setOption('standard');
    }
  };

  const onOwnCheckHandler = () => {
    if (own === false) {
      setOwn(true);
      setStand(false);
      setBoth(false);
      setOption('own');
    }
  };

  const onBothCheckHandler = () => {
    if (both === false) {
      setBoth(true);
      setStand(false);
      setOwn(false);
      setOption('both');
    }
  };

  let dPName = 'Name';
  let dPCompany = 'Firmenname';
  let dPStreet = 'Straße';
  let dPZip = 'Postleitzahl';
  let dPCity = 'Stadt';
  let dPPhone = 'Telefonnummer';
  let dPMail = 'eMail Adresse';

  if (Object.keys(activeKita).length > 0 && isLoggedIn) {
    if (activeKita.dataProcessingName !== null) {
      dPCompany = activeKita.dataProcessingCompany;
      dPName = activeKita.dataProcessingName;
      dPStreet = activeKita.dataProcessingStreet;
      dPZip = activeKita.dataProcessingZip;
      dPCity = activeKita.dataProcessingCity;
      dPPhone = activeKita.dataProcessingPhone;
      dPMail = activeKita.dataProcessingEmail;
    }
  } else {
    dPCompany = linkItIsiDefaultDataProtectionResponsibleBody.dPCompany;
    dPName = linkItIsiDefaultDataProtectionResponsibleBody.dPName;
    dPStreet = linkItIsiDefaultDataProtectionResponsibleBody.dPStreet;
    dPZip = linkItIsiDefaultDataProtectionResponsibleBody.dPZip;
    dPCity = linkItIsiDefaultDataProtectionResponsibleBody.dPCity;
    dPPhone = linkItIsiDefaultDataProtectionResponsibleBody.dPPhone;
    dPMail = linkItIsiDefaultDataProtectionResponsibleBody.dPMail;
  }

  const awobambergImprint = (
    <Wrapper>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <AppLogo />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button onClick={() => history.goBack()}>
          <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
        </Button>
      </div>
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            <h2>Herausgeber</h2>
            <br />
            <p>Arbeiterwohlfahrt Kreisverband</p>
            <p>Bamberg Stadt und Land e. V.</p>
            <br />
            <p>Hauptsmoorstraße 26 a</p>
            <p>96052 Bamberg</p>
            <br />
            <p>Telefon (09 51) 40 74 - 0</p>
            <p>Telefax (09 51) 40 74 - 410</p>
            <p>info@awo-bamberg.de</p>
            <br />
            <p>
              <a href='www.awo-bamberg.de'>www.awo-bamberg.de</a>
            </p>
            <br />
            <p>
              <b>Kreisvorsitzender</b>
            </p>
            <p>Klaus Stieringer </p>
            <br />
            <p>
              <b>Stellvertretende Kreisvorsitzende</b>
            </p>
            <p>Carsten Joneitis, Jonas Merzbacher </p>
            <br />
            <p>
              <b>Geschäftsführer</b>
            </p>
            <p>Matthias Kirsch</p>
            <br />
            <p>
              <b>Presserechtlich verantwortlich i.S.d. § 18 Abs. 2 MStV:</b>
            </p>
            <p>Matthias Kirsch (Anschrift siehe oben)</p>
            <br />
            <p>Vereinsregister: Bamberg VR 566</p>
            <p>Steuernummer: 207 / 107 / 10040</p>
          </div>
        </Row>
      </Col>
    </Wrapper>
  );

  const awoconnectImprint = (
    <Wrapper>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <AppLogo />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button onClick={() => history.goBack()}>
          <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
        </Button>
      </div>
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            <p>
              <b style={{ fontSize: '24pt' }}>Impressum</b>
            </p>
            <br />
            <p>
              <b style={{ fontSize: '18pt' }}>Angaben gemäß § 5 Telemediengesetz (TMG)</b>
            </p>
            <br />
            <p>Arbeiterwohlfahrt Unterbezirk Münsterland-Recklinghausen</p>
            <p>Clemensstraße 2-4</p>
            <p>45699 Herten</p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Vertreten durch</b>
            </p>
            <p>Vorsitzender: Christian Bugzel</p>
            <p>Geschäftsführerinnen: Melanie Angermund, Melanie Queck</p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Kontakt</b>
            </p>
            <br />
            <p>Telefon: 02366 10910</p>
            <p>Telefax: 02366 109160</p>
            <p>
              E-Mail: <a href='mailto: info@awo-msl-re.de'>info@awo-msl-re.de</a>
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Registereintrag</b>
            </p>
            <br />
            <p>Mitglied der AWO Bezirk Westliches Westfalen e.V.</p>
            <p>Eintragung im Vereinsregister.</p>
            <p>Registergericht: Amtsgericht Dortmund</p>
            <p>Registernummer: VR 1598</p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Umsatzsteuer-ID</b>
            </p>
            <br />
            <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</p>
            <p>DE 260 975 602</p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>
                Verantwortlich für den Inhalt nach § 55 Abs. 2 Rundfunkstaatsvertrag (RStV)
              </b>
            </p>
            <br />
            <p>Melanie Angermund, Melanie Queck</p>
            <p>Anschrift wie oben</p>
            <br />
            <p>
              <b style={{ fontSize: '18pt' }}>Haftungsausschluss</b>
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Inhalt des Onlineangebotes</b>
            </p>
            <br />
            <p>
              Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit
              oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor,
              welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung
              oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter
              und unvollständiger Informationen verursacht wurden, sind grundsätzlich
              ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob
              fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich.
              Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot
              ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
              Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Verweise und Links</b>
            </p>
            <br />
            <p>
              Bei direkten oder indirekten Verweisen auf fremde Webseiten (Hyperlinks), die
              außerhalb des Verantwortungsbereiches des Autors liegen, würde eine
              Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von
              den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung
              im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich,
              dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden
              Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder
              die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss.
              Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten
              /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung
              gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise
              sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen,
              Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf
              deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
              unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder
              Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der
              Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf
              die jeweilige Veröffentlichung lediglich verweist.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Urheber- und Kennzeichenrecht</b>
            </p>
            <br />
            <p>
              Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten
              Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte
              Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie
              Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des
              Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen
              unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts
              und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der
              bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte
              Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte
              Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung
              solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen
              oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht
              gestattet.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Datenschutz</b>
            </p>
            <br />
            <p>
              Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder
              geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die
              Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die
              Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich
              und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten
              oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder
              vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und
              Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich
              angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die
              Versender von sogenannten Spam-Mails bei Verstößen gegen dieses Verbot sind
              ausdrücklich vorbehalten.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '13pt' }}>Rechtswirksamkeit dieses Haftungsausschlusses</b>
            </p>
            <br />
            <p>
              Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem
              aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses
              Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
              sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
              davon unberührt.
            </p>
          </div>
        </Row>
      </Col>
    </Wrapper>
  );

  const stramplerbandeImprint = (
    <React.Fragment>
      <div className='panel-body'>
        <div className='markdown-render'>
          <Card>
            <p>
              <strong>
                {t('DataProtection.The responsible body for data processing on this website is')}:
              </strong>
              <br />
              {isLoggedIn ? (
                <div>
                  {dPCompany !== null && <div>{dPCompany}</div>}
                  {dPName !== null && <div>{dPName}</div>}
                  {dPStreet !== null && <div>{dPStreet}</div>}
                  {(dPZip !== null || dPCity !== null) && (
                    <div>
                      {dPZip} {dPCity}
                    </div>
                  )}
                  {dPPhone !== null && <div>{dPPhone}</div>}
                  {dPMail !== null && <div>{dPMail}</div>}
                </div>
              )
                : (
                  <div>
                    <div>{t('DataProtection.Responsible company')}</div>
                    <div>{t('DataProtection.Responsible full name')}</div>
                    <div>{t('DataProtection.Responsible street')}</div>
                    <div>
                      {t('DataProtection.Responsible zip and city')}
                    </div>
                    <div>{t('DataProtection.Responsible phone')}</div>
                    <div>{t('DataProtection.Responsible mail')}</div>
                  </div>
                )}
            </p>
            {isAdmin && (
              <React.Fragment>
                <Button onClick={() => setIsEditPopupOpened(true)}>
                  <i className='fa fa-pencil' style={{ marginRight: 5 }} />
                </Button>
                <br />
              </React.Fragment>
            )}
          </Card>
          <ol>
            <li>{<Trans i18nKey='Imprint.Part One' />}</li>
            <li>
              {<Trans i18nKey='Imprint.Part Two' />}
              {<Trans i18nKey='Imprint.Part Three' />}
            </li>
            <li>
              {<Trans i18nKey='Imprint.Part Four' />}
              {<Trans i18nKey='Imprint.Part Five' />}
            </li>
            <li>{<Trans i18nKey='Imprint.Part Six' />}</li>
            <li>{<Trans i18nKey='Imprint.Part Seven' />}</li>
          </ol>
        </div>
      </div>{' '}
    </React.Fragment>
  );

  const content = (
    <Wrapper>
      {!isLoggedIn && (
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <AppLogo />
        </div>
      )}

      {!isLoggedIn && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          <Button onClick={() => history.goBack()}>
            <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
          </Button>
        </div>
      )}
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '0 0 50px',
            }}
          >
            {isLoggedIn && isAdmin && (
              <Card className={styles.container}>
                <Checkbox
                  className={styles.checkbox}
                  name={t('Imprint.Use the imprint of Stramplerbande')}
                  onChange={() => {
                    setOption('stand');
                    createImprint({ setting: 'standard' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'stand'}
                />
                <Checkbox
                  className={styles.checkbox}
                  name={t('Imprint.Use your own imprint')}
                  onChange={() => {
                    setOption('own');
                    createImprint({ setting: 'own' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'own'}
                />
                <Checkbox
                  className={styles.checkbox}
                  name={t('Imprint.Use the imprint of Stramplerbande and your own')}
                  onChange={() => {
                    setOption('both');
                    createImprint({ setting: 'both' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'both'}
                />
              </Card>
            )}
            <div className='panel'>
              <div className='panel-heading'>
                <h2>
                  {t('Imprint.Imprint')} {isLoggedIn && activeKita.kitaName}
                </h2>
                <br />
                <strong> {<Trans i18nKey='Imprint.Responsible for the content:' />} </strong>
                {option === 'stand' && (
                  <React.Fragment>
                    <div>{stramplerbandeImprint}</div>
                  </React.Fragment>
                )}
                {option === 'own' && isAdmin && (
                  <React.Fragment>
                    <ImprintFeed
                      text={imprint.text}
                      setImprint={setImprint}
                      option={option}
                      type='imprint'
                    />
                  </React.Fragment>
                )}
                {option === 'own' && !isAdmin && (
                  <React.Fragment>
                    <p dangerouslySetInnerHTML={{ __html: sanitize(imprint.text) }} />
                  </React.Fragment>
                )}
                {option === 'both' && isAdmin && (
                  <React.Fragment>
                    <ImprintFeed
                      text={imprint.text}
                      setImprint={setImprint}
                      option={option}
                      type='imprint'
                    />
                    <br /> <hr /> <br />
                    <div>{stramplerbandeImprint}</div>
                  </React.Fragment>
                )}
                {option === 'both' && !isAdmin && (
                  <React.Fragment>
                    <p dangerouslySetInnerHTML={{ __html: sanitize(imprint.text) }} />
                    <br /> <hr /> <br />
                    <div>{stramplerbandeImprint}</div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Col>

      {isEditPopupOpened && (
        <CreateKita closePopup={() => { setIsEditPopupOpened(false); window.location.reload(); }} isEditing fields={currentKita} />
      )}

      {!isLoggedIn && (
        <div
          style={{
            margin: '0 0 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            style={{
              color: '#b8c7d3',
              marginRight: '10px',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
            to='/auth-imprint'
          >
            Imprint
          </Link>
          <Link
            style={{
              color: '#b8c7d3',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
            to='/auth-privacy'
          >
            Privacy
          </Link>
        </div>
      )}
    </Wrapper>
  );

  return isLoggedIn ? (
    !isLoading ? (
      <KitaPanelWrapper>{content}</KitaPanelWrapper>
    ) : (
      <Loader />
    )
  ) : window.location.hostname.includes('awobamberg.safe2connect.org') ? (
    awobambergImprint
  ) : window.location.hostname.includes('awoconnect.safe2connect.org') ? (
    awoconnectImprint
  ) : (
    content
  );
}
