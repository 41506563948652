import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Card from 'library/common/commonComponents/Card';
import Loader from 'library/common/commonComponents/Loader';
import useGalleryFilesLoading from 'library/common/commonHooks/useGalleryFilesLoading';
import GalleryItemImage from '../GalleryItemImage';
import GalleryDefaultHeader from './galleryDefaultFrames/GalleryDefaultHeader';
import GalleryFilter from '../GalleryFilter/GalleryFilterContainer';

import styles from '../../gallery.module.scss';

export default function GalleryDefault({
  groupInfo,
  goBack,
  isProfilePage,
  user,
  activeKita,
  deleteAllImagesFromGallery,
  deleteImage,
}) {
  const { isLoading, images, updateFilters, onFiltersLoad, dispatch } = useGalleryFilesLoading({
    groupId: groupInfo.groupId,
    isDefaultGallery: true,
    user,
    activeKita,
  });

  const [galleryFiles, setGalleryFiles] = useState([]);

  const onSelectFile = (file) => {
    const updatedFiles = galleryFiles.map((f) =>
      f.id === file.id ? { ...f, selected: !f.selected } : f
    );
    setGalleryFiles(updatedFiles);
  };

  const onDownloadPictures = async() => {
    const images = galleryFiles.filter(x => x.selected)
    if (images.length) {
      const zip = new JSZip();

      const fetchAndAddImage = async (item, index) => {
        const response = await fetch(item.path);
        const blob = await response.blob();
        zip.file(`${item.fileId}`, blob);
      };

      await Promise.all(images.map((item, index) => fetchAndAddImage(item, index)));

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `Default.zip`);
      });
    }
  }

  useEffect(()=> {
    if (images) {
      setGalleryFiles(images);
    }
  },[images])

  return (
    <Card className={styles.wrapper}>
      <GalleryDefaultHeader
        goBack={goBack}
        deleteAllImagesFromGallery={deleteAllImagesFromGallery}
        dispatch={dispatch}
        imagesSize={images.length}
        selectFiles={galleryFiles.filter(x=>x.selected)}
        onDownloadPictures={onDownloadPictures}
      />
      <div className={styles.container}>
        <GalleryFilter
          updateFilters={updateFilters}
          groupId={groupInfo.groupId}
          onFiltersLoad={onFiltersLoad}
          isProfilePage={isProfilePage}
        />
        <div className={styles.galleryContainer}>
          {galleryFiles.map((file, index) => (
            <GalleryItemImage
              key={file.id}
              file={file}
              index={index}
              postId={file.posts ? file.posts.id : file.comments.posts.id}
              permanent={file.posts ? file.posts.permanent : false}
              galleryFiles={images}
              groupInfo={groupInfo}
              user={user}
              isDefaultGallery
              deleteImage={async image => {
                await deleteImage(image);
                dispatch({ type: 'deleteImage', id: image.fileId });
              }}
              isSelectFile={file.selected}
              onSelectFile={()=> onSelectFile(file)}
            />
          ))}
        </div>
        {isLoading && <Loader className={styles.loader} />}
      </div>
    </Card>
  );
}
