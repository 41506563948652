import React from 'react';
import { useTranslation } from 'react-i18next';

import HelpText from 'library/common/commonComponents/HelpText';
import UsersFrameApprovalTable from './UserFrameApprovalFrames/UserFrameApprovalTable';

import styles from './userFrameApproval.module.scss';

export default function UsersFrameApproval({ users, isLoading, setUsers, showBottomNotification }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Administration.UserSection.Pending user approvals')}</div>
      <div className={styles.text}>
        <HelpText>
          {t(
            'Administration.UserSection.The following list contains all registered users awaiting an approval',
          )}
        </HelpText>
      </div>
      {users.length > 0 && (
        <div className={styles.description}>
          {t('AccountSettings.FriendsSection.Showing') +
            ` 1-${users.length} ` +
            t('AccountSettings.FriendsSection.of') +
            ` ${users.length} ` +
            t('AccountSettings.FriendsSection.items')}
        </div>
      )}
      {users.length > 0 && (
        <UsersFrameApprovalTable
          users={users}
          isLoading={isLoading}
          setUsers={setUsers}
          showBottomNotification={showBottomNotification}
        />
      )}
    </div>
  );
}
