import moment from 'moment/min/moment-with-locales';
import { adjustTimeZone } from './date';

export function dateStringToMoment(dateString) {
  return moment(dateString, 'YYYY-MM-DD hh:mm');
}

export function dateObjectToString(dateObject){
  return moment(dateObject).format('DD.MM.YY HH:mm');;
}



export function isoDateStringToGerman(dateString) {
  const momentDate = moment(dateString, 'YYYY-MM-DD');
  return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : dateString;
}

export function formatDate(dateString) {
  return dateStringToMoment(dateString).format('DD.MM.YY HH:mm');
}

export function formatDateNoTime(dateString) {
  return dateStringToMoment(dateString).format('DD.MM.YYYY');
}

export function formatDateNoTimeHalfYear(dateString) {
  const momentDateString = moment(dateString, 'DD.MM.YYYY');
  return dateStringToMoment(momentDateString).format('DD.MM.YY');
}

export function formatDateOnlyTime(dateString) {
  return dateStringToMoment(dateString).format('HH:mm');
}

export function formatDateOnlyTimeGermanTimezone(dateString) {
  return dateStringToMoment(dateString)
    .add(2, 'hours')
    .format('HH:mm');
}

export function returnTimeDifferenceInMinutes(firstTime, secondTime) {
  const startTime = moment(firstTime, 'HH:mm');
  const endTime = moment(secondTime, 'HH:mm');

  return -startTime.diff(endTime, 'minutes');
}

export function currentDateAsString() {
  return moment().format('DD.MM.yyyy');
}

export function now() {
  return moment();
}

export function formatDateObjectToTimeString(date) {
  return moment(date).format('HH:mm');
}

export function formatDateObjectToTimeZoneAdjustedTimeString(date) {
  return formatDateOnlyTime(adjustTimeZone(moment(date).format('YYYY-MM-DD HH:mm:ss')));
}

export function formatUserBirthdayDateString(datestring){
  if(datestring === null || datestring === "" ){
    return "";
  }
  return moment(datestring).format('DD.MM.yyyy');
}
