export const SET_CREATE_KITA_MODE = '@@SET_CREATE_KITA_MODE';
export const SET_NEW_KITA = '@@SET_NEW_KITA';
export const SET_ACTIVE_KITA = '@@SET_ACTIVE_KITA';
export const SET_MODULES = '@@SET_MODULES';
export const SET_IS_OPENED = '@@SET_IS_OPENED';
export const SET_KITAS = '@@SET_KITAS';
export const GO_TO_NEW_KITA_SIGNAL = '@@GO_TO_NEW_KITA_SIGNAL';
export const GO_TO_KITA_SIGNAL = '@@GO_TO_KITA_SIGNAL';

export const CONTRACT_ONLY_KITAS = [261];
export const AWOMEMBER_LINK = "https://awo.org/mitwirken/mitgliedschaft/"