import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import deepcopy from 'deepcopy';

// eslint-disable-next-line max-len
import FeedItemComments from 'library/common/commonComponents/Feed/feedFrames/feedItem/feedItemFrames/FeedItemComments/FeedItemCommentsContainer';
import useLikes from 'library/common/commonHooks/useLikes';

import styles from './taskComment.module.scss';
import MoreBtn from '../../../../../../../../../Buttons/MoreBtn';
import { assignTaskToUser } from '../../../../../../../../../../../api/tasks';

export default function TaskComment({
  likes,
  comments,
  setNewComments,
  id,
  user,
  assignedUsersList,
  group,
  reRender,
  isLikeFeatureAvailableInGroup,
  likeFeatureEnabled
}) {
  const { t } = useTranslation();
  const { LikeButton, ...likeButtonProps } = useLikes({ likes, user, id });
  const [localComments, setLocalComments] = useState(
    comments.sort((a, b) => a.id - b.id).map(deepcopy),
  );
  const [commentsIsOpened, setCommentsIsOpened] = useState(comments.length > 0);
  const canCreateCommentStatus = !group || (group && group.createCommentStatus);

  const isAssigned = assignedUsersList.find(item => item.id === user.id);

  return (
    <div className={styles.container}>
      <div className={cn(styles.bottomSection, { [styles.commentsIsOpened]: commentsIsOpened })}>
        <div className={styles.bottomSectionButton}>
          {isLikeFeatureAvailableInGroup && likeFeatureEnabled && <LikeButton {...likeButtonProps} />}
        </div>
        {canCreateCommentStatus && (
          <>
            <div className={styles.bottomSectionSeparator}>·</div>
            <div
              className={styles.bottomSectionButton}
              onClick={() => setCommentsIsOpened(!commentsIsOpened)}
            >
              <div className={styles.comments}>
                {t('Post.Comment')}
                {localComments.length > 0 && (
                  <div className={styles.commentsCount}>({localComments.length})</div>
                )}
              </div>
            </div>
          </>
        )}

        {!isAssigned && (
          <div
            className={styles.taskAssignButton}
            onClick={() => {
              assignTaskToUser(id);
              assignedUsersList.push(user);
              reRender();
            }}
          >
            <i className='fa fa-street-view' aria-hidden='true' />
            {t('Tasks.Assign to me')}
          </div>
        )}
      </div>

      <FeedItemComments
        postId={id}
        user={user}
        isOpened={commentsIsOpened}
        createCommentStatus={canCreateCommentStatus}
        isLikeFeatureAvailableInGroup={isLikeFeatureAvailableInGroup}
        likeFeatureEnabled={likeFeatureEnabled}
        comments={localComments}
        setComments={newComments => {
          setLocalComments(newComments);
          setNewComments(newComments);
        }}
        groupId={group && group.groupId}
      />
    </div>
  );
}
