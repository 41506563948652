import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import SortIndicator from 'library/common/commonComponents/SortIndicator';
import GroupsFrameListRow from '../GroupsFrameListRow';

import styles from '../../groupsFrameList.module.scss';

export default function GroupsFrameListTable({ groups, sort, setSorting }) {
  const { t } = useTranslation();
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <tr>
          <td
            className={cn(styles.headerText, styles.headerTextWithPadding)}
            onClick={() => setSorting('groupName')}
          >
            <div className={styles.headerTextWrapper}>
              {t('Administration.SpacesSection.Name')}
              <SortIndicator sort={sort} name='groupName' />
            </div>
          </td>
          <td className={styles.headerText} onClick={() => setSorting('memberCount')}>
            <div className={styles.headerTextWrapper}>
              {t('Administration.SpacesSection.Members')}
              <SortIndicator sort={sort} name='memberCount' />
            </div>
          </td>
          <td className={cn(styles.headerText)}>
            {t('Administration.SpacesSection.Administration')}
          </td>
          <td />
        </tr>

        {groups.map(group => (
          <GroupsFrameListRow group={group} />
        ))}
      </table>
    </div>
  );
}
