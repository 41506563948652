import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './employeeManualCheckInOut.module.scss';
import { useTranslation } from 'react-i18next';
import DatePicker from 'library/common/commonComponents/DatePicker';
import Label from 'library/common/commonComponents/Label';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import { manualCheckInOut, getUnreviewedManualCheckInOuts } from 'library/api/employee';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import EmployeeReviewManualCheckInOut from './EmployeeReviewManualCheckInOut';
import Checkbox from 'library/common/commonComponents/Checkbox';

export default function EmployeeManualCheckInOut() {
  const { t } = useTranslation();

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeError, setStartTimeError] = useState(false);
  const [formReason, setFormReason] = useState('');
  const [isBreak, setIsBreak] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [unreviewedCheckInOuts, setUnreviewedCheckInOuts] = useState([]);

  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    if (user.administrationAccess) {
      handleGetUnreviewedCheckInOuts();
    }
  }, []);

  const handleGetUnreviewedCheckInOuts = async () => {
    setIsLoading(true);
    try {
      const { data } = await getUnreviewedManualCheckInOuts();
      setUnreviewedCheckInOuts(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // validate the input fields with following function
  const validateForm = () => {
    setStartTimeError(false);
    setEndTimeError(false);
    setErrorMessage('');

    if (startTime.trim() && endTime.trim() && date) {
      if (startTime > endTime || startTime === endTime) {
        setErrorMessage(t('EmployeeCheckinout.Start time must be before end time'));
        setStartTimeError(true);
        setEndTimeError(true);
        return false;
      }

      const [endHours, endMinutes] = endTime.split(':').map(Number);
      const manualCheckoutDate = new Date(date);
      manualCheckoutDate.setHours(endHours, endMinutes, 0, 0);

      if (manualCheckoutDate > new Date()) {
        setErrorMessage(t('EmployeeCheckinout.Checkout cannot be in the future'));
        return false;
      }

      if (formReason.length === 0 && !isBreak) {
        setErrorMessage(t('EmployeeCheckinout.Reason for manual entries is required'));
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const [checkInHours, checkInMinutes] = startTime.split(':').map(Number);
    const [checkOutHours, checkOutMinutes] = endTime.split(':').map(Number);
    const checkInActionDate = new Date(date);
    const checkOutActionDate = new Date(date);
    const checkInOutCategory = isBreak ? 'BREAK' : 'MANUAL';

    checkInActionDate.setHours(checkInHours, checkInMinutes, 0, 0);
    checkOutActionDate.setHours(checkOutHours, checkOutMinutes, 0, 0);

    const checkInOutArray = [
      {
        userId: user.id,
        checkInOutType: isBreak ? 'CHECKOUT' : 'CHECKIN',
        checkedDate: checkInActionDate,
        checkInOutCategory: checkInOutCategory,
        note: formReason,
      },
      {
        userId: user.id,
        checkInOutType: isBreak ? 'CHECKIN' : 'CHECKOUT',
        checkedDate: checkOutActionDate,
        checkInOutCategory: checkInOutCategory,
        note: formReason,
      },
    ];

    try {
      setIsLoading(true);
      await manualCheckInOut(checkInOutArray);
      handleGetUnreviewedCheckInOuts();
      store.dispatch(
        showBottomNotification(t('EmployeeCheckinout.Successfully added working time'), {
          isSuccess: true,
        }),
      );
      setStartTime('');
      setEndTime('');
      setDate(new Date());
      setFormReason('');
    } catch (err) {
      store.dispatch(
        showBottomNotification(t(err.response.data.message), {
          isFail: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <h2>{t('EmployeeCheckinout.Add working time or break')}</h2>
      <div className={styles.inputGroup}>
        <div className={styles.timeInputContainer}>
          <Label htmlFor='startTime' type='input'>
            {t('EmployeeCheckinout.Start working time')}
          </Label>
          <Input
            className={styles.timeInput}
            type='time'
            value={startTime}
            onChange={e => {
              setStartTime(e.target.value);
            }}
            id='startTime'
            min='00:00'
            max='23:59'
            error={startTimeError}
          />
        </div>

        <div className={styles.timeInputContainer}>
          <Label htmlFor='endTime' type='input'>
            {t('EmployeeCheckinout.End working time')}
          </Label>
          <Input
            className={styles.timeInput}
            type='time'
            value={endTime}
            onChange={e => {
              setEndTime(e.target.value);
            }}
            id='endTime'
            min='00:00'
            max='23:59'
            error={endTimeError}
          />
        </div>

        <div className={styles.dateContainer}>
          <Label type='input'>{t('EmployeeCheckinout.Date')}</Label>
          <DatePicker
            selected={date}
            onChange={date => setDate(date)}
            langCode={user.currentWatsonLang === 'de' ? 'Deutsch' : 'en'}
            maxDate={new Date()}
            minDate={new Date().setDate(new Date().getDate() - 30)}
          />
        </div>
      </div>

      <Checkbox
        isChecked={isBreak}
        name={t('EmployeeCheckinout.Pause')}
        onChange={() => setIsBreak(prev => !prev)}
        className={styles.breakCheckbox}
      />

      <div className={styles.textBoxReason}>
        <Label type='input'>{t('EmployeeCheckinout.Reason')}</Label>
        <TextArea
          error={errorMessage}
          value={formReason}
          onChange={e => setFormReason(e.target.value)}
          maxLength={500}
          disabled={isBreak}
        />
        <div style={formReason.length === 500 ? { color: 'red' } : {}}>
          {formReason.length}/500 {t('EmployeeCheckinout.Characters')}
        </div>
      </div>

      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}

      <Button
        className={styles.submitButton}
        type='primary'
        disabled={isLoading}
        onClick={() => handleSubmit()}
      >
        {t('EmployeeCheckinout.Send')}
      </Button>

      {user.administrationAccess && (
        <EmployeeReviewManualCheckInOut
          handleGetUnreviewedCheckInOuts={handleGetUnreviewedCheckInOuts}
          unreviewedCheckInOuts={[...unreviewedCheckInOuts]}
          setUnreviewedCheckInOuts={setUnreviewedCheckInOuts}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
}
