import { getAllKitasWithoutPagination } from 'library/api/kita';

export function setActiveKita({ activeKita, setSelectedKita }) {
  return () => {
    if (activeKita) {
      setSelectedKita({
        label: activeKita.kitaName,
        value: activeKita.kitaId,
        id: activeKita.kitaId,
      });
    }
  };
}

export function getKitas({ setKitas }) {
  return () => {
    getAllKitasWithoutPagination().then(({ data }) =>
      setKitas(data.content.map(kita => ({ label: kita.kitaName, value: kita.id }))),
    );
  };
}
