import { useState } from 'react';

import { getGroupsByName, getGroupsPayloadWithBazaarGroup } from 'library/api/groups';
import cachedRequest from '../useCachedRequest';

export default function useGroupsLoading() {
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  async function loadGroups(name) {
    setIsLoading(true);

    try {
      setGroups(
        name
          ? await getGroupsByName(name, false)
          : (await cachedRequest(getGroupsPayloadWithBazaarGroup)).data,
      );
    } finally {
      setIsLoading(false);
    }
  }

  return {
    groups,
    isLoading,
    loadGroups,
  };
}
