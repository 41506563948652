import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './employeeHolidayAvatar.module.scss';
import ProfileAvatar from '../../../ProfileAvatar';
import { useSelector } from 'react-redux';

const EmployeeHolidayAvatar = ({ employeeInfo }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.userReducer);

  return (
    <div className={styles.employeeHistory}>
      <div className={styles.wrapper}>
        <ProfileAvatar className={styles.avatar} id={user.id} withoutLink />
        <div className={styles.name}>{`${user.firstName} ${user.lastName}`}</div>

        <div className={styles.workingTime}>
          <i className='fa fa-calendar' />
          <span className={styles.workingTimeLabel}>{t('Checkinout.Holidays')}</span>
          {employeeInfo
            ? `${employeeInfo.vacationDays - employeeInfo.takenVacation} / ${
                employeeInfo.vacationDays
              }`
            : null}
        </div>
      </div>
    </div>
  );
};

export default EmployeeHolidayAvatar;
