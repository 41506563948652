import { CancelToken } from 'axios';

import axios from 'library/api/axios';
import config, { getPort, isDev } from 'main/config';
import store from 'main/store/configureStore';
import { postEntity } from 'library/common/commonConstants/graphql';

const URL = `${config.API_BASE_URI}${getPort(8091)}/api`;
const zuulURL = isDev ? URL : `${config.API_BASE_URI}/zuul${getPort(8091)}/api`;

export const createPost = (post, setProgress) => {
  const payload = {
    ...post,
    graphql: postEntity,
  };
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.post(zuulURL + '/post', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total) || 0.01);
    },
  });
};

export const getUnreadTaxReceiptCount = kitaIds =>
  axios.get(`${URL}/tax-unread-count`, {
    params: {
      kitaIds: kitaIds && kitaIds.length ? kitaIds.reduce((f, s) => `${f},${s}`) : '',
    },
  });

const getPostAPIUrl = (isInPublicPage, isInProfileStream, groupId) => {
  if (isInPublicPage) {
    if (groupId > 0) return '/public-posts/group/page';
    else return '/public-posts/dashboard/page';
  } else if (isInProfileStream) {
    return '/users/current/profile/posts';
  } else if (groupId > 0) {
    return '/groups/post/page'
  } else {
    return '/dashboard/post/page';
  }
};

export const getPosts = async ({
  page,
  groupId = 0,
  isPrivatePostsOnly = false,
  isPublicPostsOnly = false,
  filters,
  postType,
  postId,
  profileId,
  isOnDashboard,
  isInProfileStream,
  isInPublicPage,
}) => {
  const { includeScheduled, ...filtersPayload } = filters || {};
  const apiURL = getPostAPIUrl(isInPublicPage, isInProfileStream, groupId);
  const apiResult = await axios.post(
    URL + apiURL,
    {
      graphql: postEntity,
      page,
      filter: getFilters({
        groupId,
        isPrivatePostsOnly,
        isPublicPostsOnly,
        postType,
        postId,
        profileId,
        ...filtersPayload,
      }),
      size: '10',
      includeScheduled,
      sort: getSort(filtersPayload && filtersPayload.sortingFilters, isOnDashboard),
    },
  );

  const posts = [];
  for (const post of apiResult.data.content) {
    posts.push({
      ...post,
      name: `${post.firstName} ${post.lastName}`,
      avatar: null,
      postlink: '#',
      userlink: `/profile/${post.userId}`,
      comments: post.comments.sort((a, b) => a.createdAt - b.createdAt),
    });
  }
  return posts;
};

export const updatePost = (id, post) => {
  const payload = {
    ...post,
    graphql: postEntity,
  };
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'files') {
      let fileNumber = 1;
      payload[key].forEach(item => {
        formData.append(`file${fileNumber}`, item);
        fileNumber++;
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return axios.put(zuulURL + '/post/' + id, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getPostById = id => axios.get(URL + '/posts/' + id);

export const deletePost = (id, userId) => axios.delete(URL + '/post/' + id + '?userId=' + userId);

export const deletePosts = posts => axios.delete(URL + '/post', { data: { posts } });

export const updatePosts = payload => axios.put(zuulURL + '/post', payload);

export const getSinglePostById = id =>
  axios.get(URL + '/post/' + id + '?graphql=rrm[userId,createdAt]');

export function getFilters({
  groupId,
  isPrivatePostsOnly,
  isPublicPostsOnly,
  contentFilters,
  filesFilters,
  visibilityFilters,
  groups,
  postType,
  postId,
  profileId,
}) {
  const filters = ['deleted:eq:0'];
  if (profileId) {
    filters.push('and', 'userId:eq:' + profileId, 'and', 'groupId:eq:0');
  } else if (groupId !== 0) {
    filters.push('and', 'groupId:eq:' + groupId);
  }

  if (postId) {
    filters.push('and', `id:eq:${postId}`);
  } else {
    addPostTypeFilters(filters, postType);
    addPrivateFilters(filters, isPrivatePostsOnly, isPublicPostsOnly, visibilityFilters);
    addContentFilters(filters, contentFilters, filesFilters);
    addGroupsFilters(filters, groups);
  }

  return filters;
}

export function addPostTypeFilters(filters, postType) {
  if (postType) {
    filters.push('and', 'postType:eq:' + postType);
  }
}

export function addPrivateFilters(
  filters,
  isPrivatePostsOnly,
  isPublicPostsOnly,
  visibilityFilters,
) {
  if (isPrivatePostsOnly) {
    filters.push('and', 'privatePost:eq:' + +isPrivatePostsOnly);
  } else if (isPublicPostsOnly) {
    filters.push('and', 'privatePost:eq:false');
  } else if (visibilityFilters) {
    if (visibilityFilters[0]) {
      if (!visibilityFilters[1]) {
        filters.push('and', 'privatePost:eq:false');
      }
    } else if (visibilityFilters[1]) {
      filters.push('and', 'privatePost:eq:true');
    }
    if (visibilityFilters[2]) {
      filters.push('and', 'hidePost:eq:true');
    }
  }
}

export function addContentFilters(filters, contentFilters, filesFilters) {
  const userId = store.getState().userReducer.id;
  if (!contentFilters) return;

  if (contentFilters[0]) {
    filters.push('and', [
      'userId:eq:' + userId,
      'or',
      'comments.userId:eq:' + userId,
      'or',
      `comments.text:cic:data-mention-id="${userId}"`,
      'or',
      `text:cic:data-mention-id="${userId}"`,
    ]);
  } else if (contentFilters[1]) {
    filters.push('and', 'userId:eq:' + userId);
  }

  if (contentFilters[2]) {
    addFilesFilters(filters, filesFilters);
  }

  if (contentFilters[3]) {
    addPostTypeFilters(filters, 2);
  }
}

export function addFilesFilters(filters, filesFilters) {
  if (!filesFilters) return;

  const filesFiltersPayload = [];
  filesFilters.forEach((filter, index) => {
    if (filter) {
      if (index === 0) {
        filesFiltersPayload.push(
          'files.mimeType:cic:image',
          'or',
          'comments.files.mimeType:cic:image',
        );
      } else if (index === 1) {
        if (filesFiltersPayload.length > 0) {
          filesFiltersPayload.push('or');
        }
        filesFiltersPayload.push(
          'files.mimeType:cic:video',
          'or',
          'comments.files.mimeType:cic:video',
        );
      } else if (index === 2) {
        if (filesFiltersPayload.length > 0) {
          filesFiltersPayload.push('or');
        }
        filesFiltersPayload.push([
          ['files.size:gt:0', 'or', 'comments.files.size:gt:0'],
          'and',
          [
            'files.mimeType:ncic:video',
            'and',
            'comments.files.mimeType:ncic:video',
            'and',
            'files.mimeType:ncic:image',
            'and',
            'comments.files.mimeType:ncic:image',
          ],
        ]);
      }
    }
  });
  filters.push('and', filesFiltersPayload);
}

export function addGroupsFilters(filter, groups = {}) {
  if (groups.users && groups.users.length > 0) {
    const groupsPayload = [];
    groups.users.forEach((author, index) =>
      index === 0
        ? groupsPayload.push('groupId:eq:' + author.id)
        : groupsPayload.push('or', 'groupId:eq:' + author.id),
    );
    filter.push('and', groupsPayload);
  }
}

export function getSort(sortingFilters, isOnDashboard) {
  return sortingFilters && sortingFilters[1]
    ? (isOnDashboard ? [] : ['pinned', 'desc']).concat(['editedAt', 'desc'])
    : (isOnDashboard ? [] : ['pinned', 'desc']).concat(['createdAt', 'desc']);
}

export const changePostNotifyStatus = (postId, notify) =>
  axios.put(`${URL}/post/${postId}/notification`, { notify });

export const searchPosts = ({
  page,
  text,
  selectedGroups,
  calendarState,
  postState,
  userInfo,
  from,
  to,
  category,
  pattern,
}) => {
  const source = CancelToken.source();

  if (postState || calendarState) {
    category = 'post';
  }

  const req = axios.post(
    `${URL}/homepage/search?filter=${category}`,
    {
      graphql:
        postEntity +
        // eslint-disable-next-line
        ',groupName,groupDescription,colourCode,logoUrl,grouplogoAspect,groupLogoXCoordinate,groupLogoYCoordinate,groupLogoWidth,logoUrl,profileLogoAspect,profileLogoWidth,profileLogoXCoordinate,profileLogoYCoordinate',
      page,
      size: 10,
      filter: getSearchFilters({ text, selectedGroups, calendarState, postState, pattern }),
      sort: ['createdAt', 'desc'],
      userInfo: userInfo,
      from: new Date(from).getTime(),
      to: new Date(to).getTime(),
    },
    {
      cancelToken: source.token,
    },
  );

  return { source, req };
};

export function getSearchFilters({ text, selectedGroups, calendarState, postState, pattern }) {
  let filter = [];
  if (pattern) {
    const texts = text.split(pattern).filter(x => !!x);
    filter = ['deleted:eq:0', 'and'];
    for (let index = 0; index < texts.length; index++) {
      const value = texts[index]?.trim();
      filter.push([
        `searchTerm:eq:${value}`,
        'or',
        `text:cic:${value}`,
        'or',
        `comments.text:cic:${value}`,
        'or',
        `firstName:cic:${value}`,
        'or',
        `files.fileId:cic:${value}`,
        'or',
        `comments.files.fileId:cic:${value}`,
      ]);
      if (index !== texts.length - 1) {
        filter.push('or');
      }
    }
  } else {
    filter = [
      'deleted:eq:0',
      'and',
      [
        `searchTerm:eq:${text}`,
        'or',
        `text:cic:${text}`,
        'or',
        `comments.text:cic:${text}`,
        'or',
        `firstName:cic:${text}`,
        'or',
        `files.fileId:cic:${text}`,
        'or',
        `comments.files.fileId:cic:${text}`,
      ],
    ];
  }

  if (calendarState && postState) {
    filter.push('and', `postType:eq:2`, 'or', `postType:eq:0`);
  } else {
    if (calendarState) {
      filter.push('and', `postType:eq:2`);
    }
    if (postState) {
      filter.push('and', `postType:eq:0`);
    }
  }
  const groupsToAdd = Object.keys(selectedGroups).length > 0 && { users: selectedGroups };
  if (groupsToAdd) {
    const groupsToAddPayload = [];
    Object.values(groupsToAdd.users).forEach((author, index) =>
      index === 0
        ? groupsToAddPayload.push('groupId:eq:' + author.id)
        : groupsToAddPayload.push('or', 'groupId:eq:' + author.id),
    );
    filter.push('and', groupsToAddPayload);
  }

  return filter;
}

export const pinGroupPost = ({ groupId, id }) =>
  axios.post(`${URL}/groups/${groupId}/posts/${id}/pin`);

export const unpinGroupPost = ({ groupId, id }) =>
  axios.delete(`${URL}/groups/${groupId}/posts/${id}/pin`);

export const pinUserPost = ({ id }) => axios.post(`${URL}/users/currentuser/posts/${id}/pin`);

export const unpinUserPost = ({ id }) => axios.delete(`${URL}/users/currentuser/posts/${id}/pin`);

export const updatePermanentStatus = id => axios.put(`${URL}/post/permanent/${id}`);

export const updatePermanentStatusGroup = (
  groupId,
  dateLimit = new Date().toISOString().slice(0, 10),
) => axios.put(`${URL}/post/grouppermanent/${groupId}`, null, { params: { dateLimit } });

export const updateReturnReceiptStatus = id => axios.put(`${URL}/post/returnreceipt/${id}`);

export const toggleForbidComments = postId =>
  axios.post(`${URL}/post/toggleForbidComments/${postId}`);

export const toggleHidePost = postId => axios.post(`${URL}/post/toggleHidePost/${postId}`);

export const getPostTemplate = postId => axios.get(`${URL}/posttemplate/${postId}`);

export const deletePostTemplate = postId => axios.delete(`${URL}/posttemplate/${postId}`);

export const createPostTemplate = (postId, templateData) => {
  const formData = new FormData();
  const { isGlobalTemplate, templateName } = templateData;
  formData.append('isGlobalTemplate', isGlobalTemplate);
  formData.append('templateName', templateName);

  return axios.post(`${URL}/posttemplate/${postId}`, formData);
};

export const reportToSuperAdmin = (id, groupId, name) => {
  return axios.post(zuulURL + `/reportPost/${id}`);
};

export const getAllPostTemplates = () => axios.get(`${URL}/posttemplate/all`);

export const deleteAllDuplicatePosts = () => axios.delete(`${URL}/deleteAllDuplicatePosts`);
