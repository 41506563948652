import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  declineGfzContractChanges,
  postGfzContractChanges,
  getGfzContractChanges,
  getAllKidsFromSelectedKita,
  getAllOwnKidsFromSelectedKita
} from "library/api/observationSheet";
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import RadioButton from 'library/common/commonComponents/RadioButton';
import { createPDFContractChanges } from 'library/utilities/files';
import { removeWhiteBackgroundSignature } from 'library/utilities/signature';
import store from 'main/store/configureStore';
import { onGfzFormSubmitted } from '../util.js';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';

export default function ContractChanges({ isAdmin, isEmployee, userId, kitaName, kidId, formId, setActiveTab }) {
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isExistingForKid, setIsExistingForKid] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [contractChanges, setContractChanges] = useState({
    kitaName: '',
    date: new Date().toISOString().split('T')[0],
    validFrom: new Date().toISOString().split('T')[0],
    changeSubvPriv: false,
    mondaySubvPriv: 'subv',
    tuesdaySubvPriv: 'subv',
    wednesdaySubvPriv: 'subv',
    thursdaySubvPriv: 'subv',
    fridaySubvPriv: 'subv',
    daysChange: false,
    monday: false,
    mondayMorning: false,
    mondayNoon: false,
    mondayAfternoon: false,
    mondayTotal: '',
    tuesday: false,
    tuesdayMorning: false,
    tuesdayNoon: false,
    tuesdayAfternoon: false,
    tuesdayTotal: '',
    wednesday: false,
    wednesdayMorning: false,
    wednesdayNoon: false,
    wednesdayAfternoon: false,
    wednesdayTotal: '',
    thursday: false,
    thursdayMorning: false,
    thursdayNoon: false,
    thursdayAfternoon: false,
    thursdayTotal: '',
    friday: false,
    fridayMorning: false,
    fridayNoon: false,
    fridayAfternoon: false,
    fridayTotal: '',
    groupChange: false,
    currentGroupName: '',
    futureGroupName: '',
    parentSignature: null,
    parentSignatureDate: new Date().toISOString().split('T')[0],
    kitaSignature: null,
    kitaSignatureDate: new Date().toISOString().split('T')[0],
    kidId: null,
  });

  const sigCanvasParent = useRef();
  const sigCanvasKita = useRef();
  const isAdminOrEmployee = isAdmin || isEmployee;
  const noKidSelected = Array.isArray(selectedKid) && selectedKid.length === 0;

  const user = useSelector(state => state.userReducer);

  const getKitaKidsFunction = isAdminOrEmployee
    ? getAllKidsFromSelectedKita
    : getAllOwnKidsFromSelectedKita;
  const { t } = useTranslation();

  const [isParentOfKid, setIsParentOfKid] = useState(false);

  useEffect(() => {
    setIsParentOfKid(
      selectedKid &&
      selectedKid !== [] &&
      user.kids &&
      user.kids.find(k => k.id === selectedKid.id),
    );
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }, [selectedKid]);

  useEffect(() => {
    if (isParentOfKid) {
      sigCanvasParent.current.on();
    } else {
      sigCanvasParent.current.off();
    }

    if (isAdmin) {
      sigCanvasKita.current.on();
    } else {
      sigCanvasKita.current.off();
    }
  }, [isParentOfKid]);

  useEffect(() => {
    getKitaKidsFunction().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return {
          label: obj.firstName + ' ' + obj.lastName,
          id: isAdminOrEmployee ? obj.kidId : obj.id,
          portfolioGroupId: isAdminOrEmployee ? obj.portfolioGroupId : obj.groups.id,
          kidGroup: obj.kidGroup,
        };
      });
      setKidsList(formattedKidsList);
    });
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(true);
    }
  }, []);

  const loadGfzContractChangesForKid = (kid, selectedFormId) => {
    if (!isAdmin && !isParentOfKid) {
      setFormDisabled(false);
    }
    setIsCompleted(false);
    setIsExistingForKid(false);
    getGfzContractChanges(kid.id, selectedFormId).then(res => {
      if (res.data != null) {
        setIsExistingForKid(true);
        setContractChanges({
          ...res.data,
          kitaSignatureDate:
            res.data.kitaSignatureDate === null ? '' : res.data.kitaSignatureDate,
          parentSignatureDate:
            res.data.parentSignatureDate === null ? '' : res.data.parentSignatureDate,
        });

        if (res.data.parentSignature !== null) {
          sigCanvasParent.current.fromDataURL(res.data.parentSignature);
          setFormDisabled(true);
        }
        if (res.data.kitaSignature !== null) {
          sigCanvasKita.current.fromDataURL(res.data.kitaSignature);
          setFormDisabled(true);
        }
        if (res.data.parentSignature !== null && res.data.kitaSignature !== null) {
          setIsCompleted(true);
        }
      } else {
        setContractChanges({
          kitaName: kitaName ? kitaName : '',
          date: new Date().toISOString().split('T')[0],
          validFrom: new Date().toISOString().split('T')[0],
          changeSubvPriv: false,
          mondaySubvPriv: 'subv',
          tuesdaySubvPriv: 'subv',
          wednesdaySubvPriv: 'subv',
          thursdaySubvPriv: 'subv',
          fridaySubvPriv: 'subv',
          daysChange: false,
          monday: false,
          mondayMorning: false,
          mondayNoon: false,
          mondayAfternoon: false,
          mondayTotal: '',
          tuesday: false,
          tuesdayMorning: false,
          tuesdayNoon: false,
          tuesdayAfternoon: false,
          tuesdayTotal: '',
          wednesday: false,
          wednesdayMorning: false,
          wednesdayNoon: false,
          wednesdayAfternoon: false,
          wednesdayTotal: '',
          thursday: false,
          thursdayMorning: false,
          thursdayNoon: false,
          thursdayAfternoon: false,
          thursdayTotal: '',
          friday: false,
          fridayMorning: false,
          fridayNoon: false,
          fridayAfternoon: false,
          fridayTotal: '',
          groupChange: false,
          currentGroupName: kid.kidGroup ? kid.kidGroup.groupName : '',
          futureGroupName: '',
          parentSignature: null,
          parentSignatureDate: new Date().toISOString().split('T')[0],
          kitaSignature: null,
          kitaSignatureDate: new Date().toISOString().split('T')[0],
          kidId: kid.id,
        });
      }
    });
  };

  useEffect(() => {
    if (
      kidId != null && typeof kidId !== 'undefined' &&
      formId != null && typeof formId !== 'undefined' &&
      selectedKid.length === 0
    ) {
      const kidToSelect = kidsList.filter(obj => obj.id === kidId);
      if (kidToSelect.length !== 1) {
        return;
      }

      setSelectedKid(kidToSelect[0]);
      loadGfzContractChangesForKid(kidToSelect[0], formId);
    }
  }, [kidId, formId, kidsList]);

  function resetForm() {
    setContractChanges({
      kitaName: '',
      date: new Date().toISOString().split('T')[0],
      validFrom: new Date().toISOString().split('T')[0],
      changeSubvPriv: false,
      mondaySubvPriv: 'subv',
      tuesdaySubvPriv: 'subv',
      wednesdaySubvPriv: 'subv',
      thursdaySubvPriv: 'subv',
      fridaySubvPriv: 'subv',
      daysChange: false,
      monday: false,
      mondayMorning: false,
      mondayNoon: false,
      mondayAfternoon: false,
      mondayTotal: '',
      tuesday: false,
      tuesdayMorning: false,
      tuesdayNoon: false,
      tuesdayAfternoon: false,
      tuesdayTotal: '',
      wednesday: false,
      wednesdayMorning: false,
      wednesdayNoon: false,
      wednesdayAfternoon: false,
      wednesdayTotal: '',
      thursday: false,
      thursdayMorning: false,
      thursdayNoon: false,
      thursdayAfternoon: false,
      thursdayTotal: '',
      friday: false,
      fridayMorning: false,
      fridayNoon: false,
      fridayAfternoon: false,
      fridayTotal: '',
      groupChange: false,
      currentGroupName: '',
      futureGroupName: '',
      parentSignature: null,
      parentSignatureDate: new Date().toISOString().split('T')[0],
      kitaSignature: null,
      kitaSignatureDate: new Date().toISOString().split('T')[0],
      kidId: null,
    });
    setSelectedKid([]);
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt='' />
      <br />
      <br />
      <h2>{t('GFZForms.ContractChangesContractChanges')}</h2>
      <br />
      <table>
        <tbody>
          <tr>
            <td>{t('GFZForms.ContractChangesKidName')}:</td>
            <td>
              <Select
                options={kidsList}
                onSelect={e => {
                  setSelectedKid(e);
                  loadGfzContractChangesForKid(e, -1);
                }}
                selected={selectedKid}
                style={{ zIndex: '10000000000' }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.ContractChangesKita')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.kitaName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    kitaName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.ContractChangesDate')}</td>
            <td>
              <Input
                type='date'
                value={contractChanges.date}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    date: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.ContractChangesValidFrom')}</td>
            <td>
              <Input
                type='date'
                value={contractChanges.validFrom}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    validFrom: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <CheckBox
        isChecked={contractChanges.changeSubvPriv}
        isDisabled={formDisabled}
        name={t('GfzForms.ContractChangesSubvOrPrivate')}
        onChange={() => {
          setContractChanges(prev => ({
            ...prev,
            changeSubvPriv: !prev.changeSubvPriv,
          }));
        }}
      />
      <table>
        <tbody>
          <tr>
            <td>
              <RadioButton
                checked={contractChanges.mondaySubvPriv === 'subv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondaySubvPriv: 'subv',
                  }));
                }}
                label='S'
              />
              <RadioButton
                checked={contractChanges.mondaySubvPriv === 'priv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondaySubvPriv: 'priv',
                  }));
                }}
                label='P'
              />
            </td>
            <td>
              <RadioButton
                checked={contractChanges.tuesdaySubvPriv === 'subv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdaySubvPriv: 'subv',
                  }));
                }}
                label='S'
              />
              <RadioButton
                checked={contractChanges.tuesdaySubvPriv === 'priv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdaySubvPriv: 'priv',
                  }));
                }}
                label='P'
              />
            </td>
            <td>
              <RadioButton
                checked={contractChanges.wednesdaySubvPriv === 'subv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdaySubvPriv: 'subv',
                  }));
                }}
                label='S'
              />
              <RadioButton
                checked={contractChanges.wednesdaySubvPriv === 'priv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdaySubvPriv: 'priv',
                  }));
                }}
                label='P'
              />
            </td>
            <td>
              <RadioButton
                checked={contractChanges.thursdaySubvPriv === 'subv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdaySubvPriv: 'subv',
                  }));
                }}
                label='S'
              />
              <RadioButton
                checked={contractChanges.thursdaySubvPriv === 'priv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdaySubvPriv: 'priv',
                  }));
                }}
                label='P'
              />
            </td>
            <td>
              <RadioButton
                checked={contractChanges.fridaySubvPriv === 'subv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridaySubvPriv: 'subv',
                  }));
                }}
                label='S'
              />
              <RadioButton
                checked={contractChanges.fridaySubvPriv === 'priv'}
                disabled={formDisabled}
                onClick={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridaySubvPriv: 'priv',
                  }));
                }}
                label='P'
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <CheckBox
        isChecked={contractChanges.daysChange}
        isDisabled={formDisabled}
        name={t('GfzForms.ContractChangesChangeOfDays')}
        onChange={() => {
          setContractChanges(prev => ({
            ...prev,
            daysChange: !prev.daysChange,
          }));
        }}
      />
      <table>
        <tbody>
          <tr>
            <td style={{ minWidth: '70px' }}>{t('GfzForms.ContractChangesNewSupervisionDays')}</td>
            <td>
              <CheckBox
                isChecked={contractChanges.monday}
                isDisabled={formDisabled}
                name={t('GfzForms.Monday')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    monday: !prev.monday,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.tuesday}
                isDisabled={formDisabled}
                name={t('GfzForms.Tuesday')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesday: !prev.tuesday,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.wednesday}
                isDisabled={formDisabled}
                name={t('GfzForms.Wednesday')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesday: !prev.wednesday,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.thursday}
                isDisabled={formDisabled}
                name={t('GfzForms.Thursday')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursday: !prev.thursday,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.friday}
                isDisabled={formDisabled}
                name={t('GfzForms.Friday')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    friday: !prev.friday,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <CheckBox
                isChecked={contractChanges.mondayMorning}
                isDisabled={formDisabled}
                name={t('GfzForms.Forenoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondayMorning: !prev.mondayMorning,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.tuesdayMorning}
                isDisabled={formDisabled}
                name={t('GfzForms.Forenoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdayMorning: !prev.tuesdayMorning,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.wednesdayMorning}
                isDisabled={formDisabled}
                name={t('GfzForms.Forenoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdayMorning: !prev.wednesdayMorning,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.thursdayMorning}
                isDisabled={formDisabled}
                name={t('GfzForms.Forenoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdayMorning: !prev.thursdayMorning,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.fridayMorning}
                isDisabled={formDisabled}
                name={t('GfzForms.Forenoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridayMorning: !prev.fridayMorning,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <CheckBox
                isChecked={contractChanges.mondayNoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Noon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondayNoon: !prev.mondayNoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.tuesdayNoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Noon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdayNoon: !prev.tuesdayNoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.wednesdayNoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Noon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdayNoon: !prev.wednesdayNoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.thursdayNoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Noon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdayNoon: !prev.thursdayNoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.fridayNoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Noon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridayNoon: !prev.fridayNoon,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <CheckBox
                isChecked={contractChanges.mondayAfternoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Afternoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondayAfternoon: !prev.mondayAfternoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.tuesdayAfternoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Afternoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdayAfternoon: !prev.tuesdayAfternoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.wednesdayAfternoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Afternoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdayAfternoon: !prev.wednesdayAfternoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.thursdayAfternoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Afternoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdayAfternoon: !prev.thursdayAfternoon,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={contractChanges.fridayAfternoon}
                isDisabled={formDisabled}
                name={t('GfzForms.Afternoon')}
                onChange={() => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridayAfternoon: !prev.fridayAfternoon,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.ContractChangesTotalPercent')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.mondayTotal}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    mondayTotal: target.value,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.tuesdayTotal}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    tuesdayTotal: target.value,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.wednesdayTotal}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    wednesdayTotal: target.value,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.thursdayTotal}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    thursdayTotal: target.value,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.fridayTotal}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    fridayTotal: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <CheckBox
        isChecked={contractChanges.groupChange}
        isDisabled={formDisabled}
        name={t('GfzForms.ContractChangesGroupChange')}
        onChange={() => {
          setContractChanges(prev => ({
            ...prev,
            groupChange: !prev.groupChange,
          }));
        }}
      />
      <table>
        <tbody>
          <tr>
            <td style={{ minWidth: '80px' }}>{t('GfzForms.ContractChangesExistingGroup')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.currentGroupName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    currentGroupName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.ContractChangesFutureGroup')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={contractChanges.futureGroupName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setContractChanges(prev => ({
                    ...prev,
                    futureGroupName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      {t('GFZForms.ContractChangesForwardDocs')}
      <br />
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureParents')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={contractChanges.parentSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setContractChanges(prev => ({
                ...prev,
                parentSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureKitaManagement')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasKita.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasKita}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={contractChanges.kitaSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled
            onChange={({ target }) => {
              setContractChanges(prev => ({
                ...prev,
                kitaSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      {noKidSelected && <div style={{ color: "red" }}><i className='fa fa-exclamation-triangle' /> {t('GFZForms.Please select a kid in the dropdown')}<br /></div>}
      <Button
        disabled={isCompleted || (!isAdmin && !isParentOfKid) || noKidSelected}
        onClick={() => {

          const parentSigned = !sigCanvasParent.current.isEmpty();
          const kitaSigned = !sigCanvasKita.current.isEmpty();

          let pdf = null;
          if (parentSigned && kitaSigned) {

            const pdfSaveData = {
              contractChanges: contractChanges,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
              parentSignature: removeWhiteBackgroundSignature(
                sigCanvasParent.current.getCanvas(),
              ).toDataURL(),
              kitaSignature: removeWhiteBackgroundSignature(
                sigCanvasKita.current.getCanvas(),
              ).toDataURL(),
            };

            pdf = createPDFContractChanges(pdfSaveData);
          }

          postGfzContractChanges({
            ...contractChanges,
            parentSignature: sigCanvasParent.current.isEmpty()
              ? null
              : sigCanvasParent.current.toDataURL(),
            kitaSignature: sigCanvasKita.current.isEmpty()
              ? null
              : sigCanvasKita.current.toDataURL(),
          }, pdf).then((res) => {
            store.dispatch(showBottomNotification('Gespeichert', { isFail: false }));
            resetForm();
            setActiveTab(0);
          });
        }}
        type='primary'
      >
        {t('GFZForms.ContractChangesSave')}
      </Button>
      &nbsp;
      <Button
        disabled={isCompleted || (!isAdmin && !isParentOfKid)}
        onClick={() => {
          sigCanvasKita.current.clear();
          sigCanvasParent.current.clear();
          setFormDisabled(false);
        }}
      >
        {t('GFZForms.EditFormAndResetSignatures')}
      </Button>
      &nbsp;
      {(isParentOfKid || isAdmin) && isExistingForKid && !isCompleted && (
        <Button
          onClick={() => {
            declineGfzContractChanges(selectedKid.id, contractChanges.id).then(() => {
              store.dispatch(showBottomNotification(t('GfzForms.Refused'), { isFail: false }));
              resetForm();
            });
          }}
        >
          {t('GFZForms.Decline')}
        </Button>
      )}
    </>
  );
}
