import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  declineGfzMedicalAuthorization,
  getAllKidsFromSelectedKita,
  getAllOwnKidsFromSelectedKita,
  getGfzMedicalAuthorization,
  postGfzMedicalAuthorization,
} from 'library/api/observationSheet';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Button from 'library/common/commonComponents/Buttons/Button';
import CheckBox from 'library/common/commonComponents/Checkbox';
import Dictaphone from 'library/common/commonComponents/Dictaphone';
import Input from 'library/common/commonComponents/Inputs/Input';
import Select from 'library/common/commonComponents/Inputs/Select';
import TextArea from 'library/common/commonComponents/Inputs/TextArea';
import { createPDFMedicalAuthorization } from 'library/utilities/files';
import { removeWhiteBackgroundSignature } from 'library/utilities/signature';
import store from 'main/store/configureStore';
import { onGfzFormSubmitted } from '../util.js';

import gfzLogo from 'resources/images/gfz/gfz_logo.png';

export default function MedicalAuthorization({ isAdminOrEmployee, userId, kidId, formId, setActiveTab }) {
  // console.log(isAdminOrEmployee);
  const [progress, setProgress] = useState(0);
  const [selectedKid, setSelectedKid] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [isExistingForKid, setIsExistingForKid] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);



  const [medicalAuthorization, setMedicalAuthorization] = useState({
    drugName: '',
    timeFrom: new Date().toISOString().split('T')[0],
    timeTo: new Date().toISOString().split('T')[0],
    checkboxMorningBefore: false,
    checkboxMorningDuring: false,
    checkboxMorningAfter: false,
    morningDosage: '',
    checkboxNoonBefore: false,
    checkboxNoonDuring: false,
    checkboxNoonAfter: false,
    noonDosage: '',
    checkboxAfternoonBefore: false,
    checkboxAfternoonDuring: false,
    checkboxAfternoonAfter: false,
    afternoonDosage: '',
    checkboxEveningBefore: false,
    checkboxEveningDuring: false,
    checkboxEveningAfter: false,
    eveningDosage: '',
    notes: '',
    parentSignature: null,
    parentSignatureDate: new Date().toISOString().split('T')[0],
    kitaSignature: null,
    kitaSignatureDate: new Date().toISOString().split('T')[0],
    kidId: null,
  });
  const sigCanvasParent = useRef();
  const sigCanvasKita = useRef();

  const noKidSelected = Array.isArray(selectedKid) && selectedKid.length === 0;

  const user = useSelector(state => state.userReducer);

  const getKitaKidsFunction = isAdminOrEmployee
    ? getAllKidsFromSelectedKita
    : getAllOwnKidsFromSelectedKita;
  const { t } = useTranslation();

  const [isParentOfKid, setIsParentOfKid] = useState(false);

  useEffect(() => {
    setIsParentOfKid(
      selectedKid &&
      selectedKid !== [] &&
      user.kids &&
      user.kids.find(k => k.id === selectedKid.id),
    );
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }, [selectedKid]);

  useEffect(() => {
    if (isParentOfKid) {
      sigCanvasParent.current.on();
    } else {
      sigCanvasParent.current.off();
    }

    if (isAdminOrEmployee) {
      sigCanvasKita.current.on();
    } else {
      sigCanvasKita.current.off();
    }
  }, [isParentOfKid, isAdminOrEmployee]);

  useEffect(() => {
    getKitaKidsFunction().then(data => {
      const unformattedKidsList = data.data;
      const formattedKidsList = unformattedKidsList.map(obj => {
        return {
          label: obj.firstName + ' ' + obj.lastName,
          id: isAdminOrEmployee ? obj.kidId : obj.id,
          portfolioGroupId: isAdminOrEmployee ? obj.portfolioGroupId : obj.groups.id,
          kidGroup: obj.kidGroup,
        };
      });
      setKidsList(formattedKidsList);
    });
    if (!isAdminOrEmployee && !isParentOfKid) {
      setFormDisabled(true);
    }
  }, []);

  const loadGfzMedicalAuthorizationsForKid = (kid, selectedFormId) => {
    if (!isAdminOrEmployee && !isParentOfKid) {
      setFormDisabled(false);
    }
    setIsCompleted(false);
    setIsExistingForKid(false);
    getGfzMedicalAuthorization(kid.id, selectedFormId).then(res => {
      if (res != null && res.data != null) {
        setIsExistingForKid(true);
        setMedicalAuthorization({
          ...res.data,
          kitaSignatureDate:
            res.data.kitaSignatureDate === null
              ? new Date().toISOString().split('T')[0]
              : res.data.kitaSignatureDate,
          parentSignatureDate:
            res.data.parentSignatureDate === null
              ? new Date().toISOString().split('T')[0]
              : res.data.parentSignatureDate,
        });

        if (res.data.parentSignature !== null) {
          sigCanvasParent.current.fromDataURL(res.data.parentSignature);
          setFormDisabled(true);
        }
        if (res.data.kitaSignature !== null) {
          sigCanvasKita.current.fromDataURL(res.data.kitaSignature);
          setFormDisabled(true);
        }
        if (res.data.parentSignature !== null && res.data.kitaSignature !== null) {
          setIsCompleted(true);
        }
      } else {
        setMedicalAuthorization({
          drugName: '',
          timeFrom: new Date().toISOString().split('T')[0],
          timeTo: new Date().toISOString().split('T')[0],
          checkboxMorningBefore: false,
          checkboxMorningDuring: false,
          checkboxMorningAfter: false,
          morningDosage: '',
          checkboxNoonBefore: false,
          checkboxNoonDuring: false,
          checkboxNoonAfter: false,
          noonDosage: '',
          checkboxAfternoonBefore: false,
          checkboxAfternoonDuring: false,
          checkboxAfternoonAfter: false,
          afternoonDosage: '',
          checkboxEveningBefore: false,
          checkboxEveningDuring: false,
          checkboxEveningAfter: false,
          eveningDosage: '',
          notes: '',
          parentSignature: null,
          parentSignatureDate: new Date().toISOString().split('T')[0],
          kitaSignature: null,
          kitaSignatureDate: new Date().toISOString().split('T')[0],
          kidId: kid.id,
        });
      }
    });
  };

  useEffect(() => {
    if (
      kidId != null && typeof kidId !== 'undefined' &&
      formId != null && typeof formId !== 'undefined' &&
      selectedKid.length === 0
    ) {
      const kidToSelect = kidsList.filter(obj => obj.id === kidId);
      if (kidToSelect.length !== 1) {
        return;
      }

      setSelectedKid(kidToSelect[0]);
      loadGfzMedicalAuthorizationsForKid(kidToSelect[0], formId);
    }
  }, [kidId, formId, kidsList]);

  function handleTextStateChange(newValue, property) {
    setMedicalAuthorization(prev => {
      return {
        ...prev,
        [property]: newValue,
      };
    });
  }

  function resetForm() {
    setMedicalAuthorization({
      drugName: '',
      timeFrom: new Date().toISOString().split('T')[0],
      timeTo: new Date().toISOString().split('T')[0],
      checkboxMorningBefore: false,
      checkboxMorningDuring: false,
      checkboxMorningAfter: false,
      morningDosage: '',
      checkboxNoonBefore: false,
      checkboxNoonDuring: false,
      checkboxNoonAfter: false,
      noonDosage: '',
      checkboxAfternoonBefore: false,
      checkboxAfternoonDuring: false,
      checkboxAfternoonAfter: false,
      afternoonDosage: '',
      checkboxEveningBefore: false,
      checkboxEveningDuring: false,
      checkboxEveningAfter: false,
      eveningDosage: '',
      notes: '',
      parentSignature: null,
      parentSignatureDate: new Date().toISOString().split('T')[0],
      kitaSignature: null,
      kitaSignatureDate: new Date().toISOString().split('T')[0],
      kidId: null,
    });
    setSelectedKid([]);
    sigCanvasParent.current.clear();
    sigCanvasKita.current.clear();
  }

  return (
    <>
      <img src={gfzLogo} style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alt='' />
      <br />
      <br />
      <h2>{t('GFZForms.MedicalAuthorizationMedicationAuthorization')}</h2>
      <br />
      <table>
        <tbody>
          <tr>
            <td>{t('GFZForms.MedicalAuthorizationKidName')}</td>
            <td>
              <Select
                options={kidsList}
                onSelect={e => {
                  setSelectedKid(e);
                  loadGfzMedicalAuthorizationsForKid(e, -1);
                }}
                selected={selectedKid}
                style={{ zIndex: '10000000000' }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.MedicalAuthorizationMedicamentName')}</td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={medicalAuthorization.drugName}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    drugName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.MedicalAuthorizationMedicamentBegin')}</td>
            <td>
              <Input
                type='date'
                value={medicalAuthorization.timeFrom}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    timeFrom: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>{t('GFZForms.MedicalAuthorizationMedicamentEnd')}</td>
            <td>
              <Input
                type='date'
                value={medicalAuthorization.timeTo}
                disabled={formDisabled}
                min={new Date().toISOString().split('T')[0]}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    timeTo: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      {t('GFZForms.MedicalAuthorizationMedicamentReturn')}

      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>
        {t('GfzForms.MedicalAuthorizationHandinAndDosage')}
      </span>
      <table style={{ borderWidth: '1px', borderColor: '#aaaaaa', borderStyle: 'solid' }}>
        <tbody>
          <tr style={{ backgroundColor: '#F2F2F2', fontWeight: 'bold' }}>
            <td style={{ minWidth: '75px' }} />
            <td>{t('GFZForms.MedicalAuthorizationBeforeTheMeal')}</td>
            <td>{t('GFZForms.MedicalAuthorizationWithTheMeal')}</td>
            <td>{t('GFZForms.MedicalAuthorizationAfterTheMeal')}</td>
            <td style={{ minWidth: '90px' }}>{t('GFZForms.MedicalAuthorizationDosage')}</td>
          </tr>
          <tr>
            <td style={{ minWidth: '75px' }}>{t('GFZForms.MedicalAuthorizationMorning')}</td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxMorningBefore}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxMorningBefore: !prev.checkboxMorningBefore,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxMorningDuring}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxMorningDuring: !prev.checkboxMorningDuring,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxMorningAfter}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxMorningAfter: !prev.checkboxMorningAfter,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={medicalAuthorization.morningDosage}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    morningDosage: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: '75px' }}>{t('GFZForms.MedicalAuthorizationNoon')}</td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxNoonBefore}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxNoonBefore: !prev.checkboxNoonBefore,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxNoonDuring}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxNoonDuring: !prev.checkboxNoonDuring,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxNoonAfter}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxNoonAfter: !prev.checkboxNoonAfter,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={medicalAuthorization.noonDosage}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    noonDosage: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: '75px' }}>{t('GFZForms.MedicalAuthorizationAfternoon')}</td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxAfternoonBefore}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxAfternoonBefore: !prev.checkboxAfternoonBefore,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxAfternoonDuring}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxAfternoonDuring: !prev.checkboxAfternoonDuring,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxAfternoonAfter}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxAfternoonAfter: !prev.checkboxAfternoonAfter,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={medicalAuthorization.afternoonDosage}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    afternoonDosage: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: '75px' }}>{t('GFZForms.MedicalAuthorizationEvening')}</td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxEveningBefore}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxEveningBefore: !prev.checkboxEveningBefore,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxEveningDuring}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxEveningDuring: !prev.checkboxEveningDuring,
                  }));
                }}
              />
            </td>
            <td>
              <CheckBox
                isChecked={medicalAuthorization.checkboxEveningAfter}
                isDisabled={formDisabled}
                onChange={() => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    checkboxEveningAfter: !prev.checkboxEveningAfter,
                  }));
                }}
              />
            </td>
            <td>
              <Input
                type='text'
                maxLength={50}
                value={medicalAuthorization.eveningDosage}
                disabled={formDisabled}
                onChange={({ target }) => {
                  setMedicalAuthorization(prev => ({
                    ...prev,
                    eveningDosage: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />
      <div style={{ position: 'relative', left: '5px', width: '55px' }}>
        <Dictaphone insText={() => { }} disabled={formDisabled} />
      </div>
      <br />
      <br />
      <span style={{ fontWeight: 'bold' }}>{t('GfzForms.MedicalAuthorizationNotes')}</span>
      <TextArea
        value={medicalAuthorization.notes}
        onChange={e => handleTextStateChange(e.target.value, 'notes')}
        disabled={formDisabled}
        type='text'
        placeholder={t('GFZForms.MedicalAuthorizationTextAreaPlaceholder')}
      />
      <br />
      <br />
      {t('GFZForms.MedicalAuthorizationAuthorizeAndAccept')}
      <br />
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureParents')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasParent.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasParent}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={medicalAuthorization.parentSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled={true}
            onChange={({ target }) => {
              setMedicalAuthorization(prev => ({
                ...prev,
                parentSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      <div
        style={{ width: '285px', border: '1px solid', borderColor: '#ededed', borderRadius: '5px' }}
      >
        <div style={{ fontWeight: '600', backgroundColor: '#ededed', padding: '5px' }}>
          {t('GfzForms.SignatureEmployees')}{' '}
          <i
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              sigCanvasKita.current.clear();
            }}
            className='fa fa-eraser'
          />
          <br />
          <br />
        </div>
        <div style={{ padding: '5px' }}>
          <SignatureCanvas
            ref={sigCanvasKita}
            penColor='black'
            canvasProps={{ width: 275, height: 91, className: 'sigCanvas' }}
          />
        </div>
        <div>
          <Input
            type='date'
            value={medicalAuthorization.kitaSignatureDate}
            min={new Date().toISOString().split('T')[0]}
            disabled={true}
            onChange={({ target }) => {
              setMedicalAuthorization(prev => ({
                ...prev,
                kitaSignatureDate: target.value,
              }));
            }}
          />
        </div>
      </div>
      <br />
      {noKidSelected && <div style={{ color: "red" }}><i className='fa fa-exclamation-triangle' /> {t('GFZForms.Please select a kid in the dropdown')}<br /></div>}
      <Button
        disabled={isCompleted || (!isAdminOrEmployee && !isParentOfKid) || noKidSelected}
        onClick={() => {

          const parentSigned = !sigCanvasParent.current.isEmpty();
          const kitaSigned = !sigCanvasKita.current.isEmpty();
          let pdf = null;
          if (parentSigned && kitaSigned) {

            const pdfSaveData = {
              medicalAuthorization: medicalAuthorization,
              selectedKid: selectedKid,
              setProgress: setProgress,
              userId: userId,
              parentSignature: removeWhiteBackgroundSignature(
                sigCanvasParent.current.getCanvas(),
              ).toDataURL(),
              kitaSignature: removeWhiteBackgroundSignature(
                sigCanvasKita.current.getCanvas(),
              ).toDataURL(),
            };

            pdf = createPDFMedicalAuthorization(pdfSaveData);
          }


          postGfzMedicalAuthorization({
            ...medicalAuthorization,
            parentSignature: sigCanvasParent.current.isEmpty()
              ? null
              : sigCanvasParent.current.toDataURL(),
            kitaSignature: sigCanvasKita.current.isEmpty()
              ? null
              : sigCanvasKita.current.toDataURL(),
          }, pdf).then((res) => {

            store.dispatch(showBottomNotification(t('GfzForms.Saved'), { isFail: false }));
            resetForm();
            setActiveTab(0);
          });
        }}
        type='primary'
      >
        {t('GFZForms.MedicalAuthorizationSave')}
      </Button>
      &nbsp;
      <Button
        disabled={isCompleted || !isAdminOrEmployee || !isParentOfKid}
        onClick={() => {
          sigCanvasKita.current.clear();
          sigCanvasParent.current.clear();
          setFormDisabled(false);
        }}
      >
        {t('GFZForms.EditFormAndResetSignatures')}
      </Button>
      &nbsp;
      {(isParentOfKid || isAdminOrEmployee) && isExistingForKid && !isCompleted && (
        <Button
          onClick={() => {
            declineGfzMedicalAuthorization(selectedKid.id, medicalAuthorization.id).then(() => {
              store.dispatch(showBottomNotification(t('GfzForms.Refused'), { isFail: false }));
              resetForm();
            });
          }}
        >
          {t('GFZForms.Decline')}
        </Button>
      )}
    </>
  );
}
