import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import styles from './foodOrderInvoice.module.scss';
import {
  formatMonthYearDateString,
  formatPrice,
  generateKitaInvoicePDF,
  getCurrentMonthForInput,
  hasGermanDateStringFormat,
} from '../../../../../../utilities/foodOrder';
import Button from '../../../../Buttons/Button';
import ButtonWithLoader from '../../../../Buttons/ButtonWithLoader';
import Loader from '../../../../Loader';
import Input from '../../../../Inputs/Input';
import {
  cancelAllFoodsByMonthAndKid,
  getSettings,
  kitaGetMonthlyBill,
  downloadMonthlyFoodOrdersCSV,
} from '../../../../../../api/foodOrder';
import ConfirmPopup from '../../../../Popups/ConfirmPopup';

export default function FoodOrderInvoice({ groupId, activeKita, groupInfo }) {
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthForInput());

  const [isLoading, setIsLoading] = useState(true);
  const [csvIsDownloading, setCsvIsDownloading] = useState(false);

  const [config, setConfig] = useState(null);

  const getInitialDetailsVisible = orders => {
    return orders.map(order => false);
  };
  const [isDetailsVisible, setIsDetailsVisible] = useState(getInitialDetailsVisible([]));
  const [data, setData] = useState([]);

  const [cancelKidFoods, setCancelKidFoods] = useState(null);

  const selectedMonthParts = selectedMonth.split('-');
  const year = selectedMonthParts[0];
  const monthName = moment()
    .month(parseInt(selectedMonthParts[1], 10) - 1)
    .locale('de-de')
    .format('MMMM');

  const getOrdersSum = orders => {
    let sum = 0.0;
    for (let i = 0; i < orders.length; i++) {
      sum += orders[i].price;
    }
    return sum;
  };

  const getTotalSum = orders => {
    let sum = 0.0;
    for (let i = 0; i < orders.length; i++) {
      sum += getOrdersSum(orders[i].orders);
    }
    return sum;
  };

  const downloadPDF = () => {
    generateKitaInvoicePDF(t, data, selectedMonth, activeKita.kitaName, groupInfo.groupName);
  };

  const toggleDetails = index => {
    const newDetailsVisible = [...isDetailsVisible];
    newDetailsVisible[index] = !newDetailsVisible[index];
    setIsDetailsVisible(newDetailsVisible);
  };

  const handleCancellation = () => {
    cancelAllFoodsByMonthAndKid(groupId, selectedMonth, cancelKidFoods.kidId)
      .then(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.FoodOrderCancelSuccess'), { isFail: false }),
        );
        setCancelKidFoods(null);
        reloadData();
      })
      .catch(() => {
        store.dispatch(
          showBottomNotification(t('BottomNotification.ErrorSavingTryAgain'), { isFail: true }),
        );
        setCancelKidFoods(null);
      });
  };

  const reloadData = () => {
    setIsLoading(true);
    let formatedDate = selectedMonth;
    if (!hasGermanDateStringFormat(selectedMonth)) {
      formatedDate = formatMonthYearDateString(selectedMonth);
    }
    kitaGetMonthlyBill(groupId, formatedDate).then(res => {
      setData(res.data);
    });

    // Remove timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    reloadData();
  }, [selectedMonth]);

  useEffect(() => {
    getSettings(groupId).then(res => {
      setConfig(res.data);
    });
  }, []);

  return (
    <>
      <ConfirmPopup
        isOpened={cancelKidFoods !== null}
        closePopup={() => setCancelKidFoods(null)}
        onConfirm={handleCancellation}
        text={
          cancelKidFoods
            ? t('FoodOrder.KitaCancelMonthlyWarning')
              .replace('{childName}', cancelKidFoods.kidName)
              .replace('{month}', monthName)
              .replace('{year}', year)
            : ''
        }
      />
      <br />
      <h2>{t('FoodOrder.Monthly Invoices')}</h2>
      <br />
      <label>{t('FoodOrder.Select month')}</label>
      <Input
        className={styles.monthSelect}
        type='month'
        onChange={e => {
          setSelectedMonth(e.target.value);
        }}
        value={selectedMonth}
      />
      <br />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Button onClick={() => downloadPDF()}>
            <i className='fa fa-file-pdf-o' /> {t('FoodOrder.export pdf')}
          </Button>{' '}
          <ButtonWithLoader
            onClick={() => {
              setCsvIsDownloading(true);
              downloadMonthlyFoodOrdersCSV(
                groupId,
                parseInt(selectedMonthParts[0]),
                parseInt(selectedMonthParts[1]),
              ).finally(() => {
                setCsvIsDownloading(false);
              });
            }}
            isLoading={csvIsDownloading}
          >
            <i className='fa fa-file-text-o' /> {t('FoodOrder.export csv')}
          </ButtonWithLoader>
          <br />
          <br />
          <table>
            <thead>
              <tr>
                <th className={styles.tableHeader}>{t('FoodOrder.Child')}</th>
                <th className={styles.tableHeader}>{t('FoodOrder.Food count')}</th>
                <th className={styles.tableHeader}>{t('FoodOrder.Total price')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data.map((child, index) => (
                <>
                  <tr onClick={() => toggleDetails(index)} className={styles.toggleVisibleTr}>
                    <td>{child.kidName}</td>
                    <td className={styles.tr}>x{child.orders.length}</td>
                    <td>
                      {formatPrice(getOrdersSum(child.orders))} €
                      <i
                        className={
                          !isDetailsVisible[index] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                        }
                      />
                    </td>
                    {config && config.enableCancellation && (
                      <td>
                        <Button onClick={() => setCancelKidFoods(child)}>
                          {t('FoodOrder.KitaCancel')}
                        </Button>
                      </td>
                    )}
                  </tr>
                  {child.orders.map(order => (
                    <>
                      <tr className={!isDetailsVisible[index] && styles.hidden}>
                        <td className={styles.tr}>{order.date}</td>
                        <td className={styles.tr}>
                          {order.name ? order.name : t('FoodOrder.No name')}
                        </td>
                        <td>{formatPrice(order.price)} €</td>
                        <td>{order.manualPayed && <b style={{ color: "#006400" }}>{t('FoodOrder.Is payed')}</b>}</td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
              <tr style={{ fontWeight: 'bold' }}>
                <td>{t('FoodOrder.Sum')}</td>
                <td />
                <td>{formatPrice(getTotalSum(data))} €</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
