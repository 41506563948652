import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { getPublicGroupList } from 'library/api/groups';
import Card from 'library/common/commonComponents/Card';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './publicGroups.module.scss';

const GroupNode = ({ group }) => {
  return (
    <div className={styles.groupItem}>
      <i className='fa fa-circle' />
      <Link className={styles.groupLink} to={`/groups/${group.id}`}>
        {group.groupName}
      </Link>
    </div>
  );
};

const KitaNode = ({ kita, groups, isChild = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={!isChild ? 'kita-node-with-content' : ''}>
      <div
        className={isChild ? styles.kitaItem : styles.nodeHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {kita}
      </div>
      {isOpen && (
        <div className={styles.contentPanel}>
          {groups.map(group => (
            <GroupNode key={group.id} group={group} />
          ))}
        </div>
      )}
    </div>
  );
};

const CityNode = ({ city, institutions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='city-node'>
      <div className={styles.nodeHeader} onClick={() => setIsOpen(!isOpen)}>
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {city}
      </div>
      {isOpen && (
        <div className={styles.contentPanel}>
          {Object.keys(institutions).map(kita => (
            <KitaNode key={kita} kita={kita} groups={institutions[kita]} isChild={true} />
          ))}
        </div>
      )}
    </div>
  );
};

const GroupTreeView = ({ groups }) => {
  const { withCity, withoutCity, only } = organizeAndSortGroups(groups);

  return (
    <div className='public-group-tree-view'>
      {!!Object.keys(withCity).length && (
        <>
          <Card className={styles.cardContainer}>
            <p className={styles.groupListTitle}>Entdecke hier alle AWO-Gruppen</p>
            {Object.keys(withCity).map(city => (
              <CityNode key={city} city={city} institutions={withCity[city]} />
            ))}
          </Card>
          <br />
        </>
      )}
      {/*<Card className={styles.cardContainer}>*/}
      {/*  <p className={styles.groupListTitle}>Gruppen in meinem Ortsverein</p>*/}
      {/*  {Object.keys(withoutCity).map(kita => (*/}
      {/*    <KitaNode key={kita} kita={kita} groups={withoutCity[kita]} isContentToggle={true} />*/}
      {/*  ))}*/}
      {/*</Card>*/}
      {/*{!!only.length && (*/}
      {/*  <>*/}
      {/*    <br />*/}
      {/*    <Card className={styles.cardContainer}>*/}
      {/*      {only.map(group => (*/}
      {/*        <GroupNode key={group} group={group} />*/}
      {/*      ))}*/}
      {/*    </Card>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

// Function to organize groups by city and kita
const organizeAndSortGroups = groups => {
  const organized = {
    withCity: {},
    withoutCity: {},
    only: [],
  };

  // Organize groups into the appropriate categories
  groups.forEach(group => {
    const { kitaName, kitaBelongedCity } = group;

    if (kitaBelongedCity) {
      // Group by city
      if (!organized.withCity[kitaBelongedCity]) {
        organized.withCity[kitaBelongedCity] = {};
      }

      // Group by kita
      if (!organized.withCity[kitaBelongedCity][kitaName]) {
        organized.withCity[kitaBelongedCity][kitaName] = [];
      }

      organized.withCity[kitaBelongedCity][kitaName].push(group);
    } else {
      if (kitaName) {
        // Group by kita if no city
        if (!organized.withoutCity[kitaName]) {
          organized.withoutCity[kitaName] = [];
        }
        organized.withoutCity[kitaName].push(group);
      } else {
        // Group undefined kita
        organized.only.push(group);
      }
    }
  });

  // Sort groups with city
  for (const city in organized.withCity) {
    for (const kita in organized.withCity[city]) {
      organized.withCity[city][kita].sort((a, b) => a.groupName.localeCompare(b.groupName));
    }
  }

  // Sort groups without city
  for (const kita in organized.withoutCity) {
    organized.withoutCity[kita].sort((a, b) => a.groupName.localeCompare(b.groupName));
  }

  // Sort only kita groups
  organized.only.sort((a, b) => a.groupName.localeCompare(b.groupName));

  // Combine all organized data into a single structure
  return {
    withCity: organized.withCity,
    withoutCity: organized.withoutCity,
    only: organized.only,
  };
};

export default function PublicGroups({ showBottomNotification, user, activeKita }) {
  useSiteTitle('Public groups');

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPublicGroupList = useCallback(() => {
    setIsLoading(true);
    getPublicGroupList().then(res => {
      setGroups(res.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchPublicGroupList();
  }, [fetchPublicGroupList]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className='public-group-list-page'>
          {isLoading ? <Loader /> : <GroupTreeView groups={groups} />}
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
