import React, { useState, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import cn from 'classnames';

import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import {
  getCalendarSettings,
  setCalendarSettings,
  getCalendarEventTypes,
  createEventType,
  updateEventType,
  deleteEventType,
  updateCalendarType,
} from 'library/api/calendar';
import CalendarSettingsDefaults from './CalendarSettingsFrames/CalendarSettingsDefaults';
import CalendarSettingsEventTypes from './CalendarSettingsFrames/CalendarSettingsEventTypes';
import CalendarSettingsOtherCalendars from './CalendarSettingsFrames/CalendarSettingsOtherCalendars';

import styles from './calendarSettings.module.scss';

export function reducer(state, action) {
  switch (action.type) {
    case 'setSettings':
      return { ...state, ...action.payload, maxEventItems: action.payload.maxEventItems || 5 };
    case 'setEventType':
      return { ...state, eventTypes: action.payload };
    case 'setCanParticipate':
      return { ...state, participationMode: action.payload.value };
    case 'setAllowDecline':
      return {
        ...state,
        participationStateDeclineAllowed: !state.participationStateDeclineAllowed,
      };
    case 'setAllowMaybe':
      return { ...state, participationStateMaybeAllowed: !state.participationStateMaybeAllowed };
    case 'setShowInStream':
      return { ...state, calendarDisplayedInStream: !state.calendarDisplayedInStream };
    case 'setEnableBirthdayNotification':
      return { ...state, enableBirthdayNotification: !state.enableBirthdayNotification };
    case 'setOtherCalendarEventTypes':
      return { ...state, calendarTypes: action.payload };
    case 'setSnippetShown':
      return { ...state, snippetShown: !state.snippetShown };
    case 'setUpcomingEventsIntervalDays':
      return { ...state, upcomingEventsIntervalDays: +action.payload.value };
    case 'setMaxEventItems':
      return { ...state, maxEventItems: action.payload };
    case 'setSortingOrder':
      return { ...state, sortingOrder: action.payload.value };
    case 'setTopMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled':
      return {
        ...state,
        // eslint-disable-next-line max-len
        topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled: !state.topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled,
      };
    default:
      return state;
  }
}

export function CalendarSettings({ location, match, showBottomNotificationFromProps }) {
  const calendarId = match.params.id;
  const { calendarModulePlace } = location.state || {};
  const [state, dispatch] = useReducer(reducer, {
    id: '',
    participationMode: 'EVERYBODY_CAN_PARTICIPATE',
    participationStateDeclineAllowed: true,
    participationStateMaybeAllowed: true,
    calendarDisplayedInStream: true,
    eventTypes: [],
    calendarTypes: [],
    maxEventItems: 5,
    snippetShown: true,
    sortingOrder: 'ASC',
    topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled: true,
    upcomingEventsIntervalDays: 1,
  });

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [submitiing, setSubmitting] = useState(false);

  const setSettings = () => {
    if (submitiing) {
      return;
    }
    setSubmitting(true);
    return setCalendarSettings(state)
      .then(res => {
        switch (res.status) {
          case 200:
            dispatch({
              type: 'setSettings',
              payload: {
                id: res.data.id,
                participationMode: res.data.participationMode,
                participationStateDeclineAllowed: res.data.participationStateDeclineAllowed,
                participationStateMaybeAllowed: res.data.participationStateMaybeAllowed,
                calendarDisplayedInStream: res.data.calendarDisplayedInStream,
                maxEventItems: res.data.maxEventItems,
                snippetShown: res.data.snippetShown,
                topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled:
                  res.data.topMenuAndSnippetShownOnlyIfCalendarModuleIsInstalled,
                upcomingEventsIntervalDays: res.data.upcomingEventsIntervalDays,
                enableBirthdayNotification: res.data.enableBirthdayNotification
              },
            });
            setSubmitting(false);
            showBottomNotificationFromProps(t('BottomNotifications.Saved'));
            return {
              success: true,
              error: '',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 401:
            setSubmitting(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const addEventType = newEventType => {
    setSubmitting(true);
    return createEventType(state.id, newEventType)
      .then(res => {
        switch (res.status) {
          case 200:
            setSubmitting(false);
            return {
              event: res.data,
              success: true,
              error: '',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 401:
            setSubmitting(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const deleteEvent = deletedEventType => {
    setSubmitting(true);
    return deleteEventType(deletedEventType.id)
      .then(res => {
        switch (res.status) {
          case 200:
            setSubmitting(false);
            return {
              success: true,
              error: '',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 401:
            setSubmitting(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const updateEvent = updatedEventType => {
    setSubmitting(true);
    return updateEventType(updatedEventType)
      .then(res => {
        switch (res.status) {
          case 200:
            setSubmitting(false);
            return {
              success: true,
              error: '',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 401:
            setSubmitting(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const updateOtherType = updatedType => {
    if (submitiing) {
      return;
    }
    setSubmitting(true);
    return updateCalendarType(updatedType)
      .then(res => {
        switch (res.status) {
          case 200:
            setSubmitting(false);
            showBottomNotificationFromProps(t('BottomNotifications.Saved'));
            return {
              success: true,
              error: '',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      })
      .catch(err => {
        switch (err.status || err.response.status) {
          case 401:
            setSubmitting(false);
            return {
              success: false,
              error: 'Unauthorized',
            };
          default:
            setSubmitting(false);
            return {
              success: false,
              error: 'error',
            };
        }
      });
  };

  const tabs = [
    {
      title: t('Calendar.Defaults'),
      path: 'defaults',
      component: (
        <CalendarSettingsDefaults
          calendarId={calendarId}
          submitiing={submitiing}
          calendarSettingsState={state}
          calendarSettingsDispatch={dispatch}
          onSubmit={setSettings}
          getSettings={getCalendarSettings}
          calendarModulePlace={calendarModulePlace}
        />
      ),
    },
    {
      title: t('Calendar.Event Types'),
      path: 'eventTypes',
      component: (
        <CalendarSettingsEventTypes
          calendarId={calendarId}
          submitiing={submitiing}
          calendarSettingsState={state}
          calendarSettingsDispatch={dispatch}
          onCreate={addEventType}
          onDelete={deleteEvent}
          onEdit={updateEvent}
          getEventTypes={getCalendarEventTypes}
          calendarModulePlace={calendarModulePlace}
        />
      ),
    },
    {
      title: t('Calendar.Other Calendars'),
      path: 'otherCalendars',
      component: (
        <CalendarSettingsOtherCalendars
          calendarId={calendarId}
          submitiing={submitiing}
          calendarSettingsState={state}
          calendarSettingsDispatch={dispatch}
          onSubmit={updateOtherType}
        />
      ),
    },
  ];

  return (
    <Card
      title={<Trans i18nKey='Calendar.Calendar Module-settings' />}
      className={cn(styles.body, calendarModulePlace === 'kitaCalendar' && styles.kitaCalendar)}
    >
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}

export default connect(
  null,
  {
    showBottomNotificationFromProps: showBottomNotification,
  },
)(CalendarSettings);
