import React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'library/common/commonComponents/Popups/Popup';
import Button from 'library/common/commonComponents/Buttons/Button';
import { formatDate } from 'library/utilities/dateMoment';
import styles from './groupAbsenceKidPopup.module.scss';

export default function GroupAbsenceKidPopup({ popupData, popupOpen, setSelectedKidForPopup }) {
  const { t } = useTranslation();
  return (
    <>
      <Popup
        isOpened={popupOpen}
        closePopup={() => setSelectedKidForPopup({})}
        header={t('GroupAbsence.Absence summary')}
        footer={
          <div>
            <div className={styles.tableColumn}>
              <div>
                <div className={styles.tableFirstColumn}>{t('GroupAbsence.Kid name')}:</div>
                <div>{`${popupData.firstName} ${popupData.lastName}`}</div>
              </div>
              {popupData.reason && popupData.reason !== '' && (
                <div>
                  <div className={styles.tableFirstColumn}>{t('GroupAbsence.Reason')}:</div>
                  <div>{popupData.reason}</div>
                </div>
              )}
              {popupData.sickness && popupData.sickness !== '' && (
                <div>
                  <div className={styles.tableFirstColumn}>{t('GroupAbsence.Sickness')}:</div>
                  <div>{popupData.sickness}</div>
                </div>
              )}
              <div>
                <div className={styles.tableFirstColumn}>{t('GroupAbsence.Reported by')}:</div>
                <div>{popupData.reportedBy}</div>
              </div>
              <div>
                <div className={styles.tableFirstColumn}>{t('GroupAbsence.Duration')}:</div>
                <div>{`${formatDate(popupData.startDate)} - ${formatDate(popupData.endDate)}`}</div>
              </div>
              <div>
                <div className={styles.tableFirstColumn}>{t('GroupAbsence.Confirmation')}:</div>
                <div>
                  {popupData.returnReceiptDate !== null
                    ? t('Absences.ReturnReceipt.Confirmed')
                        .replace('{date}', formatDate(popupData.returnReceiptDate))
                        .replace('{user}', popupData.returnReceiptName)
                    : t('Absences.ReturnReceipt.NotConfirmed')}
                </div>
              </div>
            </div>
            <br />
            <div>
              <Button onClick={() => setSelectedKidForPopup({})}>{t('GroupAbsence.Cancel')}</Button>
            </div>
          </div>
        }
      />
    </>
  );
}
