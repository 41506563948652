import React, { useState, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';

import GallerySnippet from 'library/common/commonComponents/Toggleable/GallerySnippet';
import Members from 'library/common/commonComponents/Toggleable/Members';
import CreatePostInput from 'library/common/commonComponents/Inputs/CreatePostInput/CreatePostInputContainer';
import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import useLoadMore from 'library/common/commonHooks/useLoadMore';
import { GroupContext } from 'modules/Group/groupModule';
import { loadGroupMembers } from 'library/api/groups';
import { isNotOnlyPublicKitaMember } from 'library/utilities/kitaChecks';
import { isPublicUser } from 'library/utilities/user';

import styles from './groupStream.module.scss';

export default function GroupStream({ groupInfo, isUserAMember, userLastName, match, history, activeKita }) {
  const { groupId, userList, postCount, globalPublicGroup } = groupInfo;
  const [kitaListLoaded, setKitaListLoaded] = useState(false);
  const user = useSelector(state => state.userReducer);

  useEffect(() => {
    if (!kitaListLoaded) {
      isNotOnlyPublicKitaMember().then(result => {
        if (!result) {
          groupInfo.addModifyGallery = false;
          groupInfo.addNewFiles = false;
          groupInfo.createCalenderEntry = false;
          groupInfo.createCommentStatus = false;
          groupInfo.createPostStatus = false;
          groupInfo.createSurvey = false;
          groupInfo.createTask = false;
          groupInfo.editDeleteCalenderEntry = false;
          groupInfo.editDeleteTask = false;
          groupInfo.editSurvey = false;
        }
        setKitaListLoaded(true);
      });
    }
  }, [kitaListLoaded]);

  const groupContext = useContext(GroupContext);
  const patchGroupInfo = groupContext.patchGroup;

  const [newPost, setNewPost] = useState(null);

  const { items, totalCount, itemsAreLoading, noMoreItems, loadItems } = useLoadMore({
    request: (data, params) => loadGroupMembers(groupId, data, params),
    pageSize: 14,
  });

  function onCreate(post) {
    setNewPost({
      ...post,
      group: groupInfo,
    });
    patchGroupInfo({ postCount: postCount + 1 });
  }

  const isPrivatePostsOnly = groupInfo.visibility;

  const { location } = useReactRouter();
  const postId = location.search.slice(1);
  return (
    <>
      <div className={styles.main}>
        {groupInfo.createPostStatus && (
          <CreatePostInput
            groupId={groupId}
            group={groupInfo}
            isPrivatePostsOnly={false}
            onCreate={onCreate}
            activeKita={activeKita}
          />
        )}
        {groupId > 0 && (
          <Feed
            match={match}
            groupId={groupId}
            isInPublicPage={isPublicUser(user)}
            onPostDelete={() => patchGroupInfo({ postCount: postCount - 1 })}
            createCommentStatus={groupInfo.createCommentStatus}
            isPrivatePostsOnly={false}
            isPublicPostsOnly={false}
            newPost={newPost}
            group={groupInfo}
            postId={postId}
          />
        )}
      </div>

      {(groupInfo.gallerySnippet.files ||
        (items.length > 0 && !globalPublicGroup && groupId !== 2093 && groupId !== 1175786483)) && (
          <div className={styles.sidebar}>
            {(isUserAMember && userLastName !== "Caterer") && (
              <>
                <GallerySnippet
                  gallerySnippet={groupInfo.gallerySnippet}
                  match={match}
                  history={history}
                  groupInfo={groupInfo}
                />
                {totalCount > 0 && !globalPublicGroup && +groupId !== 2093 && (
                  <Members
                    title={
                      <>
                        <Trans i18nKey='SpaceMembers' /> ({totalCount})
                      </>
                    }
                    uniqName='spaceMembers'
                    members={items}
                    loadMore={loadItems}
                    loading={itemsAreLoading}
                    noMoreMembers={noMoreItems}
                    groupInfo={groupInfo}
                  />
                )}
              </>
            )}
          </div>
        )}
    </>
  );
}
