import React, { useState } from 'react';
import defaultAvatar from 'resources/images/user/default-avatar.jpg';
import { useTranslation } from 'react-i18next';
import Input from 'library/common/commonComponents/Inputs/Input';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import styles from './employeeItem.module.scss';
import cn from 'classnames';
import Loader from 'library/common/commonComponents/Loader';

export default function EmployeeItem({ data, className, handleSaveButton }) {
  const { t } = useTranslation();
  const [vacationDays, setVacationDays] = useState(data.employeeInformation?.vacationDays);
  const [subtractLegalBreak, setSubtractLegalBreak] = useState(
    data.employeeInformation?.subtractLegalBreak,
  );
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={cn(className, styles.employeeItemContainer)}>
      <div className={styles.profile}>
        <div>
          <img
            className={styles.profileAvatar}
            src={data.user.modifiedProfileLogoUrl || defaultAvatar}
          />
        </div>
        <div className={styles.profileName}>{`${data.user.firstName} ${data.user.lastName}`}</div>
      </div>
      <div className={styles.employeeInfoContainer}>
        <div className={styles.holidaysInputContainer}>
          <div className={styles.holidaysInputWrapper}>
            <label htmlFor='numberDays'>{t('Administration.UserSection.Holidays')}</label>
            {data.employeeInformation ? (
              <span className={styles.takenVacation}>
                {`${data.employeeInformation.vacationDays -
                  data.employeeInformation.takenVacation} /`}
              </span>
            ) : (
              <span className={styles.takenVacation}>0 /</span>
            )}
            <Input
              id='numberDays'
              type='number'
              min='0'
              step='1'
              value={vacationDays ? vacationDays : 0}
              onChange={e => setVacationDays(parseInt(e.target.value, 10))}
              className={styles.holidaysInput}
            />
          </div>
        </div>
        <Checkbox
          isChecked={subtractLegalBreak}
          onChange={() => setSubtractLegalBreak(prev => !prev)}
          className={styles.subtractLegalBreakCheckbox}
          name={t(
            'Administration.Subtract legal break from working time and consider manual break',
          )}
        />
      </div>
      <div className={styles.saveButtonWrapper}>
        <Button
          onClick={() => {
            handleSaveButton(setIsLoading, {
              userId: data.user.id,
              vacationDays: vacationDays,
              subtractLegalBreak: subtractLegalBreak,
            });
          }}
          type='primary'
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader isSmall={true} color='#fff' />
          ) : (
            <>
              <i className='fa fa-check' aria-hidden='true' />{' '}
              {t('Administration.UserSection.Save')}
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
